import React, { useContext, useState } from 'react';
import { Menu, Button, Flex,  Typography, Image, Drawer, message, Modal, Form, Input, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { UserContext } from '../../contexts/UserContext';
import HamburgerIcon from "../../images/menu-icon-white.svg";
import { openNotification } from '../../utils/utils';
import AvatarImage from '../AvatarImage';
import './sidebar.css';

import {
  LogoutOutlined,
} from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

const AudienceHeaderCreatorInfo = ({ menuItems = [] }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const { setAuth } = useContext(AuthContext);
  const {user}  = useContext(UserContext) || {};
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [reportreasons, setReportreasons]=useState(null);
  const [selectedElaborate, setSelectedElaborate] = useState('');
  const [charactersLeft, setCharactersLeft] = useState(250);
  const maxLength = 250;

  const [modalForm] = Form.useForm();

  const handleLogout = () => {
    setIsLogout(true);
  };

  const logoutHandle = () => {
    localStorage.removeItem('token');
    openNotification('Logged out successfully!');
    setAuth(false);
    navigate('/');
  }
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };


  const handleCancel = () => {
    setIsLogout(false);
  };

  const toggleDrawer = () => {
    setDrawerVisible(!isDrawerVisible);
  };

  const onFinish = () => {
      console.log('Reported:');
      setDrawerVisible(!isDrawerVisible);
      openNotification("Report submit!");
  };  

  const handleElaborateChange = (e) => {
    setSelectedElaborate(e.target.value);
    const currentLength = e.target.value.length;
    setCharactersLeft(maxLength - currentLength);
  };

  return (<>
    <Flex className="audience-head-trans">
      <Flex gap={10} align="center" horizontal="true" style={{ width: '100%' }}>
        {user && <>
          <Button type="link" onClick={showDrawer} style={{ padding: '20px 13px' }}>
            <Image width={20} src={HamburgerIcon} preview={false} />
          </Button>
          <AvatarImage bgColor="#000000" size={40} title={`${user?.first_name} ${user?.last_name}`} Url={user?.profileUrl}/>
          <Title level={4} style={{ color: '#ffffff', margin: 0, fontSize: 16 }}>{user?.first_name} {user?.last_name}</Title> 
        </>}
        <Button onClick={toggleDrawer} type="text" style={{ background: 'transparent', fontWeight: 600, color: '#ffffff', marginLeft: 'auto' }}>Report</Button>
      </Flex>
    </Flex>
    {user && 
    <Drawer
      placement="left"
      width={310}
      onClose={onClose}
      open={open}
      className="mobile-drawer-sidebar"
    >
      <Flex horizontal="true" align="center" gap={15}>
      
         <AvatarImage size={40} title={`${user?.first_name} ${user?.last_name}`} Url={user?.profileUrl}/>
        <Flex vertical>
          <Text style={{ fontWeight: 600 }}>{user?.first_name} {user?.last_name}</Text>
          {user?.user_name && <Text>@{user?.user_name}</Text>}
        </Flex>
      </Flex>
      <Menu className="sidebar-menu main-menu" theme="light" mode="inline" defaultSelectedKeys={['1']}>
        {menuItems.map((item) => (
          <Menu.Item key={item.key} tr={item.path}>
            <Link to={item.path}  onClick={()=>{ setOpen(false);}}>
              {item.icon}
              <Text className="menu-text">{item.label}</Text>
            </Link>
          </Menu.Item>
        ))}
      </Menu>

      <Menu className="sidebar-menu bottom-menu" theme="light" mode="inline" defaultSelectedKeys={['1']}>
        <Menu.Item key="11" icon={<LogoutOutlined />} onClick={handleLogout}>
          <Text>Logout</Text>
        </Menu.Item>
      </Menu>
    </Drawer>}
    <Modal keyboard={false} maskClosable={false} className="logout-confirm" open={isLogout} onOk={logoutHandle} onCancel={handleCancel} width={480} footer={[
        <Flex gap={10} justify="center" style={{ marginTop: 30, marginBottom: 20 }}>
          <Button key="cancel" size="large" onClick={handleCancel} style={{ fontWeight: 600 }}>Cancel</Button>
          <Button key="add" size="large" 
          type="primary" 
          onClick={logoutHandle} 
          style={{ fontWeight: 600 }}
          >Yes, log me out</Button>
        </Flex>
      ]}>
        <Title level={4} style={{ textAlign: 'center' }}>Are you sure that you want to logout?</Title>
      </Modal>

      <Drawer
        placement="bottom"
        closable={true}
        onClose={toggleDrawer}
        open={isDrawerVisible}
        height="90%"
        bodyStyle={{ padding: '0px' }}
        className="report-drawer"
      > 
        <Form form={modalForm}>
          <Flex justify="space-between">
            <Title level={4} style={{ fontSize: '18px', marginTop: 5, marginBottom: 10 }}>Report {user?.first_name} {user?.last_name} ?</Title>
             <Button
              type="text"
              icon={<CloseOutlined style={{ color: '#101828' }}/>}
              onClick={toggleDrawer}
              style={{ padding: 0, fontWeight: 600, marginBottom: 5, background: 'transparent' }}
            ></Button>
          </Flex>
          <Paragraph style={{ marginBottom: 10 }}>The contents of this conversation will be flagged to our Trust and Safety team. Please select a reason.</Paragraph>
          <Form.Item 
            name="reason"
            label="Reason"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-item"
          >
            <Select 
              onChange={(value) => {
                setReportreasons(value);
              }} 
            placeholder="Select one" style={{ width: '100%' }}>
              <Select.Option value="spam">Spam</Select.Option>
              <Select.Option value="abusive">Abusive Language</Select.Option>
              <Select.Option value="harassment">Harassment</Select.Option>
              <Select.Option value="hate_speech">Hate Speech</Select.Option>
              <Select.Option value="explicit_content">Explicit Content</Select.Option>
              <Select.Option value="violence">Violence</Select.Option>
              <Select.Option value="discrimination">Discrimination</Select.Option>
              <Select.Option value="fake_information">Fake Information</Select.Option>
              <Select.Option value="phishing">Phishing</Select.Option>
              <Select.Option value="malware">Malware/Links to malicious content</Select.Option>
              <Select.Option value="scam">Scam</Select.Option>
              <Select.Option value="inappropriate_name">Inappropriate Username</Select.Option>
              <Select.Option value="underage">Underage User</Select.Option>
              <Select.Option value="inappropriate_profile_picture">Inappropriate Profile Picture</Select.Option>
              <Select.Option value="self_harm">Self-Harm/Threatening Behavior</Select.Option>
              <Select.Option value="impersonation">Impersonation</Select.Option>
              <Select.Option value="illegal_activities">Illegal Activities</Select.Option>
            </Select>
          </Form.Item>
          <p>Only the Blonde team will see your response</p>

          {reportreasons &&
            <Form.Item 
              className="custom-form-input" 
              style={{ marginBottom: 20 }}>
              <TextArea
                maxLength={maxLength}
                onChange={handleElaborateChange}
                placeholder="Please elaborate..."
                style={{
                  height: 128,
                  resize: 'none',
                  marginBottom: 0
                }}
              />
              <Text style={{ marginTop: 4, display: 'block' }}>{charactersLeft} characters left</Text>
            </Form.Item>
          }
          <Flex className="ant-modal-footer" gap={15} style={{ marginTop: 'auto' }}>
            <Button key="cancel" size="large" style={{ fontWeight: 600 }}>Cancel</Button>
            <Button key="report" size="large" type="primary" danger style={{ fontWeight: 600 }}>Report</Button>
          </Flex>
        </Form>
      </Drawer>
  </>);
};

export default AudienceHeaderCreatorInfo;
