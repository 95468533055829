// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-grid-style{
	background: rgb(255, 255, 255);
    border: 1px solid rgb(234, 236, 240);
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 18px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    gap: 15px;
}
.row-grid-style:hover{
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.09);
    transform: scale(1.015);
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/home.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;IAC3B,oCAAoC;IACpC,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,4BAA4B;IAC5B,eAAe;IACf,SAAS;AACb;AACA;CACC,0CAA0C;IACvC,uBAAuB;AAC3B","sourcesContent":[".row-grid-style{\n\tbackground: rgb(255, 255, 255);\n    border: 1px solid rgb(234, 236, 240);\n    border-radius: 10px;\n    padding: 15px;\n    margin-bottom: 18px;\n    transition: 0.2s ease-in-out;\n    cursor: pointer;\n    gap: 15px;\n}\n.row-grid-style:hover{\n\tbox-shadow: 0 2px 10px rgba(0, 0, 0, 0.09);\n    transform: scale(1.015);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
