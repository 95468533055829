import React, { useState, useRef, useEffect } from 'react';
import { Flex, Input, Button, Image, Upload, Dropdown, Tabs } from 'antd';
import { CameraOutlined, PictureOutlined, LockOutlined, PlusOutlined, SmileOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import ContentVaultUpload from './ContentVaultUpload';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react';
import Gifs from './Gifs'; // Import Gifs component
import useScreenSize from '../../utils/useScreenSize';
import CameraIcon from '../../images/camera-02.svg';
import PictureIcon from '../../images/image-01.svg';
import UploadIcon from '../../images/upload-01.svg';
import LockIcon from '../../images/lock-icon2.svg';

const { TextArea } = Input;

const ChatInputNew = ({ onInputChange, onImagesChange, isShow = true, setIsPriceModalVisible, setIsScheduleModalVisible }) => {
  const [message, setMessage] = useState('');
  const [fileList, setFileList] = useState([]);
  const [isContentVaultVisible, setContentVaultVisible] = useState(false);
  const [selectedVaultImages, setSelectedVaultImages] = useState([]);
  const [isEmojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [gifsVisible, setGifsVisible] = useState(false); // GIF state
  const [searchTerm, setSearchTerm] = useState('');
  const { isMobile } = useScreenSize();

  const textAreaRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const WORD_LIMIT = 400;

  const countWords = (text) => {
    return text.trim().split(/\s+/).filter(word => word.length > 0).length;
  };

  const stopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(()=>{
    if(onImagesChange){
      onImagesChange(fileList); 
    }
  },[fileList]);
  
  const handleEmojiSelect = (emojiData) => {
    const cursorPosition = textAreaRef.current?.resizableTextArea.textArea.selectionStart || 0;
    const newMessage = 
      message.slice(0, cursorPosition) + 
      emojiData.native + 
      message.slice(cursorPosition);
    setMessage(newMessage);
    if (onInputChange) {
      onInputChange(newMessage);
    }
    setEmojiPickerVisible(false);
    setTimeout(() => {
      textAreaRef.current?.focus();
    }, 0);
  };

  const insertPlaceholder = (placeholder) => {
    const cursorPosition = textAreaRef.current?.resizableTextArea?.textArea.selectionStart || 0;
    const newMessage = 
      message.slice(0, cursorPosition) + 
      `{${placeholder}}` + 
      message.slice(cursorPosition);
      
    setMessage(newMessage);
    if (onInputChange) {
      onInputChange(newMessage);
    }
    setTimeout(() => {
      const newCursorPosition = cursorPosition + placeholder.length + 2;
      textAreaRef.current?.resizableTextArea?.textArea.setSelectionRange(newCursorPosition, newCursorPosition);
      textAreaRef.current?.focus();
    }, 0);
  };

  const handleTabChange = (key) => {
    if (key === 'emoji') {
      setGifsVisible(false);
    } else if (key === 'gif') {
      setGifsVisible(true);
    }
  };

  const handleGifSelect = (gif) => {
    const newGifFile = {
      uid: `gif-${Date.now()}-${Math.random()}`,
      id: Date.now(),
      file: gif || null,
      type: 'gif',
      preview: gif || null,
      name: `gif_${Date.now()}.gif`
    };
    setFileList([newGifFile]);
    setEmojiPickerVisible(false);
    
    if (onImagesChange) {
      const updatedFiles = [newGifFile].map(file => ({
        id: file.uid,
        url: file.file || file.url,
        file: file.file,
        source: file.source || 'gif',
        type: file.type
      }));
      onImagesChange(updatedFiles);
    }
  };

  const handleImageUpdate = (newImage, source = 'upload') => {
    const processImage = (image) => ({
      uid: image.uid || `${source}-${Date.now()}-${Math.random()}`,
      name: image.name || `${source}-image`,
      status: 'done',
      url: image.url || (image.originFileObj ? URL.createObjectURL(image.originFileObj) : image.thumbUrl),
      thumbUrl: image.thumbUrl || image.url,
      file: image.originFileObj,
      source: source,
      type: 'image'
    });
    
    const processedImage = processImage(newImage);
    setFileList([processedImage]);
    
    if (onImagesChange) {
      const updatedFiles = [processedImage].map(file => ({
        id: file.uid,
        url: file.url,
        file: file.file,
        source: file.source,
        type: file.type
      }));
      onImagesChange(updatedFiles);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      const input = e.target;
      const cursorPosition = input.selectionStart;

      // Check if the cursor is at the start of `{First Name}`
      if ( message.slice(cursorPosition - 12, cursorPosition) === '{First Name}' || message.slice(cursorPosition - 11, cursorPosition) === '{First Name') {
        // Remove `{First Name}` if cursor is positioned right before it
        const beforeCursor = message.slice(0, cursorPosition - 11);
        const afterCursor = message.slice(cursorPosition);

        // Update the value state by removing `{First Name}`
        setMessage(beforeCursor + afterCursor);
      }
    }
  };

  const handleMessageChange = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
    if (onInputChange) {
      onInputChange(newMessage);
    }

    if (newMessage.length <= WORD_LIMIT) {
      setMessage(newMessage);
    }

  };

  const remainingWords = WORD_LIMIT - message.length;
  const isLimitReached = remainingWords === 0;

  const handleUploadChange = ({ fileList: newFileList }) => {
    const latestFile = newFileList[newFileList.length - 1];
    if (latestFile) {
      handleImageUpdate(latestFile, 'upload');
    }
  };

  const handleContentVaultData = (selectedImages) => {
    setSelectedVaultImages(selectedImages);
  };

  const handleAddContentVault = () => {
    if (selectedVaultImages.length > 0) {
      const image = selectedVaultImages[0];
      const formattedImage = {
        uid: `vault-${Date.now()}-${Math.random()}`,
        name: image || 'vault-image',
        url: `${process.env.REACT_APP_API_BASE_URL}/${image}`,
        thumbUrl: image
      };
      handleImageUpdate(formattedImage, 'vault');
    }    
    setContentVaultVisible(false);
  };

   const handleRemoveImage = (uid) => {
    const updatedFileList = fileList.filter(file => file.uid !== uid);
    setFileList(updatedFileList);
    setIsPriceModalVisible(false);
    
    if (!message || message.trim() === '') {
      setIsScheduleModalVisible(false);
    }
    // if (onImagesChange) {
    //   const processedImages = updatedFileList.map(file => ({
    //     id: file.uid,
    //     url: file.type === 'gif' ? file.file : file.url,
    //     file: file.file,
    //     source: file.source || (file.type === 'gif' ? 'gif' : 'upload'),
    //     type: file.type
    //   }));
    //   onImagesChange(processedImages);
    // }
  };

  // UI Components
  const uploadButton = (
    <Button 
      type="text" 
      icon={<Image src={UploadIcon} preview={false} />} 
      style={{ padding: 0, background: 'transparent', height: 'auto' }}
    >
      Upload
    </Button>
  );

  const uploadPhoto=( <Button 
    type="text" 
    icon={<Image src={CameraIcon} preview={false} />} 
    style={{ padding: 0, background: 'transparent', height: 'auto' }}
  >
    Camera
  </Button>);

  const EmojiPickerWithClickPrevention = () => (
    <div 
      ref={emojiPickerRef}
      onClick={stopPropagation}
      onMouseDown={stopPropagation}
      onKeyDown={stopPropagation}
      style={{ padding: '10px' }}
    >
      <Picker
        data={data}
        onEmojiSelect={handleEmojiSelect}
        previewPosition="none"
        skinTonePosition="none"
        searchPosition="sticky"
        maxFrequentRows={2}
        perLine={8}
        theme="light"
        autoFocus={true}
      />
    </div>
  );

  const tabItems = [
    {
      key: 'emoji',
      label: 'Emoji',
      children: <EmojiPickerWithClickPrevention />
    },
    {
      key: 'gif',
      label: 'GIF',
      children: (
        <Gifs
          gifsVisible={gifsVisible}
          setGifsVisible={setGifsVisible}
          onSelectGifs={handleGifSelect}
          searchTerm={searchTerm}
        />
      )
    }
  ];

  const dropdownItems = [
    {
      label:(
        <Upload
          accept="image/*"
          multiple={false}
          showUploadList={false}
          beforeUpload={() => false}
          onChange={handleUploadChange}
          capture="camera"
        >
          {uploadPhoto}
        </Upload>
      ),
      key: '0',
    },
    {
      label: (
        <Upload
          accept="image/*"
          multiple={false}
          showUploadList={false}
          beforeUpload={() => false}
          onChange={handleUploadChange}
        >
          {uploadButton}
        </Upload>
      ),
      key: '1',
    },
    {
      label: isShow && (
        <Button 
          type="text" 
          onClick={() => setContentVaultVisible(true)} 
          icon={<Image src={PictureIcon} preview={false} />} 
          style={{ padding: 0, background: 'transparent', height: 'auto' }}
        >
          Gallery
        </Button>
      ),
      key: '2',
    },
  ];

  const dropdownOverlay = (
    <div
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Tabs 
        defaultActiveKey="emoji" 
        items={tabItems} 
        onChange={handleTabChange}
        style={{ background: 'white', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0,0,0,0.15)' }}
      />
    </div>
  );

  const VideoURLChecker = (videoURL) => {
    if (!videoURL) return false;
    if (typeof videoURL !== 'string') return false;
    const SUPPORTED_VIDEO_EXTENSIONS = [
      '.mp4', 
      '.avi', 
      '.mov', 
      '.wmv', 
      '.flv', 
      '.webm', 
      '.mkv', 
      '.m4v', 
      '.mpeg', 
      '.mpg'
    ];
    try {
      const normalizedURL = String(videoURL || '');
      return SUPPORTED_VIDEO_EXTENSIONS.some(ext => 
        normalizedURL.toLowerCase().endsWith(ext)
      );
    } catch (error) {
      console.error('Error in VideoURLChecker:', error);
      return false;
    }
  };

  return (<>
    <Flex vertical className="message-inner-box" style={{ background: '#ffffff', borderRadius: '6px', border: '1px solid #d9d9d9' }}>
      {fileList.length > 0 && (
        <Flex gap={10} style={{ padding: '0 10px', marginTop: 10, marginBottom: 2 }}>
          {fileList.map((file) => (
            <Flex key={file.uid} className="images-grid" style={{ position: 'relative' }}>
              {VideoURLChecker(file?.name)?
                  <ReactPlayer width="100%" height="100px" url={file?.url} controls />
                 : <Image 
                    src={file.type === 'gif' ? file.file : file.url}
                    alt={file.name}
                    preview={false}
                    width={200}
                    style={{ borderRadius: 5, objectFit: 'cover' }}
                  />}
               {/* <Image 
                src={file.type === 'gif' ? file.file : file.url}
                alt={file.name}
                preview={false}
                width={ isMobile ? 50 : 80}
                height={isMobile ? 50 : 80}
                style={{ borderRadius: 5, objectFit: 'cover' }}
              /> */}
              <Button
                type="text"
                className="img-remove"
                onClick={() => handleRemoveImage(file.uid)}
                style={{ 
                  position: 'absolute',
                  top: '-7px',
                  right: '-7px',
                  padding: 0,
                  width: '20px',
                  height: '20px',
                  borderRadius: 20,
                  backgroundColor: '#ffffff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '16px',
                  lineHeight: 1
                }}
              >
                ×
              </Button>
            </Flex>
          ))}
        </Flex>
      )}

      <TextArea 
        ref={textAreaRef}
        placeholder="Send a message..." 
        onChange={handleMessageChange} 
        value={message}
        autoSize 
        onClick={() => setEmojiPickerVisible(false)}
        onKeyDown={handleKeyDown}
        maxLength={WORD_LIMIT}
      />

      <Flex className="message-action-row" gap={10} align="center" justify="start" style={{ marginBottom: 0, padding: '10px 15px' }}>
        <Dropdown
          menu={{ items: dropdownItems }}
          placement="topLeft"
          trigger={['click']}
        >
          <span style={{ cursor: 'pointer' }} onClick={(e) => e.preventDefault()}>
            <PlusOutlined style={{ fontSize: 18, color: '#475467' }} />
          </span>
        </Dropdown>

        <Dropdown
          open={isEmojiPickerVisible}
          onOpenChange={(visible) => {
            if (!visible) {
              if (!isEmojiPickerVisible) {
                setEmojiPickerVisible(visible);
              }
            } else {
              setEmojiPickerVisible(visible);
            }
          }}
          overlay={dropdownOverlay}
          trigger={['click']}
          placement="topLeft"
          overlayClassName="emoji-gif-dropdown"
          destroyPopupOnHide={false}
        >
          <span 
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setEmojiPickerVisible(!isEmojiPickerVisible);
            }}
            style={{ cursor: 'pointer' }}
          >
            <SmileOutlined style={{ fontSize: 18, color: '#475467' }} />
          </span>
        </Dropdown>

        <Button type="text" style={{ padding: 0, fontSize: 16 }} onClick={() => insertPlaceholder('First Name')}>{`{+}`}</Button>

      </Flex>

      <ContentVaultUpload 
        isContentVaultVisible={isContentVaultVisible} 
        setContentVaultVisible={setContentVaultVisible} 
        contentVaultData={handleContentVaultData}
        handleAddContentVault={handleAddContentVault}
      />

      <div className="remain-words" style={{ marginTop: 10, color: isLimitReached ? '#d92d20' : '#475467' }}>
        {remainingWords} words remaining
      </div>

    </Flex>
  </>);
};

export default ChatInputNew;
