import React,{useState} from 'react';
import { Form, Input, Checkbox, Button, Divider, Typography, message ,Spin} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { post } from '../../../utils/api';
import { openNotification } from '../../../utils/utils';
const { Title } = Typography;

const AddNewCard = ({ goBack }) => {
  const [isLoading,setIsLoading]=useState(false);
  // Handle form submission
  const onFinish = async(values) => {
    setIsLoading(true);
    try{
      const response=await post('user/addCard',values);
      if(response.status){
        openNotification(response?.message); 
         goBack();
      }else{
        message.error(response?.error); 
      }
    }catch(error){
      if(error?.response?.data?.error){
        message.error(error?.response?.data?.error);
      }else{
        message.error("oops something went wrong please try again.");
      }
      
      console.log("error",error);
    }finally{
      setIsLoading(false);
    }

    // console.log('Received values:', values);
    // message.success('Card details submitted successfully!');
  
  };

  // Handle form validation
  const validateCardNumber = (_, value) => {
    if (!value) {
      return Promise.reject('Please enter your card number');
    }
    const cardNumber = value.replace(/\s+/g, '');
    if (cardNumber.length !== 16) {
      return Promise.reject('Card number must be exactly 16 digits');
    }
    return Promise.resolve();
  };

  const validateExpiryDate = (_, value) => {
    if (!value) {
      return Promise.reject('Please enter expiry date');
    }
    const [month, year] = value.split('/');
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed
    const currentYear = currentDate.getFullYear();

    if (!/^(0[1-9]|1[0-2])\/\d{4}$/.test(value)) {
      return Promise.reject('Expiry date must be in MM/YYYY format');
    }
    if (parseInt(year, 10) < currentYear || (parseInt(year, 10) === currentYear && parseInt(month, 10) < currentMonth)) {
      return Promise.reject('Expiry date must be in the future');
    }
    return Promise.resolve();
  };

  const validateCVV = (_, value) => {
    if (!value) {
      return Promise.reject('Please enter CVV');
    }
    if (!/^\d{3,4}$/.test(value)) {
      return Promise.reject('CVV must be 3 or 4 digits');
    }
    return Promise.resolve();
  };

  return (
    <div style={{ padding: '20px' }}>
      {/* Back Button */}
      <Button
        type="text"
        icon={<ArrowLeftOutlined />}
        onClick={goBack}
        size="large"
        style={{ fontWeight: 600, fontSize: '14px', padding: 0 }}
      >
        Back
      </Button>

      {/* Title */}
      <Title level={3} style={{ marginBottom: '10px', fontWeight: 700, marginTop: 15 }}>
        Add New Card
      </Title>

     <Spin spinning={isLoading}>
      <Form onFinish={onFinish} layout="vertical">
        <Title level={4}>Card Details</Title>

        {/* Name on Card */}
        <Form.Item
          name="cardholderName"
          label="Name on Card"
          rules={[{ required: true, message: 'Please enter the name on card' }]}
          className="custom-form-item"
        >
          <Input placeholder="Enter name on card" />
        </Form.Item>

        {/* Card Number */}
        <Form.Item
          name="cardNumber"
          label="Card Number"
          rules={[{ validator: validateCardNumber }]}
          className="custom-form-item"
          
        >
          <InputMask
            mask="9999 9999 9999 9999"
            maskChar="_"
            placeholder="1234 1234 1234 1234"
          />
        </Form.Item>

        {/* Expiry and CVV */}
        <div style={{ display: 'flex', gap: '16px' }}>
          <Form.Item
            name="expirationDate"
            label="Expiry"
            style={{ width: '120px' }}
            rules={[{ validator: validateExpiryDate }]}
           className="custom-form-item"
          >
            <InputMask
              mask="99/9999"
              maskChar="_"
              placeholder="MM / YYYY"
            />
          </Form.Item>

          <Form.Item
            name="securityCode"
            label="CVV"
            style={{ width: '120px' }}
            rules={[{ validator: validateCVV }]}
            className="custom-form-item"
          >
            <Input type="password" placeholder="•••" />
          </Form.Item>
        </div>

        {/* Save for Future Use Checkbox */}
        <Form.Item
          name="saveForFutureUse"
          valuePropName="checked"
          className="custom-form-item checkox-label"
          style={{ marginTop: 10 }}
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject('Please agree to save for future use!'),
            },
          ]}
        >
          <Checkbox>Save for future use</Checkbox>
        </Form.Item>

        {/* Terms and Conditions Checkbox */}
        <Form.Item
          name="termsAgreement"
          valuePropName="checked"
          className="custom-form-item checkox-label"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject('Please agree to the Terms of Service!'),
            },
          ]}
        >
          <Checkbox>
            I agree I am 18 years or older and have read and agree to the{' '}
            <Link to={`/terms-service`} target="_blank">Terms of Service</Link> &{' '}
            <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>
          </Checkbox>
        </Form.Item>

        {/* Divider */}
        <Divider />

        {/* Action Buttons */}
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
          <Form.Item style={{ flex: 1 }}>
            <Button
              type="default"
              size="large"
              style={{ width: '100%', fontWeight: 600 }}
              onClick={goBack}
            >
              Cancel
            </Button>
          </Form.Item>

          <Form.Item style={{ flex: 1 }}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              style={{ width: '100%', fontWeight: 600 }}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
      </Spin>
    </div>
  );
};

export default AddNewCard;
