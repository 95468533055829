import React,{useEffect,useState} from 'react';
import { Form, Input, Layout, Typography, Button, Flex, message, Image,Spin } from 'antd';
import { CheckCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import LockIcon from '../../images/lock-icon.svg';
import CheckSMIcon from '../../images/check-sm.svg';
import CrossSMIcon from '../../images/x-circle.svg';
import CheckSMSuccess from '../../images/check-sm-green.svg';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { put } from '../../utils/api';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const SetNewPassword = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { token } = useParams();
    const [loading, setLoading] = useState(false);

    const [isLessthenEight, setIsLessthenEight] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(false);
    const [isOneSpecial, setIsOneSpecial] = useState(false);
    const [isConfirmValidPassword, setIsConfirmValidPassword] = useState(false);

  useEffect(()=>{
    console.log("token",token);
    
  },[token]);

    const onSubmit = async(values) => {
      const currentStepData = await form.validateFields();
      if(currentStepData){
        setLoading(true);
        try {
          const response = await put(`auth/forgot-password/${token}`, values);
          if (response && response.status === 200) {
             navigate('/password-reset');
          } else {
            message.error(response.message);
          }
  
        }catch (error) {
          if (error.response && error.response.data) {
            message.error(error.response.data.error);
          } else {
            message.error(error.message);
          }
        }finally {
          setLoading(false);
        }
      }
    };

    return (
        <Content style={{ paddingTop: 40, paddingBottom: 40 }}>
          <Spin spinning={loading}>
            <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>

              <Flex
                vertical
                align="center"
                style={{
                  width: '100%',
                }}
              >
                <Image
                    width={60}
                    src={LockIcon}
                    preview={false}
                />
              </Flex>

              <Title level={2} style={{ textAlign: 'center', color: '#101828', fontWeight: 600 }}>Set new password</Title>
              <Paragraph style={{ textAlign: 'center', fontSize: '16px' }}>Your new password must be different to previously used passwords.</Paragraph>

              <Form form={form} onFinish={onSubmit} className="welcome-form"
                onFieldsChange={(_, allFields) => {
                const passwordField = allFields.find(field => field.name[0] === 'password');
                const confirmPasswordField = allFields.find(field => field.name[0] === 'confirm');
                
                const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/;
                const passwordValue = passwordField?.value;
                const confirmPasswordValue = confirmPasswordField?.value;

                if ((passwordValue && passwordValue.length < 8) || (confirmPasswordValue && confirmPasswordValue.length < 8)) {
                  setIsLessthenEight(true);
                } else {
                  setIsLessthenEight(false);
                }

                const isPasswordValid = passwordValue && hasSpecialCharacter.test(passwordValue);
                if (passwordValue && passwordValue.length > 0 && !isPasswordValid) {
                  setIsOneSpecial(true);
                } else {
                  setIsOneSpecial(false);
                }

                if (passwordValue && passwordValue.length >= 8) {
                  setIsValidPassword(true);
                } else {
                  setIsValidPassword(false);
                }

                if (passwordValue && isPasswordValid) {
                  setIsConfirmValidPassword(true);
                } else {
                  setIsConfirmValidPassword(false);
                }

              }}>
                <Form.Item
                    name="password"
                    label="Password*"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="custom-form-password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      {
                        min: 8,
                        message: 'Password must be at least 8 characters long!',
                      },
                      {
                        pattern: /[!@#$%^&*(),.?":{}|<>]/,
                        message: 'Password must contain at least one special character!',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password placeholder="Create a password" />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Confirm password*"
                    dependencies={['password']}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="custom-form-password"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('The two passwords that you entered do not match!')
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Confirm password" />
                </Form.Item>

                <div className="password-criteria">
                  {isLessthenEight ?
                    <div className="criteria-item">
                      <Image
                        width={25}
                        src={CrossSMIcon}
                        preview={false}
                        style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                      />
                      <span style={{ color: '#d92d20' }}>Must be at least 8 characters</span>
                    </div>
                    : isValidPassword ?
                      <div className="criteria-item">
                        <Image
                          width={25}
                          src={CheckSMSuccess}
                          preview={false}
                          style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                        />
                        <span>Must be at least 8 characters</span>
                      </div>
                    :
                    <div className="criteria-item">
                      <Image
                        width={25}
                        src={CheckSMIcon}
                        preview={false}
                        style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                      />
                      <span>Must be at least 8 characters</span>
                    </div>
                  }
                  {isOneSpecial ?
                    <div className="criteria-item">
                      <Image
                        width={25}
                        src={CrossSMIcon}
                        preview={false}
                        style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                      />
                      <span style={{ color: '#d92d20' }}>Must contain one special character</span>
                    </div> 
                    : isConfirmValidPassword ?
                    <div className="criteria-item">
                      <Image
                        width={25}
                        src={CheckSMSuccess}
                        preview={false}
                        style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                      />
                      <span>Must contain one special character</span>
                    </div> 
                    : 
                    <div className="criteria-item">
                      <Image
                        width={25}
                        src={CheckSMIcon}
                        preview={false}
                        style={{ paddingRight: 7, verticalAlign: 'text-bottom' }}
                      />
                      <span>Must contain one special character</span>
                    </div> 
                  }
              </div>

                <Flex
                    vertical
                    gap="small"
                    style={{
                      width: '100%',
                      marginTop: '20px'
                    }}
                  >
                    <Button type="primary" htmlType='submit' size="large" style={{ fontWeight: 600 }}>
                      Reset password
                    </Button>
                    <Link to="/">
                      <Button type="text" size="large" icon={<ArrowLeftOutlined />} block style={{ fontWeight: 600, marginTop: '20px' }}>
                        Back to log in
                    </Button>
                    </Link>
                </Flex>

            </Form>
          </Flex>
        </Spin>
      </Content>
    );
}

export default SetNewPassword;
