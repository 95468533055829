import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { UserProvider } from './contexts/UserContext';
import { jwtDecode } from "jwt-decode";
import NotFound from './pages/NotFound';
import PermissionError from './pages/PermissionError';
import Login from './pages/Login';
import Contact from './pages/Contact';
import TermsService from './pages/TermsService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ContentGuidelines from './pages/ContentGuidelines';
import CreatorTermsUse from './pages/CreatorTermsUse';
import FanTermsUse from './pages/FanTermsUse';
import AgencyTermsUse from './pages/AgencyTermsUse';
import PaymentTermsConditions from './pages/PaymentTermsConditions';
import GeneralTermsService from './pages/GeneralTermsService';
import ForgotPassword from './components/ForgotPassword';
import EmailSubmitted from './components/ForgotPassword/EmailSubmitted';
import SetNewPassword from './components/ForgotPassword/SetNewPassword';
import PasswordReset from './components/ForgotPassword/PasswordReset';
import NewPrivateRequest from './pages/Audience/PrivateRequests/NewPrivateRequest';
import Signup from './pages/Signup';
import Confirmation from './components/StepFormSignup/Confirmation';
import WelcomePage from './pages/Welcome';
import AccountCreatedPage from './pages/AccountCreatedPage';
import VerifyStep from './components/VerifyStep/VerifyStep';
import VerifyTwoStep from './components/VerifyStep/VerifyTwoStep';

// Creator Routes
import Home from './pages/Home';
import PrivateRequests from './pages/PrivateRequests';
import Audience from './pages/Message/Audience';
import MessageNetwork from './pages/Message/Network';
import Communities from './pages/Communities';
import ContentVault from './pages/ContentVault';
import ScheduleMessage from './pages/ScheduleMessage';
import MyNetwork from './pages/MyNetwork';
import Campaigns from './pages/Campaigns';
import EditPermissions from './components/Network/EditPermissions';
import ContactCard from './pages/ContactCard';
import Insights from './pages/Insights';
import TotalFansDetails from './components/Insights/TotalFans/Report';
import ThisWeeksRevenueDetails from './components/Insights/ThisWeeksRevenue/Report';
import LocationsReport from './components/Insights/Locations/Report';
import AgeReport from './components/Insights/Age/Report';
import EngagementReport from './components/Insights/Engagement/Report';

import Settings from './pages/Settings';
import NotificationCenter from './pages/NotificationCenter';

import MyProfile from './pages/MyProfile';
import CreatorLayout from './layouts/CreatorLayout';
import AdminLayout from './layouts/AdminLayout';
import PublicLayout from './layouts/PublicLayout';
import TeamLayout from './layouts/TeamLayout';
import UserPublicLayout from './layouts/UserPublicLayout';
import AudienceNavLayout from './layouts/AudienceNavLayout';
import AudienceNavTrans from './layouts/AudienceNavTrans';

import ImagesGallery from './components/GlobalComponents/ImagesGallery';

// Audience Routes
import AudienceSignup from './pages/Audience/Signup';
import AudiencePayment from './pages/Audience/Payment';
import FanSignup from './pages/Audience/FanSignup';
import FanSignupUnlock from './pages/Audience/FanSignup/FanSignupUnlock';
import Billing from './pages/Audience/Billing';
import PWASettings from './pages/Audience/Settings';
import MyRequests from './pages/Audience/MyRequests';
import RequestDetails from './pages/Audience/RequestDetails';


import PWAPrivateRequests from './pages/Audience/PrivateRequests';
import CustomRequest from './pages/Audience/CustomRequest';
import MyCollection from './pages/Audience/MyCollection';
import Faq from './pages/Audience/Faq';

// Admin Routes
import Dashboard from './pages/Admin/Dashboard';
import Users from './pages/Admin/Users';
import EditUsers from './pages/Admin/Users/EditUsers';

// Team Routes
import TeamWelcome from './pages/Team/Welcome';
import TeamSignup from './pages/Team/Signup';
import Network from './pages/Team/Network';
import Notification from './pages/Team/Notification';
import TeamSettings from './pages/Team/TeamSettings';
import TeamProfile from './pages/Team/TeamProfile';


// Users Routes
import ContentSharing from './pages/Users/ContentSharing';

import TeamDashboard from './pages/Team/TeamDashboard';

const AdminRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    let user = null;
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
  
      if (decodedToken.exp > currentTime) {
        user = decodedToken;
      } else {
        localStorage.removeItem('token');
      }
    }
    if(user && !user.role.includes('Admin')){
      return (<Navigate to="/permission-error" />);
    }
    else if(user && user.role.includes('Admin')){
      return (<UserProvider initialUser={user} children={children} />);
    }else{
      return (<Navigate to="/" />);
    }
};

const CreatorRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    let user = null;
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
  
      if (decodedToken.exp > currentTime) {
        user = decodedToken;
      } else {
        localStorage.removeItem('token');
      }
    }
  
    
    if(user && user.role.includes('Creator')){
        return <UserProvider initialUser={user} children={children} />
    }else{
      return (<Navigate to="/" />);
    }
};

const TeamRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    let user = null;
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
  
      if (decodedToken.exp > currentTime) {
        user = decodedToken;
      } else {
        localStorage.removeItem('token');
      }
    }
   
    if(user && !user.role.includes('Team')){
      return (<Navigate to="/permission-error" />);
    }
    else if(user && user.role.includes('Team')){
      return (<UserProvider initialUser={user} children={children} />);
    }else{
      return (<Navigate to="/" />);
    }
};

const UserRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    let user = null;
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
  
      if (decodedToken.exp > currentTime) {
        user = decodedToken;
      } else {
        localStorage.removeItem('token');
      }
    }
   
    if(user && !user.role.includes('User')){
      return (<Navigate to="/permission-error" />);
    }
    else if(user && user.role.includes('User')){
      return (<UserProvider initialUser={user} children={children} />);
    }else{
        return (<Navigate to="/" />);
    }
};


const UserSpecialRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    let user = null;
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
  
      if (decodedToken.exp > currentTime) {
        user = decodedToken;
      } else {
        localStorage.removeItem('token');
      }
    }
   
    if(user && !user.role.includes('User')){
      return (<Navigate to="/permission-error" />);
    }
    else if(user && user.role.includes('User')){
      return (<UserProvider initialUser={user} children={children} />);
    }else{
      return (children);
    }
};


const RouteHandler=()=>{
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path='*' element={<PublicLayout><NotFound /></PublicLayout>} />
                    <Route path='/contact' element={<PublicLayout><Contact /></PublicLayout>} />
                    <Route path='/terms-service' element={<PublicLayout><TermsService /></PublicLayout>} />
                    <Route path='/privacy-policy' element={<PublicLayout><PrivacyPolicy /></PublicLayout>} />
                    <Route path='/content-guidelines' element={<PublicLayout><ContentGuidelines /></PublicLayout>} />
                    <Route path='/creator-terms-use' element={<PublicLayout><CreatorTermsUse /></PublicLayout>} />
                    <Route path='/fan-terms-use' element={<PublicLayout><FanTermsUse /></PublicLayout>} />
                    <Route path='/agency-terms-use' element={<PublicLayout><AgencyTermsUse /></PublicLayout>} />
                    <Route path='/payment-terms-conditions' element={<PublicLayout><PaymentTermsConditions /></PublicLayout>} />
                    <Route path='/general-terms-service' element={<PublicLayout><GeneralTermsService /></PublicLayout>} />
                    <Route path='/contact-info/:userName' element={<ContactCard />} />
                    <Route path='/permission-error' element={<PublicLayout><PermissionError /></PublicLayout>} />
                    <Route exact path="/" element={<PublicLayout><Login /></PublicLayout>} />
                    <Route path="/forgot-password" element={<PublicLayout><ForgotPassword /></PublicLayout>} />
                    <Route path="/email-submitted" element={<PublicLayout><EmailSubmitted /></PublicLayout>} />
                    <Route path="/new-password/:token" element={<PublicLayout><SetNewPassword /></PublicLayout>} />
                    <Route path="/password-reset" element={<PublicLayout><PasswordReset /></PublicLayout>} />
                    <Route path="/signup" element={<PublicLayout><Signup /></PublicLayout>} />
                    <Route path="/welcome/:token" element={<WelcomePage />} />
                    <Route path="/account-created" element={<PublicLayout><AccountCreatedPage /></PublicLayout>} />
                    <Route path="/verify-step" element={<PublicLayout><VerifyStep /></PublicLayout>} />
                    <Route path="/verify-two-step" element={<PublicLayout><VerifyTwoStep /></PublicLayout>} />
                    <Route path="/signup/confirmation" element={<PublicLayout><Confirmation /></PublicLayout>} />
                    <Route path="/audience/signup/:creatorId/:phone" element={<AudienceSignup />} />
                    <Route path="/audience/payment/:phone" element={
                        <AudiencePayment />
                    } />
                    <Route path="/unlock/:smsId/:phone" element={
                         <UserSpecialRoute>
                            <AudienceNavTrans>
                                <FanSignup />
                            </AudienceNavTrans>
                        </UserSpecialRoute>
                    } />
                    <Route path="/audience/fan-signup-unlock/:smsId" element={
                        <UserRoute>
                            <AudienceNavTrans>
                                <FanSignupUnlock />
                            </AudienceNavTrans>
                        </UserRoute>
                        } />
                    <Route path="/audience/billing" element={
                         <UserRoute>
                            <AudienceNavLayout>
                                <Billing />
                            </AudienceNavLayout>
                        </UserRoute>}
                         />
                    <Route path="/audience/faq" element={
                         <UserRoute>
                            <AudienceNavLayout>
                                <Faq />
                            </AudienceNavLayout>
                        </UserRoute>}
                         />
                    <Route path="/audience/settings" element={
                         <UserRoute>
                            <AudienceNavLayout>
                                <PWASettings />
                            </AudienceNavLayout>
                        </UserRoute>
                        } />
                    <Route path="/audience/myrequests" element={
                         <UserRoute>
                            <AudienceNavLayout>
                                <MyRequests />
                            </AudienceNavLayout>
                        </UserRoute>} />
                    <Route path="/audience/myrequests/:requestId" element={
                        <UserRoute>
                        <AudienceNavLayout>
                            <RequestDetails />
                        </AudienceNavLayout>
                    </UserRoute>} />
                    <Route path="/audience/private-requests/:smsId/:phone?" element={
                        <UserRoute>
                            <PWAPrivateRequests />
                        </UserRoute>
                       } />
                    <Route path="/audience/new-request/:requestId" element={
                        <UserRoute>
                            <NewPrivateRequest/>
                        </UserRoute>
                       } />
                    <Route path="/audience/customrequests" element={<AudienceNavLayout><CustomRequest /></AudienceNavLayout>} />
                    <Route path="/audience/my-collection" element={
                        <UserRoute>
                            <AudienceNavLayout>
                                    <MyCollection />
                            </AudienceNavLayout>
                        </UserRoute>
                       } />
                    <Route path="/images-gallery" element={<ImagesGallery />} />
                    <Route path="/admin/dashboard" element={
                        <AdminRoute>
                            <AdminLayout>
                             <Dashboard />
                            </AdminLayout>
                        </AdminRoute>
                    } />
                    <Route path="/admin/users" element={
                        <AdminRoute>
                            <AdminLayout>
                             <Users />
                            </AdminLayout>
                        </AdminRoute>
                    } />
                     <Route path="/admin/:id/edit" element={
                        <AdminRoute>
                            <AdminLayout>
                             <EditUsers />
                            </AdminLayout>
                        </AdminRoute>
                    } />
                    <Route path="/home" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <Home />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/private-requests/:requestId?" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <PrivateRequests />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/message/audience" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <Audience />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/message/network" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <MessageNetwork />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/schedule-message/:eventId?" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <ScheduleMessage />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/communities/:userId?" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <Communities />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/content-vault" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <ContentVault />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/my-network" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <MyNetwork />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/permissions/:id" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <EditPermissions />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/insights" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <Insights />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/total-fans-details" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <TotalFansDetails />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/revenue-report" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <ThisWeeksRevenueDetails />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/locations-report" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <LocationsReport />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/age-report" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <AgeReport />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/engagement-report" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <EngagementReport />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />  
                    <Route path="/settings" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <Settings />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />

<                   Route path="/campaigns/:campId?" element={
                        <CreatorRoute>
                            <CreatorLayout>
                                <Campaigns />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />


                    <Route path="/notification" element={
                        <CreatorRoute>
                            <CreatorLayout>
                             <NotificationCenter />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/profile" element={
                        <CreatorRoute>
                            <CreatorLayout>
                                <MyProfile />
                            </CreatorLayout>
                        </CreatorRoute>
                    } />
                    <Route path="/team/welcome/:creatorId/:teamEmail" element={<TeamWelcome />} />
                    <Route path="/team/signup/:creatorId/:teamEmail" element={<PublicLayout><TeamSignup /></PublicLayout>} />
                    
                    <Route path="/team/dashboard" element={
                        <TeamRoute>
                            <TeamLayout>
                                <TeamDashboard />
                            </TeamLayout>
                        </TeamRoute>
                    } />
                    <Route path="/team/profile" element={
                        <TeamRoute>
                            <TeamLayout>
                                <TeamProfile />
                            </TeamLayout>
                        </TeamRoute>
                    } />
                    <Route path="/team/message" element={
                        <TeamRoute>
                            <TeamLayout>
                                <Audience />
                            </TeamLayout>
                        </TeamRoute>
                    } />

                    <Route path="/team/my-network" element={
                        <TeamRoute>
                            <TeamLayout>
                                 <MyNetwork />
                            </TeamLayout>
                        </TeamRoute>
                    } />
                    <Route path="/team/schedule-message" element={
                        <TeamRoute>
                            <TeamLayout>
                                <ScheduleMessage />
                            </TeamLayout>
                        </TeamRoute>
                    } />
                    <Route path="/team/communities" element={
                        <TeamRoute>
                            <TeamLayout>
                                <Communities />
                            </TeamLayout>
                        </TeamRoute>
                    } />
                    <Route path="/team/content-vault" element={
                        <TeamRoute>
                            <TeamLayout>
                                <ContentVault />
                            </TeamLayout>
                        </TeamRoute>
                    } />
                    <Route path="/team/insights" element={
                        <TeamRoute>
                            <TeamLayout>
                                <Insights />
                            </TeamLayout>
                        </TeamRoute>
                    } />
                     <Route path="/team/notification" element={
                        <TeamRoute>
                            <TeamLayout>
                                <NotificationCenter />
                            </TeamLayout>
                        </TeamRoute>
                    } />
                     <Route path="/team/settings" element={
                        <TeamRoute>
                            <TeamLayout>
                                <Settings />
                            </TeamLayout>
                        </TeamRoute>
                    } />

                    <Route path="/team/total-fans-details" element={
                        <TeamRoute>
                            <TeamLayout>
                             <TotalFansDetails />
                            </TeamLayout>
                        </TeamRoute>
                    } />
                      <Route path="/team/revenue-report" element={
                        <TeamRoute>
                            <TeamLayout>
                             <ThisWeeksRevenueDetails />
                            </TeamLayout>
                        </TeamRoute>
                    } />

                    <Route path="/team/locations-report" element={
                        <TeamRoute>
                            <TeamLayout>
                             <LocationsReport />
                            </TeamLayout>
                        </TeamRoute>
                    } />
                     <Route path="/team/age-report" element={
                        <TeamRoute>
                            <TeamLayout>
                             <AgeReport />
                            </TeamLayout>
                        </TeamRoute>
                    } />
                    <Route path="/team/engagement-report" element={
                        <TeamRoute>
                            <TeamLayout>
                             <EngagementReport />
                            </TeamLayout>
                        </TeamRoute>
                    } />  
                   <Route path="/:userName/share/:shareId" element={
                        <UserPublicLayout>
                            <ContentSharing />
                        </UserPublicLayout>
                    } />
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default RouteHandler;