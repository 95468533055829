import { Row, Col, Image } from "antd";
import { Link } from "react-router-dom";
import './Collection.css';

const CollectionView=({allItems})=>{
  return (
      <Row gutter={[2, 2]} style={{ margin: 0, padding: '20px 2px' }}>
        {allItems.map((item, index) => (
          <Col key={index} xs={8} sm={8} md={8} lg={8}>
            <Link to={`/audience/fan-signup-unlock/${btoa(item?.ix)}`} target="_blank">
            <div className="audience-collection-grid" style={{ height: '145px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {item.type === 'image' && (
                <Image src={item.src} alt={item.type} style={{ maxWidth: '100%', maxHeight: '100%', width: '100%', height: '100%', objectFit: 'cover' }}  onError={(e) => {
                  e.target.closest('.ant-col').style.display = 'none';
              }} preview={false} />
              )}
              {item.type === 'video' && (
                <video controls style={{ maxWidth: '100%', maxHeight: '100%', height: '100%', width: '100%', objectFit: 'cover' }}>
                  <source src={item.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
              {item.type === 'audio' && (
                <audio controls style={{ width: '100%' }}>
                  <source src={item.src} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              )}
            </div>
            </Link>
          </Col>
        ))}
    </Row>
  )
}

export default CollectionView;