import React, { useState, useMemo, useEffect } from 'react';
import { Layout, Spin, Flex, Button, Typography, Card, Image, Progress, notification } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import { CloseOutlined, PlusOutlined, ArrowLeftOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import FilteredNewMessage from '../GlobalComponents/FilteredNewMessage';
import AvatarImage from '../../components/AvatarImage';
import useScreenSize from '../../utils/useScreenSize';
import { post } from '../../utils/api';
import MessagePlusIcon from '../../images/message-plus-icon.svg';
import CheckCircle from '../../images/check-with-circles.svg';
import { formatPrice } from '../../utils/utils';
import moment from 'moment';
import { get } from '../../utils/api';

const { Sider } = Layout;
const { Title, Text, Paragraph } = Typography;

const data = [
  { time: '0', value: 0 },
  { time: '15m', value: 2 },
  { time: '30m', value: 4 },
  { time: '45m', value: 2 },
  { time: '60m', value: 5 },
  { time: 'now', value: 7 }
];

const SidebarCampaigns = ({ selectedCampaignUserInfo, setSelectedFan, setIsReload, setIsSidebarOpen }) => {
	const { isMobile, isTableToDesktop } = useScreenSize();
	const [isLoader,setIsLoader]=useState(false);
	const [isCommunityDrawerVisible, setIsCommunityDrawerVisible] = useState(false);
	const [customCommunities,setCustomCommunities]=useState([]);
	const [locationsData,setLocationsData]=useState([]);
	const [userInfo, setUserInfo]=useState(null);
	const [open, setOpen] = useState(false);  
	const [filters, setFilters] = useState([]);  

	const sendMessageToAll=()=>{
		setOpen(true);	
		setFilters({
			campaign:selectedCampaignUserInfo
		});
	}
	const sendMessageToLocation=(selectedlocation)=>{
		
		setOpen(true);	
		setFilters({
			locations:selectedlocation
		});
	}

	useEffect(()=>{
		const locations = async () => {
			setIsLoader(true);
			try {
				 const loadComminties = await get(`creator/communities`);
				 	if(loadComminties){
						setCustomCommunities(loadComminties);
					}
			  const response = await get(`creator/campaigns/locations/${selectedCampaignUserInfo?.id}`);
			  if (response.status) {
				setLocationsData(response?.data);
			  }
			} catch (error) {
			  console.log("Error fetching campaigns:", error);
			} finally {
				setIsLoader(false);
			}
		  };
		  locations();
	},[selectedCampaignUserInfo]);

	const openCommunityDrawer = () => {
		setIsCommunityDrawerVisible(true);
	};

	const closeCommunityDrawer = () => {
	    setIsCommunityDrawerVisible(false);
	};

	const openNotification = (message) => {
	    notification.success({
	      message: message,
	      placement: 'topRight',
	      duration: 3,
	      icon: <Image src={CheckCircle} preview={false} /> 

	    });
	};


	const addUserToCommunity=async(communityId)=>{
	    setIsLoader(true);
	    try {
	    const response=await post(`creator/campaigns/addUserCommunity`, {
	      cId: communityId,
		  campaign:selectedCampaignUserInfo?.campaignUsers || []
	      });
	     
	        if(response.status){
	          openNotification(response.message);
	        }
	    
	    } catch (error) {
	      console.error('Error fetching user info:', error);
	    } finally {
	      setIsLoader(false);
	      setIsCommunityDrawerVisible(false);
	    }
	}

	const calculateResponses=(text)=>{
		if(!selectedCampaignUserInfo)return '0%';
		let totalClick = "--";
        if (selectedCampaignUserInfo?.campaignUsers?.length > 0 && text > 0) {
          const TotalUsers = selectedCampaignUserInfo.campaignUsers.length;
           totalClick = ((text / TotalUsers) * 100).toFixed(2) + "%";
        }
		return totalClick
	}

	const selectedCampaignsStyles = useMemo(() => 
	    isMobile ? {
	      backgroundColor: '#F2F4F7', 
	      position: 'absolute', 
	      width: '100%', 
	      left: 0,
	      height: 'calc(100vh - 70px)',
	      overflow: 'auto', 
	      scrollbarWidth: 'thin', 
	      scrollbarColor: 'unset', 
	      padding: '0', 
	      zIndex: 99,
	    } : isTableToDesktop ? {
	      backgroundColor: '#F2F4F7', 
	      position: 'absolute', 
	      right: 0,
	      height: '100vh',
	      overflow: 'auto', 
	      scrollbarWidth: 'thin', 
	      scrollbarColor: 'unset', 
	      padding: '0', 
	      borderLeft: '1px solid #D0D5DD',
	      zIndex: 99,
	    } : {
	      height: '100vh', 
	      overflow: 'auto', 
	      scrollbarWidth: 'thin', 
	      scrollbarColor: 'unset', 
	      background: '#F2F4F7', 
	      padding: '0', 
	      borderLeft: '1px solid #D0D5DD',
	      zIndex: 99,
	    }
	  , [isMobile, isTableToDesktop]);

	const handleSiderClose = () => {
		setIsSidebarOpen(false);
	}

	return (
		<Sider className="campaigns-sidebar-main" width={ isMobile ? '100%' : 375} style={selectedCampaignsStyles}>
			<Spin spinning={isLoader}>
				<Flex vertical style={{ padding: 16 }}>
					{isMobile &&
						<Flex vertical align="flex-start">
							<Button type="text" onClick={handleSiderClose} icon={<ArrowLeftOutlined />} style={{ padding: 0, color: '#475467', fontWeight: 500 }}>
		            Back
		          </Button>
	          </Flex>
	        }
	      	<Flex vertical gap="middle">
	          <Flex vertical className="fan-head" style={{ textAlign: 'center' }}>
	            <Title level={4} style={{ textTransform: 'capitalize', fontWeight: 700, fontSize: '18px', marginBottom: 5 }}>{selectedCampaignUserInfo.campaignUsers.length} Fans</Title>
	            {selectedCampaignUserInfo?.dateSent && <Text>Sent: {moment(selectedCampaignUserInfo?.dateSent).format('MM/DD/YYYY h:m A')}</Text>}
	          </Flex>
	        </Flex>

	        <Card className="fan-favorite-card" style={{ marginTop: 20, marginBottom: 15, border: '0 none', backgroundColor: 'transparent' }}>
	          	<Flex gap={12} horizontal justify="space-around">
	              <Button onClick={sendMessageToAll} type="link" icon={<Image src={MessagePlusIcon} preview={false} />} style={{ color: '#475467', padding: 0 }}>Message All</Button> 
	              <Button type="link" icon={<PlusOutlined style={{ fontSize: 20 }} />} style={{ color: '#475467', padding: 0 }} onClick={openCommunityDrawer}>Community</Button>
	           	</Flex>
	        </Card>

	        <Card className="fan-details" style={{ border: '0 none', marginBottom: 15 }}>
	          <Flex horizontal style={{ borderBottom: '1px solid #EAECF0', padding: '0px 0 10px 0' }}>
	            <strong style={{ width: '50%' }}>Responded</strong> <span style={{ width: '50%' }}>{calculateResponses(selectedCampaignUserInfo?.totalResponse || 0)}</span>
	          </Flex>
	          <Flex horizontal style={{ padding: '10px 0 0px 0' }}>
	            <strong style={{ width: '50%' }}>Revenue</strong> <span style={{ width: '50%' }}>{formatPrice(selectedCampaignUserInfo?.totalRevenue || 0)}</span>
	          </Flex>
	        </Card>

	        {locationsData?.length>0 && <Card className="insight-card" style={{ marginBottom: 15 }}>
				    <Flex vertical style={ isMobile ? { padding: 15 } : { padding: 20 }}>
				        <Title level={4} style={{ marginTop: 0, marginBottom: '20px', fontSize: '18px' }}>Locations</Title>
						{locationsData?.map((location, index) => (
							<Flex key={index} align="end" gap={10} style={{ marginBottom: 15 }}>
								<div style={{ width: 'calc(100% - 15px)' }}>
									<Text>{location.city}</Text>
									<Progress className="progress-bar" percent={location.percentage} />
								</div>
								<Button onClick={()=>{sendMessageToLocation(location.city)}} type="default" icon={<Image src={MessagePlusIcon} preview={false} />} style={{ minWidth: '36px' }}></Button> 
							</Flex>
						 ))}
				    </Flex>
			    </Card>}

					{selectedCampaignUserInfo?.totalClick>0 &&
				    <Card className="insight-card" style={{ marginBottom: 15 }}>
					    <Flex vertical style={ isMobile ? { padding: 15 } : { padding: 20 }}>
					    	<Flex align="center" justify="space-between">
						        <Title level={4} style={{ marginTop: 0, marginBottom: 0, fontSize: '18px' }}>Media Clicks</Title>
						        <Button type="default" icon={<Image src={MessagePlusIcon} preview={false} />} style={{ minWidth: '36px' }}></Button> 
					      	</Flex>
					      	<Text>{selectedCampaignUserInfo?.totalClick} of {selectedCampaignUserInfo.campaignUsers.length} Members</Text>

					      	<LineChart
					          width={400}
					          height={150}
					          data={data}
					          className="line-chart"
					          style={{ marginTop: 10 }}
					          margin={{ top: 0, right: 0, bottom: -10, left: -20 }}
					        >
					          <CartesianGrid strokeDasharray="3 3" vertical={false} />
					          <XAxis 
					            dataKey="time" 
					            axisLine={false}
					            tickLine={false}
					          />
					          <YAxis 
					            tickFormatter={(value) => `${value}%`}
					            domain={[0, 50]}
					            ticks={[0, 10, 20, 30, 40, 50]}
					            axisLine={false}
					            tickLine={false}
					          />
					          <Line 
					            type="monotone" 
					            dataKey="value" 
					            stroke="#7C3AED" 
					            strokeWidth={2}
					            dot={false}
					          />
					        </LineChart>

					    </Flex>
				    </Card>}
			   </Flex>
			   
		    	{isCommunityDrawerVisible &&
	          <Flex className="community-sidebar" vertical>
	            <Flex style={{ padding: '12px 15px', borderBottom: '1px solid #D0D5DD' }}>
	              <Button icon={ <LeftOutlined /> } type="text" style={{ padding: 0, color: '#475467', fontWeight: 600 }} onClick={closeCommunityDrawer}>Back</Button>
	              <Button icon={ <CloseOutlined /> } type="text" style={{ padding: 0, marginLeft: 'auto' }} onClick={closeCommunityDrawer}></Button>
	            </Flex>
	            <Flex style={{ padding: '15px 15px' }}>
	              <Title level={3} style={{ margin: 0, fontWeight: 700 }}>Communities</Title>
	            </Flex>
	            <Flex vertical gap={14} style={{ padding: '10px 15px' }}>
	              {customCommunities.length > 0 ? (
	                <>
	                  {customCommunities.map((community) => (
	                    <Flex gap={10} key={community.id} align="center">
	                      <AvatarImage size={40} title={`${community?.name}`}/>
	                      <span style={{ color: '#344054', fontSize: '14px', fontWeight: 700 }}>{community.name}</span>
						  <Button
	                          type="link"
	                          onClick={() => addUserToCommunity(community.id)}
	                          style={{ color: '#6941C6', fontWeight: 600, marginLeft: 'auto' }}
	                        >
	                          Add
	                        </Button>
	                    </Flex>
	                  ))}
	                </>) : (
	                  <Paragraph className="text-gray-500">No communities available.</Paragraph>
	                )}
	            </Flex>
	          </Flex>
	        }
					<FilteredNewMessage filters={filters} open={open} setOpen={setOpen}/>
      </Spin>
		</Sider>
	);
}

export default SidebarCampaigns;