import React,{useState,useEffect} from 'react';
import { Flex, Card, Typography, Form, Input, Select, Checkbox, Button,Spin,Image ,Radio,message} from 'antd';
import InputMask from 'react-input-mask';
import UserImg from '../../../images/avatar-image.png';
import Icon1 from '../../../images/visa-icon.png';
import Icon2 from '../../../images/mastercard-icon.png';
import Icon3 from '../../../images/American-Express.png';
import AvatarImage from '../../AvatarImage';
import './Payment.css';
import { post } from '../../../utils/api';
import { useNavigate,useParams } from 'react-router-dom';
import statesOrProvinces from '../../../utils/statesOrProvinces';
import FooterComponent from '../../Footer';
import { get } from '../../../utils/api';
const { Title, Text } = Typography;
const { Option } = Select;

const Payment = ({creatorData,SetUnLockHandler,data,phone,smsId}) => {
    const [isLoading,setIsLoading]=useState(false);
    const imageId = creatorData && creatorData?.uploadedIds && creatorData.uploadedIds.length > 0 ? creatorData.uploadedIds[0] : null;
    const Navigate = useNavigate();
    const [userCards,setUserCards]=useState([]);
    const [value, setValue] = useState();
    const [cvvNumber, setCvvNumber] = useState('');
    const onChange = (e) => {
        setValue(e.target.value);
        setCvvNumber('');
    };

    // Handle form validation
  const validateCardNumber = (_, value) => {
    if (!value) {
      return Promise.reject('Please enter your card number');
    }
    const cardNumber = value.replace(/\D/g, '');
   
    if (cardNumber.length !== 16) {
      return Promise.reject('Invalid card number ');
    }
    return Promise.resolve();
  };

  const validateExpiryDate = (_, value) => {
    if (!value) {
      return Promise.reject('Please enter expiry date');
    }
    const [month, year] = value.split('/');
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed
    const currentYear = currentDate.getFullYear();

    if (!/^(0[1-9]|1[0-2])\/\d{4}$/.test(value)) {
      return Promise.reject('Expiry date must be in MM/YYYY format');
    }
    if (parseInt(year, 10) < currentYear || (parseInt(year, 10) === currentYear && parseInt(month, 10) < currentMonth)) {
      return Promise.reject('Expiry date must be in the future');
    }
    return Promise.resolve();
  };


    
    const validateCVV = (_, value) => {
        if (!value) {
          return Promise.reject('Please enter CVV');
        }
        if (!/^\d{3,4}$/.test(value)) {
          return Promise.reject('CVV must be 3 or 4 digits');
        }
        return Promise.resolve();
      };

    const cardImages={
        Visa:Icon1,
        MasterCard:Icon2,
        "American Express":Icon3
      }
      const getCardImage = (cardType) => {
        return cardImages[cardType] || null; // Return null if the card type is not found
      };

    const init=async()=>{
        setIsLoading(true);
        try{
          const currentUserCards=await get('user/currentUserCards');
          if(currentUserCards?.status){
            setUserCards(currentUserCards?.cards);
          }
        }catch(error){
          console.log("error",error);
        }finally{
          setIsLoading(false);
        }
      }
useEffect(()=>{
    init();
},[]);

    const onFinish =async (values) => {
        setIsLoading(true);
        try{
            const isSavedcards=userCards?.length>0?true:false;
            const isNewcard=(!isSavedcards || value=="newcard")?true:false;
            const selectedcard=(isSavedcards && !value)?userCards.find(card => card.isPrimary)?.id:value;
            const paymentInfo={
                isSavedcards,
                isNewcard,
                selectedcard:selectedcard,
                cvvNumber
            };
            const formData={...values,postdata:data,creatorData,user_phone:phone,paymentInfo}
            const response = await post(`user/unlockPayment`,formData);
            if(!response?.status){
                return message.error(response?.message || 'Oops something went wrong please try again.');
            }
            if (response && response.status) {
                if(response?.token){
                    localStorage.setItem('token', response?.token);
                    Navigate(`/audience/fan-signup-unlock/${smsId}`);
                }
            }
            if(response?.cardRequest && response?.token){
                localStorage.setItem('token', response?.token);
                Navigate(`/audience/billing`); 
            }

        }catch(error){
                console.log("error",error);
        }finally{
            setIsLoading(false);
        }
      
    };

    const handleCVVChange = (e) => {
        const { value } = e.target;
        if(!value){
            setCvvNumber('');
        }
        if (/^\d{3,4}$/.test(value)) {
          setCvvNumber(value);
        }
      };

    return (
        <Flex vertical>
         <Spin spinning={isLoading}>
            <Form onFinish={onFinish}>
            <Flex vertical align="center" style={{ marginBottom: '25px' }}>
            <AvatarImage imageId={imageId} title={`${creatorData?.first_name} ${creatorData?.last_name}`} />
                <Title level={4} style={{ textAlign: 'center', marginTop: '20px', marginBottom: '10px', fontSize: '18px' }}>{creatorData?.first_name} {creatorData?.last_name}</Title>
                <Title level={3} style={{ textAlign: 'center', marginTop: '20px', marginBottom: '10px' }}>Payment details</Title>
                <Text style={{ textAlign: 'center' }}>In order to see this user’s content you must complete the following details.</Text>
            </Flex>
            {userCards?.length>0?
            <>
            <Radio.Group className="payment-radio-main" onChange={onChange} value={value || userCards.find(card => card.isPrimary)?.id}>
                {userCards.map((card, index) => (
                    <Radio style={{ border:(value === card?.id || (!value && card?.isPrimary) )?'':''}} className="payment-radio" value={card?.id} key={card?.id}>
                        <Card key={index} style={{ marginBottom: '10px' }}>
                            <Flex horizontal="true" gap="small">
                                <Flex vertical>
                                    <Image src={getCardImage(card?.cardType)} width={46} preview={false} />
                                </Flex>
                                <Flex vertical style={{ flex: 1 }}>
                                    <Text strong>{card?.cardType} ending in {card?.last4Digits}</Text>
                                    <div style={{ float: 'right' }}>
                                       
                                    {(value === card?.id || (!value && card?.isPrimary)) && (
                                       <Form.Item
                                            
                                            label="CVV"
                                            style={{ marginBottom: 0 }}
                                            className="custom-form-item cvv-code-input"
                                            value={cvvNumber}
                                            rules={[
                                                (value === card?.id || (!value && card?.isPrimary)) === card?.id ? { required: true, message: 'Please enter CVV' } : null,
                                                { validator: validateCVV },
                                              ].filter(Boolean)}
                                            >
                                        <Input 
                                        style={{ width: '55px', textAlign: 'center' }} 
                                        type="password" placeholder="•••"
                                        onChange={handleCVVChange}
                                        maxLength={4}
                                        
                                         />
                                    </Form.Item>)}
                                  
                                    </div>
                                </Flex>
                            </Flex>
                        </Card>
                    </Radio>
                ))}
                <Radio className="payment-card-btn" value={"newcard"} key={`addNewcard`}>
                    <Card key={`addNewcard`}>
                        Add New card
                    </Card>
                </Radio>
             </Radio.Group>
            {value && value=="newcard" && (
             <Card className="payment-new-card" key={`CardDetails`} style={{ marginBottom: '10px' }}>
                <Title level={4} style={{ marginTop: 0, fontSize: 18 }}>Card Details</Title>
                {/* Name on Card */}
                <Form.Item
                    name="cardholderName"
                    label="Name on Card"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the name on card' }]}
                    className="custom-form-item"
                >
                    <Input placeholder="Enter name on card" />
                </Form.Item>
        
                {/* Card Number */}
                <Form.Item
                    name="cardNumber"
                    label="Card Number"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ validator: validateCardNumber }]}
                    className="custom-form-item"
                >
                    <InputMask
                        mask="9999 9999 9999 9999"
                        maskChar="_"
                        placeholder="1234 1234 1234 1234"
                    />
                </Form.Item>
        
                {/* Expiry and CVV */}
                <div style={{ display: 'flex', gap: '16px' }}>
                    <Form.Item
                    name="expirationDate"
                    label="Expiry"
                    style={{ width: '120px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ validator: validateExpiryDate }]}
                    className="custom-form-item"
                    >
                        <InputMask mask="99/9999" maskChar="_" placeholder="MM / YYYY" />
                    </Form.Item>
        
                    <Form.Item
                        name="securityCode"
                        label="CVV"
                        style={{ width: '120px' }}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ validator: validateCVV }]}
                        className="custom-form-item"

                        >
                        <Input type="password" placeholder="•••"  maxLength={4}  onChange={handleCVVChange} />
                    </Form.Item>
                </div>
                <Form.Item
                    name="isSavecard"
                    valuePropName="checked"
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item checkox-label"
                    style={{ marginTop: 10 }}
                >
                    <Checkbox>
                    Save for future use
                    </Checkbox>
                </Form.Item>
                </Card>)}

                <Form.Item
                    name="terms"
                    valuePropName="checked"
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item checkox-label"
                    style={{  }}
                    // rules={[{ required: true, message: 'Please agree to the Terms of Use!' }]}
                    rules={[
                    {
                        validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error('Please agree to the Terms of Use!')),
                    },
                    ]}
                >
                    <Checkbox>
                    I have read and agree to the <a href="/">Terms of Service</a> & <a href="/">Privacy Policy</a>
                    </Checkbox>
                </Form.Item>
    
                <Flex vertical gap="middle" style={{ marginTop: 25 }}>
                    <Button disabled={!cvvNumber} type="primary" size="large" htmlType="submit" style={{ width: '100%', fontWeight: 600 }}>Pay now</Button>
                    <Button onClick={()=>SetUnLockHandler(false)} type="text" size="large" style={{ width: '100%', fontWeight: 600 }}>Cancel</Button>
                </Flex>
             </>
          :
          <Flex vertical>
                <Title level={3} style={{ textAlign: 'center', marginBottom: '10px' }}>Billing address</Title>
                <Form.Item 
                    name="firstName" 
                    label="First Name" 
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item" 
                    rules={[{ required: true, message: 'Please enter your first name' }]}
                >
                    <Input placeholder="Enter first name" />
                </Form.Item>
                <Form.Item 
                    name="lastName"
                    label="Last Name" 
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item" 
                    rules={[{ required: true, message: 'Please enter your last name' }]}
                >
                    <Input placeholder="Enter last name" />
                </Form.Item>
                <Form.Item 
                    name="address1" 
                    label="Address 1" 
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item" 
                    rules={[{ required: true, message: 'Please enter your address' }]}
                >
                    <Input placeholder="Enter address number" />
                </Form.Item>
                <Form.Item 
                    name="address2"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item"
                    label="Address 2 (Optional)"
                >
                    <Input placeholder="Enter account number" />
                </Form.Item>
                <Flex horizontal="true" gap="middle">
                    <Form.Item 
                        name="city" 
                        label="City" 
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        className="custom-form-item"
                        style={{ width: '50%' }}
                        rules={[{ required: true, message: 'Please enter your city' }]}
                    >
                        <Input placeholder="Enter City" />
                    </Form.Item>
                    <Form.Item 
                        name="state" 
                        label="State/Province" 
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        className="custom-form-item"
                        style={{ width: '50%' }}
                        rules={[{ required: true, message: 'Please select your state/province' }]}
                    >
                        <Select placeholder="Select">
                        {statesOrProvinces.map(state => (
                                <Option key={state.value} value={state.value}>
                                {state.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Flex>
                <Form.Item 
                    name="zip" 
                    label="Zip/Postal Code" 
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item"
                    style={{ width: '70%' }}
                    rules={[{ required: true, message: 'Please enter your zip/postal code' }]}
                >
                    <Input placeholder="Enter Zip/Postal Code" />
                </Form.Item>
           
                {/* Name on Card */}
                <Form.Item
                    name="cardholderName"
                    label="Name on Card"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the name on card' }]}
                    className="custom-form-item"
                >
                    <Input placeholder="Enter name on card" />
                </Form.Item>
        
                {/* Card Number */}
                <Form.Item
                    name="cardNumber"
                    label="Card Number"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ validator: validateCardNumber }]}
                    className="custom-form-item"
                >
                    <InputMask
                        mask="9999 9999 9999 9999"
                        maskChar="_"
                        placeholder="1234 1234 1234 1234"
                    />
                </Form.Item>
        
              
                <Flex horizontal="true" gap="middle">
                <Form.Item
                    name="expirationDate"
                    label="Expiry"
                    style={{ width: '120px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ validator: validateExpiryDate }]}
                    className="custom-form-item"
                    >
                        <InputMask mask="99/9999" maskChar="_" placeholder="MM / YYYY" />
                    </Form.Item>
        
                    <Form.Item
                        name="securityCode"
                        label="CVV"
                        style={{ width: '120px' }}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ validator: validateCVV }]}
                        className="custom-form-item"
                        >
                        <Input type="password"  maxLength={4} placeholder="•••" />
                    </Form.Item>
                </Flex>
                <Form.Item
                    name="isSavecard"
                    valuePropName="checked"
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item checkox-label"
                    style={{ marginTop: 10 }}
                >
                    <Checkbox>
                    Save for future use
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    name="terms"
                    valuePropName="checked"
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item checkox-label"
                    rules={[
                    {
                        validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error('Please agree to the Terms of Use!')),
                    },
                    ]}
                >
                    <Checkbox>
                    I have read and agree to the <a href="/">Terms of Service</a> & <a href="/">Privacy Policy</a>
                    </Checkbox>
                </Form.Item>
    
                <Flex vertical gap="middle" style={{ marginTop: 25 }}>
                    <Button type="primary" size="large" htmlType="submit" style={{ width: '100%', fontWeight: 600 }}>Accept & Unlock</Button>
                    <Button onClick={()=>SetUnLockHandler(false)} type="text" size="large" style={{ width: '100%', fontWeight: 600 }}>Cancel</Button>
                </Flex>
                
            </Flex>
          }
           </Form>
            </Spin>
            <div className="billing-inner-footer" style={{ paddingTop: 20, paddingBottom: 10 }}>
              <FooterComponent />
            </div>
        </Flex>
    );
}

export default Payment;