import React,{useEffect,useState} from 'react';
import { Layout, List, Flex, Button, Typography, Image, Spin,Modal } from 'antd';
import { get } from '../../../utils/api';
import AvatarImage from '../../../components/AvatarImage';
import { formatPrice } from '../../../utils/utils';
import { openNotification } from '../../../utils/utils';
import DeleteIcon from "../../../images/delete-icon-bg.svg";
import { post } from '../../../utils/api';
import { useParams,Link } from 'react-router-dom';
import { ArrowLeftOutlined, EyeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
const { Title, Text } = Typography;
const { confirm } = Modal;
const RequestDetails = () => {

    const [isLoading,setIsLoading]=useState(false);

    const [isTransactionHistory, setTransactionHistory] = useState(false);
    const [isTransactionData, setTransactionData] = useState(null);
    const [titleText, setTitleText] = useState(null);
    const [requestStatus, setRequestStatus] = useState(null);
    const { requestId } = useParams();
    const init=async()=>{
      setIsLoading(true);
      try{
        const response=await get(`user/myrequests/${requestId}`);
        if(response.status){
          const collection=response?.data;
          setTransactionData(collection);
          if(collection?.status==0 && collection?.payment_status==0){
            setTitleText('Request Pending!');  
            setRequestStatus('Pending');
          }else if(collection?.status==1 && collection?.payment_status==1){
            setTitleText('Request Transaction');  
            setRequestStatus('Transaction');
          }else if(collection?.status==2){
            setTitleText('Request Declined'); 
            setRequestStatus('Declined'); 
          }else{
            setTitleText('Request Expired'); 
            setRequestStatus('Expired');  
          }
        }
      }catch(error){
        console.log("error",error);
      }finally{
        setIsLoading(false);
      }
    }
    useEffect(()=>{
      init();
    },[]);

    const deleteconfirm=async()=>{
     
      Modal.confirm({
        title: `Cancel request?`,
        icon: <Image src={DeleteIcon} preview={false} />,
        content: `Are you sure you want to cancel your request? This action cannot be undone.`,
        okText: 'Confirm',
        okButtonProps: {
          style: {
            backgroundColor: '#D92D20',
            borderColor: '#D92D20',
            fontWeight: 'bold',
            color: 'white'       
          },
          className: 'custom-leave-button'
        },
        cancelButtonProps: {
          style: {
            backgroundColor: 'white',
            borderColor: '#d9d9d9',
            fontWeight: 'bold',
            color: '#475467'
          },
          className: 'custom-cancel-button'
        },
        async onOk() {
          try {
            setIsLoading(true);
            const response = await post('creator/private/status', { id:isTransactionData?.id, requestType:'Decline' });
            if (response.status) {
              openNotification(`Request has been cancelled successfully.`);
              await init();
            }
          } catch (error) {
            console.log("error", error);
          } finally {
            setIsLoading(false);
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });
      
     }

  return (
    <Layout>
      <Spin spinning={isLoading}>
       <Flex vertical style={{ padding: 20, height: 'calc(100vh - 60px)' }}>
      <Flex style={{ marginBottom: 10 }}>
        <Link
          style={{ padding: 0, fontWeight: 600, marginBottom: 5, background: 'transparent' }}
          to={`/audience/myrequests`}
        >
        <ArrowLeftOutlined style={{ color: '#101828' }}/>
        Back
        </Link>
      </Flex>
        <Flex vertical style={{ textAlign: 'center', flex: 1 }}>
          {titleText && <Title level={3} style={{ marginTop: 0, marginBottom: 20 }}>{titleText}</Title>}
          <Flex vertical align="center" style={{ marginBottom: 15 }}>
            <AvatarImage imageId={isTransactionData?.creator?.uploadedIds[0]} size={48} font={16} title={`${isTransactionData?.creator?.first_name} ${isTransactionData?.creator?.last_name}`}   />
          </Flex>

          <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 15 }}>
            {isTransactionData?.creator?.first_name && 
            <span style={{ textTransform: 'capitalize' }}>{isTransactionData?.creator?.first_name} {isTransactionData?.creator?.last_name}
            </span>} & 
            {isTransactionData?.user?.first_name && <span style={{ textTransform: 'capitalize' }}> {isTransactionData?.user?.first_name} {isTransactionData?.user?.last_name}</span>}
          </Title>

          <Text style={{ marginBottom: 20 }}>{isTransactionData?.title}</Text>
          <Text style={{ marginBottom: 20 }}>{isTransactionData?.message}</Text>
         {requestStatus=="Pending" && 
          <>{isTransactionData?.request_type &&
            <Flex justify="space-between" style={{ padding: '12px 0', borderBottom: '1px solid #EAECF0' }}>
              <Text style={{ fontWeight: 600 }}>{isTransactionData?.request_type=="standard"?`Standard 2-5 days`:'24 hours or less'}</Text>
              <Text>{formatPrice(isTransactionData?.price || 0)}</Text>
            </Flex>
          }
          <Flex justify="space-between" style={{ padding: '12px 0', borderBottom: '1px solid #EAECF0' }}>
              <Text style={{ fontWeight: 600 }}>Booking fee</Text>
              <Text>{formatPrice(1)}</Text>
          </Flex></>}
          {(requestStatus=="Declined" || requestStatus=="Expired") && 
            <Flex justify="space-between" style={{ padding: '12px 0', borderBottom: '1px solid #EAECF0' }}>
              <Text style={{ fontWeight: 600 }}>Date</Text>
              <Text>{moment(isTransactionData?.createdAt).format('MM/DD/YYYY')}</Text>
          </Flex>}
          <Flex justify="space-between" style={{ padding: '12px 0', borderBottom: '1px solid #EAECF0' }}>
            <Text style={{ fontWeight: 600 }}>Total</Text>
            <Text>{formatPrice(parseFloat(isTransactionData?.price)+1 || 0)}</Text>
          </Flex>
          {(requestStatus=="Declined" || requestStatus=="Expired") && 
            <Flex justify="space-between" style={{ padding: '12px 0', borderBottom: '1px solid #EAECF0' }}>
              <Text style={{ fontWeight: 600 }}>Confirmation</Text>
              <Text>0000{isTransactionData?.id}</Text>
          </Flex>}
        </Flex>
        {(isTransactionData?.status==0 && isTransactionData?.payment_status==0) ?
        <Flex gap={20} vertical style={{ padding: '15px 0', textAlign: 'center' }}>
              <Link style={{color: '#fff',
                fontSize: '16px',
                lineHeight: '1.5',
                height: '40px',
                padding: '7px 15px',
                borderRadius: '8px',
                background: '#7f56d9'}} to={`sms://+1${isTransactionData?.creator?.assign_number}`}>Message</Link>
                
                <Button onClick={deleteconfirm}>Cancel Booking</Button>
        </Flex>:
        <Flex gap={20} vertical style={{ padding: '15px 0', textAlign: 'center' }}>
            <Link to={`/audience/new-request/${btoa(isTransactionData?.id)}`} style={{color: '#fff',
                fontSize: '16px',
                lineHeight: '1.5',
                height: '40px',
                padding: '7px 15px',
                borderRadius: '8px',
                background: '#7f56d9'}}>New Request</Link>
        </Flex>}
        <Flex gap={20} vertical style={{ padding: '15px 0', textAlign: 'center' }}>
          {/*<Button type="primary" size="large" style={{ fontWeight: 600, paddingTop: 12, paddingBottom: 12, height: 'auto' }}>New Request</Button>*/}
          <Text style={{ color: '#6941C6', fontWeight: 600 }}><InfoCircleOutlined style={{ fontSize: '18px', marginRight: 5 }} /> How do refunds work?</Text>
        </Flex>

      </Flex>
      </Spin>
    </Layout>
  );
};

export default RequestDetails;
