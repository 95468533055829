import React, { useState,useEffect } from 'react';
import { Dropdown, Button, Flex, Radio, Image, Slider, Typography } from 'antd';
import FilterIcon from '../../images/filter-funnel.svg';

const { Title } = Typography;

const FilterMenu = ({setFilter}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedGender, setSelectedGender] = useState(null);
  const [ageRange, setAgeRange] = useState([16, 25]);


  

  const clearAllFilters = () => {
    setSelectedGender(null);
    setAgeRange([16, 25]);
    setFilter({});
  };

  const applyFilters = () => {
    setFilter({selectedGender,ageRange});
    setDropdownVisible(false);
  };

  const menuStyle = {
    background: '#FFFFFF',
    borderRadius: 8,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    width: 288,
  };

  const headerStyle = {
    margin: 0,
    fontSize: '14px',
    color: '#344054',
    padding: '10px 15px',
    borderBottom: '1px solid #EAECF0'
  };

  const radioGroupStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  };

  const radioStyle = {
    color: '#344054',
  };

  const sliderStyle = {
    marginTop: 8,
  };

  const ageDisplayStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
    color: '#475467',
    fontSize: '14px',
  };

  const footerStyle = {
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const filterMenu = (
    <div style={menuStyle} className="reports-filter">
      {/* Gender Section */}
      <Flex vertical>
        <Title level={5} style={headerStyle}>Gender</Title>
        <Flex vertical style={{ padding: 15 }}>
          <Radio.Group 
            value={selectedGender} 
            onChange={e => setSelectedGender(e.target.value)}
            style={radioGroupStyle}
          >
            <Radio value="male" style={radioStyle}>Male</Radio>
            <Radio value="female" style={radioStyle}>Female</Radio>
            <Radio value="non-binary" style={radioStyle}>Non-Binary</Radio>
          </Radio.Group>
        </Flex>
      </Flex>

      {/* Age Section */}
      <Flex vertical>
        <Title level={5} style={headerStyle}>Age</Title>
        <Flex vertical style={{ padding: '15px 15px 0px 15px' }}>
          <Slider
            range
            min={16}
            max={100}
            value={ageRange}
            onChange={setAgeRange}
            style={sliderStyle}
          />
          <div style={ageDisplayStyle}>
            <span>{ageRange[0]}</span>
            <span>{ageRange[1]}</span>
          </div>
        </Flex>
      </Flex>

      {/* Footer Buttons */}
      <div style={footerStyle}>
        <Button
          onClick={clearAllFilters}
          size="large"
          style={{ fontWeight: 600 }}
          disabled={!selectedGender && ageRange[0] === 16 && ageRange[1] === 25}
        >
          Clear all
        </Button>
        <Button
          onClick={applyFilters}
          type="primary"
          size="large"
          style={{ fontWeight: 600 }}
          disabled={!selectedGender && ageRange[0] === 16 && ageRange[1] === 25}
        >
          Apply
        </Button>
      </div>
    </div>
  );

  return (
    <Dropdown 
      overlay={filterMenu} 
      trigger={['click']} 
      open={dropdownVisible} 
      onOpenChange={setDropdownVisible}
      placement="bottomRight"
    >
      <Button style={{ height: 40, color: '#344054', fontWeight: 600 }} icon={<Image src={FilterIcon} preview={false} />}>
        Filter
      </Button>
    </Dropdown>
  );
};

export default FilterMenu;