import React, { useState,useEffect,useContext } from 'react';
import { Flex, Avatar, Typography, Button, Layout, Form, Input } from 'antd';
import { ArrowLeftOutlined, InfoCircleOutlined, UserOutlined, RightOutlined } from '@ant-design/icons';
import { useParams,Link } from 'react-router-dom';
import MyselfForm from '../../../components/Audience/PrivateRequests/MyselfForm';
import ForFriendForm from '../../../components/Audience/PrivateRequests/ForFriendForm';
import { UserContext } from '../../../contexts/UserContext';
import UserImg from '../../../images/avatar-image.png';
import './PrivateRequests.css';
import { get } from '../../../utils/api';
import AvatarImage from '../../../components/AvatarImage';
const { Title, Text } = Typography;
const { Content } = Layout;

const NewPrivateRequest = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const { requestId } = useParams();
    const [data,setData]=useState(null);
    const [creatorData,setCreatorData]=useState(null);
    const [userName,setUserName]=useState(null);
    const [userFullName,setUserFullName]=useState(null);
    const {user}  = useContext(UserContext);
    const smsId=null;
    useEffect(()=>{
      const init=async()=>{
        const response=await get(`user/myrequests/${requestId}`);
          if (response && response.status) {
                setCreatorData(response?.data?.creator);
            }
      }
      init();
  },[requestId]);
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setCurrentStep(0)
    };

    const handleBackClick = () => {
        if (currentStep === 0) {
          setSelectedOption(null);
        } else {
          if(currentStep==1 && selectedOption && selectedOption=="myself"){
            setSelectedOption(null);
          }else{
            setCurrentStep((prevStep) => prevStep - 1);
          }
         
        }
    };

    useEffect(()=>{
        if(selectedOption && selectedOption=="myself"){
          setUserFullName(`${user?.first_name} ${user?.last_name}`);
        }
    },[selectedOption]);

    return (
        <Layout style={{ minHeight: '100vh'}}>
          <Content style={{ display: 'flex', flexDirection: 'column', padding: '25px 20px' }}>
            <Flex vertical style={{ flex: 1 }}>
              {selectedOption===null? 
              <Flex vertical className="private-head">
                <Link to={`/audience/myrequests/${requestId}`}><Button className="back-btn" type="text" icon={<ArrowLeftOutlined />} size="large" style={{ fontWeight: 600, fontSize: '14px', height: 20, width: 20, lineHeight: 'normal' }}></Button></Link>
                <Title level={4} style={{ textAlign: 'center', color: '#475467', marginTop: '0px', marginBottom: '0px', fontSize: '14px' }}>New Request</Title>
                <Link to={`/audience/myrequests/${requestId}`}><Button className="cancel-btn" type="text" size="large" style={{ color: '#6941C6', fontWeight: 600, padding: 0, height: 'auto', border: 0, lineHeight: 'normal' }}>Cancel</Button></Link>
              </Flex>
              : <Flex vertical className="private-head">
                <Button className="back-btn" type="text" icon={<ArrowLeftOutlined />} size="large" onClick={handleBackClick} style={{ fontWeight: 600, fontSize: '14px', height: 20, width: 20, lineHeight: 'normal' }}></Button>
                <Title level={4} style={{ textAlign: 'center', color: '#475467', marginTop: '0px', marginBottom: '0px', fontSize: '14px' }}>New Request</Title>
                {/* <Button className="cancel-btn" type="text" size="large" style={{ color: '#6941C6', fontWeight: 600, padding: 0, height: 0, border: 0, lineHeight: 'normal' }}>Cancel</Button> */}
              </Flex>}
             
              {creatorData && 
              <Flex vertical align="center" style={{ marginBottom: 0 }}>
             
             <AvatarImage imageId={creatorData && creatorData?.uploadedIds && creatorData.uploadedIds.length > 0 ? creatorData.uploadedIds[0] : null} title={`${creatorData?.first_name} ${creatorData?.last_name}`} />
                <Title level={4} style={{ textAlign: 'center', marginTop: '20px', marginBottom: '10px', fontSize: '16px', color: '#344054' }}>{creatorData?.first_name} {creatorData?.last_name} 
                  {/*{userName && <span> & {userName}</span>}*/}
                  {userFullName && <span> & {userFullName}</span>}
                  </Title> 
              </Flex>}

              {selectedOption === null ? (
                <Flex vertical gap={20} style={{ marginTop: 20 }}>
                    <Flex align="center" gap={20} style={{ color: '#344054', fontSize: '16px', fontWeight: 600, border: '1px solid #EAECF0', padding: '15px 16px', borderRadius: 12 }} onClick={() => handleOptionClick('myself')}>
                        <Avatar size={48} src={UserImg} />
                        Myself
                        <RightOutlined style={{ marginLeft: 'auto' }} />
                    </Flex>
                    <Flex align="center" gap={20} style={{ color: '#344054', fontSize: '16px', fontWeight: 600, border: '1px solid #EAECF0', padding: '15px 16px', borderRadius: 12 }} onClick={() => handleOptionClick('friend')}>
                        <Avatar size={48} style={{ background: '#F4EBFF', fontSize: 26, color: '#7F56D9' }}><UserOutlined /></Avatar>
                        For a Friend
                        <RightOutlined style={{ marginLeft: 'auto' }} />
                    </Flex>
                </Flex>
              ) : (
                  <MyselfForm
                    onPrev={handleBackClick}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    creatorData={creatorData}
                    setUserName={setUserName}
                    selectedOption={selectedOption}
                    smsId={smsId}
                  />
              )}
            </Flex>

            <Flex vertical align="center" style={{ marginTop: 15 }}>
              <Link to="/payment-terms-conditions" target='_blank'><Text style={{ color: '#6941C6', fontWeight: 600 }}>
                <InfoCircleOutlined style={{ marginRight: 8, fontSize: '18px', verticalAlign: 'text-bottom' }} />
                How do refunds work?
              </Text></Link>
            </Flex>
          </Content>
        </Layout>
    );
};

export default NewPrivateRequest;
