import React, { useContext,useRef,useState,useEffect,useMemo } from 'react';
import { Typography, Layout, Flex, Button, Card, Dropdown, Select, Divider, Image, message ,Modal,Spin} from 'antd';
import {ExclamationCircleFilled, DownOutlined} from '@ant-design/icons';
import NewMessage from '../../components/GlobalComponents/NewMessage';
import { QRCodeSVG } from 'qrcode.react';
import { UserContext } from '../../contexts/UserContext';
import { openNotification } from '../../utils/utils';
import useScreenSize from '../../utils/useScreenSize';
import CopyIcon from "../../images/copy-icon.svg";
import QRCodeImg from "../../images/qr-code-img.png";
import ArrowRight from "../../images/arrow-right.svg";
import InboxTray from "../../images/inbox-tray.png";
import CakeIcon from "../../images/birthday-cake.png";
import MoneyWithWings from "../../images/money-with-wings.png";

import HandIcon from "../../images/hand.svg";
import CalendarIcon from "../../images/spiral-calendar.png";
import Robot from "../../images/Robot.png";

import './home.css';

import { Link } from 'react-router-dom';
import { get } from '../../utils/api';
const { Text, Title } = Typography;
const { confirm } = Modal;
const Home = () => {
	const {user}  = useContext(UserContext);
	const svgRef = useRef(null);
	const [isNewMessageVisible, setIsNewMessageVisible] = useState(false);  
	const [basePrice,setBasePrice]=useState(0.99);
	const [selectedPrice, setSelectedPrice] = useState(null);
	const [open, setOpen] = useState(false);

	const [settinginfo, setSettinginfo] = useState(null);
	const [birthdayCount, setBirthdayCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [position, setPosition] = useState('end');
	const { isMobile } = useScreenSize();
	const [dropdownVisible, setDropdownVisible] = useState(false);

	const onClose = () => {
		setIsNewMessageVisible(false);
		setOpen(false);
	};

	const handleNewMessageClose = () => {
		confirm({
		  title: 'Are you sure you want to leave?',
		  icon: <ExclamationCircleFilled style={{ fontSize: 35, color: '#7F56D9', marginBottom: 10 }} />,
		  content: 'This message will be closed.',
		  okText: 'Leave',
		  okButtonProps: {
			style: {
			  backgroundColor: '#6941C6',
			  borderColor: '#6941C6',
			  fontWeight: 'bold',
			  color: 'white'       
			},
			className: 'custom-leave-button'
		  },
		  cancelButtonProps: {
			style: {
			  backgroundColor: 'white',
			  borderColor: '#d9d9d9',
			  fontWeight: 'bold',
			  color: '#475467'
			},
			className: 'custom-cancel-button'
		  },
		  okType: 'danger',
		  onOk() {
			setIsNewMessageVisible(false);
			
			setOpen(false);
		  },
		  onCancel() {
			console.log('Cancel');
		  },
		});
	  };

	const downloadStringAsFile = (data, filename) => {
	    const link = document.createElement('a');
	    link.download = filename;
	    link.href = data;
	    document.body.appendChild(link);
	    link.click();
	    document.body.removeChild(link);
	};

	const handleCopy = () => {
	    const inputValue = document.querySelector('.input-number').textContent;
	    console.log(inputValue, 'inputValue');
	    navigator.clipboard.writeText(`${inputValue}`).then(() => {
	      openNotification('Copied to clipboard!');
	    }).catch(err => {
	      message.error('Failed to copy!');
	    });
	};

	const handleCopyUrl = () => {
	    const inputValue = document.querySelector('.input-number-url').textContent;
	    console.log(inputValue, 'inputValue');
	    navigator.clipboard.writeText(`${inputValue}`).then(() => {
	      openNotification('Copied to clipboard!');
	    }).catch(err => {
	      message.error('Failed to copy!');
	    });
	};

	const handleChange = (value) => {
	  console.log(`selected ${value}`);
	};
	
	const QRCodeGenerator = () => {
	  const [selectedFormat, setSelectedFormat] = useState('SVG');
	  const urlToShare = `${process.env.REACT_APP_APPLICATION_URL}/contact-info/${user?.user_name}`;
	  
	  const handleSVGDownload = () => {
	    const node = svgRef.current;
	    if (!node) return;
	    
	    const serializer = new XMLSerializer();
	    const fileURI = 'data:image/svg+xml;charset=utf-8,' +
	      encodeURIComponent(
	        '<?xml version="1.0" standalone="no"?>' +
	        serializer.serializeToString(node)
	      );

	    downloadStringAsFile(fileURI, 'qrcode.svg');
	  };

	  const handlePNGDownload = () => {
	    const node = svgRef.current;
	    if (!node) return;

	    const canvas = document.createElement('canvas');
	    const serializer = new XMLSerializer();
	    const svgStr = serializer.serializeToString(node);

	    const img = new window.Image();
	    img.src = 'data:image/svg+xml;base64,' + btoa(svgStr);

	    img.onload = () => {
	      canvas.width = img.width * 2;
	      canvas.height = img.height * 2;
	      const ctx = canvas.getContext('2d');
	      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
	      const pngFile = canvas.toDataURL('image/png');
	      downloadStringAsFile(pngFile, 'qrcode.png');
	    };
	  };

	  	const items = [
		  {
		    key: 'SVG',
		    label: 'SVG'
		  },
		  {
		    key: 'PNG',
		    label: 'PNG'
		  }
		];

		const menuStyle = {
		  background: '#FFFFFF',
		  borderRadius: 8,
		  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
		  padding: '8px 0',
		  width: '120px'
		};

		const menuItemStyle = {
		  padding: '8px 16px',
		  color: '#344054',
		  fontSize: '14px',
		  cursor: 'pointer',
		  transition: 'background-color 0.3s',
		  display: 'block',
		  width: '100%',
		  textAlign: 'left',
		  border: 'none',
		  backgroundColor: 'transparent'
		};

	  	const filterMenu = (
		  <div style={menuStyle}>
		    {items.map(item => (
		      <button
		        key={item.key}
		        onClick={() => {
		          if (item.key === 'PNG') {
		            handlePNGDownload();
		          } else {
		            handleSVGDownload();
		          }
		          setDropdownVisible(false);
		        }}
		        style={menuItemStyle}
		        onMouseEnter={e => e.target.style.backgroundColor = '#F9FAFB'}
		        onMouseLeave={e => e.target.style.backgroundColor = 'transparent'}
		      >
		        {item.label}
		      </button>
		    ))}
		  </div>
		);

	  const handleMenuClick = ({ key }) => {
	    setSelectedFormat(key);
	    if (key === 'PNG') {
	      handlePNGDownload();
	    } else {
	      handleSVGDownload();
	    }
	  };

	  return (
	    <Flex gap={30} horizontal align="top">
	      <Flex vertical align="start">
	        <Text style={{ marginBottom: 5 }}>Share my QR Code</Text>
	        <Dropdown 
			    overlay={filterMenu} 
			    trigger={['click']} 
			    open={dropdownVisible} 
			    onOpenChange={setDropdownVisible}
			>
			    <Button style={{ height: 40, color: '#344054', fontWeight: 600 }}>
			      Download <DownOutlined style={{ marginLeft: 8 }} />
			    </Button>
			</Dropdown>

	        <Text style={{ marginTop: 5 }}>
	          <b>Where to use:</b> On screen at live events, print, and in-store promotions.
	        </Text>
	      </Flex>
	      <Flex style={{ width: '100px' }}>
	        <QRCodeSVG 
	          ref={svgRef}
	          value={urlToShare} 
	        />
	      </Flex>
	    </Flex>
	  );
	};

	  const showNewMessage = () => {
		setIsNewMessageVisible(true);
		setOpen(true);
	  };


	  const timeGreeting=()=>{
		const currentHour = new Date().getHours();
  
	  // Determine the greeting based on the time of day
		let greeting = '';
		
		if (currentHour >= 12 && currentHour < 18) {
			greeting = 'Good Afternoon';
		} else if (currentHour >= 18) {
			greeting = 'Good Evening';
		} else {
			greeting = 'Good Morning';
		}
		return greeting;
	  }

	  const init = async (id) => {
		try {
		  setLoading(true);
		  const response = await get(`/creator/settings/${id}`);
		  if (response && response.data) {
			setSettinginfo(response.data);
		  }
		  const usersRsp = await get(`/creator/birthday-users`);
		  if (usersRsp && usersRsp?.count) {
			setBirthdayCount(usersRsp?.count);
		  }
		} catch (error) {
		  console.log('error', error);
		} finally {
		  setLoading(false);
		}
	  };
	  useEffect(() => {
		init(user?.id);
	  }, [user?.id]);	
	  
	return(
		<Spin spinning={loading}>
		<Layout style={{ background: '#F9FAFB', minHeight: '100vh', padding: isMobile ? 16 : 24 }}>
			<Flex vertical={isMobile ? true : false} align={isMobile ? 'start' : 'center'} justify="space-between" style={{ marginBottom: 20 }}>
				<Title level={2} style={{ fontSize: isMobile ? 24 : 36, marginTop: 0, marginBottom: isMobile ? 12 : 0 }}>{timeGreeting()}, {user?.first_name} {user?.last_name}</Title>
				<Button block={isMobile ? true : false} size="large" type="primary" style={{ fontWeight: 600 }}  onClick={showNewMessage}>New Message</Button>
			</Flex>
			{isNewMessageVisible &&
				<NewMessage basePrice={basePrice} setSelectedPrice={setSelectedPrice} visible={isNewMessageVisible} handleCancel={handleNewMessageClose} onClose={onClose} open={open} />
			}

			<Flex vertical={isMobile ? true : false} gap={40}>
				<Flex vertical style={{ flex: '1' }}>
			      	<Title level={3} style={{ marginBottom: 30 }}>Things to do</Title>
					{settinginfo?.welcome_message?
					<>
					<Link to={`/private-requests`}>
				      	<Flex horizontal gap={15} align="center" className="row-grid-style">
				      		<Flex vertical>
				      			<Image src={InboxTray} preview={false} />
				      		</Flex>
					      	<Flex vertical>
						      	<Title level={5} style={{ margin: 0 }}>Open Requests</Title>
						      	<Text>You have # open request for $# to submit.</Text>
					      	</Flex>
					      	<Flex style={{ marginLeft: 'auto' }}>
				      		<Button type="text" style={{ padding: 0 }}><Image src={ArrowRight} preview={false} /></Button>
				      		</Flex>
				      	</Flex>
					</Link>

					<Link to={`/schedule-message`}>
						<Flex horizontal gap={15} align="center" className="row-grid-style">
							
							<Flex vertical>
								<Image src={CalendarIcon} preview={false} />
							</Flex>
							<Flex vertical>
								<Title level={5} style={{ margin: 0 }}>Scheduled messages</Title>
								<Text>View and edit them here</Text>
							</Flex>
							<Flex style={{ marginLeft: 'auto' }}>
								<Button type="text" style={{ padding: 0 }}><Image src={ArrowRight} preview={false} /></Button>
							</Flex>	
						</Flex>
					</Link>

					{birthdayCount>0 &&
				      	<Flex horizontal gap={15} align="center" className="row-grid-style">
				      		<Flex vertical>
				      			<Image src={CakeIcon} preview={false} />
				      		</Flex>
					      	<Flex vertical>
						      	<Title level={5} style={{ margin: 0 }}>Birthdays Today ({birthdayCount})</Title>
						      	<Text>Send them a message!</Text>
					      	</Flex>
					      	<Flex style={{ marginLeft: 'auto' }}>
				      		<Button type="text" style={{ padding: 0 }}><Image src={ArrowRight} preview={false} /></Button>
				      		</Flex>
				      	</Flex>
				    }

					<Link to={`/total-fans-details`}>
				      	<Flex horizontal gap={15} align="center" className="row-grid-style">
				      		<Flex vertical>
				      			<Image src={HandIcon} preview={false} />
				      		</Flex>
					      	<Flex vertical>
						      	<Title level={5} style={{ margin: 0 }}>New Members</Title>
						      	<Text>Send them a message!</Text>
					      	</Flex>
					      	<Flex style={{ marginLeft: 'auto' }}>
				      		<Button type="text" style={{ padding: 0 }}><Image src={ArrowRight} preview={false} /></Button>
				      		</Flex>
				      	</Flex>
			      	</Link>
					  
					</>
					  : <>
					  	<Link to={`/settings`}>
							<Flex horizontal gap={15} align="center" className="row-grid-style">
								<Flex vertical>
									<Image src={Robot} preview={false} />
								</Flex>
								<Flex vertical>
									<Title level={5} style={{ margin: 0 }}>Set up automation!</Title>
									<Text>Enhance your customer experience by customizing your signup Confirmation Message and welcome messages.</Text>
								</Flex>
								<Flex style={{ marginLeft: 'auto' }}>
								<Button type="text" style={{ padding: 0 }}><Image src={ArrowRight} preview={false} /></Button>
								</Flex>
							</Flex>
						</Link>
						<Link to={`/message/audience`}>
							<Flex horizontal gap={15} align="center" className="row-grid-style">
								<Flex vertical>
									<Image src={HandIcon} preview={false} />
								</Flex>
								<Flex vertical>
									<Title level={5} style={{ margin: 0 }}>Send your first message!</Title>
								</Flex>
								<Flex style={{ marginLeft: 'auto' }}>
								<Button type="text" style={{ padding: 0 }}><Image src={ArrowRight} preview={false} /></Button>
								</Flex>
							</Flex>
						</Link>
					  </>}

					  <Link to={`/revenue-report`}>
						<Flex horizontal gap={15} align="center" className="row-grid-style" style={{ marginBottom: 0 }}>
							
							<Flex vertical>
								<Image src={MoneyWithWings} preview={false} />
							</Flex>
							<Flex vertical>
								<Title level={5} style={{ margin: 0 }}>Recent Transactions</Title>
								<Text>View recent revenue here</Text>
							</Flex>
							<Flex style={{ marginLeft: 'auto' }}>
								<Button type="text" style={{ padding: 0 }}><Image src={ArrowRight} preview={false} /></Button>
							</Flex>
								
						</Flex>
					  </Link>
			    </Flex>

				<Flex vertical style={{ width: isMobile ? '100%' : '350px', boxShadow: 'rgba(16, 24, 40, 0.05) 0px 1px 2px 0px' }}>
					<Title level={3} style={{ marginBottom: 30, marginTop: isMobile ? 0 : '' }}>Sharing Tools</Title>
					<Flex vertical style={{ background: '#ffffff', border: '1px solid #EAECF0', borderRadius: '10px', padding: 20 }}>
						<Title level={4} style={{ margin: '0 0 15px 0' }}>Share my Blonde number</Title>

						<Text>Share my Blonde number</Text>
						<Flex align="center" style={{ border: '1px solid #D0D5DD', borderRadius: 10, marginTop: 5, marginBottom: 5 }}>
		                  <Text className="input-number" style={{ fontSize: 16, flex: '1', padding: 10 }}>+1{user.assign_number}</Text>
		                  <Button type="text" onClick={handleCopy} style={{ padding: '10px 10px', backgroundColor: 'transparent', borderLeft: '1px solid #D0D5DD', borderRadius: 0, height: 'auto' }}>
		                    <Image 
		                      width={20} 
		                      src={CopyIcon} 
		                      preview={false}
		                    />
		                    <Title level={5} style={{ margin: 0, fontSize: 14 }}>Copy</Title>
		                  </Button>
		                </Flex>

		                <Divider style={{ marginTop: 15, marginBottom: 20 }} />

		                <Text>Share my URL</Text>
						<Flex align="center" style={{ border: '1px solid #D0D5DD', borderRadius: 10, marginTop: 5, marginBottom: 5 }}>
		                  <Text className="input-number-url" style={{ fontSize: 16, flex: '1', padding: 10, whiteSpace: 'nowrap', overflow: 'hidden' }}>{process.env.REACT_APP_APPLICATION_URL}/contact-info/{user?.user_name}</Text>
		                  <Button type="text" onClick={handleCopyUrl} style={{ padding: '10px 10px', backgroundColor: 'transparent', borderLeft: '1px solid #D0D5DD', borderRadius: 0, height: 'auto' }}>
		                    <Image 
		                      width={20} 
		                      src={CopyIcon} 
		                      preview={false}
		                    />
		                    <Title level={5} style={{ margin: 0, fontSize: 14 }}>Copy</Title>
		                  </Button>
		                </Flex>
		               	<Text style={{ marginBottom: 15 }}>When your audience visits your Blonde URL or QR Code it will automatically open their messaging app and draft a text to your Blonde Number.</Text>

		                <Text><b>Where to use:</b> Email, social media including bios, posts, and stories.</Text>
							
		                <Divider />

		                <Flex gap={25} justify="space-between">
							<QRCodeGenerator/> 
		                </Flex>

					</Flex>
				</Flex>
			</Flex>
		
		</Layout>
		</Spin>
	);
};

export default Home;