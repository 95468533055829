import React, { useState,useEffect } from 'react';
import { Layout, Tabs, Card, Button, List, Flex, Typography, Input, Divider, Tooltip, Image,Spin,Modal,message ,Empty} from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, CreditCardOutlined, MailOutlined, InfoCircleOutlined, EyeOutlined } from '@ant-design/icons';
import AddNewCard from './AddNewCard';
import FanTransactionRequest from '../../../components/GlobalComponents/FanTransactionRequest';
import { openNotification } from '../../../utils/utils';
import { formatPrice } from '../../../utils/utils';
import { get } from '../../../utils/api';
import Icon1 from '../../../images/visa-icon.png';
import Icon2 from '../../../images/mastercard-icon.png';
import Icon3 from '../../../images/American-Express.png';
import DeleteIcon from "../../../images/delete-icon-bg.svg";
import ArrowRightIcon from "../../../images/arrow-right.svg";
import { post } from '../../../utils/api';
import './Billing.css';

import moment from "moment";

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Content } = Layout;

const Billing = () => {
  const [showAddCard, setShowAddCard] = useState(false);
  const [myrequests,setMyrequests]=useState([]);
  const [userCards,setUserCards]=useState([]);
  const [isLoading,setIsLoading]=useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [isTransactionHistory, setTransactionHistory] = useState(false);
  const [isTransactionData, setTransactionData] = useState(null);

  const cardImages={
    Visa:Icon1,
    MasterCard:Icon2,
    "American Express":Icon3
  }
  const getCardImage = (cardType) => {
    return cardImages[cardType] || null; // Return null if the card type is not found
  };
  const deleteSelectcard=async(card)=>{
    setSelectedItem(card);
    setDeleteModalVisible(true);
  }

  const handleDeleteCancel=async()=>{
    setSelectedItem(null);
    setDeleteModalVisible(false);
  }
  const handleDeleteConfirm=async()=>{
    setIsLoading(true);
    try{
      const {id}=selectedItem;
      const response=await post('user/removeCard',{cardId:id});
      if(response.status){
        openNotification(response?.message); 
        setSelectedItem(null);
        setDeleteModalVisible(false);
      }else{
        message.error(response?.error); 
        setSelectedItem(null);
        setDeleteModalVisible(false);
      }
    }catch(error){
      if(error?.response?.data?.error){
        message.error(error?.response?.data?.error);
      }else{
        message.error("oops something went wrong please try again.");
      }
      
      setSelectedItem(null);
      setDeleteModalVisible(false);
    }finally{
      setIsLoading(false);
    }
  }
  const init=async()=>{
    setIsLoading(true);
    try{
      const response=await get('user/billingHistory');
      const currentUserCards=await get('user/currentUserCards');
      if(response.status){
        setMyrequests(response?.data);
      }
      if(currentUserCards?.status){
        setUserCards(currentUserCards?.cards);
      }
    }catch(error){
      console.log("error",error);
    }finally{
      setIsLoading(false);
    }
  }
    useEffect(()=>{
      
      init();
    },[showAddCard,selectedItem]);

  const handleNewCard = () => {
    setShowAddCard(true);
  };

  const setDefualtPayemnt=async(id)=>{
    setIsLoading(true);
    try{
      const response=await post('user/defualtPayemnt',{cardId:id});
      if(response.status){
        openNotification(response?.message); 
        await init(); 
      }
    }catch(error){
      console.log("error",error);
      message.error("oops something went wrong please try again.");
    }finally{
      setIsLoading(false);
    }
  }

  if (showAddCard) {
    return <AddNewCard goBack={() => setShowAddCard(false)} />;
  }

  const handleTransaction = (item) => {
    console.log(item, 'item');
    setTransactionHistory(true);
    setTransactionData(item);
  }

  const handleTransactionClose = () => {
    setTransactionHistory(false);
    setTransactionData(null);
  };

  console.log(myrequests, 'myrequests')

  return (
    <Layout>
    {!isTransactionHistory == true &&
      <Spin spinning={isLoading}>
        <Flex vertical style={{ padding: 20 }}>
          <Flex vertical align="start">
            <Title level={3} style={{ marginTop: 0, marginBottom: 5 }}>Billing</Title>
            <Text style={{ fontSize: '16px' }}>Update your plan payment details.</Text>
            <Button type="primary" icon={<CreditCardOutlined />} onClick={handleNewCard} size="large" style={{ marginBottom: 25, marginTop: 20, fontWeight: 600 }}>
              New
            </Button>
          </Flex>
          <Tabs className="billing-tabs" defaultActiveKey="1">
            <TabPane tab="Cards" key="1">
             {userCards.length>0?
              userCards.map((card, index) => (
                <Card key={index} style={{ marginBottom: '10px' }}>
                  <Flex horizontal="true" gap="small">
                    <Flex vertical>
                      <Image src={getCardImage(card?.cardType)} width={46} preview={false} />
                    </Flex>
                    <Flex vertical>
                      <Text strong>{card?.cardType} ending in {card?.last4Digits}</Text>
                      <div style={{ float: 'right' }}>
                        {card.isPrimary ? <Text type="secondary" style={{ color: '#475467', fontWeight: 600 }}>Primary</Text>
                        :<Button onClick={()=>setDefualtPayemnt(card?.id)} type="secondary" style={{ color: '#475467', fontWeight: 600 }}>Set as default</Button>}
                        <Button onClick={handleNewCard} type="link" style={{ color: '#6941C6', fontWeight: 600 }}>Edit</Button>
                        <Button onClick={()=>deleteSelectcard(card)} type="link" icon={<DeleteOutlined />} style={{ color: '#D92D20', position: 'absolute', top: 5, right: 5, fontSize: '20px' }}></Button>
                      </div>
                    </Flex>
                  </Flex>
                </Card>
              ))
              :
              <Card key={"index9999"} style={{ marginBottom: '10px' }}>
                  <Empty/>
              </Card> 
              }

              <Flex justify="flex-end">
                <Button  onClick={handleNewCard} type="text" icon={<PlusOutlined />} style={{ padding: 0, color: '#6941C6', fontWeight: 600 }}>
                  Add payment method
                </Button>
              </Flex>

              {/*<Divider />*/}


              {/*<Flex vertical>
                <Text strong style={{ marginBottom: 15 }}>Billing contact</Text>
                <Flex vertical>
                  <Text style={{ marginBottom: 7 }}>Email address</Text>
                  <Input
                    value="accounts@untitledui.com"
                    className="custom-form-item billing-email"
                    prefix={<MailOutlined />}
                    suffix={
                      <Tooltip title="Contact your billing administrator for any changes.">
                        <InfoCircleOutlined />
                      </Tooltip>
                    }
                  />
                </Flex>
                </Flex>*/}

                   {/* Custom Delete Confirmation Modal */}
                  <Modal
                    open={isDeleteModalVisible}
                    onCancel={handleDeleteCancel}
                    footer={null}
                    width={400}
                    className="delete-request-modal"
                  >
                    <Flex vertical style={{ textAlign: 'center', alignItems: 'center', paddingTop: 10 }}>
                      <Image 
                        src={DeleteIcon}
                        preview={false}
                        width={48}
                        style={{ marginBottom: 15 }}
                      />
                      <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 5 }}>Delete Payment Method?</Title>
                      <Text style={{ marginBottom: 15 }}>Are you sure you want to delete this payment method?</Text>
                    </Flex>
                    <Flex gap={5} className="ant-modal-footer">
                      <Button size="large" onClick={handleDeleteCancel} style={{ fontWeight: 600 }}>Cancel</Button>
                      <Button size="large" type="primary" danger onClick={() => handleDeleteConfirm()} style={{ fontWeight: 600 }}>Delete</Button>
                    </Flex>
                  </Modal>
            </TabPane>
            <TabPane tab="History" key="2">
              <List
                dataSource={myrequests}
                renderItem={item =>{
                  const {revenueUser}=item;
                  return (<List.Item style={{ gap: 10 }}>
                    <List.Item.Meta
                      title={<Text strong style={{ color: '#101828', fontSize: '18px' }}>{revenueUser?.first_name} {revenueUser?.last_name}</Text>}
                      description={item?.message}
                    />
                    <Flex vertical style={{ textAlign: 'right' }}>
                      <Text strong style={{ fontSize: '20px', color: '#000000' }}>{formatPrice(item?.amount)}</Text>
                      {item.payment_status === "0" ? (
                          <Text type="secondary" style={{ color: '#344054', borderRadius: 6, padding: '3px 8px', fontSize: '12px', fontWeight: 500, border: '1px solid #D0D5DD', textTransform: 'capitalize' }}>Pending</Text>
                        ) : item.payment_status === "1" ?(
                          ''
                          // <Text type="secondary" style={{ backgroundColor: '#ECFDF3', color: '#067647', borderRadius: 6, padding: '3px 8px', fontSize: '12px', fontWeight: 500, border: '1px solid #ABEFC6', textTransform: 'capitalize' }}>Completed</Text>
                        ) : (
                          <Text type="secondary" style={{ color: '#344054', borderRadius: 6, padding: '3px 8px', fontSize: '12px', fontWeight: 500, border: '1px solid #D0D5DD', textTransform: 'capitalize' }}>Decline</Text>
                        )}
                    </Flex>
                    <Button onClick={() => handleTransaction(item)} type="text" style={{ background: 'transparent', width: 'auto' }} icon={<Image src={ArrowRightIcon} preview={false} />}></Button>
                  </List.Item>)
                }}
              />
            </TabPane>
          </Tabs>
        </Flex>
        </Spin>
      }
      {isTransactionHistory &&
        <FanTransactionRequest isTransactionData={isTransactionData} handleTransactionClose={handleTransactionClose} /> 
      }

    </Layout>
  );
};

export default Billing;
