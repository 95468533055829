import React, { useState ,useEffect,useContext} from 'react';
import { Form, Input, Button, Steps, Typography, DatePicker, Checkbox, Flex, Select, Divider, Modal, Radio, message,Card,Image,Spin } from 'antd';
import { ArrowLeftOutlined, PlusOutlined, EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useMask } from "@react-input/mask";
import { openNotification } from '../../../utils/utils';
import { get, post } from '../../../utils/api';
import { UserContext } from '../../../contexts/UserContext';
import { formatPrice } from '../../../utils/utils';
import statesOrProvinces from '../../../utils/statesOrProvinces';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Icon1 from '../../../images/visa-icon.png';
import Icon2 from '../../../images/mastercard-icon.png';
import Icon3 from '../../../images/American-Express.png';

const { Step } = Steps;
const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const MyselfForm = ({ onPrev, currentStep, setCurrentStep, creatorData,setUserName,selectedOption,smsId }) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = React.useState({});
  const [isOccasionModalVisible, setIsOccasionModalVisible] = useState(false);
  const [isInstructionModalVisible, setIsInstructionModalVisible] = useState(false);
  const [selectedOccasion, setSelectedOccasion] = useState('');
  const [selectedInstruction, setSelectedInstruction] = useState('');
  const [customOccasion, setCustomOccasion] = useState('');
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState('standard');
  const [predefinedOccasions, setPredefinedOccasions] = useState([]);
  const [isCustomRequest, setIsCustomRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const [selectedItem, setSelectedItem] = useState(null);
  const [totalFee, setTotalFee] = useState(0);
  const [requestFee, setRequestFee] = useState(0);
  const [platformFee, setPlatformFee] = useState(1);
  const [customRequestFee, setCustomRequestFee] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [expressDelivery, SetExpressDelivery] = useState(0);
  const [additional_fee, setAdditional_fee] = useState(0);
  const [userCards,setUserCards]=useState([]);
  
  const [currentUser, setCurrentUser] = useState(null);
  const [recipientphone, setRecipientphone] = useState(null);


  const [value, setValue] = useState();
  const [cvvNumber, setCvvNumber] = useState('');
  const onChange = (e) => {
      setValue(e.target.value);
      setCvvNumber('');
  };
  const handleCVVChange = (e) => {
    const { value } = e.target;
    if(!value){
        setCvvNumber('');
    }
    if (/^\d{3,4}$/.test(value)) {
      setCvvNumber(value);
    }
  };

  // Handle form validation
const validateCardNumber = (_, value) => {
  if (!value) {
    return Promise.reject('Please enter your card number');
  }
  const cardNumber = value.replace(/\D/g, '');
 
  if (cardNumber.length !== 16) {
    return Promise.reject('Invalid card number ');
  }
  return Promise.resolve();
};

const validateExpiryDate = (_, value) => {
  if (!value) {
    return Promise.reject('Please enter expiry date');
  }
  const [month, year] = value.split('/');
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed
  const currentYear = currentDate.getFullYear();

  if (!/^(0[1-9]|1[0-2])\/\d{4}$/.test(value)) {
    return Promise.reject('Expiry date must be in MM/YYYY format');
  }
  if (parseInt(year, 10) < currentYear || (parseInt(year, 10) === currentYear && parseInt(month, 10) < currentMonth)) {
    return Promise.reject('Expiry date must be in the future');
  }
  return Promise.resolve();
};


  
  const validateCVV = (_, value) => {
      if (!value) {
        return Promise.reject('Please enter CVV');
      }
      if (!/^\d{3,4}$/.test(value)) {
        return Promise.reject('CVV must be 3 or 4 digits');
      }
      return Promise.resolve();
    };

  const cardImages={
      Visa:Icon1,
      MasterCard:Icon2,
      "American Express":Icon3
    }
    const getCardImage = (cardType) => {
      return cardImages[cardType] || null; // Return null if the card type is not found
    };

  
  const navigate = useNavigate();
  const {user}  = useContext(UserContext);
  useEffect(()=>{
    setCurrentUser(user);
  },[user]);
  const defaultRequests=[
    { title: 'Birthday' },
    { title: 'Pep talk' },
    { title: 'Roast' },
    { title: 'Advice' },
    { title: 'Ask a Question' },
    { title: 'Other' },
  ];

  const validatePhoneNumber = (_, value) => {
    if (!value) {
      return Promise.reject(new Error(''));
    }
    const strippedValue = value.replace(/\D/g, '');
     
    if (strippedValue.length === 10) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid 10-digit phone number!'));
  };
  
  useEffect(()=>{
    const init=async()=>{
      const response = await get(`user/requestPrivateInfo/${creatorData?.id}`);
      
      if (response && response.status) {
          const {requestList,is_custom_request,expedited,price,additional_fee}=response?.data;
          const setAllValues=[
            ...requestList || [],
            ...defaultRequests
          ];
        
          setPredefinedOccasions(setAllValues);
          setIsCustomRequest(is_custom_request);
          setCustomRequestFee(expedited);
          setRequestFee(price);
          setOriginalPrice(price);
          setAdditional_fee(parseFloat(additional_fee || 0));
          const crtValue= parseFloat(additional_fee || 0);
          const additionalFeeAmount = parseFloat(price) * crtValue/100
          const totalValue = parseFloat(price || 0) + additionalFeeAmount;
          SetExpressDelivery(totalValue);
      }
      try{
          const currentUserCards=await get('user/currentUserCards');
          if(currentUserCards?.status){
            setUserCards(currentUserCards?.cards);
          }
        }catch(error){
          console.log("errorcard",error);
      }

    }
    init();
  },[creatorData]);

  const feeCalCulator=(deliverytype=false)=>{
   
    if(deliverytype=="express"){
      
      const crtValue= parseFloat(additional_fee || 0);
      const additionalFeeAmount = parseFloat(originalPrice) * crtValue/100;
      const subTotal = parseFloat(originalPrice || 0) + additionalFeeAmount
      setRequestFee(subTotal);
      const totalValue=subTotal+ parseFloat(platformFee);
      setTotalFee(parseFloat(totalValue));
    }else{
      setRequestFee(originalPrice);
      let totalFee=parseFloat(originalPrice)+parseFloat(platformFee);
      setTotalFee(parseFloat(totalFee));
    }
    
  }

   const inputRef = useMask({
      mask: "(___) ___-____",
      replacement: { _: /\d/ },
    });

  useEffect(() => {
    if (user && selectedOption === 'myself') {
      const fullName = `${user.first_name} ${user.last_name}`.trim();
      form.setFieldsValue({ to: fullName });
      setUserName(fullName);
    }
  }, [user, selectedOption, form, setUserName]);

  const handleNameChange = (e) => {
    setUserName(e.target.value);
  };

  // useEffect(()=>{
  //   if(selectedOption && selectedOption=="myself"){
  //     setCurrentStep(1);
  //   }
    
  // },[selectedOption]);

  // const predefinedOccasions = [
  //   { label: 'None', value: 'None', price: 0 },
  //   { label: '{Defined request} $20', value: '$20', price: 20 },
  //   { label: '{Defined request} $30', value: '$30', price: 30 },
  //   { label: '{Defined request} $40', value: '$40', price: 40 },
  //   { label: '{Defined request} $15', value: '$15', price: 15 },
  //   { label: 'Custom', value: 'Custom', price: 0 },
  // ];

  // const deliveryOptions = [
  //   { label: 'Standard', text: '2-5 days', value: 'standard', price: 40 },
  //   { label: '24 hours or less', text: 'Get faster delivery', value: '24hours', price: 100 },
  // ];

  const steps = [
    {
      title: 'Step 1',
      content: (<Flex vertical style={{ marginTop: 20 }}>
        <Form.Item
          name="to"
          label="To"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[{ required: true, message: 'Please enter a name' }]}
          className="custom-form-item"
        >
          <Input placeholder="Enter Name" onChange={handleNameChange} />
        </Form.Item>
        {selectedOption && selectedOption !== "myself" && currentUser && (<>
          <Form.Item
            name="recipientphone"
            label="Recipient phone #"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: 'Please input your phone number!' }, { validator: validatePhoneNumber }]}
            className="custom-form-item"
            onChange={(e)=>{setRecipientphone(e.target.value)}}
            
          >
             <input className="ant-input phone-input" ref={inputRef} placeholder="(000) 000-0000" />
            {/* <Input
              style={{ padding: '7px 10px' }}
              placeholder="(000) 000-0000"
              maxLength={10}
              formatter={(value) => {
                // Format the phone number as (XXX) XXX-XXXX
                return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
              }}
              parser={(value) => {
                // Parse the input value to remove non-numeric characters
                return value.replace(/\D/g, '');
              }} /> */}
          </Form.Item>
            <Form.Item
              name="from_name"
              label="From"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="custom-form-item"
            >
              <Input 
                placeholder="Name" 
                defaultValue={`${currentUser?.first_name} ${currentUser?.last_name}`} 
                disabled
              />
            </Form.Item>
          </>)}
      </Flex>),
    },
    {
      title: 'Step 2',
      content: (<>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        <Flex vertical gap={25}>
          { selectedOccasion !== '' && selectedOccasion !== undefined ? (
            <Flex
                gap={10}
                horizontal="true"
                justify="space-between"
                align="start"
                className="selected-request-row"
                style={{
                  border: '1px solid #EAECF0',
                  padding: '10px 14px',
                  borderRadius: '8px',
                }}
              >
              <Flex vertical>
               
                <Text style={{ fontSize: '16px', color: '#475467', fontWeight: 600 }}>Occasion:</Text>
                <Text style={{ fontSize: '16px', color: '#475467' }}>{selectedItem}</Text>
              </Flex>
              <Flex gap={15} style={{ marginTop: 5 }}>
                <EditOutlined style={{ fontSize: '20px' }} onClick={() => setIsOccasionModalVisible(true)} />
                <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={() => setSelectedOccasion('')} />
              </Flex>
            </Flex>
          ) : (
            <Button
              size="large"
              style={{ fontSize: '14px', fontWeight: 600, color: '#6941C6', borderColor: '#D6BBFB' }}
              icon={<PlusOutlined />}
              onClick={() => setIsOccasionModalVisible(true)}
            >
              Select an occasion
            </Button>
          )}
          {selectedInstruction ? (
            <Flex
              gap={10}
              horizontal="true"
              justify="space-between"
              align="start"
              className="selected-request-row"
              style={{
                border: '1px solid #EAECF0',
                padding: '10px 14px',
                borderRadius: '8px',
              }}
            > 
              <Flex vertical>
                <Text style={{ fontSize: '16px', color: '#475467', fontWeight: 600 }}>Instructions:</Text>
                <Text style={{ fontSize: '16px', color: '#475467' }}>{selectedInstruction}</Text>
              </Flex>
              <Flex gap={15} style={{ marginTop: 5 }}>
                <EditOutlined style={{ fontSize: '20px' }} onClick={() => setIsInstructionModalVisible(true)} />
                <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={() => setSelectedInstruction('')} />
              </Flex>
            </Flex>
          ) : (
            <Button
              size="large"
              style={{ fontSize: '14px', fontWeight: 600, color: '#6941C6', borderColor: '#D6BBFB' }}
              icon={<PlusOutlined />}
              onClick={() => setIsInstructionModalVisible(true)}
            >
              Add Instructions (required)
            </Button>
          )}
        </Flex>
      </>),
    },
    {
      title: 'Step 3',
      content: (<>
        <Flex vertical style={{ marginBottom: 20 }}>
          <Text style={{ display: 'block', marginBottom: 10, textAlign: 'center' }}>{selectedItem}</Text>
          <Text style={{ display: 'block', marginBottom: 10, textAlign: 'center' }}>{selectedInstruction}</Text>
          <Button
            type="default"
            icon={<EditOutlined />}
            onClick={() => setCurrentStep(1)}
            style={{ fontWeight: 600, padding: '7px 0', height: 'auto' }}
          >
            Edit request details
          </Button>
        </Flex>
        <Divider />
      {selectedOccasion!=="Custom" &&
        <Flex vertical style={{ marginBottom: 10 }}>
          <Text style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: 10 }}>Select an option</Text>
          <Radio.Group
            className="occasion-checkbox options-checkbox"
            onChange={(e) => {setSelectedDeliveryOption(e.target.value);feeCalCulator(e.target.value);}}
            value={selectedDeliveryOption}
          >
            {selectedItem && <Radio key="optionPrice1" value="standard">
              <Flex vertical>
                <Text style={{ fontSize: '16px', color: '#344054' }}>Standard
                  (<span>{formatPrice(originalPrice)}</span>)
                </Text>
                <Text style={{ fontSize: '16px', color: '#344054' }}>2-3 days</Text>
              </Flex>
              </Radio>
            }
             {selectedItem && isCustomRequest && <Radio key="optionPrice2" value="express">
              <Flex vertical>
                <Text style={{ fontSize: '16px', color: '#344054' }}>24 hours or less
                   (<span> {formatPrice(expressDelivery)}</span>)
                </Text>
                <Text style={{ fontSize: '16px', color: '#344054' }}>Get faster delivery</Text>
              </Flex>
              </Radio>
            }
          
          </Radio.Group>
        </Flex>}
        <Divider style={{ marginTop: 0 }} />
        <Flex vertical>
          <Text style={{ fontSize: '18px', fontWeight: 'bold', color: '#344054', marginBottom: 10 }}>Payment option</Text>
          <Flex vertical style={{ marginBottom: 10,width:'100%' }}>
          {userCards?.length>0?
            <>
            <Radio.Group className="payment-radio-main" onChange={onChange} value={value || userCards.find(card => card.isPrimary)?.id}>
                {userCards.map((card, index) => (
                    <Radio style={{ border:(value === card?.id || (!value && card?.isPrimary) )?'1px solid #963a3a':''}} className="payment-radio" value={card?.id} key={card?.id}>
                        <Card key={index} style={{ marginBottom: '10px' }}>
                            <Flex horizontal="true" gap="small">
                                <Flex vertical>
                                    <Image src={getCardImage(card?.cardType)} width={46} preview={false} />
                                </Flex>
                                <Flex vertical style={{ flex: 1 }}>
                                    <Text strong>{card?.cardType} ending in {card?.last4Digits}</Text>
                                    <div style={{ float: 'right' }}>
                                       
                                    {(value === card?.id || (!value && card?.isPrimary)) && (
                                       <Form.Item
                                            
                                            label="CVV"
                                            style={{ marginBottom: 0 }}
                                            className="custom-form-item cvv-code-input"
                                            value={cvvNumber}
                                            rules={[
                                                (value === card?.id || (!value && card?.isPrimary)) === card?.id ? { required: true, message: 'Please enter CVV' } : null,
                                                { validator: validateCVV },
                                              ].filter(Boolean)}
                                            >
                                        <Input 
                                        style={{ width: '55px', textAlign: 'center' }} 
                                        type="password" placeholder="•••"
                                        onChange={handleCVVChange}
                                        maxLength={4}
                                        
                                         />
                                    </Form.Item>)}
                                  
                                    </div>
                                </Flex>
                            </Flex>
                        </Card>
                    </Radio>
                ))}
                <Radio className="payment-card-btn" value={"newcard"} key={`addNewcard`}>
                    <Card key={`addNewcard`}>
                        Add New card
                    </Card>
                </Radio>
             </Radio.Group>
            {value && value=="newcard" && (
             <Card className="payment-new-card" key={`CardDetails`} style={{ marginBottom: '10px' }}>
                <Title level={4} style={{ marginTop: 0, fontSize: 18 }}>Card Details</Title>
                {/* Name on Card */}
                <Form.Item
                    name="cardholderName"
                    label="Name on Card"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the name on card' }]}
                    className="custom-form-item"
                >
                    <Input placeholder="Enter name on card" />
                </Form.Item>
        
                {/* Card Number */}
                <Form.Item
                    name="cardNumber"
                    label="Card Number"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ validator: validateCardNumber }]}
                    className="custom-form-item"
                >
                    <InputMask
                        mask="9999 9999 9999 9999"
                        maskChar="_"
                        placeholder="1234 1234 1234 1234"
                    />
                </Form.Item>
        
                {/* Expiry and CVV */}
                <div style={{ display: 'flex', gap: '16px' }}>
                    <Form.Item
                    name="expirationDate"
                    label="Expiry"
                    style={{ width: '120px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ validator: validateExpiryDate }]}
                    className="custom-form-item"
                    >
                        <InputMask mask="99/9999" maskChar="_" placeholder="MM / YYYY" />
                    </Form.Item>
        
                    <Form.Item
                        name="securityCode"
                        label="CVV"
                        style={{ width: '120px' }}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ validator: validateCVV }]}
                        className="custom-form-item"

                        >
                        <Input type="password" placeholder="•••"  maxLength={4}  onChange={handleCVVChange} />
                    </Form.Item>
                </div>
                <Form.Item
                    name="isSavecard"
                    valuePropName="checked"
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item checkox-label"
                    style={{ marginTop: 10 }}
                >
                    <Checkbox>
                    Save for future use
                    </Checkbox>
                </Form.Item>
                </Card>)}

                <Form.Item
                    name="terms"
                    valuePropName="checked"
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item checkox-label"
                    style={{  }}
                    // rules={[{ required: true, message: 'Please agree to the Terms of Use!' }]}
                    rules={[
                    {
                        validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error('Please agree to the Terms of Use!')),
                    },
                    ]}
                >
                    <Checkbox>
                    I have read and agree to the <a href="/">Terms of Service</a> & <a href="/">Privacy Policy</a>
                    </Checkbox>
                </Form.Item>
             </>
          :
          <Flex vertical>
                <Title level={3} style={{ textAlign: 'center', marginBottom: '10px' }}>Billing address</Title>
                <Form.Item 
                    name="firstName" 
                    label="First Name" 
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item" 
                    rules={[{ required: true, message: 'Please enter your first name' }]}
                >
                    <Input placeholder="Enter first name" />
                </Form.Item>
                <Form.Item 
                    name="lastName"
                    label="Last Name" 
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item" 
                    rules={[{ required: true, message: 'Please enter your last name' }]}
                >
                    <Input placeholder="Enter last name" />
                </Form.Item>
                <Form.Item 
                    name="address1" 
                    label="Address 1" 
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item" 
                    rules={[{ required: true, message: 'Please enter your address' }]}
                >
                    <Input placeholder="Enter address number" />
                </Form.Item>
                <Form.Item 
                    name="address2"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item"
                    label="Address 2 (Optional)"
                >
                    <Input placeholder="Enter account number" />
                </Form.Item>
                <Flex horizontal="true" gap="middle">
                    <Form.Item 
                        name="city" 
                        label="City" 
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        className="custom-form-item"
                        style={{ width: '50%' }}
                        rules={[{ required: true, message: 'Please enter your city' }]}
                    >
                        <Input placeholder="Enter City" />
                    </Form.Item>
                    <Form.Item 
                        name="state" 
                        label="State/Province" 
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        className="custom-form-item"
                        style={{ width: '50%' }}
                        rules={[{ required: true, message: 'Please select your state/province' }]}
                    >
                        <Select placeholder="Select">
                        {statesOrProvinces.map(state => (
                                <Option key={state.value} value={state.value}>
                                {state.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Flex>
                <Form.Item 
                    name="zip" 
                    label="Zip/Postal Code" 
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item"
                    style={{ width: '70%' }}
                    rules={[{ required: true, message: 'Please enter your zip/postal code' }]}
                >
                    <Input placeholder="Enter Zip/Postal Code" />
                </Form.Item>
           
                 {/* Name on Card */}
                 <Form.Item
                    name="cardholderName"
                    label="Name on Card"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ required: true, message: 'Please enter the name on card' }]}
                    className="custom-form-item"
                >
                    <Input placeholder="Enter name on card" />
                </Form.Item>
        
                {/* Card Number */}
                <Form.Item
                    name="cardNumber"
                    label="Card Number"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ validator: validateCardNumber }]}
                    className="custom-form-item"
                >
                    <InputMask
                        mask="9999 9999 9999 9999"
                        maskChar="_"
                        placeholder="1234 1234 1234 1234"
                    />
                </Form.Item>
        
                {/* Expiry and CVV */}
                <div style={{ display: 'flex', gap: '16px' }}>
                    <Form.Item
                    name="expirationDate"
                    label="Expiry"
                    style={{ width: '120px' }}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[{ validator: validateExpiryDate }]}
                    className="custom-form-item"
                    >
                        <InputMask mask="99/9999" maskChar="_" placeholder="MM / YYYY" />
                    </Form.Item>
        
                    <Form.Item
                        name="securityCode"
                        label="CVV"
                        style={{ width: '120px' }}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        rules={[{ validator: validateCVV }]}
                        className="custom-form-item"

                        >
                        <Input type="password" placeholder="•••"  maxLength={4}  onChange={handleCVVChange} />
                    </Form.Item>
                </div>
                <Form.Item
                    name="isSavecard"
                    valuePropName="checked"
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item checkox-label"
                    style={{ marginTop: 10 }}
                >
                    <Checkbox>
                    Save for future use
                    </Checkbox>
                </Form.Item>

                <Form.Item
                    name="terms"
                    valuePropName="checked"
                    wrapperCol={{ span: 24 }}
                    className="custom-form-item checkox-label"
                    rules={[
                    {
                        validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error('Please agree to the Terms of Use!')),
                    },
                    ]}
                >
                    <Checkbox>
                    I have read and agree to the <a href="/">Terms of Service</a> & <a href="/">Privacy Policy</a>
                    </Checkbox>
                </Form.Item>
                
            </Flex>
          }
          </Flex>
        </Flex>
        <Divider style={{ marginTop: 0 }} />
        <Flex vertical style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: '18px', fontWeight: 'bold', color: '#344054', marginBottom: 10 }}>Summary</Text>
          <Flex horizontal="true" justify="space-between" style={{ fontWeight: 500, fontSize: '14px', color: '#344054' }}>
            <Text>Request:</Text>
              <Text>{formatPrice(requestFee)}</Text>
          </Flex>
          <Divider style={{ margin: '7px 0' }} />
          <Flex horizontal="true" justify="space-between" style={{ fontWeight: 500, fontSize: '14px', color: '#344054' }}>
            <Text>Booking fee:</Text> <Text>{formatPrice(platformFee)}</Text>
          </Flex>
          <Divider style={{ margin: '7px 0' }} />
          <Flex horizontal="true" justify="space-between" style={{ fontWeight: 600, color: '#344054' }}>
            <Text style={{ fontSize: '16px' }}>Total:</Text> <Text style={{ fontSize: '16px' }}>{formatPrice(totalFee)}</Text>
          </Flex>
        </Flex>
      </>),
    },
  ];

  const next = () => {
    if(currentStep==1 && selectedOccasion===''){
      message.error('Please select occasion');
      return  false; 
    }
    if(currentStep==1 && selectedInstruction===''){
      message.error('Please select instructions');
      return  false; 
    }
    form.validateFields()
      .then((currentStepData) => {
        console.log("currentStepData",currentStepData);
        setFormData({ ...formData, ...currentStepData });
        setCurrentStep((prevStep) => prevStep + 1);
      })
      .catch((info) => {
        console.log('Validation failed:', info);
      });
  };
  const handleApplyOccasion = () => {
    
    if(selectedOccasion===""){
      message.error('Please select occasion');
      return;
    }
    if (selectedOccasion === 'Custom' && customOccasion.trim() === '') {
      message.error('Please enter a custom occasion');
      return;
    }

    setIsOccasionModalVisible(false);
    feeCalCulator();
  };
  const handleOccasionModalCancel = () => {
    setIsOccasionModalVisible(false);
  };
  const handleOccasionChange = (e) => {
    const index=e.target.value;
    setSelectedOccasion(index);
    setSelectedItem(index);
    // const selectedItem=predefinedOccasions[index];
    // if(selectedItem){
    //   setSelectedItem(selectedItem);
    // }
   
  };
  const handleCustomOccasionChange = (e) => {
    setCustomOccasion(e.target.value);
  };
  const handleInstructionModalCancel = () => {
    setIsInstructionModalVisible(false);
  };
  const handleInstructionChange = (e) => {
    setSelectedInstruction(e.target.value);
  };
  const handleApplyInstruction = () => {
    setIsInstructionModalVisible(false);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try{
      const isSavedcards=userCards?.length>0?true:false;
      const isNewcard=(!isSavedcards || value=="newcard")?true:false;
      const selectedcard=(isSavedcards && !value)?userCards.find(card => card.isPrimary)?.id:value;
      const paymentInfo={
          isSavedcards,
          isNewcard,
          selectedcard:selectedcard,
          cvvNumber
      };


      const allFields = form.getFieldsValue();
     
      const sendData={...formData,...allFields,selectedOccasion,selectedInstruction,selectedDeliveryOption,ctr:creatorData?.id,recipientphone,paymentInfo};
    
      const response = await post(`user/sendPrivateRequest`,sendData);
      if(response.status){
        openNotification("Request submitted successfully, awaiting approval.");
        navigate(`/audience/myrequests/${btoa(response?.data?.id)}`);
      }else{
        message.error(response?.message);
      }
    }catch(error){
      console.log('Form error',error);
    }finally{
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading}>
    <Form form={form} onFinish={handleSubmit}>
       {/*{selectedOccasion && <Title level={5} style={{ textAlign: 'center', marginTop: '0px', fontSize: '16px', color: '#344054' }}>{selectedOccasion}</Title>}*/}
      <div className="steps-content">
        {steps[currentStep].content}
      </div>
      <div className="steps-action" style={{ marginTop: '24px' }}>
        <Flex
            vertical
            gap="middle"
            style={{
              width: '100%',
            }}
          >
          {currentStep < steps.length - 1 && (
              <Button type="primary" onClick={() => next()} size="large" style={{ fontWeight: 600 }}>
                Next
              </Button>
          )}
          {currentStep === steps.length - 1 && (
              <Button disabled={!cvvNumber} type="primary" htmlType="submit" size="large" style={{ fontWeight: 600 }}>
                Book
              </Button>
          )}
        </Flex>
      </div>

      {isOccasionModalVisible && (
        <Modal
          open={isOccasionModalVisible}
          onCancel={handleOccasionModalCancel}
          footer={null}
          width={560}
          className="occasion-modal"
        >
        <Flex vertical style={{ padding: 20 }}>
          <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 25, textAlign: 'center' }}>Select an Occasion</Title>
          <Radio.Group className="occasion-checkbox" onChange={handleOccasionChange} value={selectedOccasion}>
            {predefinedOccasions.map((occasion,index) => (
              <Radio style={{ width: '100%', margin: '5px 0', fontSize: '16px', color: '#344054' }} key={index} value={occasion?.title}>{occasion?.title}</Radio>
            ))}
          </Radio.Group>
        </Flex>
        <Flex gap="small" vertical style={{ padding: '0px 20px 20px 20px' }}>
          <Button size="large" type="primary" onClick={handleApplyOccasion} style={{ fontWeight: 600 }}>Save</Button>
        </Flex>
        </Modal>
      )}

      {isInstructionModalVisible && (
        <Modal
          open={isInstructionModalVisible}
          onCancel={handleInstructionModalCancel}
          footer={null}
          width={560}
          className="occasion-modal"
        >
        <Flex vertical style={{ padding: 20 }}>
          <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 25, textAlign: 'center' }}>Add Instructions</Title>
          <Form.Item 
            
            label="Instructions"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-input" 
            rules={[{ required: true, message: 'Please enter a name' }]}
            style={{ marginBottom: 20 }}>
            <TextArea
              showCount
              maxLength={230}
              onChange={handleInstructionChange}
              placeholder="Instructions added here, this can be up to 300 characters long"
              style={{
                height: 128,
                resize: 'none',
              }}
              value={selectedInstruction}
            />
          </Form.Item>

        </Flex>
        <Flex gap="small" vertical style={{ padding: '0px 20px 20px 20px' }}>
          <Button size="large" disabled={selectedInstruction.length===0?true:false} type="primary" onClick={handleApplyInstruction} style={{ fontWeight: 600 }}>Save</Button>
        </Flex>
        </Modal>
      )}

    </Form>
    </Spin>
  );
};

export default MyselfForm;
