import React, { useState, useEffect,useMemo } from 'react';
import { Flex, Button, Typography, Layout, Spin } from 'antd';
import AvatarImage from '../AvatarImage';
import useScreenSize from '../../utils/useScreenSize';
import { post, get } from '../../utils/api';
import { openNotification } from '../../utils/utils';
import { CloseOutlined, LeftOutlined } from '@ant-design/icons';

const { Sider } = Layout;
const { Title, Text, Paragraph } = Typography;

const AddToCommunities = ({ isVisible = false, seIsVisible, usersList = [], setActiveButton }) => {
    const { isMobile, isTableToDesktop } = useScreenSize();
  const [customCommunities, setCustomCommunities] = useState([]);
  const [campaignUsers, setCampaignUsers] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  
const selectedCampaignsStyles = useMemo(() => 
        isMobile ? { 
          backgroundColor: '#F2F4F7', 
          position: 'absolute', 
          width: '100%', 
          left: 0,
          height: 'calc(100vh - 70px)',
          overflow: 'auto', 
          scrollbarWidth: 'thin', 
          scrollbarColor: 'unset', 
          padding: '0', 
          zIndex: 99,
        } : isTableToDesktop ? { 
          backgroundColor: '#F2F4F7', 
          position: 'absolute', 
          right: 0,
          height: '100vh',
          overflow: 'auto', 
          scrollbarWidth: 'thin', 
          scrollbarColor: 'unset', 
          padding: '0', 
          borderLeft: '1px solid #D0D5DD',
          zIndex: 99,
        } : { 
          height: '100vh', 
          overflow: 'auto', 
          scrollbarWidth: 'thin', 
          scrollbarColor: 'unset', 
          background: '#F2F4F7', 
          padding: '0', 
          borderLeft: '1px solid #D0D5DD',
          zIndex: 99,
        }
      , [isMobile, isTableToDesktop]);

  // When usersList changes, update campaignUsers
  useEffect(() => {
    setCampaignUsers(usersList);
  }, [usersList]);

  // Fetch communities when the component mounts
  useEffect(() => {
    const fetchCommunities = async () => {
      setIsLoader(true);
      try {
        const loadCommunities = await get('creator/communities');
        if (loadCommunities) {
          setCustomCommunities(loadCommunities);
        }
      } catch (error) {
        console.log('Error fetching communities:', error);
      } finally {
        setIsLoader(false);
      }
    };
    fetchCommunities();
  }, []);

  const closeCommunityDrawer = () => {
    seIsVisible(false);
    setActiveButton(null);
  };

  const addUserToCommunity = async (communityId) => {
    setIsLoader(true);
    try {
      const response = await post('creator/campaigns/addUserCommunity', {
        cId: communityId,
        campaign: campaignUsers,
      });

      if (response.status) {
        openNotification(response.message);
      }
    } catch (error) {
      console.error('Error adding user to community:', error);
    } finally {
      setIsLoader(false);
      seIsVisible(false); // Close the drawer after adding the user
    }
  };

  return (
    <>
      {isVisible && (
        <Sider className="campaigns-sidebar-main" width={ isMobile ? '100%' : 375} style={selectedCampaignsStyles}>
          <Flex vertical>
            <Flex style={{ padding: '12px 15px', borderBottom: '1px solid #D0D5DD' }}>
              <Button
                icon={<LeftOutlined />}
                type="text"
                style={{ padding: 0, color: '#475467', fontWeight: 600 }}
                onClick={closeCommunityDrawer}
              >
                Back
              </Button>
              <Button
                icon={<CloseOutlined />}
                type="text"
                style={{ padding: 0, marginLeft: 'auto' }}
                onClick={closeCommunityDrawer}
              />
            </Flex>
            <Flex style={{ padding: '15px 15px' }}>
              <Title level={3} style={{ margin: 0, fontWeight: 700 }}>
                Communities
              </Title>
            </Flex>

            <Spin spinning={isLoader} tip="Loading..." style={{ margin: '20px' }}>
              <Flex vertical gap={14} style={{ padding: '10px 15px' }}>
                {customCommunities.length > 0 ? (
                  customCommunities.map((community) => (
                    <Flex key={community.id} gap={10} align="center">
                      <AvatarImage size={40} title={community?.name} />
                      <span style={{ color: '#344054', fontSize: '14px', fontWeight: 700 }}>
                        {community.name}
                      </span>
                      <Button
                        type="link"
                        onClick={() => addUserToCommunity(community.id)}
                        style={{ color: '#6941C6', fontWeight: 600, marginLeft: 'auto' }}
                      >
                        Add
                      </Button>
                    </Flex>
                  ))
                ) : (
                  <Paragraph className="text-gray-500">No communities available.</Paragraph>
                )}
              </Flex>
            </Spin>
          </Flex>
        </Sider>
      )}
    </>
  );
};

export default AddToCommunities;
