import React, { useRef, useState, useEffect } from 'react';
import { Flex, Typography, Spin, Form, Button } from 'antd';
import AvatarImage from '../AvatarImage';
import moment from 'moment';
import PrintMessage from '../GlobalComponents/PrintMessage';
import ChatInputNewMessage from '../GlobalComponents/ChatInputNewMessage';
import { post } from '../../utils/api';

const { Title, Text } = Typography;

const SelectMessage = ({ selectedMessage, isHistoryLoading, chatHistory, setChatHistory }) => {
  const messageBoxRef = useRef(null);
  const [form] = Form.useForm();
  const [isMessageSending, setIsMessageSendingg] = useState(false);
  const [chatImages, setChatImages] = useState([]);
  const [setPrice, setInlinePrice] = useState(true);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [message, setMessage] = useState('');
  const [setSendButton, setInlineButton] = useState(true);
  const [basePrice, setBasePrice] = useState(0.99);
  const [audioEnable] = useState(true);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

  const scrollToBottom = (behavior = 'smooth') => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTo({
        top: messageBoxRef.current.scrollHeight,
        behavior
      });
    }
  };

  // Handle scroll events to determine if we're near the bottom
  const handleScroll = () => {
    if (messageBoxRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageBoxRef.current;
      const scrollPosition = scrollHeight - scrollTop - clientHeight;
      const isCloseToBottom = scrollPosition < 100; // Consider "near bottom" if within 100px
      setShouldAutoScroll(isCloseToBottom);
    }
  };

  useEffect(() => {
    const scrollToBottom = () => {
        if (messageBoxRef.current) {
            // Use requestAnimationFrame to ensure DOM is ready
            requestAnimationFrame(() => {
                messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
            });
        }
    };

    // Scroll on initial load and when new messages arrive
    if (!isHistoryLoading && chatHistory?.length > 0) {
        scrollToBottom();
    }
}, [chatHistory, isHistoryLoading]);

  useEffect(() => {
    const messageBox = messageBoxRef.current;
    if (messageBox) {
      messageBox.addEventListener('scroll', handleScroll);
      return () => messageBox.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const shouldShowDate = (index, date) => {
    if (index === 0) return true;
    const prevDate = moment(chatHistory[index - 1].updatedAt).startOf('day');
    const currDate = moment(date).startOf('day');
    return !prevDate.isSame(currDate);
  };

  const formatDate = (date) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const messageDate = moment(date).startOf('day');

    if (messageDate.isSame(today)) return 'Today';
    if (messageDate.isSame(yesterday)) return 'Yesterday';
    return messageDate.format('MMMM D, YYYY');
  };

  const handleInputChange = (value) => {
    setMessage(value);
  };

  const getRealPath = async (file) => {
    try {
      const formData = new FormData();
      formData.append('singleImage', file);
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/single-upload`, {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        return false;
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Upload failed:', error);
      return false;
    }
  };

  const sendMessageHandler = async (values) => {
    setIsMessageSendingg(true);
    try {
      let mediaPath = null;
      if (chatImages.length > 0) {
        const FileUrl = chatImages[0] || null;
        const fileType = chatImages[0]?.type || null;
        const source = chatImages[0]?.source || null;

        if (fileType == "gif") {
          mediaPath = FileUrl?.file;
        } else if (source == "vault") {
          mediaPath = FileUrl?.thumbUrl;
        } else {
          const urlId = await getRealPath(FileUrl?.file);
          if (urlId && urlId?.image) {
            mediaPath = urlId?.image?.filepath;
          }
        }
      }
      const pricesend = (setPrice) ? selectedPrice : '';
      const completeFormData = { ...values, phone_number: selectedMessage.phone_number, message, price: pricesend, mediaPath: mediaPath };

      const response = await post(`creator/sendMessageByItem`, completeFormData);
      if (response && response.status) {
        form.resetFields();
        const UpdateData = [...chatHistory, ...response?.data];
        setChatHistory(UpdateData);
        setMessage('');
        // Force scroll to bottom after sending a message
        setShouldAutoScroll(true);
        setTimeout(() => scrollToBottom('smooth'), 100);
      }
    } catch (error) {
      console.log("loading......");
    } finally {
      setIsMessageSendingg(false);
    }
  };

  // console.log(chatHistory, 'chatHistory')

  return (
    <Flex vertical align="start" style={{ backgroundColor: '#FFFFFF', padding: '0', borderBottom: '1px solid #EAECF0' }}>
      <Flex vertical style={{ width: '100%', padding: '12px 15px', borderBottom: '1px solid #EAECF0' }}>
        <Flex horizontal="true" gap="middle" align="center">
          {selectedMessage?.first_name && <AvatarImage title={selectedMessage?.first_name} />}
          {selectedMessage?.from_name && <AvatarImage title={selectedMessage?.from_name} />}
          {selectedMessage?.revenueUser?.first_name && (
            <AvatarImage title={`${selectedMessage?.revenueUser?.first_name} ${selectedMessage?.revenueUser?.last_name}`} />
          )}
          {selectedMessage?.user?.first_name && (
            <AvatarImage title={`${selectedMessage?.user?.first_name} ${selectedMessage?.user?.last_name}`} />
          )}
          {selectedMessage?.first_name && selectedMessage?.last_name && (
            <Flex vertical>
              <Title level={4} style={{ marginBottom: 0, marginTop: 0, fontSize: '18px', color: '#101828', textTransform: 'capitalize' }}>
                {selectedMessage?.first_name} {selectedMessage?.last_name}
              </Title>
            </Flex>
          )}
          {selectedMessage?.revenueUser?.first_name && selectedMessage?.revenueUser?.last_name && (
            <Flex vertical>
              <Title level={4} style={{ marginBottom: 0, marginTop: 0, fontSize: '18px', color: '#101828', textTransform: 'capitalize' }}>
                {selectedMessage?.revenueUser?.first_name} {selectedMessage?.revenueUser?.last_name}
              </Title>
            </Flex>
          )}
          {selectedMessage?.user?.first_name && selectedMessage?.user?.last_name && (
            <Flex vertical>
              <Title level={4} style={{ marginBottom: 0, marginTop: 0, fontSize: '18px', color: '#101828', textTransform: 'capitalize' }}>
                {selectedMessage?.user?.first_name} {selectedMessage?.user?.last_name}
              </Title>
            </Flex>
          )}
          {selectedMessage?.from_name && (
            <Flex vertical>
              <Title level={4} style={{ marginBottom: 0, marginTop: 0, fontSize: '18px', color: '#101828', textTransform: 'capitalize' }}>
                {selectedMessage?.from_name}
              </Title>
            </Flex>
          )}
        </Flex>
      </Flex>

      <div
        ref={messageBoxRef}
        className='message-box'
        style={{
          backgroundColor: '#F9FAFB',
          padding: '20px',
          flex: 1,
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          scrollbarColor: 'unset',
          width: '100%',
          boxSizing: 'border-box',
          position: 'relative'
        }}
      >
        <Spin spinning={isHistoryLoading}>
          {chatHistory && chatHistory.map((message, index) => (
            <Flex vertical key={message.id} style={{ marginBottom: '10px' }}>
              {shouldShowDate(index, message.updatedAt) && (
                <Text style={{ textAlign: 'center', fontWeight: 'bold', margin: '10px 0' }}>
                  {formatDate(message.updatedAt)}
                </Text>
              )}
              {message?.direction === 0 ? (
                <Flex justify="flex-start" className='fan-message'>
                  <PrintMessage message={message?.message} type={message?.type} userType="you" priceInput={message?.price} />
                </Flex>
              ) : (
                <Flex className="message-response" justify="flex-end">
                  <PrintMessage message={message?.message} type={message?.type} userType="me" priceInput={message?.price} />
                </Flex>
              )}
            </Flex>
          ))}
        </Spin>
      </div>

      <Flex vertical style={{ padding: '8px 20px 5px 20px', borderTop: '1px solid #EAECF0', width: '100%', boxSizing: 'border-box' }}>
        <Spin spinning={isMessageSending}>
          <Form form={form} onFinish={sendMessageHandler} className="messagebox-form">
            <Form.Item
              name="messagebox"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  validator: (_, value) =>
                    chatImages.length < 1 && !message
                      ? Promise.reject(new Error('Please input your message!'))
                      : Promise.resolve(),
                },
              ]}
              className="custom-form-item"
            >
              <ChatInputNewMessage
                sendMessageHandler={sendMessageHandler}
                setChatImages={setChatImages}
                setSelectedPrice={setSelectedPrice}
                onInputChange={handleInputChange}
                setPriceButton={setInlinePrice}
                setSendButton={setInlineButton}
                setLoading={isMessageSending}
                basePrice={basePrice}
                audioEnable={audioEnable}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Flex>
    </Flex>
  );
};

export default SelectMessage;