import { Button, Flex, Typography } from 'antd';
import { ArrowLeftOutlined, EyeOutlined, InfoCircleOutlined } from '@ant-design/icons';
import AvatarImage from '../AvatarImage.js';
import { formatPrice } from '../../utils/utils';
import moment from "moment";
const { Title, Text } = Typography;

const FanTransactionRequest = ({ isTransactionData, handleTransactionClose }) => {
  return(
    <Flex vertical style={{ padding: 20, height: 'calc(100vh - 60px)' }}>
      <Flex style={{ marginBottom: 10 }}>
        <Button
          type="text"
          icon={<ArrowLeftOutlined style={{ color: '#101828' }}/>}
          onClick={handleTransactionClose}
          style={{ padding: 0, fontWeight: 600, marginBottom: 5, background: 'transparent' }}
        >
        Back
        </Button>
      </Flex>
      <Flex vertical style={{ textAlign: 'center', flex: 1 }}>
        {isTransactionData?.payment_status == '1' ?(
          <Title level={3} style={{ marginTop: 0, marginBottom: 20 }}>Transaction</Title>
        ) : (
          <Title level={3} style={{ marginTop: 0, marginBottom: 20 }}>Transaction Pending</Title>
        )}

        <Flex vertical align="center" style={{ marginBottom: 15 }}>
          <AvatarImage size={48} font={16} title={`${isTransactionData?.firstName} ${isTransactionData?.lastName}`}   />
        </Flex>

        <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 15 }}>
          <span style={{ textTransform: 'capitalize' }}>{isTransactionData?.firstName}</span> {isTransactionData?.lastName}
        </Title>

        {/*<Text style={{ marginBottom: 20 }}>{isTransactionData?.message}</Text>*/}
        {isTransactionData?.campaign?.dateSent &&
          <Flex justify="space-between" style={{ padding: '12px 0', borderBottom: '1px solid #EAECF0' }}>
            <Text style={{ fontWeight: 600 }}>Date</Text>
            <Text>{moment(isTransactionData?.campaign?.dateSent).format('MM/DD/YYYY')}</Text>
          </Flex>
        }

        <Flex justify="space-between" style={{ padding: '12px 0', borderBottom: '1px solid #EAECF0' }}>
          <Text style={{ fontWeight: 600 }}>Total</Text>
          <Text>{formatPrice(isTransactionData?.amount)}</Text>
        </Flex>

        <Flex justify="space-between" style={{ padding: '12px 0', borderBottom: '1px solid #EAECF0' }}>
          <Text style={{ fontWeight: 600 }}>Confirmation</Text>
          <Text>{isTransactionData?.campaign?.id}</Text>
        </Flex>

        <Flex justify="space-between" style={{ padding: '12px 0' }}>
          <Text style={{ fontWeight: 600 }}>Content</Text>
          <Text style={{ color: '#6941C6', fontWeight: 600 }}>View <EyeOutlined /></Text>
        </Flex>

      </Flex>

      <Flex gap={20} vertical style={{ padding: '15px 0', textAlign: 'center' }}>
        {/*<Button type="primary" size="large" style={{ fontWeight: 600, paddingTop: 12, paddingBottom: 12, height: 'auto' }}>New Request</Button>*/}
        <Text style={{ color: '#6941C6', fontWeight: 600 }}><InfoCircleOutlined style={{ fontSize: '18px', marginRight: 5 }} /> How do refunds work?</Text>
      </Flex>

    </Flex>
  );
};

export default FanTransactionRequest;