import React, { useState,useEffect } from 'react';
import { Flex, Typography, Tabs, Image, Checkbox, Button, Modal, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { get, post } from '../../utils/api';
import moment from 'moment';
import ReplyMessage from '../GlobalComponents/ReplyMessage';
import ArrowRight from '../../images/arrow-right.svg';

import SelectMessage from '../Communities/SelectMessage';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const CampaignResponse = ({editingCampaign}) => {
  
  const [selectedResponses, setSelectedResponses] = useState([]);
  const compianId=editingCampaign?.id;
  const [isLoading, setIsLoading] = useState(false);
  const [responses, setResponses] = useState([]);
  const [repliedTo, setRepliedTo] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [replyTotal, setReplyTotal] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [replyHasMore, setReplyHasMore] = useState(true);
  const [isreply, setIsreply] = useState(false);
  
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false); 
  const [userInfo,setUserInfo]= useState(null);
  const [chatHistory, setChatHistory] = useState([]);


  const shouldShowDate = (index, date) => {
    if (index === 0) return true;
    const prevDate = moment(chatHistory[index - 1].updatedAt).startOf('day');
    const currDate = moment(date).startOf('day');
    return !prevDate.isSame(currDate);
  };

  const formatDate = (date) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const messageDate = moment(date).startOf('day');

    if (messageDate.isSame(today)) return 'Today';
    if (messageDate.isSame(yesterday)) return 'Yesterday';
    return messageDate.format('MMMM D, YYYY');
  };

  const fetchIncomming = async (page = 1) => {
    const finaldata = {
      _page: page,
      _limit: 50,
    };
    setIsLoading(true);
    try {
      const response = await get(`creator/campaigns/pendingReplied/${compianId}/incoming`, finaldata);
      if (response.status) {
        const { pagination } = response;
        if (page==1) {
          setResponses(response?.data);
        } else {
          setResponses((prevCampaignsList) => [
            ...prevCampaignsList,
            ...response?.data,
          ]);
        }
        setTotal(pagination?.total);
        setHasMore(response?.data?.length > 0);
      }
    } catch (error) {
      console.log("Error fetching campaigns:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const fetchReplyies = async (page = 1) => {
    const finaldata = {
      _page: page,
      _limit: 50,
    };
    setIsLoading(true);
    try {
      const response = await get(`creator/campaigns/pendingReplied/${compianId}/reply`, finaldata);
      if (response.status) {
        const { pagination } = response;
        if (page==1) {
          setRepliedTo(response?.data);
        } else {
          setRepliedTo((prevCampaignsList) => [
            ...prevCampaignsList,
            ...response?.data,
          ]);
        }
        setReplyTotal(pagination?.total);
        setReplyHasMore(response?.data?.length > 0);
      }
    } catch (error) {
      console.log("Error fetching campaigns:", error);
    } finally {
      setIsLoading(false);
    }
  };

   useEffect(() => {
    fetchIncomming(page);
    }, [page]);
  
  // const responses = [
  //   {
  //     id: 1,
  //     firstName: "John",
  //     lastName: "Doe",
  //     message: "This is text response from a fan",
  //     timestamp: "2024-03-19T10:00:00",
  //     replied: false
  //   },
  //   {
  //     id: 2,
  //     firstName: "Jane",
  //     lastName: "Smith",
  //     message: "This is text response from a fan",
  //     timestamp: "2024-03-19T11:00:00",
  //     replied: false
  //   }
  // ];

  const handleSelectResponse = (id) => {
    if (selectedResponses.includes(id)) {
      setSelectedResponses(selectedResponses.filter(responseId => responseId !== id));
    } else {
      setSelectedResponses([...selectedResponses, id]);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedResponses(responses.map(response => response.id));
    } else {
      setSelectedResponses([]);
    }
  };

  const replyMessage=async()=>{
    setIsreply(true);
  }
  const onClose=async()=>{
    setIsreply(false); 
    setSelectedResponses([]);
    fetchIncomming(page);
  }
  const tabsChange=async (key)=>{
    if(key==1){
      await fetchIncomming(page);
    }else{
      await fetchReplyies(page);
    }
  }

  const handleSelectMessage = async(response) => { 
    console.log(response, 'response')
    setSelectedMessage(response);
    setIsModalVisible(true);
    setIsHistoryLoading(true);
    try{
      const getUserInfo = await post(`creator/getUserInfo`, {
        phone: response?.user_phone
      });
      if (getUserInfo && getUserInfo.status) {
        setUserInfo(getUserInfo.data);
      } else {
        console.error('Information not available!');
      }

        const responsec = await post(`creator/chat`,{
          phn:response?.user_phone
        });
        if(responsec && responsec.status){
          setChatHistory(responsec?.data.reverse());
        }
    }catch(error){
      console.log("loading......");
    } finally {
      setIsHistoryLoading(false);
    }
  }

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedMessage(null);
    setIsHistoryLoading(false);
  };

  const tabItems = [
    {
      key: '1',
      label: (
        <Flex align="center" gap={8}>
          Not Replied To
          <span style={{
            background: '#EEF4FF',
            color: '#155EEF',
            padding: '2px 8px',
            borderRadius: '16px',
            fontSize: '12px'
          }}>
            {responses?.length}
          </span>
        </Flex>
      ),
      children: (
        <>
        {responses?.length>0?
        <Flex vertical gap={16}>
          <Flex vertical gap={8} style={{ padding: '0 15px'}}>
            <Checkbox
              checked={selectedResponses.length === responses.length}
              onChange={handleSelectAll}
              style={{ marginBottom: 5 }}
            >
              Select All
            </Checkbox>

            {responses.map((response,index) => (<>
              {shouldShowDate(index, response.updatedAt) && (
                <Text style={{ textAlign: 'center', fontWeight: 'bold', margin: '10px 0 -5px 0' }}>
                  {formatDate(response.updatedAt)}
                </Text>
              )}
              <Flex 
                key={response.id} 
                gap={12} 
              >
                <Checkbox
                  checked={selectedResponses.includes(response.id)}
                  onChange={() => handleSelectResponse(response.id)}
                />
                <Flex vertical align="flex-start" flex={1} gap={2}>
                  <Flex justify="space-between" align="center">
                    <Typography.Text style={{ fontSize: '12px', color: '#344054' }}>
                      {response?.user?.first_name} {response?.user?.last_name}
                    </Typography.Text>
                  </Flex>
                  <Typography.Text className="chat-bubble replies-chat-bubble">
                    {response.message}
                  </Typography.Text>
                </Flex>
              </Flex>
            </>))}
          </Flex>

          <Flex 
            justify="flex-end" 
            align="center"
            gap={10}
            style={{
              borderTop: '1px solid #EAECF0',
              paddingTop: 16,
              paddingBottom: 16,
              marginTop: 'auto',
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <Typography.Text style={{ color: '#475467' }}>
              {selectedResponses.length} Selected
            </Typography.Text>
            <Space>
              <Button size="large" disabled={selectedResponses.length===0?true:false} icon={<PlusOutlined />} style={{ fontWeight: 600 }}>
                Community
              </Button>
              <Button size="large" onClick={replyMessage} disabled={selectedResponses.length===0?true:false} type="primary" style={{ fontWeight: 600 }}>
                Reply
              </Button>
            </Space>
            {isreply && 
              <ReplyMessage 
                open={isreply} 
                isreplyToAll={selectedResponses.length === responses.length}
                onClose={onClose}
                selectedUsers={selectedResponses}
              />
            }
          </Flex>
        </Flex>:
          <Flex justify="center" align="center" style={{ padding: 32 }}>
            <Typography.Text type="secondary">
              No replies have been received yet.
            </Typography.Text>
          </Flex>
        }
        </>
      ),
    },
    {
      key: '2',
      label: 'Replied To',
      children: (
        <>
        {repliedTo?.length>0?
        <Flex vertical gap={16} style={{ padding: '0 15px'}}>
          {repliedTo.map((response, index) => (<>
            {shouldShowDate(index, response.updatedAt) && (
              <Text style={{ textAlign: 'center', fontWeight: 'bold', margin: '10px 0 -5px 0' }}>
                {formatDate(response.updatedAt)}
              </Text>
            )}
            <Flex 
              key={response.id} 
              gap={12} 
            >
              <Flex vertical align="flex-start" flex={1} gap={5}>
                <Flex justify="space-between" align="center">
                  <Typography.Text style={{ fontSize: '12px', color: '#344054' }}>
                  Replied to {response?.user?.first_name} {response?.user?.last_name}
                  </Typography.Text>
                </Flex>
                <Flex gap={2} align="center" style={{ width: '100%' }}>
                  <Typography.Text className="chat-bubble repliesto-chat-bubble">
                    {response.message}
                  </Typography.Text>
                  <Button type="text" onClick={() => handleSelectMessage(response)} icon={<Image src={ArrowRight} preview={false} />}></Button>
                </Flex>
              </Flex>
            </Flex>
          </>))}
        </Flex>:
          <Flex justify="center" align="center" style={{ padding: 32 }}>
            <Typography.Text type="secondary">
              No replied messages to display
            </Typography.Text>
          </Flex>
        }
        </>
      ),
    },
  ];

  return (
    <Flex vertical style={{ flex: 1 }}>
      <div style={{ padding: '0 15px' }}>
        <Title level={4} style={{ fontSize: 18, color: '#101828', marginBottom: 0 }}>Responses</Title>
      </div>
      <Tabs 
        defaultActiveKey="1" 
        items={tabItems}
        style={{ marginTop: 16, height: '100%' }}
        onChange={tabsChange}
        className="campaign-tabs"
      />


      {selectedMessage &&
        <Modal
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
          width={800}
          className="select-message-modal"
        >
          <SelectMessage selectedMessage={selectedMessage} isHistoryLoading={isHistoryLoading} chatHistory={chatHistory} setChatHistory={setChatHistory} />
        </Modal>
      }

    </Flex>
  );
};

export default CampaignResponse;