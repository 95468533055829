import React, { useState ,useContext,useEffect} from 'react';
import { Form, Input, Flex, Typography, Button, Image, Modal,message } from 'antd';
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import DeleteIcon from '../../images/delete-icon-bg.svg';
import { UserContext } from '../../contexts/UserContext';
import { put } from '../../utils/api';
import { openNotification } from '../../utils/utils';

const { Title, Text } = Typography;
const { TextArea } = Input;

const RequestList = ({settinginfo}) => {
  const [form] = Form.useForm();
  const { user } = useContext(UserContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isRequsterror, setIsRequsterror] = useState('');
  const [newRequestName, setNewRequestName] = useState('');
  const [description, setDescription] = useState('');
  const [removeIndex, setRemoveIndex] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [savedRequestList, setSavedRequestListe] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [defaultRequests, setDefaultRequests] = useState([
    { title: 'Birthday' },
    { title: 'Pep talk' },
    { title: 'Roast' },
    { title: 'Advice' },
    { title: 'Ask a Question' },
    { title: 'Other' },
  ]);

 useEffect(()=>{
    if(settinginfo?.requestList){
      setSavedRequestListe(settinginfo?.requestList);
      // console.log("defaultRequests",defaultRequests);
    }
 },[settinginfo]);

  const showModal = (field = null) => {
    if (field) {
      // Edit mode
      const requests = form.getFieldValue('requests');
      if (requests && requests[field.name]) {
        const currentValue = requests[field.name];
        setNewRequestName(currentValue.title || '');
        setDescription(currentValue.description || '');
        setEditingIndex(field.name);
      } else {
        console.error('Request not found');
        return;
      }
    } else {
      setNewRequestName('');
      setDescription('');
      setEditingIndex(null);
    }
    setIsModalVisible(true);
  };

  const handleSave = async (add) => {
    if (!newRequestName.trim()) {
      setIsRequsterror("error");
      return;
    }

    const newRequest = {
      title: newRequestName,
      description,
      isCustom: true,
    };

    let finalvalue;
    let updatedFields;

    if (editingIndex !== null) {
      // Edit existing request
      updatedFields = form.getFieldValue('requests').map((item, index) => {
        if (index === editingIndex) {
          return newRequest;
        }
        return item;
      });
      form.setFieldsValue({ requests: updatedFields });
      finalvalue = updatedFields.filter(request => request.isCustom === true);
    } else {
      // Add new request
      add(newRequest, 0);
      finalvalue = [
        ...savedRequestList,
        newRequest,
      ];
    }

    setLoading(true);
    try {
      const response = await put(`creator/save-settings/${user?.id}`, {
        requestList: finalvalue
      });
      if (response && response.status === 200) {
        openNotification('Request type successfully created');
      } else {
        message.error(response.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        message.error(error.response.data.message);
      } else {
        message.error(error.message);
      }
    } finally {
      setLoading(false);
    }

    handleCancel();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNewRequestName('');
    setDescription('');
    setEditingIndex(null);
    setIsRequsterror('');
  };

  // Open the custom deletion modal
  const showDeleteModal = (index) => {
    setRemoveIndex(index);
    setIsDeleteModalVisible(true);
  };

   // Confirm and delete the custom request
  const handleDeleteConfirm =async (remove) => {
    if (removeIndex !== null) {
      remove(removeIndex);
      setRemoveIndex(null);
      const requests=form.getFieldValue('requests') || [];
     const finalValues= requests.filter(request => request.isCustom === true);
     setLoading(true);
     try {
       const response = await put(`creator/save-settings/${user?.id}`,{
         requestList:finalValues
       });
        if (response && response.status === 200) {
          openNotification('Request type successfully deleted');
        } else {
          message.error(response.message);
        }
  
      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setLoading(false);
      }
      console.log("requests",finalValues);
    }
    setIsDeleteModalVisible(false);
  };

  // Cancel the deletion
  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setRemoveIndex(null);
  };

  useEffect(() => {
    if (form) {
      if(settinginfo?.requestList){
         const setAllValues=[
          ...settinginfo?.requestList || [],
          ...defaultRequests
        ];
        form.setFieldsValue({
          requests:setAllValues,
        });
      }
    }
  }, [settinginfo, form]);

  return (
    <Form
     form={form}
      name="dynamic_form_nest_item"
      autoComplete="off"
      initialValues={{
        requests: defaultRequests,
      }}
    >

      <Form.List name="requests">
        {(fields, { add, remove }) => (
          <>
            <Form.Item style={{ marginBottom: 15 }}>
              <Button
                type="dashed"
                onClick={() => showModal()}
                icon={<PlusOutlined />}
                style={{ width: '100%', fontWeight: 600, border: '1px solid #D0D5DD', minHeight: '40px' }}
              >
                Add another
              </Button>
            </Form.Item>

            {fields.map((field, index) => {
              const isCustom = index < fields.length - defaultRequests.length;
              return (
                <div
                  key={field.key}
                  style={{ position: 'relative', marginBottom: 10, width: '100%' }}
                  onClick={() => isCustom && showModal(field)}
                >
                  <Form.Item
                    {...field}
                    name={[field.name, 'title']}
                    fieldKey={[field.fieldKey, 'title']}
                    style={{ marginBottom: 0, width: '100%', opacity: !isCustom ? 0.5 : "" }}
                    className="custom-form-item"
                  >
                    <div className="request-item" style={{ 
                      padding: '10px 30px 10px 12px',
                      border: '1px solid #d9d9d9',
                      fontSize: '16px',
                      borderRadius: '6px',
                      cursor: isCustom ? 'pointer' : 'default',
                      backgroundColor: isCustom ? 'white' : '#F9FAFB',
                      height: '44px',
                      color: '#101828',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}>
                      {form.getFieldValue(['requests', field.name, 'title'])}
                    </div>
                  </Form.Item>

                  {isCustom && (
                    <CloseCircleOutlined
                      style={{
                        color: '#D92D20',
                        position: 'absolute',
                        right: 10,
                        top: 12,
                        fontSize: 20,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        showDeleteModal(field.name);
                      }}
                    />
                  )}
                </div>
              );
            })}

            <Modal
              open={isModalVisible}
              onCancel={handleCancel}
              footer={null}
              width={400}
              className="create-request-modal"
            >
              <Flex vertical>
                <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 25 }}>
                  {editingIndex !== null ? 'Edit Request' : 'Create New Request'}
                </Title>

                <Form.Item
                  label="Request Name"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  validateStatus={isRequsterror}
                  className="custom-form-item"
                >
                  <Input
                    placeholder="Write name here..."
                    value={newRequestName}
                    onChange={(e) => {
                      setNewRequestName(e.target.value);
                      setIsRequsterror(e.target.value ? '' : 'error');
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Description"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className="custom-form-item"
                  style={{ marginBottom: 10 }}
                >
                  <TextArea
                    placeholder="Message..."
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{ border: '1px solid #d9d9d9' }}
                  />
                  <Text>250 characters left</Text>
                </Form.Item>
              </Flex>

              <Flex gap={5} className="ant-modal-footer">
                <Button size="large" onClick={handleCancel} style={{ fontWeight: 600 }}>
                  Cancel
                </Button>
                <Button size="large" type="primary" onClick={() => handleSave(add)} style={{ fontWeight: 600 }}>
                  {editingIndex !== null ? 'Save Changes' : 'Save'}
                </Button>
              </Flex>
            </Modal>


            {/* Custom Delete Confirmation Modal */}
            <Modal
              open={isDeleteModalVisible}
              onCancel={handleDeleteCancel}
              footer={null}
              width={400}
              className="delete-request-modal"
            >
              <Flex vertical style={{ textAlign: 'center', alignItems: 'center', paddingTop: 10 }}>
                <Image 
                  src={DeleteIcon}
                  preview={false}
                  width={48}
                  style={{ marginBottom: 15 }}
                />
                <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 5 }}>Delete Request Type?</Title>
                <Text style={{ marginBottom: 15 }}>Are you sure you want to delete this request type?</Text>
              </Flex>
              <Flex gap={5} className="ant-modal-footer">
                <Button size="large" onClick={handleDeleteCancel} style={{ fontWeight: 600 }}>Cancel</Button>
                <Button size="large" type="primary" danger onClick={() => handleDeleteConfirm(remove)} style={{ fontWeight: 600 }}>Delete</Button>
              </Flex>
            </Modal>

          </>
        )}
      </Form.List>
    </Form>
  );
};

export default RequestList;