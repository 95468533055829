import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Form, Layout,Typography, Button, Input, Checkbox, Flex, Spin, message } from 'antd';
import { post } from '../utils/api';
import useScreenSize from '../utils/useScreenSize';
import VerifyTwoStep from '../components/VerifyStep/VerifyTwoStep';
import { jwtDecode } from 'jwt-decode';
import { openNotification } from '../utils/utils';

const { Title, Paragraph } = Typography;

const { Content } = Layout;

const Login = () => {
  const [form] = Form.useForm();
  const { isMobile } = useScreenSize();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [loginValue, setLoginValue] = useState({});
  

  useEffect(() => {
    const savedEmail = localStorage.getItem('rememberEmail');
    const savedPassword = localStorage.getItem('rememberPassword');
    if (savedEmail && savedPassword) {
      form.setFieldsValue({
        email: savedEmail,
        password: savedPassword,
        rememberme: true,
      });
    }
  }, [form]);

  useEffect(()=>{
    const token = localStorage.getItem('token');
    let user = null;
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
  
      if (decodedToken.exp > currentTime) {
        user = decodedToken;
      } else {
        localStorage.removeItem('token');
      }
    }

 
    if(user && user.role.includes('Creator')){
      navigate('/home');
    }else if(user && user.role.includes('Admin')){
      navigate('/admin/dashboard');
    }else{
      navigate('/');
    }
  },[navigate]);

  const Signup= () =>{
    navigate('/signup');
  }

  const handleSubmit = async (values) => {
    const currentStepData = await form.validateFields();
    if (currentStepData) {
      setLoading(true);
      try {
        setLoginValue(values);
        const response = await post('auth/login', values);
        if (response && response.status === 200) {
          if(response?.token){

            const token = response.token;
            localStorage.setItem('token', token);

            const decodedToken = jwtDecode(token);
              
            if(decodedToken && decodedToken.role.includes('Admin')){
              navigate('/admin/dashboard');
            }else if(decodedToken && decodedToken.role.includes('Creator')){
              navigate('/home');
            }else if(decodedToken && decodedToken.role.includes('Team')){
              navigate('/team/dashboard');
            }
            else if(decodedToken && decodedToken.role.includes('User')){
              navigate('/audience/billing');
            }else{
              navigate('/');
            }

          }else{
            openNotification('OTP sent to your email!');
            setIsOtpSend(true);
  
            // Save credentials to localStorage if "Remember Me" is checked
            if (values.rememberme) {
              localStorage.setItem('rememberEmail', values.email);
              localStorage.setItem('rememberPassword', values.password);
            } else {
              localStorage.removeItem('rememberEmail');
              localStorage.removeItem('rememberPassword');
            }
          }
         
        } else {
          message.error(response.message || 'Login failed');
        }
      } catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message || 'Error during login');
        } else {
          message.error(error.message || 'Error during login');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Content>
      <Spin size="small" spinning={loading}>
      <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>
        {isOtpSend?
          <VerifyTwoStep setIsOtpSend={setIsOtpSend} loginValue={loginValue}/>:(
          <Form form={form} onFinish={handleSubmit}>
            <Title level={2} style={{ textAlign: 'center', marginTop: isMobile ? '' : '40px', marginBottom: isMobile ? '5px' : '15px' }}>Log in to your account</Title>
            <Paragraph style={{ textAlign: 'center', fontSize: '16px' }}>Welcome back! Please enter your details.</Paragraph>

            <Form.Item
              name="email"
              label="Email"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your email!' }]}
              className="custom-form-item"
            >
              <Input placeholder="Enter your email" style={{ padding: '7px 10px' }} />
            </Form.Item>

            <Form.Item
                name="password"
                label="Password"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className="custom-form-item"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    min: 8,
                    message: 'Password must be at least 8 characters long!',
                  },
                  {
                    pattern: /[!@#$%^&*(),.?":{}|<>]/,
                    message: 'Password must contain at least one special character!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Enter your password" />
            </Form.Item>

            <Flex horizontal="true" align="center" justify="space-between" style={{ marginBottom: '20px' }}>
              <Flex vertical>
                <Form.Item
                  name="rememberme"
                  valuePropName="checked"
                  wrapperCol={{ span: 24 }}
                  style={{ marginBottom: 0, fontWeight: 500 }}
                >
                  <Checkbox>
                    Remember me
                  </Checkbox>
                </Form.Item>

              </Flex>
              <Flex vertical>
                  <Link to="/forgot-password" style={{ fontWeight: 600 }}>Forgot password</Link>
              </Flex>
            </Flex>

            <Button type="primary" htmlType="submit"   size="large" block style={{ fontWeight: 600 }}>
              Sign in
            </Button>
            <Button size="large" onClick={Signup} block style={{ fontWeight: 600, marginTop: '20px' }}>
                Sign up
            </Button>

            {isMobile &&
              <Flex vertical style={{ marginTop: 20, textAlign: 'center' }}>
                {location.pathname === '/signup' ?
                  <Paragraph>
                    Already have an account? <Link to="/" style={{ fontWeight: 600 }}>Log in</Link>
                  </Paragraph>
                : 
                  <Paragraph>
                    Don’t have an account? <Link to="/signup" style={{ fontWeight: 600 }}>Sign up</Link>
                  </Paragraph>
                 }
              </Flex>
            }

          </Form>)}
      </Flex>
      </Spin>
    </Content>
  );
};

export default Login;