import React, { useState, useEffect } from 'react';
import { Statistic, Image, Flex } from 'antd';
import ExpressIcon from "../../images/express-icon.svg";
import ClockIcon from "../../images/clock.svg";
import ClockOrgIcon from "../../images/clock-org.svg";
import CrossIcon from "../../images/x-circle.svg";
import axios from 'axios';
import moment from 'moment';

const { Countdown } = Statistic;

// Function to format the time as '2d 18h 2m 25s'
const formatTime = (time) => {
  const duration = moment.duration(time, 'seconds');
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return `${days > 0 ? `${days}d ` : ''}${hours > 0 ? `${hours}h ` : ''}${minutes > 0 ? `${minutes}m ` : ''}${seconds}s`;
};

const CountdownTimerSelected = ({ time, id }) => {
  const target = moment(time);
  const [expired, setExpired] = useState(false);
  const [isUnder24Hours, setIsUnder24Hours] = useState(false);

  // Determine if the target time is already passed when component mounts
  useEffect(() => {
    if (moment().isAfter(target)) {
      setExpired(true);
    }

    const remainingTime = moment.duration(target.diff(moment()));
    setIsUnder24Hours(remainingTime.asHours() <= 24);

  }, [target]);

  // Uncomment if you want to use the API call feature
  // useEffect(() => {
  //   if (time <= 60 && !expired) {
  //     axios.post('/api/trigger-expiry', { id })
  //       .then(response => {
  //         console.log('API triggered: ', response.data);
  //       })
  //       .catch(error => {
  //         console.error('Error triggering API: ', error);
  //       });
  //   }
  // }, [time, expired, id]);

  const handleExpire = () => {
    setExpired(true);
  };

  const customFormatter = (value) => {
    const duration = moment.duration(value, 'seconds');
    const hours = Math.floor(duration.asHours()); // Get total hours
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours > 0 ? `${hours}h ` : ''}${minutes > 0 ? `${minutes}m ` : ''}${seconds}s`;

  };

  return (
    <div className="countdown-timer">
      {!expired ? (<>
        {!isUnder24Hours == true ?
          <Countdown
            title={
              <span>
                <Image src={ClockIcon} alt="clock" />
              </span>
            }
            value={target}
            onFinish={handleExpire}
            format="D[d] H[h] m[m] s[s]"
            className="selected-timer"
          />
          : 
          <Countdown
            title={
              <Flex gap={5} style={{ color: '#DC6803' }}>
                <Image src={ClockOrgIcon} alt="clock" />
                Expiring in
              </Flex>
            }
            value={target}
            onFinish={handleExpire}
            format="H[h] m[m] s[s]"
            className="timer-less-24 selected-timer"
          />
        }
      </>) : (
        <Flex gap={6} style={{ fontSize: 14, color: '#D92D20', marginTop: 0 }}>
          <Image src={CrossIcon} alt="Expired" />
          Expired
        </Flex>
      )}
    </div>
  );
};

export default CountdownTimerSelected;