import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Layout, Typography, Breadcrumb, Button, Progress, Flex, Radio, Divider, Image } from 'antd';
import { RightOutlined, BarChartOutlined, ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { UserContext } from '../../../contexts/UserContext';
import { get } from '../../../utils/api';
import useScreenSize from '../../../utils/useScreenSize';
import { useNavigate } from 'react-router-dom';
import AddToCommunities from '../../GlobalComponents/addToCommunities';
import UserIcon from '../../../images/user-icon.svg';
import UserIconWhite from '../../../images/user-icon-white.svg';
import EditIconWhite from '../../../images/edit-icon-white.svg';
import FilteredNewMessage from '../../GlobalComponents/FilteredNewMessage';
const { Title: AntTitle, Text } = Typography;

const { Content } = Layout;

const AgeReport = () => {

  const [selectedRange, setSelectedRange] = useState('all');
  const {user}  = useContext(UserContext);
  const [typeValue,setTypeValue]  = useState('percent');
  const [ageData,setAgeData]  = useState([]);
  const [loading, setLoading] = useState(false);
  const [isVisible, seIsVisible] = useState(false); 
  const { isMobile } = useScreenSize();
  const navigate = useNavigate();
  const [filters, setFilters] = useState([]);  
  const [open, setOpen] = useState(false); 
  const [activeButton, setActiveButton] = useState(null);

  const communityToLocation=async()=>{
    seIsVisible(true); 
  }

  const sendMessageToAge=(selectedlocation)=>{
		setOpen(true);	
		setFilters({
			ageRange:selectedlocation
		});
	}

  const handleRangeChange = (range) => {
    const ageRange=range.target.value;
    setSelectedRange(ageRange);
  };

  const handletypeValue=async (type)=>{
    const ageType=type.target.value;
    setTypeValue(ageType);
  }

  const fetchChartData=async(params={})=>{
    setLoading(true);
    try{
      const response = await get('creator/ageReport', params);
      if(response && response.status){
        setAgeData(response?.data);
      }
    }catch (error) {
      console.error('Error fetching users:', error);
    }finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(()=>{
    fetchChartData({gender:selectedRange});
  },[selectedRange]);

  const reportContentStyle = useMemo(() => 
    isMobile ? { 
      padding: '20px 15px', 
    } : { 
      backgroundColor: '#ffffff',
      height: '100vh', 
      minHeight: '100vh',
      overflow: 'auto', 
      scrollbarWidth: 'thin', 
      scrollbarColor: 'unset', 
      padding: '30px',
    }
  , [isMobile]);

  return (
    <Layout>
      <Content style={reportContentStyle}>
       {isMobile ?<div>
          <Button
            type="text"
            icon={<ArrowLeftOutlined style={{ color: '#101828' }}/>}
            onClick={handleClose}
            style={{ padding: 0, fontWeight: 600, marginBottom: 10, background: 'transparent' }}
          >Back</Button>
        </div> 
        : <>
          {user?.role==="Team"?
          <Breadcrumb className="breadcrumb-insights" separator={<RightOutlined />} style={{ marginBottom: 25 }}>
            <Breadcrumb.Item href="/team/insights">
              <BarChartOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/team/insights">
              <span>Audience Insights</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Age</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          : <Breadcrumb className="breadcrumb-insights" separator={<RightOutlined />} style={{ marginBottom: 25 }}>
          <Breadcrumb.Item href="/insights">
            <BarChartOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/insights">
            <span>Audience Insights</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>Age</span>
          </Breadcrumb.Item>
        </Breadcrumb>}</>
      }

      <Flex style={ isMobile ? { flexDirection: 'column', alignItems: 'start', marginBottom: 40 } : { flexDirection: 'row', marginBottom: 40 }}>
        <Flex vertical>
          <AntTitle level={2} style={{ marginBottom: 5, marginTop: 0 }}>Age report</AntTitle>
          <Text>View and see your top user age groups.</Text>
        </Flex>
        <Flex className="button-date-group" style={ isMobile ? { marginTop: 15 } : { marginLeft: 'auto' }}>
          <Radio.Group value={typeValue} onChange={handletypeValue}>
            <Radio.Button value="percent">Percent %</Radio.Button>
            <Radio.Button value="number">Number #</Radio.Button>
          </Radio.Group>
        </Flex>
      </Flex>

      <Flex align="center" style={ isMobile && { flexDirection: 'column', alignItems: 'start' }}>
        <Flex vertical>
          <AntTitle level={4} style={{ marginTop: 0, marginBottom: 0, fontSize: '18px' }}>Metrics</AntTitle>
        </Flex>
        <Flex className="button-date-group" style={ isMobile ? { marginTop: 15 } : { marginLeft: 'auto' }}>
          <Radio.Group value={selectedRange} onChange={handleRangeChange}>
            <Radio.Button value="all">All</Radio.Button>
            <Radio.Button value="Female">Female</Radio.Button>
            <Radio.Button value="Male">Male</Radio.Button>
            <Radio.Button value="Other">Non-binary</Radio.Button>
          </Radio.Group>
        </Flex>
      </Flex>

      <Divider />

      {ageData.map((location, index) =>{
          if(location?.count>0){
            return (<div key={index} style={{ marginBottom: '25px', width: 'calc(100% - 18px)' }}>
              <Flex gap={15} align="end">
                <Flex vertical style={{ flex: 1 }}>
                  <Text style={{ color: '#344054' }}>{location.ageRange}</Text>
                  <Progress className="progress-bar" percent={location.percentage} format={(percent)=>{
                    return (typeValue=="number")?location?.count:`${percent}%`;
                  }} />
                </Flex>
                <Button onClick={()=>{sendMessageToAge(location.ageRange)}} type="primary" size="large" style={{ minWidth: 40 }} icon={<Image src={EditIconWhite} preview={false} />}></Button>
                {/*<Button 
                  onClick={() => {
                    setActiveButton(location.ageRange);
                    communityToLocation(location?.ageRange);
                  }} 
                  type={activeButton === location.ageRange ? 'primary' : 'default'} 
                  size="large" 
                  style={{ fontWeight: 600 }} 
                  icon={<Image src={activeButton === location.ageRange ? UserIconWhite : UserIcon} preview={false} width={20} />}
                >
                  New Community
                </Button>*/}
              </Flex>
            </div>)
          }
      })}
    </Content>    
        <FilteredNewMessage filters={filters} open={open} setOpen={setOpen}/>
        {/* <AddToCommunities isVisible={isVisible} seIsVisible={seIsVisible} setActiveButton={setActiveButton} /> */}
    </Layout>
  );
};

export default AgeReport;
