import React from "react";
import { Typography, Image, Flex, Button, Alert } from "antd";
import AudioPlayer from "./AudioPlayer";

import { InfoCircleOutlined, CheckCircleFilled } from '@ant-design/icons';

import UnlockIcon from "../../images/lock-04.svg";
import ArrowRight from "../../images/arrow-right.svg";
import CheckIcon from "../../images/check-circle.svg";
import CrossIcon from "../../images/x-circle.svg";

import ReactPlayer from "react-player";
import { Link } from 'react-router-dom';

const { Text } = Typography;

const PrintMessage=({message,type,userType="me",priceInput})=>{

    if(type=="text" || type==null){
      return (
        <Text className={`chat-bubble chat_${userType}`}> 
          <div dangerouslySetInnerHTML={{ __html: message?.replace(/<p><br><\/p>/g, '') }} style={{ whiteSpace: 'normal' }} />
        </Text>
      );
    }

    if(type=="declineText"){
      return (
        <Flex gap={10} vertical align="end" className={`chat-bubbl declineText`}>
          <Flex justify="space-between" align="center" style={{ backgroundColor: 'white', borderRadius: 12, border: '1px solid #EAECF0', padding: '15px 20px', width: '100%', maxWidth: '360px' }}>
            <Text style={{ fontSize: '16px', color: '#475467', fontWeight: 600, display: 'flex', gap: 8 }}><Image src={CrossIcon} preview={false} width={20} style={{ marginRight: 8 }} /> Request Declined</Text>
          </Flex>
          <Text className={`chat-bubble chat_${userType}`}>
            <div dangerouslySetInnerHTML={{ __html: message?.replace(/<p><br><\/p>/g, '') }} style={{ whiteSpace: 'normal' }} />
          </Text>
        </Flex>
      );
    }

    if(type=="completeText"){
      return (
        <Text className={`chat-bubble chat_${userType}`}> 
          <div dangerouslySetInnerHTML={{ __html: message?.replace(/<p><br><\/p>/g, '') }} style={{ whiteSpace: 'normal' }} />
        </Text>
      );
    }

    if(type=="completefinal"){
      return (
        <Flex className={`chat-bubbl completefinal`} justify="space-between" align="center" style={{ backgroundColor: 'white', borderRadius: 12, border: '1px solid #EAECF0', padding: '15px 20px', width: '100%', maxWidth: '360px' }}>
            <Text style={{ fontSize: '16px', color: '#475467', fontWeight: 600, display: 'flex', gap: 8 }}><Image src={CheckIcon} preview={false} width={20} style={{ marginRight: 8 }} /> Complete</Text>
            <Link to={`/private-requests/${btoa(message)}`} target="_blank" style={{ color: '#475467', fontWeight: 600 }}>View request <Image src={ArrowRight} preview={false} /></Link>
        </Flex>
      );
    }
 
    if(type=="link"){
      if(message.match('audience/myrequests')){
        const hasId = message.split("/").pop();
        return (
          <Flex justify="space-between" align="center" className={`chat-bubbl request-booked`} style={{ backgroundColor: 'white', borderRadius: 12, border: '1px solid #EAECF0', padding: '15px 20px', width: '100%', maxWidth: '360px' }}>
              <Text style={{ fontSize: '16px', color: '#475467', fontWeight: 600 }}><InfoCircleOutlined style={{ marginRight: 8 }} />Request Booked</Text>
              <Link to={`/private-requests/${hasId}`} target="_blank" style={{ color: '#475467', fontWeight: 600 }}>View request <Image src={ArrowRight} preview={false} /></Link>
          </Flex>
        );
      }else{
        return (
          <Text className={`chat-bubble chat_${userType}`}> 
            <a href={message} target="_blank">{message}</a>
          </Text>
        );
      } 
    }

    if(type=="image" || type=="completeVI"){
      const isImage = /\.(jpg|jpeg|png|gif|webp)$/i.test(message);
      const isVideo = /\.(mp4|webm|ogg)$/i.test(message);
      const isGiphy = message.match('https://media[1-3].giphy.com/media/');

      const mediaUrl = isGiphy ? message : `${process.env.REACT_APP_API_BASE_URL}/${message}`;
      if (isImage) {
        return (
          <Image
            src={mediaUrl}
            preview={false}
            className="chat-image" 
          />
        );
      }
      if (isVideo) {
        return (
          <video 
            controls
            src={mediaUrl}
            className="chat-video"
          />
        );
      }
    }

    if(type=="paidcontent"){
      return (
        <Flex vertical style={{ maxWidth: '230px', width: '100%', textAlign: 'center' }}>
          <a href={message} target="_blank"><Flex vertical justify="center" align="center" style={{ backgroundColor: '#4D5159', padding: 25, borderRadius: '12px 12px 0px 0px', width: '100%', minHeight: '236px', boxSizing: 'border-box' }}>
           <Image
              width={56}
              src={UnlockIcon}
              preview={false}
              style={{ marginBottom: 0 }}
            />
            {/*<Button size="large" style={{ backgroundColor: '#ffffff', fontWeight: 600 }}>Unlock for ${priceInput}</Button>*/}
          </Flex>
          <Flex vertical justify="center" align="start" style={{ backgroundColor: '#E5E5EA', padding: '8px 15px', borderRadius: '0px 0px 12px 12px', width: '100%', boxSizing: 'border-box'  }}>
            <Text style={{ color: '#101828', fontSize: '16px' }}>See content to unlock</Text>
            <Text style={{ fontSize: '12px' }}>blonde.social</Text>
          </Flex></a>
        </Flex>
      );
    }

    if(type=="audio"){
      const audioURL=`${process.env.REACT_APP_API_BASE_URL}/${message}`;
      return (
        <AudioPlayer audioURL={audioURL}/>
      );
    }

    if(type=="video"){
      const VideoURL=`${process.env.REACT_APP_API_BASE_URL}/${message}`;
      return ( 
        <video 
          controls
          src={VideoURL}
          className="chat-video"
        />
      );
    }
}

export default PrintMessage;