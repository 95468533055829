import React,{useEffect,useState} from 'react';
import { Link,useNavigate,useParams,Navigate } from 'react-router-dom';
import { Layout, Col, Row, Flex, Image, Drawer, Divider, Radio, Form, Typography, Button, Spin } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

import PlaceholderImg from '../../../images/placeholder-img2.jpg';
import Payment from '../../../components/Audience/Payment';
import './FanSignup.css';
import { get } from '../../../utils/api';
import { openNotification } from '../../../utils/utils';

const { Content } = Layout;
const { Text, Title } = Typography;

const FanSignup = () => {
    const [data,setData]=useState(null);
    const [creatorData,setCreatorData]=useState(null);
    const [unLockHandler,SetUnLockHandler]=useState(null);
    const [token,setToken]=useState(null);
    const { smsId, phone } = useParams();
    const [privateRequestEnabled,setPrivateRequestEnabled]=useState(false);
    const Navigate = useNavigate();

    useEffect(()=>{
        const init=async()=>{
            const response = await get(`user/unlockRequest/${smsId}/${phone}`);
            if (response && response.status) {
                
                setToken(response?.data?.token);
                setData(response?.data.postData);
                if(response && response?.data?.creatorData){
                    setCreatorData(response?.data?.creatorData);
                    setPrivateRequestEnabled(response?.data?.privateRequestEnabled);
                }
            }
        }
        init();
    
    },[smsId]);

    useEffect(()=>{
        if(data?.message && token){
            Navigate(`/audience/fan-signup-unlock/${smsId}`);  
        }
        if(token){
            localStorage.setItem('token',token);
        }
    },[data,token]);


    return (
        <Layout style={{ height: '100vh', backgroundColor: '#000000' }}>
            {data ? 
            <Content style={{ flex: '1' }}>
                {!unLockHandler?<Flex vertical className="unclock-content" style={{ margin: '0 auto', borderRadius: 0, height: '100%' }}>
                    {/*<Button type="text" style={{ backgroundColor: 'rgba(0,0,0,0.1)', position: 'absolute', right: 5, top: 5, zIndex: 1, padding: '2px 6px' }} onClick={toggleDrawer}><EllipsisOutlined style={{ fontSize: 30, color: 'rgba(255,255,255,0.6)' }} /></Button>*/}
                    <Image
                        src={PlaceholderImg}
                        style={{ borderRadius: 0, maxWidth: '100%' }}
                        preview={false}
                    />
                    <Flex gap={15} justify="center" className="button-group" style={{ padding: 12 }}>
                        <Link style={{ display: 'contents' }} to={`/audience/private-requests/${smsId}/${phone}`}>
                            <Button size="large" style={{ maxWidth: '230px', width: '100%', fontWeight: 600, fontSize: '14px' }}>Private request</Button>
                        </Link>
                        <Button type="primary" onClick={() => SetUnLockHandler(true)} size="large" style={{ maxWidth: '230px', width: '100%', fontWeight: 600 }}>Unlock ${data.price}</Button>
                    </Flex>
                </Flex>:
                <Flex vertical style={{ maxWidth: '416px', margin: '0 auto', padding: '25px 20px', backgroundColor: '#FFFFFF', borderRadius: 10 }}>
                    <Payment smsId={smsId} phone={phone} creatorData={creatorData} data={data} SetUnLockHandler={SetUnLockHandler} />
                </Flex>}
            </Content>: 
             <Spin spinning={true}>
                <Content style={{ height:"100Vh", flex: '0' }}>
                </Content>
            </Spin>}
        </Layout>
    );
}

export default FanSignup;