import React, { useEffect, useState,useContext } from 'react';
import { Modal, Button, DatePicker, Drawer, Input, Tooltip, Typography, Flex, Select ,Form, Avatar, Calendar, TimePicker, Image, message as antdMessage,Spin} from 'antd';
import { SmileOutlined, CloseOutlined, PictureOutlined, LeftOutlined, RightOutlined, EditOutlined, CheckOutlined,CloseCircleOutlined } from '@ant-design/icons';
import { UserContext } from '../../contexts/UserContext';
import ChatInputNew from '../GlobalComponents/ChatInputNew';
import { getInitials ,getImageUrl,openNotification} from '../../utils/utils';
import dayjs from 'dayjs';
import momenttmz from 'moment-timezone';
import { post } from '../../utils/api';
import useScreenSize from '../../utils/useScreenSize';
import CalendarPlusIcon from '../../images/calendar-plus-icon.svg';
import CurrencyDollarIcon from '../../images/currency-dollar-icon.svg';
import UnlockIcon from "../../images/unlock-icon.svg";

const { Title, Text } = Typography;
const { Option } = Select;

const SendMessage = ({ openSendMessage, handleCancel, handleCloseMessage, basePrice, selectedItems, setSelectedItems, isScheduled=false,setSelectAll, open, onClose }) => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [title, setTitle] = useState('Untitled');
  const [testMessageForm] = Form.useForm();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const {user}  = useContext(UserContext);
  const [isPriceModalVisible, setIsPriceModalVisible] = useState(false);
  const [priceInput, setPriceInput] = useState('');
  const [scheduleMessage, setScheduleMessage] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedAmPm, setSelectedAmPm] = useState('AM');
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [price, setPrice] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedTimezone, setSelectedTimezone] = useState('America/Los_Angeles');
  const { isMobile } = useScreenSize();
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [chatImages, setChatImages] = useState([]);
  const [isSendTestModalVisible, setIsSendTestModalVisible] = useState(false);
  const [isSeningTestMessage, setIsSeningTestMessage] = useState(false);
  const [testUser, setTestUser] = useState(null);
  const timeZoneAbbreviation = momenttmz.tz.guess(true); 
  const [form] = Form.useForm();

  const handleClearScheduleDate = () => {
    setScheduleMessage(null);
    setIsScheduleModalVisible(false);
  };

  const showModal = () => {
    setIsScheduleModalVisible(true);
  };
  const handleClearPrice = () => {
    setPriceInput(null);
    setIsPriceModalVisible(false);
  };

  const handleInputChange = (value) => {
    setMessage(value);

    const newValue = value;
    setMessage(newValue);

    if (newValue === '') {
      handleClearPrice();
      handleClearScheduleDate();
    }
  };

  useEffect(() => {
    setVisible(openSendMessage);
  }, [openSendMessage]);

  useEffect(()=>{
    setTitle(`${selectedItems?.length} fans`);
    form.setFieldsValue({
      to: `${selectedItems?.length} Fans`
    });
  },[selectedItems]);

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const toggleEditTitle = () => {
    setIsEditingTitle(!isEditingTitle);
  };

  const handleTitleBlur = () => {
    setIsEditingTitle(false);
  };

  const handlePriceModalCancel = () => {
    setIsPriceModalVisible(false);
  };

  const handlePriceInputChange = (e) => {
    setPriceInput(e.target.value);
  };

  const handleTimeChange = (time) => {
    if (time) {
      const getAMPM = time.format('A'); // 'A' will return AM/PM
      setSelectedAmPm(getAMPM);
      setSelectedTime(time);
    } else {
      setSelectedAmPm('');
      setSelectedTime(null);
    }
  };

  const handleApplyPrice = () => {
    const priceValue = parseFloat(priceInput);
    if (!isNaN(priceValue) && priceValue >= 0.99) {
      setPrice(priceValue);
      setIsPriceModalVisible(false);
    } else {
      alert("Price must be at least $0.99");
    }
  };

  const handleOk = () => {
    if (selectedDate && selectedTime) {
      const formattedDate = selectedDate.format('MM/DD/YYYY');
      const formattedTime = selectedTime.format('h:mm') + ` ${selectedAmPm}`;
      const formattedSchedule = `Schedule for ${formattedDate} at ${formattedTime} - ${selectedTimezone}`;
      setScheduleMessage(formattedSchedule);
      setIsScheduleModalVisible(false);
    }else{
      antdMessage.error('Please choose a time to send the SMS.');
    }
  };

  const handleScheduleCancel = () => {
    setIsScheduleModalVisible(false);
  }

  const handleMonthChange = (isNext) => {
    const newMonth = isNext ? currentMonth.add(1, 'month') : currentMonth.subtract(1, 'month');
    setCurrentMonth(newMonth);
    setSelectedDate(newMonth);
  };
  const handleTodayClick = () => {
    const today = dayjs();
    setSelectedDate(today);
    setCurrentMonth(today);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const onPanelChange = (value, mode) => {
    setCurrentMonth(value);
  };

  const timezones = momenttmz.tz.names();

  const sendMessageHandler=async (values)=>{
    setIsMessageSending(true);
    try{
      let schedule={};
      if(selectedDate && selectedTime){
        const formattedDate = selectedDate.format('MM/DD/YYYY');
        const formattedTime = selectedTime.format('h:mm');
  
         schedule={
          formattedDate,
          selectedAmPm,
          selectedTimezone,
          formattedTime
        };
      }
    
     
      const completeFormData = { ...values,message,selectedItems,title,schedule,price};
      const response = await post(`creator/sendMessageSelectedItems`,completeFormData);
      if(response && response.status){
        form.resetFields();
        openNotification('Success!');
        setSelectedItems([]);
       // handleCancel();
        onClose();
        setSelectAll(false);
      }else{
        antdMessage.error('Oops something went wrong please try again later.');
      }
     }catch(error){
      console.log("loading......",error);
    } finally {
      setIsMessageSending(false);
    }
  }

  const handleImagesChange = (images) => {
    setChatImages(images);

    const newImageValue = images;
    setChatImages(newImageValue);

    if (newImageValue === '') {
      setChatImages([]);
    }
  };

  const handlePriceClick = () => {
    setIsPriceModalVisible(true);
    if(basePrice){
      setPriceInput(basePrice);
    }else{
      setPriceInput('0.99');
    }
  }

  useEffect(()=>{
    setPriceInput(basePrice);
    console.log("basePrice=>ddd",basePrice);
  },[basePrice]);

  const showSendTestModal = () => {
    setIsSendTestModalVisible(true);
  };

  const handleSendTestMessage = async() => {
    setIsSeningTestMessage(true);
    try{
      let mediaPath=null;
      if(chatImages.length>0){
        const FileUrl=chatImages[0] || null;
        const urlId=await getRealPath(FileUrl?.file);
       
     
        if(urlId && urlId?.image){
          mediaPath=urlId?.image?.filepath;
        } 
      }

      if(testMessageForm.validateFields()){
        const completeFormData={
          uId:testUser,
          msg:message,
          mediaPath,
          }
        const response = await post(`creator/TestMessage`,completeFormData);
        if(response && response.status){
          openNotification('Test message sent successfully!');
        }
      }
    
   }catch(error){
    console.log("loading......",error);
  } finally {
    setIsMessageSending(false);
    setIsSeningTestMessage(false);
  }
    // console.log("Selected user ID:", testUser);
    // setIsSendTestModalVisible(false);
  };

  const getRealPath = async (file ) => {
    try {
      const formData = new FormData();
      formData.append('singleImage', file);
      
      // Replace with your actual API endpoint
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/single-upload`, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        return false;
      }

      const data = await response.json();
     return data;
    } catch (error) {
      console.error('Upload failed:', error);
     return false;
    }
  };

  const handleSendTestCancel = () => {
    setIsSendTestModalVisible(false);
  };

  const handleChange = (e) => {
    const value=e.target.value;
    
    setTestUser(value);
  };

  return (
    <Drawer
      placement="right"
      onClose={onClose}
      open={open}
      width="100%"
      className="new-message"
      extra={null}
    >
      <Spin spinning={isMessageSending}>
       <Form form={form}  onFinish={sendMessageHandler} layout="vertical">
        <Flex horizontal="true" className="head" justify="space-between">
          <div style={{ padding: 0, borderBottom: '1px solid #EAECF0' }}>
            {isEditingTitle ? (
              <Flex>
                <Input 
                  value={title} 
                  onChange={handleTitleChange} 
                  onBlur={handleTitleBlur} 
                  placeholder="Enter campaign name"
                  autoFocus 
                  style={{ marginRight: 10, width: 'auto', fontSize: 18, fontWeight: 700, padding: 0, border: 0, borderBottom: '1px solid #EAECF0', boxShadow: '0 0', borderRadius: 0 }} 
                />
              </Flex>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text style={{ fontSize: 18, fontWeight: 700, color: '#000000' }}>{title}</Text>
                <Button type="text" icon={<EditOutlined style={{ color: '#000000' }} />} onClick={toggleEditTitle} />
              </div>
            )}
          </div>
          <Button
            type="link"
            icon={<CloseOutlined style={{ color: '#101828' }}/>}
            onClick={handleCancel}
            style={{ alignSelf: 'flex-end' }}
          >
          </Button>
        </Flex>
      <Flex>
        <Flex vertical style={{ flex: 1, padding: 20 }}>

          <Flex gap={10} horizotanl="true" style={{ marginBottom: 10 }}>
            <Form.Item 
              name="to" 
              rules={[{ required: true, message: "Please add the receiver's phone numbers.!" }]}
              className="custom-form-item"
              style={{ flex: '1', marginBottom: 0 }}
              
            >
              <Input
                placeholder=""
                addonBefore={<Text>To:</Text>}
                readOnly={true}
               
              />
            </Form.Item>
          </Flex>


          <Form.Item 
           name="messagebox"
           labelCol={{ span: 24 }}
           wrapperCol={{ span: 24 }}
           rules={[{ required: !message, message: 'Please input your message!' }]}
           className="custom-form-item custom-message-box"
          >
            <ChatInputNew setSelectedPrice={setSelectedPrice} onInputChange={handleInputChange} onImagesChange={handleImagesChange} setIsPriceModalVisible={setIsPriceModalVisible} setIsScheduleModalVisible={setIsScheduleModalVisible} />

          </Form.Item>

          <Flex
            vertical
            justify="end"
            gap="small"
            style={{
              width: '100%',
              marginTop: 10
            }}
          >

          {price !== null ? ( 
              <Flex
                horizotanl="true"
                justify="end"
                gap={12}
                style={{
                  width: '100%',
                  marginTop: '20px'
                }}
              >
             <span style={{ color: '#344054', fontSize: '16px', fontWeight: 600 }}>{`$${price.toFixed(2)}`}</span> <EditOutlined style={{ fontSize: '20px' }} onClick={() => setIsPriceModalVisible(true)} /> <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={handleClearPrice} />
            </Flex>
            ) : (
              ''
          )}

          {scheduleMessage !== null ? ( 
              <Flex
                horizotanl="true"
                justify="end"
                gap={12}
                style={{
                  width: '100%',
                  marginTop: '0px'
                }}
              >
                <span style={{ color: '#344054', fontSize: '16px' }}>{scheduleMessage}</span> <EditOutlined style={{ fontSize: '20px' }} onClick={() => setIsScheduleModalVisible(true)} /> <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={handleClearScheduleDate} />
              </Flex>
            ) : (
            ''
          )}
          </Flex>

          {(message || isScheduled) && <>
            {(isScheduleModalVisible || isScheduled) &&
              <Flex gap={10} horizotanl align="center" style={{ padding: '15px 0px', borderBottom: '1px solid #d9d9d9' }}>
                <Text style={{ marginTop: 0, marginBottom: 0 }}>Send on</Text>
                <Flex gap={10} className="date-today-button">
                  <DatePicker 
                      value={selectedDate ? dayjs(selectedDate, 'MM/DD/YYYY') : null} // Use dayjs here
                      onChange={handleDateChange}
                      format="MM/DD/YYYY"
                      style={{ flex: 1 }}
                  />
                </Flex>
                <Text style={{ marginTop: 0, marginBottom: 0 }}>at</Text>
                <Form.Item 
                   className="custom-form-item timepicker-input"
                   style={{ marginBottom: 0 }}
                  >
                  <TimePicker
                    use24Hours={false} // Ensure it's 12-hour format
                    format="h:mm A" // Show AM/PM in the picker
                    style={{ flex: 1 }}
                    onChange={handleTimeChange}
                    minuteStep={15} // Optional: Step to adjust minutes selection
                  />
                </Form.Item>
                <Text style={{ marginTop: 0, marginBottom: 0 }}>{timeZoneAbbreviation}</Text>
                <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={handleClearScheduleDate} />
              </Flex>
            }

           {isPriceModalVisible &&
              <Flex gap={10} horizotanl align="center" style={{ padding: '15px 0px', borderBottom: '1px solid #d9d9d9' }}>
                <Text style={{ marginTop: 0, marginBottom: 0 }}>Content Price</Text>
                <Form.Item className="custom-form-input-price" style={{ marginBottom: 0 }}>
                  <Input
                    placeholder=""
                    addonBefore={<Text>$</Text>}
                    value={priceInput}
                    onChange={handlePriceInputChange}
                    style={{ marginBottom: '0px' }}
                  />
                </Form.Item>
                <CloseCircleOutlined style={{ color: '#D92D20', fontSize: '20px' }} onClick={handleClearPrice} />
              </Flex>
            }
          </>}
 
          <Flex
            horizotanl
            justify="end"
            gap="small"
            style={{
              width: '100%',
              marginTop: 10
            }}
          >

            {!isScheduleModalVisible && (
              <Button 
                size="large" 
                style={{ 
                  fontSize: '14px', 
                  fontWeight: 600,
                  display: 'flex', 
                  alignItems: 'center', 
                  padding: '7px 15px 7px 15px' 
                }} 
                onClick={showModal}
                disabled={!(message || chatImages.length > 0) || isScheduled}
                >Schedule</Button>
            )}

            {!isPriceModalVisible && (
              <Button
                size="large"
                style={{ 
                  fontSize: '14px', 
                  fontWeight: 600, 
                  display: 'flex', 
                  alignItems: 'center', 
                  padding: '7px 15px 7px 15px' 
                }}
                onClick={handlePriceClick}
                disabled={!(chatImages.length > 0)}
              >
                Set Price
              </Button>
            )}

            <Button disabled={!(message || chatImages.length > 0)} key="submit" htmlType='submit' type="primary" size="medium" style={{ fontWeight: 600, paddingTop: 7, paddingBottom: 7, height: 'auto' }}>
             {isScheduleModalVisible?'Create Schedule':'Send'}  
            </Button>
  
          </Flex>

        </Flex>
        {isMobile ?
          ""
        :
          <Flex vertical style={{ flex: '0 1 316px', maxWidth: '316px', background: 'linear-gradient(to top, #F5F1FF, #FFFFFF)', borderLeft: '1px solid #EAECF0', minHeight: 'calc(100vh - 73px)' }}>
            <Flex vertical style={{ flex: 1, overflow: 'hidden', overflow: 'hidden', padding: '24px 24px' }}>
              <Flex vertical align="center" style={{ textAlign: 'center', marginBottom: 10, paddingTop: 0, paddingBottom: 10 }}>
              {user?.profileUrl ?
                  <Avatar size={40} src={getImageUrl(user?.profileUrl)} />          
                  :
                  <Avatar size={40} style={{ fontSize: 16, fontWeight: 600 }}>{getInitials(`${user?.first_name} ${user?.last_name}`)}</Avatar>
                }
               <Text>{user?.first_name} {user?.last_name}</Text>
              </Flex>

              <div style={{ flex: '1 1 0%', overflow: 'hidden auto', scrollbarWidth: 'none', minHeight: '380px', maxHeight: '380px' }}>
              {isPriceModalVisible == true ? (<>
                <Flex vertical style={{ maxWidth: '230px', width: '100%', textAlign: 'center' }}>
                  <Flex vertical justify="end" align="center" style={{ backgroundColor: '#4D5159', padding: 25, borderRadius: '12px 12px 0px 0px', width: '100%', minHeight: '236px', boxSizing: 'border-box' }}>
                   <Image
                      width={56}
                      src={UnlockIcon}
                      preview={false}
                      style={{ marginBottom: 20 }}
                    />
                    <Button size="large" style={{ backgroundColor: '#ffffff', fontWeight: 600 }}>Unlock for ${priceInput}</Button>
                  </Flex>
                  <Flex vertical justify="center" align="start" style={{ backgroundColor: '#E5E5EA', padding: '8px 15px', borderRadius: '0px 0px 12px 12px', width: '100%', boxSizing: 'border-box' }}>
                    <Text style={{ color: '#101828', fontSize: '16px' }}>See content to unlock</Text>
                    <Text style={{ fontSize: '12px' }}>blonde.social</Text>
                  </Flex>
                </Flex>
               </>) : (<>

                {chatImages.length > 0 && (
                  <Flex className="images-preview-grid" wrap gap={10} style={{ marginBottom: 10 }}>
                    {chatImages.map((image) => (
                      <Image
                        key={image.id}
                        src={(image?.type==="gif")?image?.file:image.url}
                        preview={false}
                        alt="Chat Image"
                        style={{ borderRadius: 8, objectFit: 'cover', flex: '1 auto' }}
                      />
                    ))}
                  </Flex>
                )} 
                </>)
              }

              {message &&
                <Flex style={{ padding: '5px 0', maxWidth: '80%'}}>
                  <div style={{ background: '#E9E9EB', borderRadius: 18, padding: '6px 12px', overflowWrap: 'anywhere' }}>
                 
                     <div dangerouslySetInnerHTML={{ __html: message }} style={{ whiteSpace: 'pre-wrap' }} />
                  </div>
                </Flex>
              }
              </div>
            </Flex>

            <Flex style={{ padding: '20px 24px', background: '#ffffff', borderTop: '1px solid #D0D5DD' }}>
              {message || chatImages.length > 0? 
               <Button onClick={showSendTestModal} type="default" block style={{ fontWeight: 600, paddingTop: 7, paddingBottom: 7, height: 'auto' }}>Send a test message</Button>
               :<Button type="default" disabled="true" block style={{ fontWeight: 600, paddingTop: 7, paddingBottom: 7, height: 'auto' }}>Send a test message</Button>}
            
           
            </Flex>

          </Flex>
        }

      </Flex>
      </Form>
      </Spin>


      <Modal
        title="Send Test Message"
        open={isSendTestModalVisible}
        onOk={handleSendTestMessage}
        onCancel={handleSendTestCancel}
        okText="Send"
        confirmLoading={isSeningTestMessage}
      >
        <Spin spinning={isSeningTestMessage}>
        <Form form={testMessageForm} layout="vertical">
          {/* <Form.Item name="testUser" label="Select User"  rules={[{ required: true, message: "Please Select User." }]}>
            <Select
              showSearch
              placeholder="Choose a user"
              filterOption={false}
              notFoundContent={searchloading ? <Spin size="small" /> : 'No users found'}
              onSearch={handleSearch}
              onChange={handleChange}
           
            >
              {users.map((user) => (
                <Select.Option key={user.value} value={user.value}>
                  {user.label} {console.log("user",user)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item name="testPhone" label="To phone number"  rules={[{ required: true, message: "Please add to phone number." }]}>
          <Input
              placeholder=""
              prefix="+1"
              style={{ marginBottom: '5px' }}
              onChange={handleChange}
            />
          </Form.Item>
        </Form>
        </Spin>
      </Modal>

      {/*{isPriceModalVisible && (
        <Modal
          open={isPriceModalVisible}
          onCancel={handlePriceModalCancel}
          footer={null}
          width={560}
          className="price-modal"
        >
        <Flex vertical style={{ padding: 20 }}>
          <Title level={4} style={{ fontSize: '18px', marginTop: 0, marginBottom: 25 }}>Content Price</Title>
          <Form.Item className="custom-form-input-price" style={{ marginBottom: 5 }}>
            <Input
              placeholder=""
              prefix="$"
              value={priceInput}
              onChange={handlePriceInputChange}
              style={{ marginBottom: '5px' }}
            />
          </Form.Item>
          <Text>$0.99 is standard minimum starting number.</Text>
        </Flex>
        <Flex gap="small" justify="flex-end" style={{ marginTop: '10px', borderTop: '1px solid #EAECF0', padding: '15px 20px' }}>
          <Button size="large" onClick={handlePriceModalCancel} style={{ fontWeight: 600 }}>Cancel</Button>
          <Button size="large" type="primary" onClick={handleApplyPrice} style={{ fontWeight: 600 }}>Apply</Button>
        </Flex>
        </Modal>
      )}*/}

      {/*<Modal visible={isScheduleModalVisible} onOk={handleOk} onCancel={handleScheduleCancel} width={688} className="schedule-modal" footer={[
        <Flex gap={14} justify="end" style={{ padding: '16px 20px', borderTop: '1px solid #EAECF0' }}>
          <Button key="cancel" size="large" onClick={handleCancel} style={{ fontWeight: 600 }}>Cancel</Button>
          <Button key="add" size="large" 
          type="primary" 
          onClick={handleOk} 
          style={{ fontWeight: 600 }}
          >Apply</Button>
        </Flex>]}>
        <Flex horizotanl style={{ display: 'flex', flexDirection: isMobile && 'column', justifyContent: 'space-between' }}>
          
          <Flex vertical style={{ borderRight: isMobile ? '' : '1px solid #EAECF0', padding: '20px 10px' }}>
            <Flex vertical style={{ padding: '0 10px 0 15px', marginBottom: 20 }}>
              <Text style={{ color: '#101828', fontSize: '16px', marginBottom: 15, display: 'block' }}>Send on</Text>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                <Button type="text" icon={<LeftOutlined />} onClick={() => handleMonthChange(false)} />
                <Text style={{ fontWeight: 600 }}>{currentMonth.format('MMMM YYYY')}</Text>
                <Button type="text" icon={<RightOutlined />} onClick={() => handleMonthChange(true)} />
              </div>
              <Flex gap={10} className="date-today-button">
                <Input 
                  value={selectedDate ? selectedDate.format('MMM DD, YYYY') : ''}
                  readOnly
                  style={{ flex: 1 }}
                />
                <Button onClick={handleTodayClick}>Today</Button>
              </Flex>
            </Flex>
            <Calendar
              fullscreen={false}
              value={selectedDate || currentMonth}
              onSelect={handleDateChange}
              onPanelChange={onPanelChange} 
              className="schedule-calendar"
              headerRender={() => null}
            />
          </Flex>

          <Flex vertical style={{ minWidth: '300px', padding: 20, borderTop: isMobile && '1px solid #EAECF0' }}>
            <Text style={{ color: '#101828', fontSize: '16px', marginBottom: 15, display: 'block' }}>At this time</Text>
            <Flex horizotanl gap={10} align="center">
              <TimePicker 
                use12Hours 
                format="h:mm" 
                style={{ flex: 1 }}
                onChange={(time) =>{
                 
                  if(time){
                    const getAMPM=time.format('a');
                    setSelectedAmPm(getAMPM);
                  }
                  
                  setSelectedTime(time)
                }} 
                changeOnScroll 
                needConfirm={false}
              />
              <Text>at</Text>
              <Select 
                value={selectedAmPm} 
                onChange={(value) => setSelectedAmPm(value)}
              >
                <Option value="am">AM</Option>
                <Option value="pm">PM</Option>
              </Select>
            </Flex>

            <div style={{ marginTop: '20px' }}>
              <Text>Timezone</Text>
              <Select 
                allowClear
                style={{ width: '100%', marginTop: '10px' }} 
                value={selectedTimezone} 
                onChange={(value) => setSelectedTimezone(value)}
                showSearch
                optionFilterProp="children"
              >
                 {timezones.map((timezone) => {
                    const offset = momenttmz.tz(timezone).format('Z');
                    return (
                      <Option key={timezone} value={`${timezone}`}>
                        {`${timezone} UTC${offset}`}
                      </Option>
                    );
                  })}
              </Select>
            </div>

          </Flex>
        </Flex>
      </Modal>*/}

    </Drawer>
  );
};

export default SendMessage;
