import React, { useEffect, useState } from "react";
import { Table, Typography, Button, Row, Col, Input, Image, Space, Collapse, Layout, Spin, Flex, Modal, message } from "antd";
import { PlusOutlined, SearchOutlined, ArrowLeftOutlined, DownOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import NewMessage from "../../components/GlobalComponents/NewMessage";
import { get } from "../../utils/api";
import { formatPrice } from "../../utils/utils";
import useScreenSize from '../../utils/useScreenSize';
import './campaigns.css';
import SidebarCampaigns from '../../components/Campaigns/SidebarCampaigns';
import CampaignResponse from '../../components/Campaigns/CampaignResponse';
import moment from "moment";
import ReactPlayer from 'react-player';
import { useParams } from 'react-router-dom';
import ArrowDown from '../../images/chevron-down.svg';
import ArrowRight from '../../images/arrow-right.svg';

const { Title, Text, Paragraph } = Typography;
const { Content } = Layout;
const { Panel } = Collapse;

const { confirm } = Modal;

const Campaigns = () => {
  const { isMobile } = useScreenSize();
  const [isLoading, setIsLoading] = useState(false);
  const [openSendMessage, setSendMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [isCampaignMessage, setCampaignMessage] = useState(false);
  const [campaignsList, setCampaignsList] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const { campId } = useParams();
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingCampaign, setEditingCampaign] = useState(null);

  const [selectedCampaignUserInfo, setCampaignUserInfo] = useState(null);
  const [selectedFan, setSelectedFan] = useState(null);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const fetchCampaigns = async (s = '', page = 1) => {
    const finaldata = {
      q: s,
      _page: page,
      _limit: 20,
    };
    setIsLoading(true);
    try {
      const response = await get('creator/campaigns/inbox', finaldata);
      if (response.status) {
        const { pagination } = response;
        if (s || page==1) {
          setCampaignsList(response?.data);
        } else {
          setCampaignsList((prevCampaignsList) => [
            ...prevCampaignsList,
            ...response?.data,
          ]);
        }
        setTotal(pagination?.total);
        setHasMore(response?.data?.length > 0);
      }
    } catch (error) {
      console.log("Error fetching campaigns:", error);
    } finally {
      setIsLoading(false);
    }
  };

 
  useEffect(()=>{
      const getSingleRecord=async()=>{
        if(campId){
          const recordData = await get(`creator/campaigns/${campId}`);
          if(recordData?.status){
            setEditingCampaign(recordData?.data);
            setIsEditing(true);
            setCampaignUserInfo(recordData?.data);
          }else{
            message.error(recordData?.message);
          }
        
         
        }
      }
      getSingleRecord();
  },[campId]);

  useEffect(() => {
    fetchCampaigns(search, 1);
  }, [search]);

  useEffect(() => {
    fetchCampaigns(search, page);
  }, [page]);

  const handleSendMessage = () => {
    setSendMessage(true);
    setOpen(true);
    setCampaignMessage(true);
  };

  const handleCloseMessage = async () => {
    confirm({
      title: 'Are you sure you want to leave?',
      icon: <ExclamationCircleFilled style={{ fontSize: 35, color: '#7F56D9', marginBottom: 10 }} />,
      content: 'This message will be closed.',
      okText: 'Leave',
      okButtonProps: {
        style: {
          backgroundColor: '#6941C6',
          borderColor: '#6941C6',
          fontWeight: 'bold',
          color: 'white'       
        },
        className: 'custom-leave-button'
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'white',
          borderColor: '#d9d9d9',
          fontWeight: 'bold',
          color: '#475467'
        },
        className: 'custom-cancel-button'
      },
      okType: 'danger',
      onOk() {
        setSendMessage(false);
        fetchCampaigns();
        setOpen(false);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onClose = async () => {
    setSendMessage(false);
    await fetchCampaigns(search, 1);
    setOpen(false);
  };

  const onScroll = (e) => {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if (bottom && !isLoading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  
  const columns = [
    {
      title: "Campaign",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <Flex vertical>
          <Flex vertical onClick={() => handleRowClick(record)}>
            <Text strong>{text}</Text>
            <Paragraph style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', margin: 0, color: '#475467' }} type="secondary">{record.description}</Paragraph>
            {record.link && (
              <>
                <br />
                <Button type="link" href={`https://${record.link}`} target="_blank">
                  {record.link}
                </Button>
              </>
            )}
            {record.imageUrl && record.type == 'image' && (
              <Image width={60} style={{ borderRadius: 10, height: 60, objectFit: 'cover', marginTop: 7 }} src={`${process.env.REACT_APP_API_BASE_URL}/${record.imageUrl}`} preview={false} />
            )}
            {record.imageUrl && record.type == 'video' && (
              <div className="campaign-video" style={{ marginTop: 7 }}>
                <ReactPlayer width={60} height={60} url={`${process.env.REACT_APP_API_BASE_URL}/${record.imageUrl}`} controls={false} />
              </div>
            )}
          </Flex>
          {console.log(record, 'record')}
          {isMobile &&
            <Collapse
              expandIcon={({ isActive }) => (
                <Image width={24} src={ArrowDown} preview={false} style={{ transform: isActive?'rotate(180deg)':'rotate(0deg)' }} />
              )}
              expandIconPosition="right"
              className="collapse-campaigns"
              ghost
            >
              <Panel
                header={
                  <div style={{ 
                    padding: '12px 0px',
                  }}>
                    <Text>
                      <span style={{ fontWeight: 500, color: '#101828' }}>Sent </span>
                      {moment(record.dateSent).format('MM/DD/YYYY')}
                    </Text>
                  </div>
                }
                key="1"
              >
                <Row gutter={[24, 24]}>
                  <Col span={12}>
                    <Paragraph style={{ fontWeight: 500, color: '#101828', marginBottom: 0 }}>Fans</Paragraph>
                    <Paragraph style={{ marginBottom: 0 }}>{record.campaignUsers.length}</Paragraph>
                  </Col>
                   <Col>
                    <Paragraph style={{ fontWeight: 500, color: '#101828', marginBottom: 0 }}>Media Clicks %</Paragraph>
                    <Paragraph style={{ marginBottom: 0 }}>
                      {(() => {
                        let totalClick = "--";
                        if (record?.campaignUsers?.length > 0 && record.totalClick > 0) {
                          const TotalUsers = record.campaignUsers.length;
                          totalClick = ((record.totalClick / TotalUsers) * 100).toFixed(2) + "%";
                        }
                        return totalClick;
                      })()}
                    </Paragraph>
                  </Col>
                  <Col span={12}>
                    <Paragraph style={{ fontWeight: 500, color: '#101828', marginBottom: 0 }}>Media View %</Paragraph>
                    <Paragraph style={{ marginBottom: 0 }}>
                      {(() => {
                        let totalClick = "--";
                        if (record?.campaignUsers?.length > 0 && record.totalClick > 0) {
                          const TotalUsers = record.campaignUsers.length;
                          totalClick = ((record.totalClick / TotalUsers) * 100).toFixed(2) + "%";
                        }
                        return totalClick;
                      })()}
                    </Paragraph>
                  </Col>
                   <Col>
                    <Paragraph style={{ fontWeight: 500, color: '#101828', marginBottom: 0 }}>Respones</Paragraph>
                    <Paragraph style={{ marginBottom: 0 }}>
                       {(() => {
                        let totalResponse = "--";
                        if (record?.campaignUsers?.length > 0 && record.totalResponse > 0) {
                          const TotalUsers = record.campaignUsers.length;
                          totalResponse = ((record.totalResponse / TotalUsers) * 100).toFixed(2) + "%";
                        }
                        return totalResponse;
                      })()}
                    </Paragraph>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          }
        </Flex>
      ),
    },
    {
      title: "Fans",
      dataIndex: "campaignUsers",
      key: "campaignUsers",
      responsive: ['md'],
      render: (text) => <>{text ? text.length : 0}</>,
    },
    {
      title: "Media Clicks %",
      dataIndex: "totalClick",
      key: "totalClick",
      responsive: ['md'],
      render: (text, record) => {
        let totalClick = "--";
        if (record?.campaignUsers?.length > 0 && text > 0) {
          const TotalUsers = record.campaignUsers.length;
          totalClick = ((text / TotalUsers) * 100).toFixed(2) + "%";
        }
        return <>{totalClick}</>;
      },
    },
    {
      title: "Response %",
      dataIndex: "totalResponse",
      key: "totalResponse",
      responsive: ['md'],
      render: (text, record) => {
        let totalClick = "--";
        if (record?.campaignUsers?.length > 0 && text > 0) {
          const TotalUsers = record.campaignUsers.length;
          totalClick = ((text / TotalUsers) * 100).toFixed(2) + "%";
        }
        return <>{totalClick}</>;
      },
    },
    {
      title: "Revenue",
      dataIndex: "totalRevenue",
      key: "totalRevenue",
      responsive: ['md'],
      render: (text) => {
        let revenu = "--";
        if (text > 0) {
          revenu = formatPrice(text);
        }
        return <>{revenu}</>;
      },
    },
    {
      title: "Date Sent",
      dataIndex: "dateSent",
      key: "dateSent",
      responsive: ['md'],
      render: (text) => moment(text).format('MM/DD/YYYY'),
    },
  ];

  const handleRowClick = (record) => {
    setEditingCampaign(record);
    setIsEditing(true);
    setCampaignUserInfo(record);
  };

  const handleCampaignClose = () => {
    setIsEditing(false);
    setEditingCampaign(null);
  }

  const handleCampaignSider = () => {
    setIsSidebarOpen(true);
  }

  if (isEditing) {
    return (
      <Flex vertical={isMobile?true:false} horizontal>
        {!isSidebarOpen &&
          <Content style={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'auto', scrollbarWidth: 'thin', scrollbarColor: 'unset' }}>
              <Flex vertical={isMobile?true:false} align={isMobile?"flex-start":"center"} gap={10} style={{ padding: isMobile?'20px 16px':20 }}>
                <Button type="text" onClick={handleCampaignClose} icon={<ArrowLeftOutlined />} style={{ padding: 0, color: '#475467', fontWeight: 500 }}>
                  {isMobile &&
                    'Back'
                  }
                </Button>
                <Flex justify="space-between" style={{ width: '100%' }}>
                  <Title style={{ margin: 0 }} level={4}>{editingCampaign.title}</Title>
                  {isMobile &&
                    <Button type="text" onClick={handleCampaignSider} icon={<Image src={ArrowRight} preview={false} />} iconPosition="end" style={{ padding: 0, color: '#475467', fontWeight: 500 }}>Details</Button>
                  }
                </Flex>
              </Flex>
              <Flex vertical align="end" style={{ padding: '0px 15px' }}>
                {editingCampaign.imageUrl && editingCampaign.type == 'image' &&
                  <Image width={160} style={{ borderRadius: 10, objectFit: 'cover' }} src={`${process.env.REACT_APP_API_BASE_URL}/${editingCampaign.imageUrl}`} preview={false} />
                }
                {editingCampaign.imageUrl && editingCampaign.type == 'video' &&
                  <div className="campaign-video">
                    <ReactPlayer width={240} height="auto" url={`${process.env.REACT_APP_API_BASE_URL}/${editingCampaign.imageUrl}`} controls />
                  </div>
                }
                {editingCampaign.description &&
                  <Paragraph className="chat-bubble chat_me campaigns-bubble" style={{ marginTop: 10, fontSize: '14px' }} type="secondary">{editingCampaign.description}</Paragraph>
                }
              </Flex>
              <CampaignResponse editingCampaign={editingCampaign}/>
          </Content>
        }
        {(isSidebarOpen || !isMobile) && (
          <SidebarCampaigns setIsSidebarOpen={setIsSidebarOpen} selectedCampaignUserInfo={selectedCampaignUserInfo} />
        )}
      </Flex>
    );
  }

  return (
    <Layout style={{ backgroundColor: '#ffffff', height: isMobile?'calc(100vh - 70px)':'100vh', overflow: isMobile?'hidden':'' }}>
      <Spin spinning={isLoading}>
        <Flex vertical style={{ padding: isMobile?'20px 16px 0px 16px':'20px 20px 0px 20px', borderBottom: '1px solid #D0D5DD' }}>
          <Flex vertical={isMobile?true:false} horizontal justify="space-between" align={isMobile?"flex-start":"center"} style={{ marginBottom: 20 }}>
            <Flex justify={isMobile?"space-between":""} align={isMobile?'center':''} style={{ marginBottom: isMobile?15:'', width:isMobile?'100%':'' }}>
              <Title style={{ marginTop: 0, marginBottom: 0 }} level={2}>Campaigns</Title>
              {isMobile&&
                <Button type="primary" size="large" icon={<PlusOutlined />} onClick={handleSendMessage} style={{ fontWeight: 600 }}>
                  New
                </Button>
              }
            </Flex>
            <Space style={{ alignItems: 'center', width: isMobile?'100%':'', display: isMobile?'block':'' }}>
              <Input
                placeholder="Search campaigns"
                onChange={e => setSearch(e.target.value)}
                prefix={<SearchOutlined />}
                className="search-scheduled"
                style={{ marginBottom: 0 }}
                allowClear={true}
              />
              {!isMobile&&
                <Button type="primary" size="large" icon={<PlusOutlined />} onClick={handleSendMessage} style={{ fontWeight: 600 }}>
                  New
                </Button>
              }
            </Space>
          </Flex>
        </Flex>
        <Content>
          <Table
            columns={columns}
            dataSource={campaignsList}
            pagination={false}
            scroll={{ y: 100 }}
            onScroll={onScroll}
            className="campaigns-table"
            onRow={(record) => ({
              onClick: () => !isMobile && handleRowClick(record),
              style: { cursor: !isMobile ? 'pointer' : 'default' }
            })}
          />

          {openSendMessage && (
            <NewMessage isCampaignMessage={isCampaignMessage} visible={openSendMessage} handleCancel={handleCloseMessage} onClose={onClose} open={open} setSelectedDateTime={setSelectedDateTime} />
          )}

        </Content>
      </Spin>
    </Layout>
  );
};

export default Campaigns;
