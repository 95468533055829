import React, { useState,useEffect,useContext } from 'react';
import { Card, Button, Progress, Flex, Typography } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import useScreenSize from '../../../utils/useScreenSize';

const { Title, Text } = Typography;

const Locations = ({data}) => {
  const [position, setPosition] = useState('end');
  const [locationData, setLocationData] = useState([]);
  const navigate = useNavigate();
  const {user}  = useContext(UserContext);
  const { isMobile } = useScreenSize();

  const navigateToReport = () => {
    const redirectLink=user?.role==="Team"?'/team/locations-report':'/locations-report';
    navigate(redirectLink);
  };

  return (
    <Card className="insight-card">
      <Flex className="top-content" vertical style={ isMobile ? { padding: 20 } : { padding: 30 }}>
        <Title level={4} style={{ marginTop: 0, marginBottom: '30px', fontSize: '18px' }}>Locations</Title>

        {data?.locationDistribution?.map((location, index) => (
          <div key={index} style={{ marginBottom: '15px', width: '100%' }}>
            <Text>{location.city}</Text>
            <Progress className="progress-bar" percent={location.percentage} />
          </div>
        ))}

      </Flex>
      <Flex vertical align="end" style={ isMobile ? { padding: '10px 20px', borderTop: '1px solid #EAECF0' } : { padding: '15px 30px', borderTop: '1px solid #EAECF0' }}>
        <Button type="text" icon={<RightOutlined />} iconPosition={position} style={{ color: '#6941C6', fontWeight: 600, padding: 0, background: 'transparent' }} onClick={navigateToReport}>View report</Button>
      </Flex>
    </Card>
  );
}

export default Locations;
