import React, { useState, useEffect, useContext } from 'react';
import { Layout, Timeline, Avatar, Typography, Button, Card, Image, Divider, Flex, Input, Select, Modal, Spin, message, Tabs,Tag, Form } from 'antd';
import { CloseOutlined, SearchOutlined, ArrowLeftOutlined, CheckCircleFilled ,CloseCircleOutlined} from '@ant-design/icons';
import AvatarImage from '../../components/AvatarImage';
import ChatInputNewMessage from '../../components/GlobalComponents/ChatInputNewMessage';
import useScreenSize from '../../utils/useScreenSize';
import { formatPrice, cleanPhoneNumber, openNotification } from '../../utils/utils';
import socket from '../../services/socket';
import { post,get } from '../../utils/api';
import { UserContext } from '../../contexts/UserContext';
import PrivateRequestFillter from '../../components/PrivateRequest';
import SelectMessage from '../../components/Communities/SelectMessage';
import CountdownTimerSelected from '../../components/PrivateRequest/CountdownTimerSelected';
import { useParams,useNavigate } from 'react-router-dom';
import './privaterequests.css';

import ClockIcon from "../../images/clock.svg";
import MessageIcon from "../../images/message-icon.svg";
import CrossIcon from "../../images/x-circle.svg";
import ExpressIcon from "../../images/express-icon.svg";
import ClockOrgIcon from "../../images/clock-org.svg";
import CheckIcon from "../../images/check-circle.svg";

import moment from 'moment';

const { Sider, Content } = Layout;
const { Text, Title, Paragraph } = Typography;

const { TextArea } = Input;

const PrivateRequests = () => {
  const { isMobile, isTablet } = useScreenSize();
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [requestsData, setRequestsData] = useState([]);
  const [dataCounts, setdataCounts] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);
  
  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();
  
   const { requestId } = useParams();
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [chatImages, setChatImages] = useState([]);
  const [message, setMessage] = useState('');
  const [inlinePrice, setInlinePrice] = useState(false);
  const [inlineButton, setInlineButton] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [isReLoad, setIsReLoad] = useState(false);
  const [basePrice, setBasePrice] = useState(0);

  const [currentTime, setCurrentTime] = useState(new Date());
  
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false);
  const [reportreasons, setReportreasons]=useState(null);
  const [declinereasons, setDeclinereasons]=useState(null);
  const [selectedElaborate, setSelectedElaborate] = useState('');
  const [charactersLeft, setCharactersLeft] = useState(250);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userInfo,setUserInfo]= useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [tabName, setTabName] = useState('open');
  const navigate = useNavigate();
  const [selectedResponse, setSelectedResponse] = useState([]);
  const maxLength = 250;

  const [audioEnable] = useState(false);

  const [buttonSubmitOption] = useState(true);

  const handleInputChange = (value) => {
    setMessage(value);
  };

  useEffect(() => {
      const timer = setInterval(() => {
        setCurrentTime(new Date());
      }, 60000);
      
      return () => clearInterval(timer);
  }, []);

  const getTimeLeft = (expireTime) => {
    const expiryDate = new Date(expireTime);
    const difference = expiryDate - currentTime;
    
    if (difference <= 0) {
      const formattedDate = expiryDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
      return { 
        timeString: 'Expired', 
        isExpired: true,
        days: 0
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    if (days === 0) {
      return { 
        timeString: `Expiring in ${hours}h ${minutes}m`, 
        isExpired: false,
        days: 0
      };
    }
    return { 
      timeString: `${days}d ${hours}h ${minutes}m`, 
      isExpired: false,
      days
    };
  };

  const getRealPath = async (file ) => {
    try {
      const formData = new FormData();
      formData.append('singleImage', file);      
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/single-upload`, {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        return false;
      }
      const data = await response.json();
     return data;
    } catch (error) {
      console.error('Upload failed:', error);
     return false;
    }
  };

  const sendMessageHandler = async (values) => {
    if (isMessageSending) return;
    
    setIsMessageSending(true);
    try {
      let mediaPath=null;
      if(chatImages.length>0){
        const FileUrl=chatImages[0] || null;
        const fileType=chatImages[0]?.type || null;
        const source=chatImages[0]?.source || null;
        
        if(fileType=="gif"){
            mediaPath=FileUrl?.file;
        }else if(source=="vault"){
          mediaPath=FileUrl?.thumbUrl;
        }else{
          const urlId=await getRealPath(FileUrl?.file);
          if(urlId && urlId?.image){
            mediaPath=urlId?.image?.filepath;
          } 
        }
      }

      const formData = {
        message: message,
        images: mediaPath,
        requestId: selectedRequest
      };

     
       const response = await post(`creator/private/completePrivateRequest`, formData);
         if(response?.status){
          openNotification(`Request has been submited successfully.`);
          setSelectedRequest(false);
          await init();
          setIsDeclineModalVisible(false);
          setDeclinereasons('');
          setSelectedElaborate('');

         }else{
          message.error(response?.message);
         }
    } catch (error) {
      console.error('Error sending message:', error);
      message.error('Failed to send message');
    } finally {
      setIsMessageSending(false);
    }
  };

  const handleClose = () => {
    setSelectedRequest(false);
  };

  const handleNewRequest = async (message) => {
    // console.log("output", message);
    if (message && message.status) {
      const { output } = message;
      if (cleanPhoneNumber(output?.creator_phone) === cleanPhoneNumber(user?.creator_phone)) {
        // Handle new request
      }
    }
  };

  useEffect(()=>{
    const callRsponse=async()=>{
      if(selectedRequest?.id){
        const response = await get(`creator/private/PrivateRequestResponse/${btoa(selectedRequest?.id)}`);
        if(response?.status){
          setSelectedResponse(response?.data);
        }
      }
    }
    callRsponse();
    
  },[selectedRequest]);

  const init = async (fillter = false) => {
    setIsLoading(true);
    try {
      const fromdata = {
        status: fillter || "open",
        requestId:requestId || null
      }
      const response = await post('creator/private/privateRequestList', fromdata);
      if (response.status) {
        const { rows, count } = response?.data;
        setRequestsData(rows);
        if(requestId && rows?.length>0){
          const getLast=rows[0];
          if(getLast?.status==1){
            setTabName('done');
          }
        }
        if(!fillter || fillter==="open"){
          setdataCounts(count);
          const total = rows.reduce((sum, item) => sum + parseFloat(item.price || 0), 0);
          setTotalPrice(total);
        }
       
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    init();
    socket.on('privateRequest', handleNewRequest);

    return () => {
      socket.off('privateRequest', handleNewRequest);
    };
  }, []);

  useEffect(()=>{
    
    if(isReLoad){
      init();
    }
  },[isReLoad]);

  const requestUpdate = async (request) => {
    setIsLoading(true);
    try {
      const response = await post('creator/private/creator-decline-Request', request);
      if (response.status) {
        openNotification(`Request has been declined successfully.`);
        setSelectedRequest(false);
        await init();
        setIsDeclineModalVisible(false);
        setDeclinereasons('');
        setSelectedElaborate('');
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  const baseStyles = {
    backgroundColor: '#ffffff',
    borderRight: isMobile ? '' : '1px solid #EAECF0',
    height: isMobile ? 'calc(100vh - 70px)' : '100vh',
    display: 'flex',
    flexDirection: 'column',
  };
  
  const conditionalStyles = isMobile ? {} : { height: '100vh' };
  const combinedStyles = { ...baseStyles, ...conditionalStyles };

  const items = [
    {
      key: 'open',
      label: 'Open',
      children: <PrivateRequestFillter tabName="Open" setIsReLoad={setIsReLoad} setSelectedRequest={setSelectedRequest} requestsData={requestsData} isMobile={isMobile} selectedRequest={selectedRequest} />,
    },
    {
      key: 'done',
      label: 'Done',
      children: <PrivateRequestFillter tabName="done" setIsReLoad={setIsReLoad} setSelectedRequest={setSelectedRequest} requestsData={requestsData} isMobile={isMobile} selectedRequest={selectedRequest} />,
    },
    {
      key: 'expired',
      label: 'Expired',
      children: <PrivateRequestFillter tabName="expired" setIsReLoad={setIsReLoad} setSelectedRequest={setSelectedRequest} requestsData={requestsData} isMobile={isMobile} selectedRequest={selectedRequest} />,
    },
  ];

  const onChange = async (key) => {
    setSelectedRequest(null);
    navigate('/private-requests', { replace: true });
    await init(key);
    setTabName(key);
   
  };

  const showReportModal = () => { 
    setIsReportModalVisible(true);
  }
  const handleReportCancel = () => { 
    setIsReportModalVisible(false);
    setReportreasons(undefined);
    modalForm.resetFields();
  }

  const showDeclineModal = () => {
    setIsDeclineModalVisible(true);
  }
  const handleDeclineCancel = () => { 
    setIsDeclineModalVisible(false);
    setDeclinereasons(undefined);
    modalForm.resetFields();
  }
  const handleDeclineConfirm = async () => {
    if(!declinereasons || !selectedElaborate){
      return;
    }
    await requestUpdate({
      declinereasons,
      selectedElaborate,
      selectedRequest
    });

  };

  const handleReportConfirm = async () => {
   await notificationHandler();
   
  };
  const notificationHandler=async()=>{
    console.log('test')
  }

  const handleElaborateChange = (e) => {
    setSelectedElaborate(e.target.value);
    const currentLength = e.target.value.length;
    setCharactersLeft(maxLength - currentLength);
  };

  const { timeString, isExpired, days } = getTimeLeft(selectedRequest?.expire_time); 


  const handleSelectMessage = async(selectedRequest) => {
   
    setSelectedMessage({...selectedRequest,phone_number:selectedRequest?.user_phone});
    setIsModalVisible(true);
    setIsHistoryLoading(true);
    try{
      const getUserInfo = await post(`creator/getUserInfo`, {
        phone: selectedRequest?.user_phone
      });
      if (getUserInfo && getUserInfo.status) {
        setUserInfo(getUserInfo.data);
      } else {
        console.error('Information not available!');
      }

        const response = await post(`creator/chat`,{
          phn:selectedRequest?.user_phone
        });
        if(response && response.status){
          setChatHistory(response?.data.reverse());
        }
    }catch(error){
      console.log("loading......");
    } finally {
      setIsHistoryLoading(false);
    }
  }

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedMessage(null);
    setIsHistoryLoading(false);
  };

  

  return (
    <Layout style={{ minHeight: isMobile ? 'calc(100vh - 70px)' : '100vh', flexDirection: isMobile ? 'column' : 'row' }}>
      <Spin spinning={isLoading}>
        <Sider width={isMobile ? '100%' : isTablet ? 330 : 360} style={combinedStyles}>
          <Flex vertical style={{ padding: '20px' }}>
            <div className="head-area" style={{ marginBottom: 5 }}>
              <Title level={2}>Private Requests</Title>
              <Text className="count" strong>{dataCounts}</Text>
            </div>
            <Text style={{ fontSize: 16 }}>You have {dataCounts} open request for ${totalPrice} to submit.</Text>
          </Flex>
          <Tabs className="message-tabs request-tabs" style={{ height: 'calc(100% - 144px)', flex: 1 }}  defaultActiveKey="open" activeKey={tabName} items={items} onChange={onChange} />
        </Sider>
      </Spin>

      {selectedRequest ? (
        <Content style={isMobile ? { backgroundColor: '#F2F4F7', position: 'fixed', width: '100%', top: 0, left: 0, zIndex: 999, height: '100%' } : { backgroundColor: '#F2F4F7', height: '100vh' }}>
          <Card className="request-card" style={{ borderRadius: 0, border: 0 }}>
            <Flex vertical={isMobile ? true : false} className="head" justify="space-between">
              {isMobile && 
                <Button
                  type="text"
                  icon={<ArrowLeftOutlined style={{ color: '#101828' }}/>}
                  onClick={handleClose}
                  style={{ padding: 0, fontWeight: 600, marginBottom: isMobile ? 5 : 10, background: 'transparent' }}
                >
                Back
                </Button>
              }
              <Flex horizontal wrap gap={8} align="center" style={{ marginBottom: isMobile?20:'' }}>
                <Title level={4}>Request Details</Title>
                {selectedRequest?.request_type == 'express' &&
                    <Text style={{ background: '#FCFAFF', border: '1px solid #D0D5DD', padding: '1px 6px', borderRadius: '6px', fontSize: '14px', color: '#6941C6', fontWeight: 500, display: 'flex', alignItems: 'center', gap: 4 }}><Image src={ExpressIcon} preview={false} style={{ display: 'block' }} />24hr Express</Text>
                  }
              </Flex>
              <Button
                type="link"
                // onClick={handleClose}
                onClick={showReportModal}
                style={{ alignSelf: isMobile ? 'flex-start' : 'flex-end', color: '#B42318', fontWeight: 600, padding: isMobile?'0':'', height: isMobile?'auto':'' }}>Report</Button>
            </Flex>

            <Flex vertical style={{ padding: isMobile?'16px 12px':'25px 20px', flex: 1, overflowY: 'auto', scrollbarWidth: 'thin', scrollbarolCor: 'unset' }}>

              <Flex align="center" justify="space-between" style={{ backgroundColor: '#ffffff', padding: '18px 18px', borderRadius: 10, marginBottom: isMobile?20:25 }}>
                  <Title level={4} style={{ display: 'flex', gap: 6, fontSize: 14, color: isExpired ? '#D92D20': days === 0 ? '#F79009' : '#475467' }}>
                  
                  {/*{selectedRequest?.status==0 ?<CountdownTimerSelected time={selectedRequest?.expire_time} id={selectedRequest?.id} />:<Tag className={tabName}>{tabName}</Tag>}*/}
                  {selectedRequest?.status != 1 ? (<>
                    {!isExpired ? (
                      <>
                        <Image src={days === 0 ? ClockOrgIcon : ClockIcon} preview={false} />
                        {timeString}
                      </>
                    ) : (
                      <>
                        <Image src={CrossIcon} preview={false} />
                        {timeString}
                      </>
                    )}
                  </>) : (
                      <Text style={{ fontSize: 16, display: 'flex', alignItems: 'center', gap: 10 }}><Image src={CheckIcon} preview={false} /> Complete</Text>
                    ) 
                  }
                </Title>
                <Title level={4} style={{ color: '#475467', fontSize: 18 }}>{formatPrice(selectedRequest?.price || 0)}</Title>
              </Flex>

              <Flex vertical style={{ backgroundColor: '#ffffff', padding: '18px 17px', borderRadius: 10 }}>
                <Flex horizontal="true" justify="space-between">
                 {/* <Flex vertical>
                    <Title level={3} style={{ marginBottom: 8, marginTop: 0, display: 'flex', alignItems: 'center' }}>
                      <Text style={{ backgroundColor: '#FCFAFF', color: '#6941C6', borderRadius: 8, padding: '5px 10px', fontWeight: 600, border: '1px solid #E9D7FE', textTransform: 'capitalize', marginLeft: 10 }}>2-5 Days</Text>
                    </Title>
                  </Flex>*/}
                 {/* <Flex vertical>
                    <Text style={{ fontSize: '16px', color: '#344054' }}>{selectedRequest?.date}</Text>
                  </Flex>*/}
                </Flex>
                {/*<Divider />*/}
                <Title level={4} style={{ fontSize: '16px', color: '#101828', marginBottom: 20 }}>To: {selectedRequest?.to_name}</Title>
                <Title level={4} style={{ fontSize: '16px', color: '#475467', marginBottom: 10 }}>Instructions:</Title>
                <Paragraph style={{ color: '#475467', fontSize: 16, marginBottom: 10 }}>{selectedRequest?.message}</Paragraph>
                <Flex style={{ marginTop: 0 }}>
                  <Text style={{ background: '#ffffff', border: '1px solid #D0D5DD', padding: '2px 8px', borderRadius: '6px', fontSize: '14px', color: '#344054', fontWeight: 500 }}>{selectedRequest?.title}</Text>
                </Flex>
                <Divider />

                <Title level={4} style={{ fontSize: '16px', color: '#475467', marginBottom: 10 }}>
                {selectedRequest?.status == 0 ? (<>
                    From:
                  </>) : (<>
                    Booked By:
                  </>)}
                </Title>

                <Flex justify="space-between">
                  <Flex gap={10} align="center">
                    <AvatarImage title={selectedRequest?.from_name} size={isMobile?40:48} font={isMobile?16:18}/>
                    <Title level={4} style={{ fontSize: '16px', color: '#475467', marginBottom: 0 }}>{selectedRequest?.from_name}</Title>
                  </Flex>
                  <Button size="large" onClick={() => handleSelectMessage(selectedRequest)} icon={<Image src={MessageIcon} preview={false} />}></Button>
                </Flex>

              </Flex>

              {selectedRequest?.status!==0 && 
                <Flex align="center" justify="space-between" style={{ backgroundColor: '#ffffff', padding: '18px 17px 8px 17px', borderRadius: 10, marginBottom: isMobile?20:25, marginTop:isMobile?20:25 }}>
                  <Flex vertical>
                    <Title level={4} style={{ fontSize: '16px', color: '#101828', marginBottom: 20 }}>History</Title>
                    <Timeline className="timeline-private-request" style={{ marginTop:5 }}
                      items={[
                        {
                          dot: <Image src={CheckIcon} preview={false} />,
                          children: (
                            <div>
                              <div style={{ fontWeight: 600, fontSize: 16, color: '#101828' }}>Request submitted</div>
                              <div style={{ fontSize: 16, color: '#101828' }}>
                                {moment(selectedRequest?.createdAt).format('hh:mm A, MMM D, YYYY')}
                              </div>
                            </div>
                          ),
                        },
                        ...(selectedRequest?.status === 2 ? [{
                          dot: <Image width={24} src={CrossIcon} preview={false} />,
                          children: (
                            <div>
                              <div style={{ fontWeight: 600, fontSize: 16, color: '#101828' }}>Request Expired</div>
                              <div style={{ fontSize: 16, color: '#101828' }}>
                                {moment(selectedRequest?.updatedAt).format('hh:mm A, MMM D, YYYY')}
                              </div>
                            </div>
                          )
                        }] : []),
                        ...(selectedRequest?.status === 1 ? [{
                          dot: <Image src={CheckIcon} preview={false} />,
                          children: (
                            <div>
                              <div style={{ fontWeight: 600, fontSize: 16, color: '#101828' }}>Request Done</div>
                              <div style={{ fontSize: 16, color: '#101828' }}>
                                {moment(selectedRequest?.updatedAt).format('hh:mm A, MMM D, YYYY')}
                              </div>
                            </div>
                          )
                        }] : [])
                      ]}
                    />
                  </Flex>
                </Flex>
              }
              {selectedRequest?.status === 1 &&  selectedResponse?.length>0 &&
                <Flex vertical style={{ backgroundColor: '#ffffff', padding: '18px 17px 18px 17px', borderRadius: 10, marginBottom: 0 }}>
                  <Flex vertical block>
                    <Title level={4} style={{ fontSize: '16px', color: '#101828', marginBottom: 20 }}>Your response</Title>
                     <Flex vertical align="start" gap={10}>
                      {selectedResponse.map((rsp, index) => {
                          if(rsp?.type === "images") {
                            const { message } = rsp || {};
                            const isImage = /\.(jpg|jpeg|png|gif|webp)$/i.test(message);
                            const isVideo = /\.(mp4|webm|ogg)$/i.test(message);
                            const isGiphy = message?.match('https://media[1-3].giphy.com/media/');
                            const mediaUrl = isGiphy ? message : `${process.env.REACT_APP_API_BASE_URL}/${message}`;

                            if (isImage) {
                              return (
                                <Flex className="message-response">
                                  <Image
                                    key={index} 
                                    src={mediaUrl}
                                    preview={false}
                                    className="chat-image"
                                  />
                                </Flex>
                              );
                            }

                            if (isVideo) {
                              return (
                                <Flex className="message-response">
                                  <video 
                                    key={index}
                                    controls
                                    src={mediaUrl} 
                                    className="chat-video"
                                  />
                                </Flex>
                              );
                            }
                          }
                          if(rsp?.type === "text"){
                            return (<Text className="chat-bubble private-response"> 
                              <div dangerouslySetInnerHTML={{ __html: rsp?.message?.replace(/<p><br><\/p>/g, '') }} style={{ whiteSpace: 'pre-wrap' }} />
                            </Text>);
                          }
                          
                          return null;
                          })}
                      </Flex>
                    </Flex>
                </Flex>}
            </Flex>

           

            {selectedRequest?.status!==1 && <Flex vertical style={{ padding: isMobile ? '8px 10px 5px 10px' : '8px 20px 8px 20px', borderTop: '1px solid #EAECF0', backgroundColor: '#ffffff', marginTop: 'auto' }}>
              <Spin spinning={isMessageSending}>
                <Form form={form} onFinish={sendMessageHandler} className={`${selectedRequest?.status === 0 ? '' : 'decline-hide' } messagebox-form private-request-area`}>
                  <Form.Item
                    name="messagebox"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        validator: (_, value) =>
                          chatImages.length < 1 && !message
                            ? Promise.reject(new Error('Please input your message!'))
                            : Promise.resolve(),
                      },
                    ]}
                    className="custom-form-item"
                  >
                    <ChatInputNewMessage
                      sendMessageHandler={sendMessageHandler}
                      setChatImages={setChatImages}
                      setSelectedPrice={setSelectedPrice}
                      onInputChange={handleInputChange}
                      setPriceButton={setInlinePrice}
                      setSendButton={setInlineButton}
                      setLoading={isMessageSending}
                      basePrice={basePrice}
                      audioEnable={audioEnable}
                      buttonSubmitOption={buttonSubmitOption}
                    />
                    {selectedRequest?.status===0 && 
                      <Flex className="private-request-buttons" justify={isMobile?"start":"end"} horizotanl style={{ marginTop: 10, paddingRight: isMobile?'52%':155 }}>
                        <Button onClick={showDeclineModal} type="default" size="large" style={{ marginRight: isMobile?0:'10px', fontWeight: 600 }}>Decline Request</Button>
                      </Flex>
                    }
                  </Form.Item>
                </Form>
              </Spin>
            </Flex>}

            {/*{selectedRequest?.status === 0 &&
              <Flex justify="flex-end" style={{ marginTop: 'auto', backgroundColor: '#FFFFFF', borderTop: '1px solid #EAECF0', padding: '20px 20px' }}>
                <Button onClick={() => requestUpdate('Approve')} type="primary" size="large" style={{ backgroundColor: '#17B26A', borderColor: '#17B26A', fontWeight: 600 }}>Approve</Button>
              </Flex>
            }*/}
          </Card>
        </Content>
      ) : null}
    

      {/* Decline Modal */}
      <Modal
        visible={isDeclineModalVisible}
        onCancel={handleDeclineCancel}
        onOk={handleDeclineConfirm}
        className="user-common-dialog"
        footer={[
          <Button key="cancel" size="large" onClick={handleDeclineCancel} style={{ fontWeight: 600 }}>Cancel</Button>,
          <Button key="report" size="large" 
          type="primary"
          danger
          onClick={handleDeclineConfirm} 
          style={{ fontWeight: 600 }}
          disabled={(declinereasons && selectedElaborate) ? false : true}
          >Decline Request</Button>
        ]}
        width={400}
      >
        <Form form={modalForm}>
          <Title level={4} style={{ fontSize: '18px', marginTop: 5, marginBottom: 10 }}>Reason for declining</Title>
          <Paragraph style={{ marginBottom: 10 }}>Please select one:</Paragraph>
          <Form.Item 
            name="reason"
            label="Reason"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-item"
          >
            <Select 
              onChange={(value) => {
                setDeclinereasons(value);
              }} 
            placeholder="Select one" style={{ width: '100%' }}>
              <Select.Option value="spam">Spam</Select.Option>
              <Select.Option value="abusive">Don’t like the request</Select.Option>
              <Select.Option value="harassment">Harassment</Select.Option>
              <Select.Option value="hate_speech">Hate Speech</Select.Option>
              <Select.Option value="explicit_content">Explicit Content</Select.Option>
              <Select.Option value="violence">Violence</Select.Option>
              <Select.Option value="discrimination">Discrimination</Select.Option>
              <Select.Option value="fake_information">Fake Information</Select.Option>
              <Select.Option value="phishing">Phishing</Select.Option>
              <Select.Option value="malware">Malware/Links to malicious content</Select.Option>
              <Select.Option value="scam">Scam</Select.Option>
              <Select.Option value="inappropriate_name">Inappropriate Username</Select.Option>
              <Select.Option value="underage">Underage User</Select.Option>
              <Select.Option value="inappropriate_profile_picture">Inappropriate Profile Picture</Select.Option>
              <Select.Option value="self_harm">Self-Harm/Threatening Behavior</Select.Option>
              <Select.Option value="impersonation">Impersonation</Select.Option>
              <Select.Option value="illegal_activities">Others</Select.Option>
            </Select>
          </Form.Item>
          <p>We will let the fan know why you’ve declined. You can choose to customize the message below.</p>

          {declinereasons &&
            <Form.Item 
              className="custom-form-input" 
              label="Include a message"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ marginBottom: 20 }}>
              <TextArea
                maxLength={maxLength}
                onChange={handleElaborateChange}
                placeholder="Please elaborate..."
                style={{
                  height: 128,
                  resize: 'none',
                  marginBottom: 0
                }}
              />
              <Text style={{ marginTop: 4, display: 'block' }}>{charactersLeft} characters left</Text>
            </Form.Item>
          }
        </Form>

      </Modal>

      {/* Report Modal */}
      <Modal
        visible={isReportModalVisible}
        onCancel={handleReportCancel}
        onOk={handleReportConfirm}
        className="user-common-dialog"
        footer={[
          <Button key="cancel" size="large" onClick={handleReportCancel} style={{ fontWeight: 600 }}>Cancel</Button>,
          <Button key="report" size="large" 
          type="primary"
          danger
          onClick={handleReportConfirm} 
          style={{ fontWeight: 600 }}
          >Report</Button>
        ]}
        width={400}
      >
        <Form form={modalForm}>
          <Title level={4} style={{ fontSize: '18px', marginTop: 5, marginBottom: 10 }}>Report</Title>
          <Paragraph style={{ marginBottom: 10 }}>The contents of this conversation will be flagged to our Trust and Safety team. Please select a reason.</Paragraph>
          <Form.Item 
            name="reason"
            label="Reason"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            className="custom-form-item"
          >
            <Select 
              onChange={(value) => {
                setReportreasons(value);
              }} 
            placeholder="Select one" style={{ width: '100%' }}>
              <Select.Option value="spam">Spam</Select.Option>
              <Select.Option value="abusive">Abusive Language</Select.Option>
              <Select.Option value="harassment">Harassment</Select.Option>
              <Select.Option value="hate_speech">Hate Speech</Select.Option>
              <Select.Option value="explicit_content">Explicit Content</Select.Option>
              <Select.Option value="violence">Violence</Select.Option>
              <Select.Option value="discrimination">Discrimination</Select.Option>
              <Select.Option value="fake_information">Fake Information</Select.Option>
              <Select.Option value="phishing">Phishing</Select.Option>
              <Select.Option value="malware">Malware/Links to malicious content</Select.Option>
              <Select.Option value="scam">Scam</Select.Option>
              <Select.Option value="inappropriate_name">Inappropriate Username</Select.Option>
              <Select.Option value="underage">Underage User</Select.Option>
              <Select.Option value="inappropriate_profile_picture">Inappropriate Profile Picture</Select.Option>
              <Select.Option value="self_harm">Self-Harm/Threatening Behavior</Select.Option>
              <Select.Option value="impersonation">Impersonation</Select.Option>
              <Select.Option value="illegal_activities">Illegal Activities</Select.Option>
            </Select>
          </Form.Item>
          <p>Only the Blonde team will see your response</p>

          {reportreasons &&
            <Form.Item 
              className="custom-form-input" 
              style={{ marginBottom: 20 }}>
              <TextArea
                maxLength={maxLength}
                onChange={handleElaborateChange}
                placeholder="Please elaborate..."
                style={{
                  height: 128,
                  resize: 'none',
                  marginBottom: 0
                }}
              />
              <Text style={{ marginTop: 4, display: 'block' }}>{charactersLeft} characters left</Text>
            </Form.Item>
          }
        </Form>

      </Modal>

      {selectedMessage &&
        <Modal
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
          width={800}
          className="select-message-modal"
        >
          <SelectMessage selectedMessage={selectedMessage} isHistoryLoading={isHistoryLoading} chatHistory={chatHistory} setChatHistory={setChatHistory} />
        </Modal>
      }

    </Layout>
  );
};

export default PrivateRequests;