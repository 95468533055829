import React, { useState, useEffect, useContext } from 'react';
import { Layout, Tabs, Card, Button, List, Flex, Typography, Modal, Form, Input, Avatar, Upload, Divider, Spin, Image ,message} from 'antd';
import { CloudUploadOutlined, LogoutOutlined, MailOutlined, MobileOutlined } from '@ant-design/icons';
import { UserContext } from '../../../contexts/UserContext';
import { AuthContext } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getImageUrl,getInitials,imageUrl,extractImageIds,openNotification } from '../../../utils/utils';
import { get,put } from '../../../utils/api';
import AvatarImage from '../../../components/AvatarImage';
import ProfileImg from '../../../images/avatar-image.png';
import UploadIcon from '../../../images/upload-icon.svg';

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const { Content } = Layout;

const { Dragger } = Upload;

const PWASettings = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);
  const [loading,setLoading]=useState(false);
  const [profileInfo,setProfileInfo]=useState(null);
  const [profileImage,setProfileImage]=useState(null);
  const [settinginfo, setSettingInfo] = useState(null);
  const [uploadedIds, setUploadedIds] = useState([]);
  const {user}  = useContext(UserContext);
  const [isLogout, setIsLogout] = useState(false);

  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^\d{10}$/; // Example: 1234567890 (10 digits)
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter a valid 10-digit phone number!'));
  };

  const validatePasswords = (_, value) => {
    if (!value || form.getFieldValue('newPassword') === value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('The two passwords that you entered do not match!'));
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try{
        const completeFormData = { ...values,uploadedIds};
        const response = await put(`admin/users/update/${user?.id}`, completeFormData);
        if (response && response.status === 200) {
            if(response?.token){
              localStorage.setItem('token', response?.token);
            }
            openNotification('Update successful');
            window.location.reload();
          }else{
            message.error(response.message);
          }
    }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message || 'Error submitting form');
        } else {
          message.error(error.message || 'Error submitting form');
        }
      } finally {
        setLoading(false);
      }
  };

  useEffect(()=>{
    const init=async()=>{
      try {
        setLoading(true);
        const response = await get(`user/get-profile/${user?.id}`);
        if (response && response.data) {
          setProfileInfo(response.data); 
            setUploadedIds(response.data?.uploadedIds);
            setSettingInfo(response.data?.bio);
            if(response.data && response.data?.uploadedIds){
              const pathId=response.data?.uploadedIds[0];
              console.log("filepath",pathId);
              setProfileImage(pathId);
            }
        }
      }catch(error){
        console.log('error',error);
      }finally {
        setLoading(false);
      }
  }
  init();
  },[])


  const changePasswpord=async(values)=>{
    const currentStepData = await form.validateFields();

      if(!currentStepData){
        return false;
      }
      setLoading(true);
      try {
        const response = await put(`creator/change-password/${user?.id}`,values);
        if (response && response.status === 200) {
          form.resetFields();
          openNotification(response.message);
        } else {
          message.error(response.message);
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setLoading(false);
      }
  }


  const validateNewPassword = (_, value) => {
    const currentPassword = form.getFieldValue('currentPassword');
    if (value === currentPassword) {
        return Promise.reject(new Error('New password must be different from the current password'));
    }
    return Promise.resolve();
  };

  const validateConfirmPassword = (_, value) => {
      const newPassword = form.getFieldValue('newPassword');
      if (value !== newPassword) {
          return Promise.reject(new Error('The two passwords that you entered do not match!'));
      }
      return Promise.resolve();
  };

  const handleLogout = () => {
    setIsLogout(true);
  };
  const handleCancel = () => {
    setIsLogout(false);
  };

  const logoutHandel = () => {
    localStorage.removeItem('token');
    openNotification('Logged out successfully!');
    setAuth(false);
    navigate('/');
  }

  if(!profileInfo){
    return (  <Layout className="profile-page" style={{ padding: 30 }}><Spin spinning={true}></Spin></Layout>);
  }

  return (
    <Layout style={{ padding: '20px 20px' }}>
        <Spin spinning={loading}>
      <Flex vertical align="start">
        <Title level={3} style={{ marginTop: 0, marginBottom: 20 }}>Settings</Title>
      </Flex>
      <Tabs className="billing-tabs" defaultActiveKey="1">
        <TabPane tab="Profile" key="1">

          <Form form={form} initialValues={profileInfo} onFinish={onSubmit} className="setting-form">
            <Flex vertical style={{ marginBottom: 20 }}>
              <Text level={4} style={{ fontWeight: 600 }}>Personal info</Text>
              <Text>Update your photo and personal details,  this will be public.</Text> 
            </Flex>

            <Form.Item
              name="first_name"
              label="First name"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: false, message: 'Please input your first name!' }]}
              className="custom-form-item"
            >
              <Input placeholder="Enter first name" style={{ padding: '7px 10px' }} />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Last name"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: false, message: 'Please input your first name!' }]}
              className="custom-form-item"
            >
              <Input placeholder="Enter first name" style={{ padding: '7px 10px' }} />
            </Form.Item>

            {/*<Flex className="avatar-with-upload" vertical gap={20} align="center" style={{ marginTop: 20 }}>
                  <Flex>
                    <AvatarImage imageId={profileImage} size={64} title={`${profileInfo?.first_name} ${profileInfo?.last_name}`}/>
                  </Flex>
                  <Flex className="avatar-upload-area">
                  <Form.Item name="uploadedIds"  noStyle>
                      <Input type="hidden" />
                  </Form.Item>
                    <Form.Item
                      name="photo"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      rules={[{ required: false }]}
                    >
                      <Dragger 
                       
                        multiple
                        name="singleImage"
                        action={`${process.env.REACT_APP_API_BASE_URL}/auth/single-upload`}
                        accept="image/jpeg"
                        onChange={async (info)=>{
                          console.log("info",info);
                          const { status } = info.file;
                          if (status !== 'uploading') {
                            console.log(info.file, info.fileList);
                          }
                          if (status === 'done') {
                            openNotification(`${info.file.name} file uploaded successfully.`);
                          } else if (status === 'error') {
                            message.error(`${info.file.name} file upload failed.`);
                          }
                          const {fileList}=info;
                          const getIds=extractImageIds(fileList);
                          if(getIds){
                            const url=await imageUrl(getIds[0]);
                            setProfileImage(url);
                            setUploadedIds(getIds);
                          }
                        }} style={{ backgroundColor: '#ffffff', border: '1px solid #EAECF0' }}>
                          <p className="ant-upload-drag-icon" style={{ marginBottom: 10 }}>
                            <Image src={UploadIcon} preview={false} size={40} />
                          </p>
                          <p className="ant-upload-text"><span style={{ fontWeight: 600, color: '#6941C6', fontSize: '14px' }}>Click to upload</span> or drag and drop</p>
                          <p className="ant-upload-hint" style={{ fontSize: '12px' }}>SVG, PNG, JPG or GIF (max. 800x400px)</p>
                      </Dragger>
                    </Form.Item>
                  </Flex>
                </Flex>*/}

            {/* <Flex className="avatar-with-upload" vertical gap={20} align="center" style={{ marginTop: 20 }}>
              <Flex>
                <Avatar size={64} src={ProfileImg} />
              </Flex>
              <Flex horizontal="true" className="avatar-upload-area">
                <Form.Item name="uploadedIds"  noStyle>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item
                  name="photo"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  rules={[{ required: false }]}
                >
                  <Dragger style={{ backgroundColor: '#ffffff', border: '1px solid #EAECF0' }}>
                      <p className="ant-upload-drag-icon" style={{ marginBottom: 10 }}>
                        <Image src={UploadIcon} preview={false} size={40} />
                      </p>
                      <p className="ant-upload-text"><span style={{ fontWeight: 600, color: '#6941C6', fontSize: '14px' }}>Click to upload</span> or drag and drop</p>
                      <p className="ant-upload-hint" style={{ fontSize: '14px' }}>SVG, PNG, JPG or GIF (max. 800x400px)</p>
                  </Dragger>
                </Form.Item>
              </Flex>
            </Flex> */}

            <Form.Item
              name="email"
              label="Email address"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: false, message: 'Please input your first name!' }]}
              className="custom-form-item email-input"
            >
              <Input readOnly={true} prefix={<MailOutlined />} placeholder="Enter first name" style={{ padding: '4px 10px' }} />
            </Form.Item>

            <Form.Item
              name="phone_number"
              label="Phone"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Please input your phone number!' },{ validator: validatePhoneNumber }]}
              className="custom-form-item phone-input"
            >
              <Input
                style={{ padding: '7px 10px' }}
                placeholder="(000) 000-0000"
                maxLength={10}
                prefix={<MobileOutlined />}
              />
            </Form.Item>

            <Divider />

            <Flex
              horizotanl="true"
              justify="end"
              gap="middle"
              style={{
                width: '100%',
                marginTop: '20px'
              }}
            >
              <Button onClick={handleLogout} type="text" icon={<LogoutOutlined style={{ fontSize: 18 }} />} style={{ fontWeight: 600, padding: 0 }}>Log out</Button>
              
              <Button style={{ fontWeight: 600, marginLeft: 'auto' }} size="large">
                Cancel
              </Button>

              <Button type="primary" htmlType='submit' size="large" style={{ fontWeight: 600 }}>
                Save
              </Button>
            
            </Flex>

          </Form>

        </TabPane>
        <TabPane tab="Password" key="2">

          <Flex className="head-inner" horizotanl={true} justify="space-between">
            <Flex vertical>
                <Title level={4} style={{ marginBottom: '8px', fontSize: '18px' }}>Password</Title>
                <Text>Please enter your current password to change your password.</Text> 
            </Flex>
          </Flex>

          <Divider />

          <Form form={form} onFinish={changePasswpord} className="setting-form">

          <Form.Item
              label="Current Password"
              name="currentPassword"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              rules={[{ required: true, message: 'Please enter your current password' }]}
              className="custom-form-password"
            >
              <Input.Password placeholder="Current Password" />
            </Form.Item>

            <Divider />

            <Form.Item
                label="New Password"
                name="newPassword"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 12 }}
                rules={[
                    { required: true, message: 'Please input your new password!' },
                    { min: 8, message: 'Password must be at least 8 characters long!' },
                    { pattern: /[!@#$%^&*(),.?":{}|<>]/, message: 'Password must contain at least one special character!' },
                    { validator: validateNewPassword }
                ]}
                className="custom-form-password"
                style={{ marginBottom: 0 }}
            >
                <Input.Password placeholder="New Password" />
                <Text style={{ marginTop: 7, display: 'block' }}>Your new password must be more than 8 characters.</Text>
            </Form.Item>

            <Divider style={{ marginTop: 14 }} />

            <Form.Item
                label="Confirm New Password"
                name="confirmNewPassword"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 12 }}
                dependencies={['newPassword']}
                className="custom-form-password"
                rules={[
                    { required: true, message: 'Please confirm your new password' },
                    { validator: validateConfirmPassword }
                ]}
            >
                <Input.Password placeholder="Confirm New Password" />
            </Form.Item>
            <Divider />

            <Flex
                horizotanl={true}
                justify="end"
                gap="middle"
                style={{
                  width: '100%',
                  marginTop: '20px'
                }}
              >
              <Button style={{ fontWeight: 600 }} size="large">
                Cancel
              </Button>
                <Button type="primary" htmlType='submit' size="large" style={{ fontWeight: 600 }}>
                  Update password
                </Button>
            </Flex>

          </Form>
        </TabPane>
      </Tabs>
      </Spin>

      <Modal keyboard={false} maskClosable={false} className="logout-confirm" open={isLogout} onOk={logoutHandel} onCancel={handleCancel} width={480} footer={[
        <Flex gap={10} justify="center" style={{ marginTop: 30, marginBottom: 20 }}>
          <Button key="cancel" size="large" onClick={handleCancel} style={{ fontWeight: 600 }}>Cancel</Button>
          <Button key="add" size="large" 
          type="primary" 
          onClick={logoutHandel} 
          style={{ fontWeight: 600 }}
          >Yes, log me out</Button>
        </Flex>
      ]}>
        <Title level={4} style={{ textAlign: 'center' }}>Are you sure that you want to logout?</Title>
      </Modal>

    </Layout>
  );
};

export default PWASettings;
