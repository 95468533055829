import React, { useState, useContext,useEffect, useMemo } from 'react';
import { Layout, Typography, Breadcrumb, Button, Progress, Modal, Flex, Radio, Image, Divider, Avatar, Row, Col, Input, Table, Form, message } from 'antd';
import { ArrowUpOutlined, EditOutlined, ArrowDownOutlined, RightOutlined, BarChartOutlined, SearchOutlined } from '@ant-design/icons';
import { get, post } from '../../../utils/api';
import { UserContext } from '../../../contexts/UserContext';
import AvatarImage from '../../AvatarImage';

import SelectMessage from '../../Communities/SelectMessage';
import SidebarFan from '../../../pages/Communities/SidebarFan';
import useScreenSize from '../../../utils/useScreenSize';
import { calculateAge,formatPrice } from '../../../utils/utils';
import EditIconN from '../../../images/edit-icon-n.svg';

const { Title: AntTitle, Text } = Typography;
const { Search } = Input;
const { Content } = Layout;

const EngagementReport = () => {
  const [selectedRange, setSelectedRange] = useState('top');
  const {user}  = useContext(UserContext);
  const [topPagination, setTopPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [loading, setLoading] = useState(false);
  const [topFansData, setTopFansData] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openSendMessage, setSendMessage] = useState (false);
  const { isMobile } = useScreenSize();
  
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userInfo,setUserInfo]= useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false); 
  const [selectedTopFanMessage, setSelectedTopFanMessage] = useState(null);
  const [selectedFan, setSelectedFan] = useState(null);
  const [selectedUser,setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setIsReload] = useState(false);

  const handleRangeChange = (elem) => {
    const val=elem.target.value;
    setSelectedRange(val);
    fetchTopUsers({sortBy:val});
  };
 
  const fetchTopUsers = async (params = {}) => {
    setLoading(true);
    try {
      const response = await get('creator/topFans', {
      _page: params.pagination?.current || 1,
      _limit: params.pagination?.pageSize || 10,
      q: params?.searchTerm ||'',
      sortBy:params?.sortBy ||'',
      });
      setTopFansData(response.data);
      setTopPagination({
      ...params.pagination,
      total: response.pagination.total,
      });
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    fetchTopUsers();
   },[]);

  const handleTopTableChange = (pagination) => {
      fetchTopUsers({ pagination });
    };
  const handleTopSearch = (value) => {
    fetchTopUsers({searchTerm:value});
  };
  const searchTopChange=(elem)=>{
    try{
      const val=elem.target.value;
      if(val?.length===0){
        fetchTopUsers();
      }
    }catch(error){
      console.log(error);
    }
  }

  const handleSearch = (value) => {
    fetchTopUsers({searchTerm:value});
  };
  const searchChange=(elem)=>{
    try{
      const val=elem.target.value;
      if(val?.length===0){
        fetchTopUsers();
      }
    }catch(error){
      console.log(error);
    }
  }

  const handleSendMessage = () => {
    if(selectedItems?.length>0){
      setSendMessage(true);
    }else{
      message.error('Please select at least one user to send a message.');
    }
  };

  const handleSelectMessage = async(record) => {    
    setSelectedMessage(record.revenueUser);
    setIsModalVisible(true);
    setIsHistoryLoading(true);
    try{
      const getUserInfo = await post(`creator/getUserInfo`, {
        phone: record?.revenueUser.phone_number
      });
      if (getUserInfo && getUserInfo.status) {
        setUserInfo(getUserInfo.data);
      } else {
        console.error('Information not available!');
      }

        const response = await post(`creator/chat`,{
          phn:record?.revenueUser.phone_number
        });
        if(response && response.status){
          setChatHistory(response?.data.reverse());
        }
    }catch(error){
      console.log("loading......");
    } finally {
      setIsHistoryLoading(false);
    }
  }

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedMessage(null);
    setSelectedTopFanMessage(null);
    setIsHistoryLoading(false);
  };


  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedItems(selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  const reportContentStyle = useMemo(() => 
    isMobile ? { 
      padding: '20px 15px', 
    } : { 
      backgroundColor: '#ffffff',
      height: '100vh', 
      minHeight: '100vh',
      overflow: 'auto', 
      scrollbarWidth: 'thin', 
      scrollbarColor: 'unset', 
      padding: '30px',
    }
  , [isMobile]);

  const columnsTopFan = [
    {
      title: 'Name & Number',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => `${a?.revenueUser?.first_name} ${a?.revenueUser?.last_name}`.localeCompare(`${b?.revenueUser?.first_name} ${b?.revenueUser?.last_name}`),
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        const imageId = record && record?.revenueUser?.uploadedIds && record?.revenueUser?.uploadedIds.length > 0 ? record?.revenueUser?.uploadedIds[0] : null;
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
               <AvatarImage size={42} font={16} imageId={imageId} title={`${record?.revenueUser?.first_name} ${record?.revenueUser?.last_name}`}   />
            <div>
              <AntTitle level={5} style={{ color: '#101828', fontSize: '14px', marginTop: 0, marginBottom: 0, textTransform: 'capitalize' }}>{record?.revenueUser?.first_name} {record?.revenueUser?.last_name}</AntTitle>
              <div style={{ fontSize: '14px', color: '#101828' }}>+1{record?.revenueUser?.phone_number}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      sorter: (a, b) => a?.revenueUser?.gender.localeCompare(b?.revenueUser?.gender),
      responsive: ['md'],
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>{record?.revenueUser?.gender}</div>
        );
      }
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      sorter: (a, b) => calculateAge(a?.revenueUser?.dob) - calculateAge(b?.revenueUser?.dob),
      responsive: ['md'],
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        const value = calculateAge(record?.revenueUser?.dob);
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>{value}</div>
        );
      }
    },
    {
      title: 'Location',
      dataIndex: 'location',
      responsive: ['md'],
      key: 'location',
      sorter: (a, b) => a?.revenueUser?.city.localeCompare(b?.revenueUser?.city),
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>{record?.revenueUser?.city}</div>
        );
      }
    },
    {
      title: 'Interactions',
      dataIndex: 'interactions',
      key: 'interactions',
      sorter: (a, b) => {
        const totalAmountA = a?.totalAmount ? parseFloat(a.totalAmount) : 0;
        const totalAmountB = b?.totalAmount ? parseFloat(b.totalAmount) : 0;
        return totalAmountA - totalAmountB;
      },
      responsive: ['md'],
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        return (
          <Flex vertical>
            <span style={{ fontWeight: 500, color: '#101828' }}>
              {record?.itemCount} Interactions
            </span> 
            {formatPrice(record?.totalAmount || 0)} Spent
          </Flex>
        );
      }
    },
    {
      title: '',
      dataIndex: '',
      responsive: ['md'],
      key: '',
      render: (text, record) => {
        return (
          <Flex gap={10}>
            <Button type="text" onClick={() => handleSelectMessage(record)} icon={<Image src={EditIconN} preview={false} />}></Button>
            <Button 
              type="text" 
              icon={<RightOutlined />}
              onClick={() =>{
                setSelectedFan(true);
                const updatedObject = {
                  ...record,
                  user_phone: record.revenueUser.phone_number
              };
                setSelectedUser(updatedObject);
              }}
            ></Button>
          </Flex>
        );
      }
    }
  ];


  return (
    <Layout>

      <Content style={reportContentStyle}>

      {user?.role==="Team"?
      <Breadcrumb className="breadcrumb-insights" separator={<RightOutlined />} style={{ marginBottom: 25 }}>
        <Breadcrumb.Item href="/team/insights">
          <BarChartOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/team/insights">
          <span>Audience Insights</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>Engagement</span>
        </Breadcrumb.Item>
      </Breadcrumb>:
      <Breadcrumb className="breadcrumb-insights" separator={<RightOutlined />} style={{ marginBottom: 25 }}>
        <Breadcrumb.Item href="/insights">
          <BarChartOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/insights">
          <span>Audience Insights</span>
          </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>Engagement</span>
        </Breadcrumb.Item>
      </Breadcrumb>}

      <Flex horizontal="true" style={{ marginBottom: isMobile?20:40 }}>
        <Flex vertical>
          <AntTitle level={2} style={{ marginBottom: 5, marginTop: 0 }}>Engagement report</AntTitle>
          <Text>Manage and see your top fan engagements.</Text>
        </Flex>
      </Flex>

      <Flex vertical={isMobile ? true:false} horizontal="true" align={isMobile?"flex-start":"center"}>
        <Flex vertical>
          <AntTitle level={4} style={{ marginTop: 0, marginBottom: isMobile?10:0, fontSize: '18px' }}>Metrics</AntTitle>
        </Flex>
        <Flex className="button-date-group" style={{ marginLeft: isMobile?'0':'auto' }}>
          <Radio.Group value={selectedRange} onChange={handleRangeChange}>
            <Radio.Button key="top" value="top">Top</Radio.Button>
            <Radio.Button key="most" value="most">Most recent</Radio.Button>
            <Radio.Button key="least" value="least">Least recent</Radio.Button>
          </Radio.Group>
        </Flex>
      </Flex>

      <Divider />

      <Flex vertical>
        <Flex vertical={isMobile ? true : false} justify="space-between" align={isMobile ? "flex-start" : "flex-end"} style={{ marginBottom: 25 }}>
          <Flex vertical={isMobile ? true : false} align={isMobile ? "normal" : "flex-end"} gap={12} style={{ marginBottom: isMobile ? 12 : 0, width: isMobile? '100%':''}}>
            <Form.Item 
              label="Search fan"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              style={{ marginBottom: 0 }}
            >
              <Search 
                className="custom-form-search-item search-no-icon"
                placeholder="Search" 
                onSearch={handleSearch}
                enterButton
                onChange={searchChange}
                prefix={<SearchOutlined />} 
                style={{ marginBottom: 0 }} 
                allowClear
              />
            </Form.Item>
            {/*<Button icon={<FilterOutlined />} size="large" type="default" style={{ fontWeight: 600 }}>Filters</Button>*/}
          </Flex>

          <Button block={isMobile ? 'block':''} onClick={handleSendMessage} disabled={selectedRowKeys.length === 0} icon={<EditOutlined />} size="large" type="primary" style={{ fontWeight: 600 }}>New Message</Button>
        </Flex>
        <div className="insight-table">
          <Table 
            rowSelection={rowSelection}
            columns={columnsTopFan} 
            dataSource={topFansData} 
            pagination={topPagination} 
            rowKey={(record) => record.id}
            loading={loading}
            onChange={handleTopTableChange}
          />
        </div>
      </Flex>

      </Content>

      {selectedFan &&
        <SidebarFan
          selectedUser={selectedUser} 
          selectedFan={selectedFan} 
          setSelectedFan={setSelectedFan} 
          setIsLoading={setIsLoading} 
          setIsReload={setIsReload} 
        />
      }

      {selectedMessage &&
        <Modal
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
          width={800}
          className="select-message-modal"
        >
          <SelectMessage selectedMessage={selectedMessage} isHistoryLoading={isHistoryLoading} chatHistory={chatHistory} setChatHistory={setChatHistory} />
        </Modal>
      }
  
    </Layout>
  );
};

export default EngagementReport;
