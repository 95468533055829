import React, { useState, useEffect } from 'react';
import { Input, Col, Form } from 'antd';
import './CodeInput.css';

const CodeInput = ({ setCode, maxLength = 4 }) => {
  const [otp, setOtp] = useState(Array(maxLength).fill(''));
  const [form] = Form.useForm();

  const handleChange = (e, index) => {
    const { value } = e.target;
    const ValidInput = /^[0-9]$/.test(value) || value === '';
    if(!ValidInput){
      return form.setFieldsValue({ [`otp-${index}`]: '' });
       
    }
    // Allow both numeric and alphabetic characters
    const isValidInput = /^[a-zA-Z0-9]$/.test(value) || value === '';

    if (isValidInput || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value.toUpperCase(); // Convert to uppercase for consistency
      setOtp(newOtp);
      form.setFieldsValue({ [`otp-${index}`]: value.toUpperCase() });
      
      // Auto focus to next input if current input is not empty
      if (value !== '' && index < maxLength - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      const newOtp = [...otp];
      if (otp[index] === '') {
        // Move focus to previous input if current input is empty
        if (index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
        }
      } else {
        // Clear current input
        newOtp[index] = '';
        setOtp(newOtp);
        form.setFieldsValue({ [`otp-${index}`]: '' });
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, maxLength).toUpperCase();
   
    // Validate that pasted data contains only alphanumeric characters
    if (
      pastedData.length === maxLength && 
      /^[a-zA-Z0-9]+$/.test(pastedData)
    ) {
      const newOtp = pastedData.split('');
      setOtp(newOtp);
      newOtp.forEach((value, index) => {
        const ValidInput = /^[0-9]$/.test(value) || value === '';
        if(!ValidInput){
          return form.setFieldsValue({ [`otp-${index}`]: '' });
           
        }

        form.setFieldsValue({ [`otp-${index}`]: value });
      });
      document.getElementById(`otp-input-${maxLength - 1}`).focus();
    }
  };

  useEffect(() => {
    setCode(otp);
  }, [otp, setCode]);

  return (
    <Form form={form} className="code-input-container" onPaste={handlePaste}>
      {otp.map((val, index) => (
        <Col key={index}>
          <Form.Item
            name={`otp-${index}`}
            rules={[{ required: false, message: '' }]}
            style={{ marginBottom: 0 }}
            
          >
            {console.log("val",val)}
            <Input
              id={`otp-input-${index}`}
              placeholder="-"
              onChange={(e) => handleChange(e, index)}
              maxLength={1}
              style={{ 
                width: '40px', 
                textAlign: 'center', 
                textTransform: 'uppercase' 
              }}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="code-input"
            />
          </Form.Item>
        </Col>
      ))}
    </Form>
  );
};

export default CodeInput;