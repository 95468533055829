import React, { useState, useEffect } from 'react';
import { Statistic, Image, Flex } from 'antd';
import ExpressIcon from "../../images/express-icon.svg";
import ClockIcon from "../../images/clock.svg";
import ClockOrgIcon from "../../images/clock-org.svg";
import { post } from '../../utils/api';
import moment from 'moment';

const { Countdown } = Statistic;

const formatTime = (time) => {
  const duration = moment.duration(time, 'seconds');
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return `${days > 0 ? `${days}d ` : ''}${hours > 0 ? `${hours}h ` : ''}${minutes > 0 ? `${minutes}m ` : ''}${seconds}s`;
};

const CountdownTimer = ({ time, id }) => {
  const target = moment(time);
  const [expired, setExpired] = useState(false);
  const [isUnder24Hours, setIsUnder24Hours] = useState(false);

  // Determine if the target time is already passed when component mounts
  useEffect(() => {
    if (moment().isAfter(target)) {
      setExpired(true);
      
    }

    const remainingTime = moment.duration(target.diff(moment()));
    setIsUnder24Hours(remainingTime.asHours() <= 24);

  }, [target]);

  // Uncomment if you want to use the API call feature
  useEffect(() => {
    const callExpire=async()=>{
       await post('creator/private/status', { id:id, requestType:'Decline' });
    }
    if(expired){
      callExpire();
    }
   
  }, [expired]);
  const handleExpire = () => {
    setExpired(true);
    
  };

  const customFormatter = (value) => {
    const duration = moment.duration(value, 'seconds');
    const hours = Math.floor(duration.asHours()); // Get total hours
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${hours > 0 ? `${hours}h ` : ''}${minutes > 0 ? `${minutes}m ` : ''}${seconds}s`;

  };

  
  return (
    <div className="countdown-timer">
      {!expired ? (<>
        {!isUnder24Hours == true ?
          <Countdown
            title={
              <span>
                <Image src={ClockIcon} alt="clock" />
              </span>
            }
            value={target}
            onFinish={handleExpire}
            format="D[d] H[h] m[m] s[s]"
          />
          : 
          <Countdown
            title={
              <Flex gap={5} style={{ color: '#DC6803' }}>
                <Image src={ClockOrgIcon} alt="clock" />
                Expiring in
              </Flex>
            }
            value={target}
            onFinish={handleExpire}
            format="H[h] m[m] s[s]"
            className="timer-less-24"
          />
        }
      </>) : (
        <div className="expired-container">
          <div style={{ fontSize: 14, color: '#8C8C8C', marginTop: 0 }}>
            Expired on {moment(time).format('MMM D, YYYY')}
          </div>
        </div>
      )}
    </div>
  );
};

export default CountdownTimer;