import { useState, useEffect } from 'react';
import { List, Flex, Typography, Image, Tag} from "antd";
import AvatarImage from "../AvatarImage";
import ClockIcon from "../../images/clock.svg";
import ExpressIcon from "../../images/express-icon.svg";
import ClockOrgIcon from "../../images/clock-org.svg";
import { formatPrice } from '../../utils/utils';
import CountdownTimer from './CountdownTimer';
import { post } from '../../utils/api';
const { Text, Title, Paragraph } = Typography;

const PrivateRequestFillter = ({ setSelectedRequest, requestsData, isMobile, selectedRequest,setIsReLoad,tabName }) => {
    const [currentTime, setCurrentTime] = useState(new Date());
    // Remove the global expired state since we'll handle it per item

    const setExpiredId=async(expiredId)=>{
      console.log("ffff");
      // const response = await post('creator/private/status', { id:expiredId,requestType:"Decline" });
      // if (response.status) {
      //   setIsReLoad(true);
      // }
    }
    
    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentTime(new Date());
      }, 60000);
      
      return () => clearInterval(timer);
    }, []);

    const getTimeLeft = (expireTime) => {
    const expiryDate = new Date(expireTime);
   
    const difference = expiryDate - currentTime;
    
    if (difference <= 0) {
      const formattedDate = expiryDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
      return { 
        timeString: `Expired on ${formattedDate}`, 
        isExpired: true,
        days: 0
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    if (days === 0) {
      return { 
        timeString: `Expiring in ${hours}h ${minutes}m`, 
        isExpired: false,
        days: 0
      };
    }
    return { 
      timeString: `${days}d ${hours}h ${minutes}m`, 
      isExpired: false,
      days
    };
  };

    console.log(selectedRequest, 'selectedRequest')

    return (
      <List
        itemLayout="horizontal"
        dataSource={requestsData}
        style={isMobile ? {} : { flex: 1, overflowY: 'auto', scrollbarWidth: 'thin', scrollbarolCor: 'unset' }}
        renderItem={item => {
          const date = new Date(item?.expire_time);
          const timeInSeconds = Math.floor(date.getTime() / 1000);
          const { timeString, isExpired, days } = getTimeLeft(item?.expire_time);  
          return (
            <List.Item
              style={{ cursor: 'pointer', padding: '13px 20px', background: item.id === selectedRequest?.id ? '#F9FAFB' : 'transparent' }}
              onClick={() => setSelectedRequest(item)}
            >
              <List.Item.Meta
                title={<>
                  <Flex justify="space-between" style={{ marginBottom: 10 }}>
                    <Text style={{ display: 'flex', gap: 6, color: isExpired ? '#475467': days === 0 ? '#F79009' : '#475467' }}>
                      {/*{item?.status==0 ? <CountdownTimer time={item?.expire_time} id={item?.id} setExpiredId={setExpiredId} />:<Tag className={tabName}>{tabName}</Tag>}*/}
                      {item?.status != 1 ? (<>
                        {!isExpired ? (
                          <>
                            <Image src={days === 0 ? ClockOrgIcon : ClockIcon} preview={false} />
                            {timeString}
                          </>
                        ) : (
                          <>
                            {timeString}
                          </>
                        )}
                      </>) : (
                        <Text>Complete</Text>
                      )}
                    </Text>
                    <Text>{formatPrice(item?.price||0)} </Text>
                  </Flex>
                  <Text strong style={{ color: '#344054', fontSize: 16 }}>For {item?.to_name}</Text>
                </>}
                description={<Flex vertical>
                  <Typography.Text
                    ellipsis={{ 
                      rows: 2
                    }}
                  >{item?.message}</Typography.Text>
                  <Flex gap={5} style={{ marginTop: 8 }}>
                    <Text style={{ background: '#ffffff', border: '1px solid #D0D5DD', padding: '0px 6px', borderRadius: '6px', fontSize: '12px', color: '#344054', fontWeight: 500 }}>{item?.title}</Text>
                    {item?.request_type == 'express' &&
                      <Text style={{ background: '#FCFAFF', border: '1px solid #D0D5DD', padding: '0px 6px', borderRadius: '6px', fontSize: '12px', color: '#6941C6', fontWeight: 500, display: 'flex', alignItems: 'center', gap: 4 }}><Image src={ExpressIcon} preview={false} style={{ display: 'block' }} />24hr Express</Text>
                    }
                  </Flex>
                </Flex>}
              />
            </List.Item>
          );
        }}
      />
    );
}

export default PrivateRequestFillter;