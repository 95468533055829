import React, { useState,useContext,useMemo,useEffect } from 'react';
import { Form, Upload, Layout, List, Modal, Typography, Tabs, Space, Drawer, Button, Dropdown, Input, Divider, Checkbox, Flex, Card, Radio, Row, Col, Slider, Image, Spin, message,Popconfirm } from 'antd';
import { PlusOutlined, SearchOutlined, FilterOutlined, DownOutlined, ArrowLeftOutlined, UserOutlined, MinusCircleOutlined, StarFilled, StarOutlined, CloseOutlined, CloudUploadOutlined, RightOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';

import SidebarFan from './SidebarFan';
import SelectMessage from '../../components/Communities/SelectMessage';
import './communities.css';
import ArrowRight from "../../images/arrow-right.svg";
import UserIcon from "../../images/user-icon.svg";
import EllipsisIcon from "../../images/ellipsis-icon.svg";
import AllFansIcon from "../../images/user-icon-white.svg";
import StarIcon from "../../images/star-icon.svg";
import FilterIcon from "../../images/filter-lines.svg";
import EditIcon from "../../images/edit-icon.svg";
import TrashIcon from "../../images/trash-icon.svg";
import DeleteIcon from "../../images/delete-icon-bg.svg";

import { UserContext } from '../../contexts/UserContext';
import { getInitials,calculateAge,imageUrl,openNotification} from '../../utils/utils';
import AvatarImage from '../../components/AvatarImage';
import { get,post,del } from '../../utils/api';
import ImageUpload from '../../components/ImageUploads/ImageUpload';
import AddressAutocomplete from '../../components/AddressAutoComplete';
import SendMessage from '../../components/Communities/SendMessage';
import useScreenSize from '../../utils/useScreenSize';
import { useParams } from 'react-router-dom';

const { Sider, Content } = Layout;
const { Text, Title, Paragraph } = Typography;
const { confirm } = Modal;

const Communities = () => {
  const { userId } = useParams();
  const [form] = Form.useForm();
  const { isMobile } = useScreenSize();
  const [open, setOpen] = useState(false);
  const [opencommunities, setCommunitiesOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [communitiesList, setCommunitiesList] = useState([]);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [selectOnclick, setSelectOnclick] = useState(null);
  const [selectedFan, setSelectedFan] = useState(null);
  const [selectedUser,setSelectedUser] = useState(null);
  const [selectedNewCommunity, setNewCommunity] = useState(null);
  const [editCommunity, setEditCommunity] = useState(null);
  const [searchCommunity, setSearchCommunity] = useState('');
  const [searchFan, setSearchFan] = useState('');
  const [imageId,setImageId]=useState(null);
  const [photo,setPhoto]=useState(null);
  const [photoId,setPhotoId]=useState(null);
  const [fansOption, setFansOption] = useState('all');
  const [gender, setGender] = useState([]);
  const [ageRange, setAgeRange] = useState([16, 25]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [openSendMessage, setSendMessage] = useState (false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [userInfo,setUserInfo]= useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false); 

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [fileType, setFileType] = useState('Messages');
  const [activeTab, setActiveTab] = useState('Messages');

  const [sortOption, setSortOption] = useState(null);
  const [isNewMessageVisible, setIsNewMessageVisible] = useState(false);
  
  const tabsHandller=async (key)=>{
    setFileType(key);
  }

  // console.log(selectedItems, 'selectedItems')

  const {user}  = useContext(UserContext);
  const [communities, setCommunities] = useState([
    { id: 1, name: 'All Audience', userCount: 0, icon: AllFansIcon, bgColor: '#7F56D9' },
    { id: 2, name: 'Favorite Audience', userCount: 0, icon: StarIcon, bgColor: '#FAC515' },
  ]);

  const sortItems = [
    {
      key: 'lastName',
      label: 'Last Name'
    },
    {
      key: 'age',
      label: 'Age'
    },
    {
      key: 'city',
      label: 'City'
    },
    {
      key: 'state',
      label: 'State'
    },
    {
      key: 'aToZ',
      label: 'A → Z'
    },
    {
      key: 'zToA',
      label: 'Z → A'
    }
  ];

  const loadCommunities = async (current, pageSize) => {
   
    setCommunitiesList([]);
    setIsLoading(true);

    try {
      const response = await get('creator/communities/',{s:searchCommunity});
      if(response){
        setCommunitiesList(response);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(()=>{
    loadCommunities();
  },[searchCommunity]);

  const loadfavoriteAudiencefanCounts=async()=>{
    try {
      const response = await get('creator/fanCounts/');
      if(response && response.status){
        const { allUsers, favoriteAudienceCount } = response.data;
        setCommunities(prevCommunities =>
          prevCommunities.map(community => {
            if (community.name === 'All Audience') {
              return { ...community, userCount: allUsers };
            } else if (community.name === 'Favorite Audience') {
              return { ...community, userCount: favoriteAudienceCount };
            }
            return community;
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const init = async () => {
    await loadCommunities();
    await loadfavoriteAudiencefanCounts();
  };

  useEffect(() => {
    init(); // Call the initialization function immediately
  }, []);


 const deleteconfirm=async()=>{
  Modal.confirm({
    title: `Delete Community?`,
    icon: <Image src={DeleteIcon} preview={false} />,
    content: `Are you sure you want to delete ${selectedCommunity?.name}?`,
    okText: 'Delete',
    okButtonProps: {
      style: {
        backgroundColor: '#D92D20',
        borderColor: '#D92D20',
        fontWeight: 'bold',
        color: 'white'       
      },
      className: 'custom-leave-button'
    },
    cancelButtonProps: {
      style: {
        backgroundColor: 'white',
        borderColor: '#d9d9d9',
        fontWeight: 'bold',
        color: '#475467'
      },
      className: 'custom-cancel-button'
    },
    async onOk() {
      try {
        await del(`creator/deleteCommunities/${selectOnclick?.id}`);
        setSelectedCommunity(null);
        await loadCommunities();
        openNotification("Successfully deleted!");
      } catch (error) {
        openNotification("Failed to delete community!");
        message.error("Failed to delete community");
      }
    },
    onCancel() {
      console.log('Cancel');
    },
  });
  
 }
 const cancel=()=>{
  console.log("cancel");
 }

 const fneditCommunity=async()=>{
  form.resetFields();

      let memData=selectOnclick;
     
      if(memData?.image){
        setPhotoId(memData?.image);
        const imagePath=await imageUrl(memData?.image);
        setPhoto(imagePath);
      }else{
        setPhotoId(null);
      }
      
      setEditCommunity(memData);
      setSelectedCommunity(null);
 }

  const fans = [
    { id: 1, name: 'John Doe', phone: '+1 (800) 758-5000',  gender: 'Male', age: 23, location: 'Newport Beach, CA', spent: 1000, interactions: 30, communities: ['Avocado Junkies'] },
    { id: 2, name: 'Olivia Rhye', phone: '+1 (800) 758-5000', gender: 'Female', age: 23, location: 'Palm Springs, CA', spent: 1000, interactions: 30, communities: ['Avocado Junkies'] },
  ];

  const communityitems = (
    <Flex vertical align="start" style={{ backgroundColor: '#ffffff', border: '1px solid #EAECF0', borderRadius: '8px', paddingTop: 0, paddingBottom: 0 }}>
      <Button onClick={fneditCommunity} block type="text" size="large" style={{ textAlign: 'left', color: '#344054', justifyContent: 'flex-start', fontSize: '14px' }} icon={<Image src={EditIcon} preview={false} />}>Edit</Button>
      <Divider style={{ margin: 0 }} />
        <Button block type="text" size="large" onClick={deleteconfirm} style={{ textAlign: 'left', color: '#344054', justifyContent: 'flex-start', fontSize: '14px' }} icon={<Image src={TrashIcon} preview={false} />}>Delete Community</Button>
    </Flex>
  );


  // const filteredCommunities = communities.filter(community =>
  //   community.name.toLowerCase().includes(searchCommunity.toLowerCase())
  // );

  // const filteredFans = fans.filter(fan =>
  //   fan.name.toLowerCase().includes(searchFan.toLowerCase())
  // );

  const addNewcommunity = () => {
    setEditCommunity(null);
    setPhoto(null);
    setPhotoId(null);
    form.resetFields();
    setFansOption('all');
    setSelectedCommunity(false);
    setSelectedFan(false);
    setNewCommunity(true);
    setCommunitiesOpen(true);
    setAgeRange([16, 25])
 
  };
  const onClose = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      icon: <ExclamationCircleFilled style={{ fontSize: 35, color: '#7F56D9', marginBottom: 10 }} />,
      content: 'This community will not be created.',
      okText: 'Leave',
      okButtonProps: {
        style: {
          backgroundColor: '#6941C6',
          borderColor: '#6941C6',
          fontWeight: 'bold',
          color: 'white'       
        },
        className: 'custom-leave-button'
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'white',
          borderColor: '#d9d9d9',
          fontWeight: 'bold',
          color: '#475467'
        },
        className: 'custom-cancel-button'
      },
      okType: 'danger',
      onOk() {
        setCommunitiesOpen(false);
        if(form){
          form.resetFields();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
   
  };

  const handleCommnityClose = () => {
    setNewCommunity(false);
  }

  const handleFansOptionChange = (e) => {
    setFansOption(e.target.value);
  };

  const handleGenderChange = (checkedValues) => {
    setGender(checkedValues);
  };

  const handleAgeChange = (value) => {
    setAgeRange(value);
  };

  const updateEdits=async(values)=>{
    const data = { ...values,photo:imageId};
    setIsLoading(true);
      try {
        const response = await post(`creator/communities/update/${editCommunity?.id}`,data);
        if (response && response.status) {
          await loadCommunities();
          await init();
          setNewCommunity(null);
          setEditCommunity(null);
          form.resetFields();
          openNotification(response.message);

        } else {
          message.error(response.message);
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setIsLoading(false);
      }
  }

  const onFinish = async(values) => {
    const data = { ...values,photo:imageId ,created_by:user?.id,age:ageRange};
    setIsLoading(true);
      try {
        const response = await post(`creator/communities/create`,data);
        if (response && response.status) {
          await loadCommunities();
          setNewCommunity(null);
          form.resetFields();

          openNotification(response.message);

        } else {
          message.error(response.message || 'Login failed');
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setIsLoading(false);
      }
  };

  const GetSingleCommunity=async(item,searchFan='',sortBy="lastName",filters={})=>{

    const formData = {
      id: item?.id,
      s:searchFan,
      userId,
      sortBy,
      filters
    };
    setIsLoading(true);
    setSelectedFan(false);
    setEditCommunity(null);
    setNewCommunity(null);
    if(item.name==="All Audience"){
      try {
        const response = await get(`creator/allCommunities`,formData);
        if(response.status){
          const {Users}=response.data || {};
          const dataCollection={
            name:"All Audience",
            Users,
            userCount:item?.userCount
          }
          setSelectedCommunity(dataCollection);
        }
        
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
      
    }else if(item.name==="Favorite Audience"){

      try {
        const response = await get(`creator/favoriteAudience`,formData);
        if(response.status){
          const {favorites}=response.data || {};
          const dataCollection={
            name:"Favorite Audience",
            Users:favorites,
            userCount:item?.userCount
          }
          setSelectedCommunity(dataCollection);
        }
        
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }

    }else{
      try {
        const response = await post(`creator/communities/${user?.id}`, formData);
        if(response.status){
          setSelectedCommunity({...response.data,userCount:item?.userCount});
        }
        
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    }
  }


  const handleCommnityEditClose=async()=>{
    setEditCommunity(null);
  }
  

  useEffect(() =>{ 
    if(form){
      form.resetFields();
    }
    
  }, [form,editCommunity]);
  useEffect(() => {
    const loaderInit = async () => {
      if (selectedCommunity) {
        try {
          await GetSingleCommunity(selectedCommunity);
        } catch (error) {
          console.error('Failed to fetch community data:', error);
        }
      }
    };

    loaderInit();
  }, [isReload]);

  const searchFans=async(searchvalue)=>{
    if(searchvalue.target.value){
      await GetSingleCommunity(selectOnclick,searchvalue.target.value);
    }else{
      await GetSingleCommunity(selectOnclick);
    }
  }

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    setSelectedItems(checked ? selectedCommunity?.Users : []);
  };

  const handleItemChange = (item, e) => {
    const checked = e.target.checked;
    setSelectedItems((prevSelectedItems) =>
      checked ? [...prevSelectedItems, item] : prevSelectedItems.filter((i) => i !== item)
    );
    if (!checked) setSelectAll(false);
  };

  const handleSortClick = async ({ key }) => {
    await GetSingleCommunity(selectedCommunity, null, key);
    setSortOption(key);
    setSelectedItems([]);
    setSelectAll(false);
  };

  const handleSendMessage = () => {
    if(selectedItems?.length>0){
      setSendMessage(true);
      setOpen(true);
    }else{
      message.error('Please select at least one user to send a message.');
    }
   
  };

  const handleCloseMessage = () => {
    setSendMessage(false);
  };

  const handleClose = () => {
    setSelectedCommunity(false);
  };

  const selectedCommunityStyles = useMemo(() => 
    isMobile ? { 
      backgroundColor: '#ffffff', 
      position: 'absolute', 
      width: '100%', 
      left: 0,
      height: 'calc(100vh - 70px)',
      display: 'flex', 
      flexDirection: 'column' 
    } : { 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column' 
    }
  , [isMobile]);

  useEffect(()=>{
   
    if(userId){
      const selectedItem={ id: 1, name: 'All Audience', userCount: 0 };
      setSelectOnclick(selectedItem);
      GetSingleCommunity(selectedItem);
    }
   
  },[userId]);

  const handleTabChange = async (key) => {
    setActiveTab(key);
    setSelectedFan(null);
    await tabsHandller(key); 
  };

  const marks = {
    [ageRange[0]]: ageRange[0].toString(),
    [ageRange[1]]: ageRange[1].toString()
  };


  const addRemovefavorite=async(item)=>{
    const updatedUsers = selectedCommunity.Users.map((user) =>
      user.id === item.id ? { ...user, hasFavorites: !user.hasFavorites } : user
    );
    setSelectedCommunity((prev) => ({
      ...prev,
      Users: updatedUsers,
    }));

    setIsLoading(true);
    try {
    const response=await post(`creator/addRemovefavorite`, {
        favrateId: item?.id
      });
      if(response.status){
        await loadfavoriteAudiencefanCounts();
       
        openNotification(response.message);
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const removeUserFromCommunity=async(userId)=>{

    confirm({
      title: 'Are you sure you want to remove?',
      icon: <Image src={DeleteIcon} preview={false} />,
      content: 'This community will not be created.',
      okText: 'Yes Remove',
      okButtonProps: {
        style: {
          backgroundColor: '#D92D20',
          borderColor: '#D92D20',
          fontWeight: 'bold',
          color: 'white'       
        },
        className: 'custom-leave-button'
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'white',
          borderColor: '#d9d9d9',
          fontWeight: 'bold',
          color: '#475467'
        },
        className: 'custom-cancel-button'
      },
      async onOk() {
        setIsLoading(true);
          try {
          const response=await post(`creator/rmUserCommunity`, {
            id: selectedCommunity?.id,
            uId:userId
            });
            if(response.status){
            
              setSelectedFan(false);
              setIsReload(true);
            openNotification(response.message);
              
            }
          } catch (error) {
            console.error('Error fetching user info:', error);
          } finally {
            setIsLoading(false);
          }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
    
    
}
  const handleSelectMessage = async(item) => {
    // const updatedObject = {
    //   ...item,
    //   user_phone: item.phone_number
    // };
    // setSelectedUser(updatedObject);
    
    setSelectedMessage(item);
    setIsModalVisible(true);
    setIsHistoryLoading(true)
    try{
      const getUserInfo = await post(`creator/getUserInfo`, {
        phone: item?.phone_number
      });
      if (getUserInfo && getUserInfo.status) {
        setUserInfo(getUserInfo.data);
      } else {
        console.error('Information not available!');
      }

        const response = await post(`creator/chat`,{
          phn:item?.phone_number
        });
        if(response && response.status){
          setChatHistory(response?.data.reverse());
        }
    }catch(error){
      console.log("loading......");
    } finally {
      setIsHistoryLoading(false);
    }
  }

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedMessage(null);
    setIsHistoryLoading(false);
  };

  const handleNewMessageClose = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      icon: <ExclamationCircleFilled style={{ fontSize: 35, color: '#7F56D9', marginBottom: 10 }} />,
      content: 'This message will be closed.',
      okText: 'Leave',
      okButtonProps: {
        style: {
          backgroundColor: '#6941C6',
          borderColor: '#6941C6',
          fontWeight: 'bold',
          color: 'white'       
        },
        className: 'custom-leave-button'
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'white',
          borderColor: '#d9d9d9',
          fontWeight: 'bold',
          color: '#475467'
        },
        className: 'custom-cancel-button'
      },
      okType: 'danger',
      onOk() {
        setIsNewMessageVisible(false);
        setOpen(false);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };


  return (
    <Layout className="communities-main" style={{ minHeight: '100vh' }}>
      <Spin spinning={isLoading}>
      <Sider width={ isMobile ? '100%' : 360} style={{ backgroundColor: '#ffffff', borderRight: '1px solid #EAECF0', minHeight: '100vh' }}>
        <div className="head-area" style={{ padding: '20px', justifyContent: 'space-between' }}>
          <Title level={2}>Communities</Title>
          <Button type="primary" onClick={addNewcommunity} icon={<PlusOutlined />} style={{ float: 'right' }} />
        </div>

        <div style={{ padding: '0 20px 0px 20px' }}>
          <Input
            placeholder="Search communities"
            onChange={e => setSearchCommunity(e.target.value)}
            prefix={<SearchOutlined />}
            style={{ marginBottom: '20px' }}
          />
        </div>

        {communitiesList.length>0 ?
        <>
              <List
              itemLayout="horizontal"
              dataSource={communities}
              style={{ borderTop: '1px solid #EAECF0' }}
              renderItem={item => (
                <List.Item
                  style={{ cursor: 'pointer', padding: '10px 20px', background: item.name === selectedCommunity?.name ? '#F9FAFB' : 'transparent' }}
                   onClick={() =>{
                    setSelectOnclick(item);
                    GetSingleCommunity(item)
                    setSelectedItems([]);
                    setSelectAll(false);
                  }}
                >
                  <List.Item.Meta
                    avatar={<AvatarImage imageId={item?.image} title={item.name} customIcon={item.icon} bgColor={item.bgColor} />}
                    title={<Text strong>{item.name}</Text>}
                    description={<Text>{item.userCount} Fans</Text>}
                  />
                </List.Item>
              )}
            />
          <List
            itemLayout="horizontal"
            dataSource={communitiesList}
            style={{ borderTop: '1px solid #EAECF0' }}
            renderItem={item => (
              <List.Item
                style={{ cursor: 'pointer', padding: '10px 20px', background: item.id === selectedCommunity?.id ? '#F9FAFB' : 'transparent' }}
                onClick={()=>{
                   setSelectOnclick(item);
                   GetSingleCommunity(item);
                   setSelectedItems([]);
                   setSelectAll(false);
                  }
                }
              >
                <List.Item.Meta
                  avatar={<AvatarImage imageId={item?.image} title={item.name} />}
                  title={<Text strong>{item.name}</Text>}
                  description={<Text>{item.userCount} Fans</Text>}
                />
              </List.Item>
            )}
          />
        </>
        : (<>
            <Flex vertical style={{ padding: 20, flex: 1, overflowY: 'auto', borderTop: '1px solid #EAECF0' }}>
              <Title level={3} style={{ marginTop: 0 }}>Suggestions</Title>
              <Flex gap={20} align="center" style={{ border: '1px solid #EAECF0', borderRadius: 12, padding: '17px 20px', cursor: 'pointer' }} onClick={addNewcommunity}>
                <Image
                    width={36}
                    src={UserIcon}
                    preview={false}
                />
                <Title level={5} style={{ margin: 0 }}>Add your first community</Title>
                <RightOutlined />
              </Flex>
            </Flex>
            <List
              itemLayout="horizontal"
              dataSource={communities}
              style={{ borderTop: '1px solid #EAECF0' }}
              renderItem={item => (
                <List.Item
                style={{ cursor: 'pointer', padding: '10px 20px', background: item.name === selectedCommunity?.name ? '#F9FAFB' : 'transparent' }}
                  onClick={() =>{
                    setSelectOnclick(item);
                    GetSingleCommunity(item);
                    setSelectedItems([]);
                    setSelectAll(false);
                  }}
                >
                  <List.Item.Meta
                    avatar={<AvatarImage />}
                    title={<Text strong>{item.name}</Text>}
                    description={<Text>{item.userCount} Fans</Text>}
                  />
                </List.Item>
              )}
            />
          </>)}
      </Sider>
      </Spin>
      
      {selectedNewCommunity && 
        <Drawer
          placement="right"
          onClose={onClose}
          open={opencommunities}
          width="100%"
          extra={null}
          className="new-community-drawer"
        >
          <Content className="new-community">
             <Spin spinning={isLoading}>
              <Flex horizontal="true" className="head" justify="space-between">
                <Title level={4}>New Community</Title>
                <Button
                  type="link"
                  icon={<CloseOutlined style={{ color: '#101828' }}/>}
                  // onClick={handleCommnityClose}
                  onClick={onClose}
                  style={{ alignSelf: 'flex-end' }}
                >
                </Button>
              </Flex>

              <Row>
                <Col xs={{ span: 24, offset: 0 }} md={{ span: 14, offset: 5 }}>
                  <Flex vertical>
                    <Form form={form} layout="vertical" onFinish={onFinish}>
                      <Flex gap="large" vertical style={{ padding: '20px 20px' }}>
                        <Flex vertical className="community-form-grid">
                          <Flex gap="large" horizontal="true">
                            <Flex vertical style={{ flex: 1 }}>
                              <Form.Item
                                name="photo"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                rules={[{ required: false }]}
                                default
                              >
                               
                                <ImageUpload setImageId={setImageId} photoId={photoId} imageUrl={photo}/>
                              </Form.Item>
                              
                            </Flex>
                          </Flex>
                          <Form.Item 
                            className="custom-form-item"
                            name="communityName"
                            label="Community Name"
                            style={{ marginBottom: 0 }}
                            rules={[{ required: true, message: 'Please enter community name' }]}
                            >
                            <Input placeholder="Enter community name" className="custom-form-item" />
                          </Form.Item>
                        </Flex>
                        <Card className="fans-form-grid">
                          <Flex vertical style={{ padding: '25px 24px 0px 24px', borderBottom: '1px solid #EAECF0' }}>
                            <Title level={4}>Fans</Title>
                            <Form.Item label="Fans & Communities" name="fansOption" initialValue={fansOption}>
                              <Radio.Group onChange={handleFansOptionChange}>
                                <Radio value="all">All</Radio>
                                <Radio value="favorites">Favorites</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Flex>
                          <Flex vertical style={{ padding: '20px 24px 0px 24px', borderBottom: '1px solid #EAECF0' }}>
                            <Form.Item name="gender" label="Gender">
                              <Checkbox.Group onChange={handleGenderChange}>
                                <Row gutter={10}>
                                  <Col><Checkbox value="male">Male</Checkbox></Col>
                                  <Col><Checkbox value="female">Female</Checkbox></Col>
                                  <Col><Checkbox value="other">Other</Checkbox></Col>
                                </Row>
                              </Checkbox.Group>
                            </Form.Item>
                          </Flex>
                          <Flex vertical style={{ padding: '20px 24px 10px 24px', borderBottom: '1px solid #EAECF0' }}>
                            <Form.Item name="ageRange" label="Age">
                              <Slider range min={16} max={80} defaultValue={ageRange} onChange={handleAgeChange} tooltip={false} marks={marks} />
                            </Form.Item>
                          </Flex>
                          <Flex vertical style={{ padding: '20px 24px 24px 24px' }}>
                            <Form.Item name="location" label="Location" className="location-input">
                              <AddressAutocomplete />
                            </Form.Item>
                          </Flex>
                        </Card>
                      </Flex>
                      <Flex vertical className="form-footer">
                        <Form.Item>
                          <Button type="default" size="large" onClick={() =>{
                             form.resetFields();
                              onClose();
                            }} style={{ marginRight: '20px', border: 0, fontWeight: 600 }}>Cancel</Button>
                          <Button type="primary" size="large" htmlType="submit" style={{ fontWeight: 600 }}>Create</Button>
                        </Form.Item>
                      </Flex>
                    </Form> 
                  </Flex>
                </Col>
              </Row>
            </Spin>
          </Content>
        </Drawer>
      }

    {editCommunity && !selectedNewCommunity && 
        <Content className="new-community" style={{ backgroundColor: '#F9FAFB' }}>
           <Spin spinning={isLoading}>
            <Flex horizontal="true" className="head" justify="space-between">
              <Title level={4}>Edit Community</Title>
              <Button
                type="link"
                icon={<CloseOutlined style={{ color: '#101828' }}/>}
                onClick={handleCommnityEditClose}
                style={{ alignSelf: 'flex-end' }}
              >
              </Button>
            </Flex>

            <Flex vertical>
           
              <Form name='editCommunity' form={form} id="editCommunity"  initialValues={editCommunity}  layout="vertical" onFinish={updateEdits}>
                <Flex gap="large" vertical style={{ padding: '20px 30px' }}>
                  <Card className="community-form-grid">
                    <Title level={4}>Community Details</Title>
                    <Flex gap="large" horizontal="true">
                    
                      <Flex vertical style={{ flex: 1 }}>
                        <Form.Item
                          label="Avatar"
                          name="photo"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          rules={[{ required: false }]}
                          default
                        >

                          <ImageUpload setImageId={setImageId} photoId={photoId} imageUrl={photo}/>
                          {console.log("setImageId=>setImageId",imageId,"photo",photo)}
                        </Form.Item>
                      </Flex>
                    </Flex>
                    <Form.Item className="community-field" name="name" label="Community Name" rules={[{ required: true, message: 'Please enter community name' }]}>
                      <Input placeholder="Enter community name" className="custom-form-item" />
                    </Form.Item>
                  </Card>
                  <Card className="fans-form-grid">
                    <Flex vertical style={{ padding: '25px 24px 0px 24px', borderBottom: '1px solid #EAECF0' }}>
                      <Title level={4}>Fans</Title>
                      <Form.Item label="Fans & Communities" name="fansOption" initialValue={fansOption}>
                        <Radio.Group onChange={handleFansOptionChange}>
                          <Radio value="all">All</Radio>
                          <Radio value="favorites">Favorites</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Flex>
                    <Flex vertical style={{ padding: '20px 24px 0px 24px', borderBottom: '1px solid #EAECF0' }}>
                      <Form.Item name="gender" label="Gender">
                        <Checkbox.Group onChange={handleGenderChange}>
                          <Row gutter={10}>
                            <Col><Checkbox value="male">Male</Checkbox></Col>
                            <Col><Checkbox value="female">Female</Checkbox></Col>
                            <Col><Checkbox value="other">Other</Checkbox></Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </Flex>
                    <Flex vertical style={{ padding: '20px 24px 10px 24px', borderBottom: '1px solid #EAECF0' }}>
                      <Form.Item name="ageRange" label="Age">
                        <Slider range min={16} max={80} defaultValue={ageRange} onChange={handleAgeChange} tooltip={false} marks={marks} />
                      </Form.Item>
                    </Flex>
                    <Flex vertical style={{ padding: '20px 24px 20px 24px' }}>
                      <Form.Item name="location" label="Location" className="location-input">
                         <AddressAutocomplete/>
                      </Form.Item>
                    </Flex>
                  </Card>
                </Flex>
                <Flex vertical className="form-footer">
                  <Form.Item>
                    <Button type="default" size="large" onClick={() => form.resetFields()} style={{ marginRight: '20px', border: 0, fontWeight: 600 }}>Cancel</Button>
                    <Button type="primary" size="large" htmlType="submit" style={{ fontWeight: 600 }}>Save Edits</Button>
                  </Form.Item>
                </Flex>
              </Form> 
            </Flex>
            </Spin>
        </Content>
      }

      {selectedCommunity ? (
        <Content style={selectedCommunityStyles}>
            <Flex horizontal="true" className="communities-head" align="start" justify="space-between" style={ isMobile && { flexDirection: 'column' }}>
              {isMobile && 
                <Button
                  type="text"
                  icon={<ArrowLeftOutlined style={{ color: '#101828' }}/>}
                  onClick={handleClose}
                  style={{ padding: 0, fontWeight: 600, marginBottom: 10, background: 'transparent' }}
                >
                Back
                </Button>
              }
              <Flex gap={20} horizontal="true" style={ isMobile && { marginBottom: 15, width: '100%' }}>
                {(selectedCommunity.name !== 'All Audience' && selectedCommunity.name !== 'Favorite Audience') &&
                  <AvatarImage imageId={selectedCommunity?.image} title={selectedCommunity.name} />
                }
                <Flex vertical>
                  <Title level={3}>{selectedCommunity.name}</Title>
                  <Text className="count">{selectedCommunity.userCount} Fans</Text>
                </Flex>

                {isMobile &&<>
                  {(selectedCommunity.name !== 'All Audience' && selectedCommunity.name !== 'Favorite Audience') &&
                    <Dropdown overlay={communityitems} autoFocus={true} trigger={['click']}>
                      <a onClick={(e) => e.preventDefault()} style={{ marginLeft: 'auto' }}>
                        <Image src={EllipsisIcon} width={20} preview={false} />
                      </a>
                    </Dropdown>
                  }
                </>}
              </Flex>
              <Flex gap={15} horizontal="true" align="start">
                {selectedItems.length>0 &&
                  <Button type="primary" size="medium" onClick={handleSendMessage} style={{ fontWeight: 600, paddingTop: 7, paddingBottom: 7, height: 'auto' }}>Send Message</Button>
                }
                {isMobile ? 
                  ""
                :<>
                  {(selectedCommunity.name !== 'All Audience' && selectedCommunity.name !== 'Favorite Audience') &&
                    <Dropdown overlay={communityitems} autoFocus={true} trigger={['click']}>
                      <a onClick={(e) => e.preventDefault()}>
                        <Image src={EllipsisIcon} width={20} preview={false} />
                      </a>
                    </Dropdown>
                  }
                </>}
              </Flex>
            </Flex>

            {selectedCommunity.name !== 'All Audience' &&
              <Tabs
                className="community-tab"
                activeKey={activeTab}
                onChange={handleTabChange}
              >
                <Tabs.TabPane tab="Messages" key="Messages" />
                <Tabs.TabPane tab="Fans" key="Fans" />
              </Tabs>
            }

          {selectedCommunity.name !== 'All Audience' ? (<>
          {activeTab === 'Messages' && <>

            <Flex horizontal gap={14} style={ isMobile ? { padding: '0px 15px 10px 15px' } : { padding: '0px 20px 5px 20px', borderBottom: '1px solid #EAECF0' }}>
              <Input
                placeholder="Search messages"
                onChange={e => searchFans(e)}
                prefix={<SearchOutlined />}
                style={{ marginBottom: '10px' }}
                className="custom-form-search-item"
              />
            </Flex>

            <Flex vertical style={ isMobile ? { padding: '0px 15px 10px 15px', flex: 1, overflowY: 'auto' } : { padding: '0px 20px 10px 20px', flex: 1, overflowY: 'auto' }}>
              <Spin spinning={isLoading}>
                <List
                  itemLayout="horizontal"
                  dataSource={selectedCommunity?.Users}
                  className="communities-list"
                  renderItem={item => (
                    <List.Item
                      style={{ 
                        cursor: 'pointer', 
                        padding: '15px 0', 
                        background: item.id === selectedFan?.id ? 'transparent' : 'transparent' 
                      }}
                    >
                      <List.Item.Meta
                        avatar={<AvatarImage imageId={item?.image} title={`${item?.first_name} ${item?.last_name}`} />}
                        title={<Text strong>{item.first_name} {item.last_name}</Text>}
                        description={<Text>{item.gender}</Text>}
                        onClick={() => handleSelectMessage(item)}
                      />
                      <Flex gap={20}>
                       <Button
                          type="link"
                          icon={item?.hasFavorites?<StarFilled style={{ fontSize: 20,color: '#FAC515' }} />:<StarOutlined style={{ fontSize: 20 }} />}
                          style={{ color: '#475467', padding: 0 }}
                          onClick={()=>{
                            addRemovefavorite(item);
                          }}
                        >
                        </Button>
                      </Flex>
                    </List.Item>
                  )}
                />
              </Spin>
            </Flex>
          </>}

          {activeTab === 'Fans' && <>

            <Flex horizontal gap={14} style={ isMobile ? { padding: '0px 15px 10px 15px' } : { padding: '0px 20px 15px 20px' }}>
              <Input
                placeholder="Search fans"
                onChange={e => searchFans(e)}
                prefix={<SearchOutlined />}
                style={{ marginBottom: '10px' }}
                className="custom-form-search-item"
              />
              {/*<Button type="primary" size="large" onClick={addNewcommunity} icon={<PlusOutlined />} style={{ float: 'right', height: '44px', fontWeight: 600 }}>Add Fans</Button>*/}
            </Flex>

            <Flex justify="space-between" align="center" style={{ padding: '0px 20px 8px 20px', borderBottom: '1px solid #EAECF0' }}>
                <Flex>
                  <Checkbox
                    onChange={handleSelectAllChange}
                    checked={selectAll}
                  >
                    Select All ({selectedItems.length})
                  </Checkbox>
                </Flex>

                <Space size="middle">
                  <Dropdown
                    menu={{
                      items: sortItems,
                      onClick: handleSortClick,
                      selectable: true,
                      selectedKeys: sortOption ? [sortOption] : []
                    }}
                    trigger={['click']}
                    placement="bottomRight"
                  >
                    <Button size="large" className="flex items-center" style={{ fontWeight: 600 }}>
                      Sort by
                      <DownOutlined className="mr-2" />
                    </Button>
                  </Dropdown>

                  {/*<Button 
                    className="flex items-center"
                    size="large"
                    icon={<Image src={FilterIcon} preview={false} />}
                    style={{ fontWeight: 600 }}
                  >
                    Filters
                  </Button>*/}
                </Space>
              </Flex>

            <Flex vertical style={ isMobile ? { padding: '0px 15px 10px 15px', flex: 1, overflowY: 'auto' } : { padding: '0px 20px 10px 20px', flex: 1, overflowY: 'auto' }}>
              <Spin spinning={isLoading}>
                <List
                  itemLayout="horizontal"
                  dataSource={selectedCommunity?.Users}
                  className="communities-list"
                  renderItem={item => (
                    <List.Item
                      style={{ 
                        cursor: 'pointer', 
                        padding: '15px 0', 
                        background: item.id === selectedFan?.id ? 'transparent' : 'transparent' 
                      }}
                    >
                      <Checkbox
                        checked={selectedItems.includes(item)}
                        onChange={(e) => handleItemChange(item, e)} 
                        style={{ marginRight: 15 }} 
                      />

                      <List.Item.Meta
                        avatar={<AvatarImage imageId={item?.image} title={`${item?.first_name} ${item?.last_name}`} />}
                        title={<Text strong>{item.first_name} {item.last_name}</Text>}
                        description={<Text>{item.gender && (<span>{item.gender} | </span>)}{calculateAge(item?.dob)}  {item.city && (<span>| {item.city}</span>)} </Text>}
                        onClick={() =>{
                          setSelectedFan(true);
                          const updatedObject = {
                            ...item,
                            user_phone: item.phone_number
                        };
                          setSelectedUser(updatedObject);
                        }}
                      />
                      <Flex gap={20}>
                       <MinusCircleOutlined onClick={()=>{removeUserFromCommunity(item?.id)}} style={{ fontSize: 20, color: '#B42318' }} />
                       <Button
                          type="link"
                          icon={item?.hasFavorites?<StarFilled style={{ fontSize: 20,color: '#FAC515' }} />:<StarOutlined style={{ fontSize: 20 }} />}
                          style={{ color: '#475467', padding: 0 }}
                          onClick={()=>{
                            addRemovefavorite(item);
                          }}
                        >
                        </Button>
                      </Flex>
                    </List.Item>
                  )}
                />
              </Spin>
            </Flex>
          </>}
          </>) : (<>
            <Flex horizontal gap={14} style={ isMobile ? { padding: '0px 15px 10px 15px' } : { padding: '0px 20px 15px 20px' }}>
              <Input
                placeholder="Search fans"
                onChange={e => searchFans(e)}
                prefix={<SearchOutlined />}
                style={{ marginBottom: '10px' }}
                className="custom-form-search-item"
              />
             
            </Flex>

            <Flex justify="space-between" align="center" style={{ padding: '0px 20px 8px 20px', borderBottom: '1px solid #EAECF0' }}>
                <Flex>
                  <Checkbox
                    onChange={handleSelectAllChange}
                    checked={selectAll}
                  >
                    Select All ({selectedItems.length})
                  </Checkbox>
                </Flex>

                <Space size="middle">
                  <Dropdown
                    menu={{
                      items: sortItems,
                      onClick: handleSortClick,
                      selectable: true,
                      selectedKeys: sortOption ? [sortOption] : []
                    }}
                    trigger={['click']}
                    placement="bottomRight"
                  >
                    <Button size="large" className="flex items-center" style={{ fontWeight: 600 }}>
                      Sort by
                      <DownOutlined className="mr-2" />
                    </Button>
                  </Dropdown>

                  {/*<Button 
                    className="flex items-center"
                    size="large"
                    icon={<Image src={FilterIcon} preview={false} />}
                    style={{ fontWeight: 600 }}
                  >
                    Filters
                  </Button>*/}
                </Space>
              </Flex>

            <Flex vertical style={ isMobile ? { padding: '0px 15px 10px 15px', flex: 1, overflowY: 'auto' } : { padding: '0px 20px 10px 20px', flex: 1, overflowY: 'auto' }}>
              <Spin spinning={isLoading}>
                <List
                  itemLayout="horizontal"
                  dataSource={selectedCommunity?.Users}
                  className="communities-list"
                  renderItem={item => (
                    <List.Item
                      style={{ 
                        cursor: 'pointer', 
                        padding: '15px 0', 
                        background: item.id === selectedFan?.id ? 'transparent' : 'transparent' 
                      }}
                    >
                      <Checkbox
                        checked={selectedItems.includes(item)}
                        onChange={(e) => handleItemChange(item, e)} 
                        style={{ marginRight: 15 }} 
                      />

                      <List.Item.Meta
                        avatar={<AvatarImage imageId={item?.image} title={`${item?.first_name} ${item?.last_name}`} />}
                        title={<Text strong>{item.first_name} {item.last_name}</Text>}
                        description={<Text>{item.gender && (<span>{item.gender} | </span>)}{calculateAge(item?.dob)}  {item.city && (<span>| {item.city}</span>)} </Text>}
                        onClick={() =>{
                          setSelectedFan(true);
                          const updatedObject = {
                            ...item,
                            user_phone: item.phone_number
                        };
                          setSelectedUser(updatedObject);
                        }}
                      />
                      <Flex gap={20}>
                      
                       <Button
                          type="link"
                          icon={item?.hasFavorites?<StarFilled style={{ fontSize: 20,color: '#FAC515' }} />:<StarOutlined style={{ fontSize: 20 }} />}
                          style={{ color: '#475467', padding: 0 }}
                          onClick={()=>{
                            addRemovefavorite(item);
                          }}
                        >
                        </Button>
                      </Flex>
                    </List.Item>
                  )}
                />
              </Spin>
            </Flex>
          </>)}
        </Content>
      ) : (
          ''
      )}

      {openSendMessage &&
        <SendMessage
          selectedItems={selectedItems} 
          setSelectedItems={setSelectedItems} 
          openSendMessage={openSendMessage} 
          handleCloseMessage={handleCloseMessage} 
          setSelectAll={setSelectAll}
          handleCancel={handleNewMessageClose}
          onClose={onClose} 
          open={open}
        /> 
      }
      
      {selectedFan &&
        <SidebarFan
          selectedUser={selectedUser} 
          selectedFan={selectedFan} 
          setSelectedFan={setSelectedFan} 
          setIsLoading={setIsLoading} 
          setIsReload={setIsReload} 
          selectedCommunity={selectedCommunity}
        />
      }

      {selectedMessage &&
        <Modal
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
          width={800}
          className="select-message-modal"
        >
          <SelectMessage selectedMessage={selectedMessage} isHistoryLoading={isHistoryLoading} chatHistory={chatHistory} setChatHistory={setChatHistory} />
        </Modal>
      }

    </Layout>
  );
};

export default Communities;
