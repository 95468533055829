import React, { useEffect, useState } from 'react';
import { Flex, Button, Checkbox, Typography } from 'antd';

const { Title } = Typography;

const MessageFilter = ({ setFilters, filters, onApplyFilters, filtersData }) => {
  useEffect(() => {
      console.log("filtersData in Child:", filtersData);
    }, [filtersData]);
  
  const [activeFilters, setActiveFilters] = useState([]);

  const handleFilterChange = (filterId, checked) => {
    setFilters(prevFilters => 
      prevFilters.map(filter => 
        filter.id === filterId ? { ...filter, checked } : filter
      )
    );
  };

  const clearAllFilters = () => {
    setFilters(prevFilters => 
      prevFilters.map(filter => ({ ...filter, checked: false }))
    );
    setActiveFilters([]);
    if (onApplyFilters) {
      onApplyFilters([]);
    }
  };

  const applyFilters = () => {
    const newActiveFilters = filters.filter(filter => filter.checked);
    setActiveFilters(newActiveFilters);
    if (onApplyFilters) {
      onApplyFilters(newActiveFilters);
    }
  };

  useEffect(()=>{
   // setActiveFilters(selectedFilters);
   console.log("selectedFilters",filtersData);
  },[filtersData]);

  

  // Filter section component
  const FilterSection = ({ title, group }) => (
    <Flex vertical>
      <Title level={5} style={{
        margin: 0,
        fontSize: '14px',
        color: '#344054',
        padding: '10px 15px',
        borderBottom: '1px solid #EAECF0'
      }}>
        {title}
      </Title>
      <Flex vertical gap={10} style={{ padding: '10px 15px' }}>
        {filters
          .filter(filter => filter.group === group)
          .map(filter => (
            <Checkbox
              key={filter.id}
              checked={filter.checked}
              onChange={(e) => handleFilterChange(filter.id, e.target.checked)}
            >
              {filter.label}
            </Checkbox>
          ))}
      </Flex>
    </Flex>
  );

  return (
    <Flex vertical style={{
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      width: '320px'
    }}>
     
      <FilterSection title="Filter Messages" group="messages" />
      <FilterSection title="Last Direct Message" group="directMessage" />
      <FilterSection title="Communities" group="communities" />

      <Flex 
        className="filter-menu-footer" 
        horizontal 
        justify="space-between"
        style={{ 
          padding: '15px 20px', 
          borderTop: '1px solid #EAECF0' 
        }}
      >
        <Button 
          onClick={clearAllFilters} 
          size="large" 
          style={{ fontWeight: 600 }}
          disabled={activeFilters.length === 0}
        >
          Clear all
        </Button>
        <Button 
          type="primary" 
          onClick={applyFilters} 
          size="large" 
          style={{ fontWeight: 600 }}
          disabled={filters.filter(f => f.checked).length === 0}
        >
          Apply
        </Button>
      </Flex>
    </Flex>
  );
};

export default MessageFilter;