import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Layout, Typography, Button, Input, Flex, Image,Spin,message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import ForgotIcon from '../../images/forgot-icon.svg';
import { post } from '../../utils/api';
import useScreenSize from '../../utils/useScreenSize';

const { Title, Paragraph } = Typography;

const { Content } = Layout;

const ForgotPassword = () => {
  const { isMobile } = useScreenSize();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleSubmit =async (values) => {
    const currentStepData = await form.validateFields();
    if(currentStepData){
      setLoading(true);
      setLoading(true);
      try {
        const response = await post('auth/forgot-password', values);
        if (response && response.status === 200) {
           navigate('/email-submitted',{ state: {values}});
        } else {
          message.error(response.message || 'Login failed');
        }

      }catch (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
        } else {
          message.error(error.message);
        }
      }finally {
        setLoading(false);
      }
    }
  };

  const emailRules = [
    { required: true, message: 'Please input your email!' },
    {
      type: 'email',
      message: 'The email provided is not in a valid format.',
    },
  ];

  return (
    <Spin size="small" spinning={loading}>
      <Content style={{ paddingTop: isMobile ? '40px' : ''}}>
        <Flex vertical style={{ maxWidth: '416px', margin: '0 auto' }}>
          <Form form={form} onFinish={handleSubmit}>
              <Flex
                vertical
                align="center"
                style={{
                  width: '100%',
                }}
              >
                <Image
                    width={60}
                    src={ForgotIcon}
                    preview={false}
                />
              </Flex>

              <Title level={2} style={{ textAlign: 'center', marginTop: isMobile ? '15px' : '30px', marginBottom: '15px' }}>Forgot password?</Title>
              <Paragraph style={{ textAlign: 'center', fontSize: '16px' }}>No worries, we’ll send you reset instructions.</Paragraph>

              <Form.Item
                name="email"
                label="Email"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={emailRules}
                className="custom-form-item"
              >
                <Input placeholder="Enter your email" style={{ padding: '7px 10px' }} />
              </Form.Item>

                <Button type="primary" htmlType="submit" size="large" block style={{ fontWeight: 600, marginTop: 10 }}>
                  Reset password
                </Button>
                <Button type="text" icon={<ArrowLeftOutlined />} onClick={()=>{ navigate('/')}} size="large" block style={{ fontWeight: 600, marginTop: '20px' }}>
                    Back to log in
                </Button>
            </Form>
          </Flex>
      </Content>
    </Spin>
  );
};

export default ForgotPassword;