import React, {useEffect,useState,useMemo,useContext,useRef } from 'react';
import { Layout, Button, Input, List, Typography, Avatar, Flex, Form, Checkbox, Modal, Divider, Spin, Dropdown, Image } from 'antd';
import { SearchOutlined, EditOutlined, ArrowLeftOutlined, ExclamationCircleFilled, RightOutlined, FilterOutlined, CloseOutlined } from '@ant-design/icons';
import { get,post } from '../../../utils/api';
import { Link } from 'react-router-dom';
import useScreenSize from '../../../utils/useScreenSize';
import NewMessage from '../../../components/GlobalComponents/NewMessage';
import ReplyMessage from '../../../components/Message/Audience/ReplyMessage';
import AvatarImage from '../../../components/AvatarImage';
import TimeAgo from '../../../components/TimeAgo';
import socket from '../../../services/socket';
import { UserContext } from '../../../contexts/UserContext';
import { cleanPhoneNumber,processHtmlContent,calculateAge,openNotification } from '../../../utils/utils';
import SidebarFan from '../../Communities/SidebarFan';
import ChatInputNewMessage from '../../../components/GlobalComponents/ChatInputNewMessage';
import moment from 'moment';
import '../message.css';
import ArrowRight from "../../../images/arrow-right.svg";
import UnlockIcon from "../../../images/lock-04.svg";
import GroupUserIcon from "../../../images/user-icon-white.svg";
import hand from "../../../images/hand.svg"
import EditIconWhite from "../../../images/edit-icon-white.svg"

import PrintMessage from '../../../components/GlobalComponents/PrintMessage';
import MessageFilter from '../../../components/Message/Audience/MessageFilter';
import AudioRecorder from '../../../components/GlobalComponents/AudioRecorder';

const { Title, Text } = Typography;
const { Sider, Content } = Layout;
const { Search } = Input;
const { confirm } = Modal;

const Audience = () => {
  const [form] = Form.useForm();
  const { isMobile, isTablet } = useScreenSize();
  const [isNewMessageVisible, setIsNewMessageVisible] = useState(false);  
  const [isLoading, setIsLoading] = useState(false);  
  const [searchText, setSearchText] = useState('');  
  const [isReplyMessageVisible, setIsReplyMessageVisible] = useState(false);  
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [messagesData,setMessagesData] = useState([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);  
  const [isMessageSending, setIsMessageSendingg] = useState(false);  
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const {user}  = useContext(UserContext);
  const [selectedFan, setSelectedFan] = useState(false);
  const [message, setMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesEndRef = useRef(null);
  const [setPrice, setInlinePrice] = useState(true);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [setSendButton, setInlineButton] = useState(true);
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState([]);
  const [userInfo,setUserInfo]= useState(null);
  const [chatImages,setChatImages]=useState([]);
  const [communitiesList,setCommunitiesList]=useState([]);
  const [basePrice,setBasePrice]=useState(0.99);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [open, setOpen] = useState(false);
  const [audioEnable] = useState(true);

  const chatInputRef = useRef();


  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filters, setFilters] = useState([
    { id: "read", label: "Read", checked: false, group: "messages" },
    { id: "unread", label: "Unread", checked: false, group: "messages" },
    { id: "inbound", label: "Inbound", checked: false, group: "directMessage" },
    { id: "outbound", label: "Outbound", checked: false, group: "directMessage" },
  ]);


  useEffect(() => {
    // Get the current URL and its query parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Check if the 'unsubscribe' query parameter exists in the URL
    if (urlParams.has('unsubscribe')) {
      openNotification('Fan was successfully unsubscribed');
      const currentUrl = window.location.origin + window.location.pathname;
      window.history.replaceState({}, '', currentUrl);
    }
  }, []);
 

  const loadCommunities = async (current, pageSize) => {
    setCommunitiesList([]);
    setIsLoading(true);
    try {
      const response = await get('creator/communities/');
      if(response){
        const formattedData = response.map(item => ({
          value: item.id,
          label: item.name
      }));
      formattedData.unshift({value: 'Favorites',label:'Favorites'});
       setCommunitiesList(formattedData);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const load = async () => {
      await loadCommunities(1, 10);
    };
    load();
  }, []); 

  useEffect(() => {
    setFilters((prevFilters) => [
      ...prevFilters.filter((filter) => filter.group !== "communities"),
      ...communitiesList.map((community) => ({
        id: community.value,
        label: community.label,
        checked: false,
        group: "communities",
      })),
    ]);
  }, [communitiesList]);

  const initSettings = async (id) => {
    try {
      const response = await get(`/creator/settings/${id}`);
      if (response && response.data) {
        const {basePrice}=response.data;
        if(basePrice){
          setBasePrice(basePrice);
        }
      }
    } catch (error) {
      console.log('error', error);
    } 
  };
  useEffect(()=>{
    initSettings(user?.id);
  },[user]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const fetchData = async (params = {}) => {
    const response = await get(`creator/inbox`,{
      _page: params.pagination?.current || 1,
      _limit: params.pagination?.pageSize || 10,
      q: params?.searchTerm ||'',
      filters: JSON.stringify(params?.filters || []),
    });
   
   
    if (response && response.data) {
      setMessagesData(response.data);
      setPagination({
        ...params.pagination,
        total: response.pagination.total,
      });
    }
  };
  const handleInputChange = (value) => {
    setMessage(value);
  };

  const handleClose = () => {
    setSelectedMessage(false);
  };



  useEffect(() => {
    if(selectedMessage){
      fetchData({searchTerm:searchText}); 
    }else{
      fetchData(); 
    }
    const handleNewMessage =async (message) => {
      if (message && message.status) {
        const { output } = message;
        
        if (cleanPhoneNumber(output?.creator_phone) === cleanPhoneNumber(user?.assign_number)) {
          setMessagesData(prevData => updateOrAppendMessage(prevData, output));
          if(selectedMessage && output?.user_phone && selectedMessage?.user_phone==output?.user_phone){
            const response = await post(`creator/chat`,{
              phn:output?.user_phone
            });
            
            if(response && response.status){
              setChatHistory(response?.data.reverse());
            }
          }
        }
      }
    };
    socket.on('messageInbox', handleNewMessage);
    return () => {
      socket.off('messageInbox', handleNewMessage);
    };
  }, [user,selectedMessage]);

  const updateOrAppendMessage = (data, newMessage) => {
    const index = data.findIndex(item => cleanPhoneNumber(item.user_phone) === cleanPhoneNumber(newMessage.user_phone));
    if (index !== -1) {
      data[index] = {
        ...data[index],
        last_message: newMessage.last_message,
        updatedAt: new Date().toISOString()
      };
    } else {
      data.push(newMessage);
    }
    data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    return [...data];
  };

  const handleSearch = (value) => {
    const searchvalue=value.target.value;
    setSelectedMessage(false);
    setSearchText(searchvalue);
    fetchData({searchTerm:searchvalue});
  };

  const showNewMessage = () => {
    setIsNewMessageVisible(true);
    setSelectedMessage(false);
    setIsReplyMessageVisible(false);
    setSelectedFan(false);
    setOpen(true);
  };

  const handleNewMessageClose = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      icon: <ExclamationCircleFilled style={{ fontSize: 35, color: '#7F56D9', marginBottom: 10 }} />,
      content: 'This message will be closed.',
      okText: 'Leave',
      okButtonProps: {
        style: {
          backgroundColor: '#6941C6',
          borderColor: '#6941C6',
          fontWeight: 'bold',
          color: 'white'       
        },
        className: 'custom-leave-button'
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'white',
          borderColor: '#d9d9d9',
          fontWeight: 'bold',
          color: '#475467'
        },
        className: 'custom-cancel-button'
      },
      okType: 'danger',
      onOk() {
        setIsNewMessageVisible(false);
        fetchData();
        setOpen(false);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleReplyMessageClose = async() => {
    setIsReplyMessageVisible(false);
    if(selectedMessage){
      await handleSelectMessage(selectedMessage);
    }
  };  

  const handleSelectMessage = async(item) => {
      console.log(item, 'item')
     setSelectedMessage(item);
     setIsNewMessageVisible(false);
     setSelectedFan(false);
     setIsHistoryLoading(true);
     setChatImages([]);

     if (chatInputRef.current) {
      chatInputRef.current.resetAllStates();
    }

     if(item && item?.type==="individual"){
            try{
              const getUserInfo = await post(`creator/getUserInfo`, {
                phone: item?.user_phone
              });
              if (getUserInfo && getUserInfo.status) {
                setUserInfo(getUserInfo.data);
              } else {
                console.error('Information not available!');
              }

                const response = await post(`creator/chat`,{
                  phn:item?.user_phone
                });
                if(response && response.status){
                  setChatHistory(response?.data.reverse());
                }
              }catch(error){
                console.log("loading......");
              } finally {
                setIsHistoryLoading(false);
            }
     }else{
      try{
        const response = await post(`creator/groupMessage`,{
            grpId:item?.id
          });
            if(response && response.status){
                const {groupLogs}=response?.data||{};
              setChatHistory(groupLogs.reverse());
            }
          }catch(error){
            console.log("loading......");
          } finally {
            setIsHistoryLoading(false);
        }
     } 
  };

  const getRealPath = async (file ) => {
    try {
      const formData = new FormData();
      formData.append('singleImage', file);      
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/single-upload`, {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        return false;
      }
      const data = await response.json();
     return data;
    } catch (error) {
      console.error('Upload failed:', error);
     return false;
    }
  };

  const sendMessageHandler = async (values) => {
    setIsMessageSendingg(true);

    try {
      let mediaPath=null;
      if(chatImages.length>0){
        const FileUrl=chatImages[0] || null;
        const fileType=chatImages[0]?.type || null;
        const source=chatImages[0]?.source || null;
        
        if(fileType=="gif"){
            mediaPath=FileUrl?.file;
        }else if(source=="vault"){
          mediaPath=FileUrl?.thumbUrl;
        }else{
          const urlId=await getRealPath(FileUrl?.file);
          if(urlId && urlId?.image){
            mediaPath=urlId?.image?.filepath;
          } 
        }
      }
      const pricesend=(setPrice)?selectedPrice:'';
      const completeFormData = { ...values, phone_number: selectedMessage.user_phone,message,price:pricesend,mediaPath:mediaPath};
    
      const response = await post(`creator/sendMessageByItem`, completeFormData);
      if (response && response.status) {
        form.resetFields();
        const UpdateData = [...chatHistory, ...response?.data];
        
        setChatHistory(UpdateData);
        setMessage('');
      }
    } catch (error) {
      console.log("loading......");
    } finally {
      setIsMessageSendingg(false);
    }
  };

  const formatDate = (date) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const messageDate = moment(date).startOf('day');

    if (messageDate.isSame(today)) return 'Today';
    if (messageDate.isSame(yesterday)) return 'Yesterday';
    return messageDate.format('MMMM D, YYYY');
  };

  const shouldShowDate = (index, date) => {
    if (index === 0) return true;
    const prevDate = moment(chatHistory[index - 1].updatedAt).startOf('day');
    const currDate = moment(date).startOf('day');
    return !prevDate.isSame(currDate);
  };

  const getUserDetails=async()=>{
    setSelectedFan(true);
  }

  const handleCheckboxChange = (event, value) => {
    if (event.target.checked) {
      setSelectedPhoneNumbers([...selectedPhoneNumbers, value]);
    } else {
      setSelectedPhoneNumbers(selectedPhoneNumbers.filter(item => item !== value));
    }
  };

  const layoutStyles = useMemo(() => ({
    backgroundColor: '#ffffff', 
    borderRight: '1px solid #EAECF0', 
    height: isMobile ? 'calc(100vh - 70px)' : '100vh', 
    display: 'flex', 
    flexDirection: 'column',
  }), [isMobile]);

  const selectedMessageStyles = useMemo(() => 
    isMobile ? {
      backgroundColor: '#ffffff', 
      position: 'absolute', 
      width: '100%', 
      left: 0,
      height: 'calc(100vh - 70px)',
      display: 'flex', 
      flexDirection: 'column',
      zIndex: '1'
    } : { 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column' 
    }
  , [isMobile]);

  const filterStyles = useMemo(() => ({
    backgroundColor: '#ffffff', 
    border: '1px solid #EAECF0',
    borderRadius: '10px',
    width: '330px',
    boxShadow: '0px 24px 48px -12px rgb(16 24 40 / 18%)'
  }));

  const replyToAll=async()=>{
    setIsReplyMessageVisible(true);
    console.log("selectedMessage",selectedPhoneNumbers);
  }

  const handleApplyFilters = async(activeFilters) => {
    setSelectedFilters(activeFilters);
    setDropdownVisible(false);
    await fetchData({filters:activeFilters});
  };

  const removeFilter = async(filterId) => {
     // Remove the filter from selectedFilters
  const updatedSelectedFilters = selectedFilters.filter(filter => filter.id !== filterId);
  
  // Update selectedFilters state with the new list
  setSelectedFilters(updatedSelectedFilters);

  // Update filters state to set the filter as unchecked
  setFilters((prevFilters) =>
    prevFilters.map((filter) =>
      filter.id === filterId ? { ...filter, checked: false } : filter
    )
  );

  // Fetch new data with the updated filters
  await fetchData({ filters: updatedSelectedFilters });
  };
  const filterMenu = (
    <Flex vertical style={filterStyles}>
        <MessageFilter 
            communitiesList={communitiesList}
            onApplyFilters={handleApplyFilters}
            filtersData={selectedFilters}  // Add this prop
            setFilters={setFilters}
            filters={filters}
          />
         
    </Flex>
  );

  const onClose = () => {
    setIsNewMessageVisible(false);
    fetchData();
    setOpen(false);
  };

  return (
    <Layout style={ isMobile ? "" : {  }} className="message-screen">
      {isNewMessageVisible != true &&
      <Sider width={isMobile ? '100%' : isTablet ? 320 : 370} style={layoutStyles}>
        <div className="head-area" style={{ padding: '20px' }}>
          <Title level={2}>Messages</Title>
        </div>

        {/*<Flex  className="message-tabs">
          <Flex className="ant-tabs-nav">
            <Flex className="ant-tabs-nav-wrap">
              <Flex className="ant-tabs-nav-list">
                <Flex className="ant-tabs-tab ant-tabs-tab-active">
                  <Link to="/message/audience"><Text>Audience</Text></Link>
                </Flex>
                <Flex className="ant-tabs-tab">
                  <Link to="/message/network"><Text>Network</Text></Link>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>*/}

        <Flex vertical style={{ paddingLeft: 20, paddingRight: 20, borderBottom: '1px solid #EAECF0' }}>
          <Button size="large" type="primary" style={{ textAlign: 'center', fontWeight: '600' }} icon={<Image src={EditIconWhite} preview={false} />} onClick={showNewMessage}>New Message</Button>
          <Flex horizontal className="search-with-filter" gap={12} style={{ marginTop: 15, marginBottom: 15 }}>
            <Form.Item 
              labelCol={{ span: 24 }}
              style={{ marginBottom: 0 }}
              wrapperCol={{ span: 24 }}>
              <Search 
                className="custom-form-search-item search-no-icon"
                placeholder="Search messages" 
                onChange={handleSearch}
                prefix={<SearchOutlined />} 
                style={{ marginBottom: 0 }} 
                allowClear
              />
            </Form.Item>
            <Dropdown overlay={filterMenu} trigger={['click']} open={dropdownVisible} onOpenChange={setDropdownVisible}>
              <Flex className="filter-button">
                <Button style={{ height: '40px', color: '#344054', fontWeight: 600 }}>
                  Filter
                </Button>
              </Flex>
            </Dropdown>
          </Flex>

          <Flex horizontal gap={10} style={{ marginBottom: 15, flexWrap: 'wrap' }}>
            {selectedFilters.map((filter) => (
              <Flex
                key={filter.id}
                align="center"
                style={{ 
                  backgroundColor: '#F9FAFB', 
                  padding: '4px 10px', 
                  border: '1px solid #EAECF0', 
                  borderRadius: '50px', 
                  color: '#344054',
                  fontSize: '14px'
                }}
              >
                {filter.label}
                <Button
                  onClick={() => removeFilter(filter.id)}
                  type="text"
                  style={{ 
                    padding: 0, 
                    height: 'auto', 
                    marginLeft: 4, 
                    color: '#98A2B3', 
                    backgroundColor: 'transparent',
                    border: 'none',
                    boxShadow: 'none'
                  }}
                >
                  <CloseOutlined style={{ fontSize: 12 }} />
                </Button>
              </Flex>
            ))}
          </Flex>

        </Flex>
        
        {messagesData && messagesData.length>0 ?(
          <List
            itemLayout="horizontal"
            dataSource={messagesData}
            className="message-data"
            style={{ flex: 1, overflowY: 'auto', scrollbarWidth: 'thin', scrollbarolCor: 'unset' }}
            renderItem={(item) => (
            <List.Item onClick={() => handleSelectMessage(item)} style={{ cursor: 'pointer', background: item.id === selectedMessage?.id ? '#F9FAFB' : 'transparent', paddingLeft: 20, paddingRight: 20 }}>
              <List.Item.Meta
                className={(item?.is_read)?'readmsg':'uneadmsg'}
                avatar={item.type == 'group' ? <Avatar size={48} style={{ backgroundColor: '#98A2B3', padding: 10 }} src={<img src={GroupUserIcon} />} /> : <span><AvatarImage imageId={item.image} title={item.title}/></span>}
                title={
                  <Flex horizontal="true">
                    <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{item.title}</span> <span style={{ color: '#475467', marginLeft: 'auto', fontSize: '14px', fontWeight: 'normal', minWidth: '120px', textAlign: 'right' }}><TimeAgo timestamp={item.updatedAt}/></span>
                  </Flex>
                }
                // description={<div dangerouslySetInnerHTML={{ __html:item.last_message}}/> }
                description={processHtmlContent(item?.last_message)}
              />
            </List.Item>
          )}
        />):<>
          <Flex vertical style={{ padding: 20, flex: 1, overflowY: 'auto', borderTop: '1px solid #EAECF0' }}>
            <Title level={3} style={{ marginTop: 0 }}>Suggestions</Title>
            <Flex gap={20} align="center" style={{ border: '1px solid #EAECF0', borderRadius: 12, padding: '17px 20px', cursor: 'pointer' }} onClick={showNewMessage}>
                <Image
                    width={36}
                    src={hand}
                    preview={false}
                />
                <Flex vertical>
                <Title level={5} style={{ margin: 0 }}>Send your first message!</Title>
                <Text>Send them a message!</Text>
              </Flex>
              <RightOutlined />
            </Flex>
          </Flex>
        </>}

      </Sider>
      }


      {selectedMessage &&
        <Content style={selectedMessageStyles}>
          <Flex vertical align="start" style={{ backgroundColor: '#FFFFFF', padding: isMobile ? '15px' : '20px 25px', borderBottom: '1px solid #EAECF0' }}>
            {isMobile && 
              <Button
                type="text"
                icon={<ArrowLeftOutlined style={{ color: '#101828' }}/>}
                onClick={handleClose}
                style={{ padding: 0, fontWeight: 600, marginBottom: isMobile ? 5 : 10, background: 'transparent' }}
              >
              Back
              </Button>
            }
            <Flex vertical style={{ width: '100%' }}>
              {selectedMessage.title !== selectedMessage?.user_phone && selectedMessage?.type === "individual" ? (
                <Flex horizontal gap="middle" align="center">
                  <AvatarImage title={selectedMessage.title} />
                  <Flex vertical>
                    <Title level={4} style={{ marginBottom: 0, marginTop: 0, fontSize: '18px', color: '#101828' }}>
                      {selectedMessage.title}
                    </Title>
                    {userInfo && <Text>{userInfo?.gender} | {calculateAge(userInfo?.dob)} | {userInfo?.city}</Text>}
                    
                  </Flex>
                  {selectedMessage.title !== selectedMessage?.user_phone && (
                    <Flex align="center" onClick={getUserDetails} style={{ cursor: 'pointer', marginLeft: 'auto' }}>
                      {!isMobile == true && <Text style={{ color: '#475467', fontWeight: 600 }}>Details</Text>}
                      <Image
                        width={20}
                        src={ArrowRight}
                        preview={false}
                      />
                    </Flex>
                  )}
                </Flex>
              ) : (
                <span>
                  <Flex horizontal="true" gap="middle" align="center">
                    <Avatar size={48} style={{ backgroundColor: '#7F56D9', padding: 10 }} src={<img src={GroupUserIcon} />} />
                    <Flex vertical>
                      <Title level={4} style={{ marginBottom: 0, marginTop: 0, fontSize: '18px', color: '#101828' }}>
                        {selectedMessage.title}
                      </Title>
                    </Flex>
                  </Flex>
                </span>
              )}
            </Flex>
          </Flex>

      {selectedMessage && selectedMessage?.type==="individual" ?
          <>

            <div className='message-box' style={{ backgroundColor: '#F9FAFB', padding: '20px', flex: 1, overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: 'unset' }}>
              <Spin spinning={isHistoryLoading}>
                  {chatHistory && chatHistory.map((message,index) => ( 
                     <Flex vertical key={message.id} style={{ marginBottom: '10px' }}>
                        {shouldShowDate(index, message.updatedAt) && (
                          <Text style={{ textAlign: 'center', fontWeight: 'bold', margin: '10px 0' }}>
                            {formatDate(message.updatedAt)}
                          </Text>
                        )}
                          {message?.direction===0?
                            <Flex justify="flex-start" className='fan-message'>
                              <PrintMessage message={message?.message} type={message?.type} userType="you" priceInput={message?.price}/>
                            </Flex>:
                             <Flex className="message-response" justify="flex-end">
                                <PrintMessage message={message?.message} type={message?.type} userType="me" priceInput={message?.price}/>
                              </Flex>}
                     </Flex>
                  ))}
                   <div ref={messagesEndRef} />
              </Spin>
            </div>
            <Flex vertical style={{ padding: isMobile ? '8px 10px 5px 10px' : '8px 20px 5px 20px', borderTop: '1px solid #EAECF0' }}>
              <Spin spinning={isMessageSending}>
                <Form form={form} onFinish={sendMessageHandler} className="messagebox-form">
                  <Form.Item
                    name="messagebox"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    rules={[
                      {
                        validator: (_, value) =>
                          chatImages.length < 1 && !message
                            ? Promise.reject(new Error('Please input your message!'))
                            : Promise.resolve(),
                      },
                    ]}
                    className="custom-form-item"
                  >   
                    <ChatInputNewMessage 
                      ref={chatInputRef}
                      sendMessageHandler={sendMessageHandler} 
                      setChatImages={setChatImages} 
                      setSelectedPrice={setSelectedPrice} 
                      onInputChange={handleInputChange} 
                      setPriceButton={setInlinePrice} 
                      setSendButton={setInlineButton} 
                      setLoading={isMessageSending} 
                      basePrice={basePrice} 
                      audioEnable={audioEnable} 
                    />
                  </Form.Item>
                  {/*<Button loading={isMessageSending} type="primary" htmlType='submit' size="large" style={{ fontWeight: 600 }}>Send</Button>*/}
                </Form>
              </Spin>
            </Flex>
          </>
          :
          <>
            <div className='message-box' style={{ padding: '20px', flex: 1, overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: 'unset' }}>
              <Spin spinning={isHistoryLoading}>
                  {chatHistory && chatHistory.map((message,index) => ( 
                     <Flex vertical key={message.id} style={{ marginBottom: '10px' }}>
                        {shouldShowDate(index, message.updatedAt) && (
                          <Text style={{ textAlign: 'center', fontWeight: 'bold', margin: '10px 0' }}>
                            {formatDate(message.updatedAt)}
                          </Text>
                        )}
                     
                          {message?.direction===0?
                            <Flex justify="flex-start" className='fan-message'>
                                <Flex>
                                  <Input type="checkbox" style={{ marginRight: '10px', width: '16px', marginTop: '24px' }}  onChange={(e) => handleCheckboxChange(e, message?.user_phone|| index)} />
                                </Flex>
                                <Flex vertical>
                                    {message?.user_name && <Text block style={{ marginBottom: 5 }}>{message.user_name}</Text>}
                                    <Flex horizontal gap={10}>
                                      <Text style={{ fontSize: '17px', backgroundColor: '#E5E5EA', padding: '6px 14px', color: '#000000', borderRadius: 18}}>
                                        <div dangerouslySetInnerHTML={{ __html: message?.message?.replace(/<p><br><\/p>/g, '') }} style={{ whiteSpace: 'pre-wrap' }} />
                                      </Text>
                                      <RightOutlined/>
                                    </Flex>
                                </Flex>
                            </Flex>
                            :
                            <Flex className="message-response" justify="flex-end">
                              {message?.price && message.price > 0 ? 
                                <Flex vertical style={{ maxWidth: '230px', width: '100%', textAlign: 'center', marginLeft: 'auto' }}>
                                  <Flex vertical justify="end" align="center" style={{ backgroundColor: '#4D5159', padding: 25, borderRadius: '12px 12px 0px 0px', width: '100%', minHeight: '236px' }}>
                                   <Image
                                      width={56}
                                      src={UnlockIcon}
                                      preview={false}
                                      style={{ marginBottom: 20 }}
                                    />
                                    <Button size="large" style={{ backgroundColor: '#ffffff', fontWeight: 600 }}>Unlock for ${message.price}</Button>
                                  </Flex>
                                  <Flex vertical justify="center" align="start" style={{ backgroundColor: '#E5E5EA', padding: '8px 15px', borderRadius: '0px 0px 12px 12px', width: '100%' }}>
                                    <Text style={{ color: '#101828', fontSize: '16px' }}>See content to unlock</Text>
                                    <Text style={{ fontSize: '12px' }}>blonde.social</Text>
                                  </Flex>
                                </Flex>
                                :
                                <Text style={{ fontSize: '17px', backgroundColor: '#7F56D9', padding: '6px 14px', color: '#ffffff', borderRadius: 18, maxWidth: '75%' }}><div dangerouslySetInnerHTML={{ __html: message?.message?.replace(/<p><br><\/p>/g, '') }} style={{ whiteSpace: 'pre-wrap' }} /></Text>}
                            </Flex>
                          }
                     </Flex>
                  ))}
                   <div ref={messagesEndRef} />
              </Spin>
            </div>
              <Flex horizontal justify="end" style={{ padding: '12px 20px', borderTop: '1px solid #EAECF0' }}>
                <Button loading={isMessageSending} type="primary" onClick={replyToAll} size="large" style={{ fontWeight: 600 }}>Reply to all</Button>
              </Flex>
          </>
          }
        </Content>
      }

      {isNewMessageVisible &&
        <NewMessage basePrice={basePrice} setSelectedPrice={setSelectedPrice} visible={isNewMessageVisible} handleCancel={handleNewMessageClose} onClose={onClose} open={open} />
      }

      {isReplyMessageVisible &&
        <ReplyMessage setSelectedPrice={setSelectedPrice} visible={isReplyMessageVisible} handleCancel={handleReplyMessageClose} replyTo={selectedPhoneNumbers} selectedMessage={selectedMessage} />
      }
      
      {selectedFan && <SidebarFan selectedUser={selectedMessage} selectedFan={selectedFan} setSelectedFan={setSelectedFan} setIsLoading={setIsHistoryLoading} />}
    </Layout>
  );
};

export default Audience;
