import React, { useState } from 'react';
import { Layout, Image } from 'antd';
import { CreditCardOutlined, LogoutOutlined, PictureOutlined, SettingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import RequestIcon from '../images/SidebarIcons/request-icon.svg';
import CardIcon from '../images/SidebarIcons/credit-card-icon.svg';
import PictureIcon from '../images/SidebarIcons/my-collection-icon.svg';
import SettingsIcon from '../images/SidebarIcons/settings-icon.svg';

import AudienceHeaderCreatorInfo from '../components/Sidebar/AudienceHeaderCreatorInfo';
import FooterComponent from '../components/Footer';
import { useLocation } from 'react-router-dom';

const { Content } = Layout;

const AudienceNavTrans = ({ children }) => {

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  
  const menuItems = [
    {
      key: '1',
      icon: <Image src={CardIcon} preview={false} width={25} />,
      label: 'Billing',
      path: '/audience/billing',
    },
    {
      key: '2',
      icon: <Image src={RequestIcon} preview={false} width={25} />,
      label: 'My Requests',
      path: '/audience/myrequests',
    },
    {
      key: '3',
      icon: <Image src={PictureIcon} preview={false} width={25} />,
      label: 'My Collections',
      path: '/audience/my-collection',
    },
    // {
    //   key: '4',
    //   icon: <QuestionCircleOutlined style={{ fontSize: 24, width: 25, minHeight: 40 }} />,
    //   label: 'FAQ',
    //   path: '/audience/faq',
    // },
    // {
    //   key: '5',
    //   icon: <Image src={SettingsIcon} preview={false} width={25} />,
    //   label: 'Settings',
    //   path: '/audience/settings',
    // }
  ];

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Layout style={{ minHeight: '100vh', background: '#000000' }}>
      <AudienceHeaderCreatorInfo menuItems={menuItems} currentPath={currentPath} collapsed={collapsed} setCollapsed={setCollapsed} toggleCollapsed={toggleCollapsed} />
      <Layout className="site-layout">
        <Content>
          <div className="site-layout-background" style={{ padding: 0, minHeight: 360 }}>
            {children}
          </div>
        </Content>
        {/*<div style={{ paddingTop: 20, paddingBottom: 10 }}>
          <FooterComponent />
        </div>*/}
      </Layout>
    </Layout>
  );
};

export default AudienceNavTrans;
