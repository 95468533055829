import React, { useState ,useContext,useEffect} from "react";
import NewMessage from "./NewMessage";
import { Modal } from "antd";
import ExclamationCircleFilled from '@ant-design/icons';
import { UserContext } from "../../contexts/UserContext";
import { get } from "../../utils/api";
const { confirm } = Modal;
const FilteredNewMessage=({
    open,
    setOpen,
    filters
})=>{
    const {user}  = useContext(UserContext);
    const [isNewMessageVisible, setIsNewMessageVisible] = useState(false);  
    const [basePrice,setBasePrice]=useState(0.99);
    const [selectedPrice, setSelectedPrice] = useState(null);
   
 
     const handleNewMessageClose = () => {
        confirm({
          title: 'Are you sure you want to leave?',
          icon: <ExclamationCircleFilled style={{ fontSize: 35, color: '#7F56D9', marginBottom: 10 }} />,
          content: 'This message will be closed.',
          okText: 'Leave',
          okButtonProps: {
            style: {
              backgroundColor: '#6941C6',
              borderColor: '#6941C6',
              fontWeight: 'bold',
              color: 'white'       
            },
            className: 'custom-leave-button'
          },
          cancelButtonProps: {
            style: {
              backgroundColor: 'white',
              borderColor: '#d9d9d9',
              fontWeight: 'bold',
              color: '#475467'
            },
            className: 'custom-cancel-button'
          },
          okType: 'danger',
          onOk() {
            setIsNewMessageVisible(false);
            setOpen(false);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };

      const onClose = () => {
        setIsNewMessageVisible(false);
        setOpen(false);
      };

    const initSettings = async (id) => {
        try {
          const response = await get(`/creator/settings/${id}`);
          if (response && response.data) {
            const {basePrice}=response.data;
            if(basePrice){
              setBasePrice(basePrice);
            }
          }
        } catch (error) {
          console.log('error', error);
        } 
      };
      useEffect(()=>{
        initSettings(user?.id);
      },[user]);

    return (<>
        <NewMessage filters={filters} basePrice={basePrice} setSelectedPrice={setSelectedPrice}  handleCancel={handleNewMessageClose} onClose={onClose} open={open} />
    </>);
}

export default FilteredNewMessage