import React,{useEffect,useState} from 'react';
import { Layout, List, Flex, Button, Typography, Image, Spin } from 'antd';
import { get } from '../../../utils/api';
import { formatPrice } from '../../../utils/utils';
import ArrowRightIcon from "../../../images/arrow-right.svg";
import FanTransactionRequest from '../../../components/GlobalComponents/FanTransactionRequest';
import { Link } from 'react-router-dom';
const { Title, Text } = Typography;

const MyRequests = () => {
    const [myrequests,setMyrequests]=useState([]);
    const [isLoading,setIsLoading]=useState(false);

    const [isTransactionHistory, setTransactionHistory] = useState(false);
    const [isTransactionData, setTransactionData] = useState(null);

    useEffect(()=>{
      const init=async()=>{
        setIsLoading(true);
        try{
          const response=await get('user/myrequests');
          if(response.status){
            const {rows,count}=response?.data;
            setMyrequests(rows);
          }
        }catch(error){
          console.log("error",error);
        }finally{
          setIsLoading(false);
        }
      }
      init();
    },[]);

    const handleTransaction = (item) => {
      console.log(item, 'item');
      setTransactionHistory(true);
      setTransactionData(item);
    }

    const handleTransactionClose = () => {
      setTransactionHistory(false);
      setTransactionData(null);
    };

  return (
    <Layout>
      {!isTransactionHistory == true &&
        <Flex vertical style={{ padding: 20 }}>
          <Flex vertical align="start">
            <Title level={3} style={{ marginTop: 0, marginBottom: 15 }}>My Requests</Title>
          </Flex>
          <Spin spinning={isLoading}>
            <List
              dataSource={myrequests}
              renderItem={item => (
                <List.Item style={{ gap: 10 }}>
                  <List.Item.Meta
                    title={<Text strong style={{ color: '#101828', fontSize: '18px' }}>{item?.creator?.first_name} {item?.creator?.last_name}</Text>}
                    description={<Text>Private Request</Text>}
                  />
                  <Flex vertical style={{ textAlign: 'right' }}>
                    <Text strong style={{ fontSize: '20px', color: '#000000' }}>{formatPrice(item?.price||0)}</Text>
                    <span>
                    {item.status === 0 ? (
                      <Text type="secondary" style={{ color: '#344054', borderRadius: 6, padding: '3px 8px', fontSize: '12px', fontWeight: 500, border: '1px solid #D0D5DD', textTransform: 'capitalize' }}>Pending</Text>
                    ) : item.status === 1 ?(
                      <Text type="secondary" style={{ color: '#344054', borderRadius: 6, padding: '3px 8px', fontSize: '12px', fontWeight: 500, border: '1px solid #D0D5DD', textTransform: 'capitalize' }}>Approved</Text>
                    ) : (
                      <Text type="secondary" style={{ color: '#344054', borderRadius: 6, padding: '3px 8px', fontSize: '12px', fontWeight: 500, border: '1px solid #D0D5DD', textTransform: 'capitalize' }}>Decline</Text>
                    )}
                    </span>
                  </Flex>
                  <Link to={`/audience/myrequests/${btoa(item?.id)}`}><Image src={ArrowRightIcon} preview={false} /></Link>
                </List.Item>
              
              )}
            />
          </Spin>
        </Flex>
      }

      {isTransactionHistory &&
        <FanTransactionRequest isTransactionData={isTransactionData} handleTransactionClose={handleTransactionClose} /> 
      }

    </Layout>
  );
};

export default MyRequests;
