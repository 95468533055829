import React, { useState, useRef, useMemo, useEffect,useContext } from 'react';
import { Layout, Card, List, Checkbox, Modal, Typography, Breadcrumb, Dropdown, Image, Button, Tabs, DatePicker, Table, Input, Row, Col, Flex, Radio, Divider, Avatar, Form, Spin, message} from 'antd';
import { ArrowUpOutlined, RightOutlined, ExclamationCircleFilled, BarChartOutlined, FilterOutlined, EditOutlined, SearchOutlined, ArrowLeftOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext.js';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Analytics from '../../Graph/Analytics';
import dataSet  from "../../Graph/data.js";
import DropdownSelector from "../../Graph/menu.js";

import FilterMenu from "../FilterMenu.js";

import SidebarFan from '../../../pages/Communities/SidebarFan';
import SelectMessage from '../../Communities/SelectMessage';
import SendMessage from '../../Communities/SendMessage';

import { get, post } from '../../../utils/api.js';
import useScreenSize from '../../../utils/useScreenSize';
import { calculateAge,formatPrice,formatNumber } from '../../../utils/utils.js';
import AvatarImage from '../../AvatarImage.js';

import CalendarIcon from '../../../images/calendar.png';
import EditIconN from '../../../images/edit-icon-n.svg';
import EditIcon from "../../../images/edit-icon-n-grey.svg"
import EditIconWhite from "../../../images/edit-icon-white.svg"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const { Content } = Layout;
const { Title: AntTitle, Text } = Typography;
const { TabPane } = Tabs;
const { Search } = Input;
const { confirm } = Modal;

const initialChartData = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  datasets: [
    {
      label: 'Total Fans',
      data: [0, 400, 400, 600, 800, 1000],
      fill: false,
      backgroundColor: '#6941C6',
      borderColor: '#6941C6',
    },
  ],
};

const TotalFansDetails = () => {
  const [selectedRange, setSelectedRange] = useState('12 months');
  const [graphData, setGraphData] = useState(initialChartData);
  const chartRef = useRef(null);
  const [data, setData] = useState([]);
  const [allFansData, setAllFansData] = useState([]);
  const [topFansData, setTopFansData] = useState([]);
  const {user}  = useContext(UserContext);
	const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
	const [topPagination, setTopPagination] = useState({ current: 1, pageSize: 10, total: 0 });
	const [loading, setLoading] = useState(false);
	const [totalUsers, setTotalUsers] = useState(0);
  const [growth, setGrowth] = useState(0);
  const [growthString, setGrowthString] = useState(null);
  const [weeks, setweeks] = useState({});
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [selectedFan, setSelectedFan] = useState(null);
  const [selectedUser,setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReload, setIsReload] = useState(false);

  const [isHistoryLoading, setIsHistoryLoading] = useState(false); 
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedTopFanMessage, setSelectedTopFanMessage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [userInfo,setUserInfo]= useState(null);
  const [openSendMessage, setSendMessage] = useState (false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [isNewMessageVisible, setIsNewMessageVisible] = useState(false);  
  const [open, setOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});  

  useEffect(()=>{
    fetchUsers({filters:selectedFilters});
  },[selectedFilters]);
  

  const fetchUsers = async (params = {}) => {
		setLoading(true);
		try {
		  const response = await get('creator/allFans', {
			_page: params.pagination?.current || 1,
			_limit: params.pagination?.pageSize || 10,
			q: params?.searchTerm ||'',
      filters: params?.filters || {},
		  });
		  setAllFansData(response.data);
		  setPagination({
			...params.pagination,
			total: response.pagination.total,
		  });
		} catch (error) {
		  console.error('Error fetching users:', error);
		} finally {
		  setLoading(false);
		}
	  };

  
    const fetchChartData=async(keyName="Last_12_months")=>{
      setLoading(true);
      try{
        const response = await get('creator/fansChart', {dataKey:keyName});
        if(response && response.status){
            setData(response?.data);
            setweeks(response?.weeks);
        }
      }catch (error) {
        console.error('Error fetching users:', error);
      }finally {
        setLoading(false);
      }
    }

    const compareWeeks=(data)=> {
      const { currentWeekFans, previousWeekFans } = data;
      let ctl=0;
      let changeStatus;
      // Calculate the percentage change
      const percentageChange = ((currentWeekFans - previousWeekFans) / previousWeekFans) * 100;
      
      if(percentageChange===Infinity){
        ctl=100;
      }else{
         ctl = isNaN(percentageChange) ? 0 : percentageChange;
        if (percentageChange >=0) {
            changeStatus = 'increased';
        } else if (percentageChange < 0) {
            changeStatus = 'decreased';
        } 
      }
     return {ctl,changeStatus};
  }

    useEffect(()=>{
      if(weeks){
        const p=compareWeeks(weeks);
        setGrowth(p?.ctl);
        setGrowthString(p?.changeStatus);
      }
   },[weeks]);

    const fetchTopUsers = async (params = {}) => {
      setLoading(true);
      try {
        const response = await get('creator/topFans', {
        _page: params.pagination?.current || 1,
        _limit: params.pagination?.pageSize || 10,
        q: params?.searchTerm ||'',
        });
        setTopFansData(response.data);
        setTopPagination({
        ...params.pagination,
        total: response.pagination.total,
        });
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
      };

	  const handleTableChange = (pagination) => {
		fetchUsers({ pagination });
	  };

    const handleTopTableChange = (pagination) => {
      fetchTopUsers({ pagination });
      };

	 useEffect(()=>{
		fetchUsers();
    fetchTopUsers();
    fetchChartData();
	 },[]);
   useEffect(()=>{
    const p=(pagination?.total>0)?pagination?.total:0;
    setTotalUsers(p);
   },[pagination]);

  const fetchCustomData =async (key) => {
    await fetchChartData(key);
  };

  const handleSearch = (value) => {
    fetchUsers({searchTerm:value});
  };
  const searchChange=(elem)=>{
    try{
      const val=elem.target.value;
      if(val?.length===0){
        fetchUsers();
      }
    }catch(error){
      console.log(error);
    }
  }

  const handleTopSearch = (value) => {
    fetchTopUsers({searchTerm:value});
  };
  const searchTopChange=(elem)=>{
    try{
      const val=elem.target.value;
      if(val?.length===0){
        fetchTopUsers();
      }
    }catch(error){
      console.log(error);
    }
  }

  const handleRangeChange = (range) => {
    setSelectedRange(range);
  };

  const handleDateChange = (date) => {
    // Update graph data based on the selected date
  };

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    return () => {
      if (chartRef.current && chartRef.current.chartInstance) {
        chartRef.current.chartInstance.destroy();
      }
    };
  }, []);


  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          display: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };  

  const columnsAllFan = [
    {
      title: 'Name & Number',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => `${a?.info?.first_name} ${a?.info?.last_name}`.localeCompare(`${b?.info?.first_name} ${b?.info?.last_name}`),
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        const imageId = record && record?.info?.uploadedIds && record?.info?.uploadedIds.length > 0 ? record?.info?.uploadedIds[0] : null;
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
               <AvatarImage size={42} font={16} imageId={imageId} title={`${record?.info?.first_name} ${record?.info?.last_name}`}   />
            <div>
              <AntTitle level={5} style={{ color: '#101828', fontSize: '14px', marginTop: 0, marginBottom: 0, textTransform: 'capitalize' }}>{record?.info?.first_name} {record?.info?.last_name}</AntTitle>
              <div style={{ fontSize: '14px', color: '#101828' }}>+1{record?.info?.phone_number}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      sorter: (a, b) => a?.info?.gender.localeCompare(b?.info?.gender),
      responsive: ['md'],
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>{record?.info?.gender}</div>
        );
      }
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      sorter: (a, b) => calculateAge(a?.info?.dob) - calculateAge(b?.info?.dob),
      responsive: ['md'],
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        const value = calculateAge(record?.info?.dob);
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>{value}</div>
        );
      }
    },
    {
      title: 'Location',
      dataIndex: 'location',
      responsive: ['md'],
      key: 'location',
      sorter: (a, b) => a?.info?.city.localeCompare(b?.info?.city),
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>{record?.info?.city}</div>
        );
      }
    },
    {
      title: 'Interactions',
      dataIndex: 'interactions',
      key: 'interactions',
      sorter: (a, b) => {
        const totalAmountA = a?.revenueInfo.reduce((acc, item) => acc + (item.amount ? parseFloat(item.amount) : 0), 0);
        const totalAmountB = b?.revenueInfo.reduce((acc, item) => acc + (item.amount ? parseFloat(item.amount) : 0), 0);
        return totalAmountA - totalAmountB;
      },
      responsive: ['md'],
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        const totalAmount = record?.revenueInfo.reduce((acc, item)=>{
              if(item.amount){
                return  acc + parseFloat(item.amount);
              }else{
                return  acc + 0;
              }
        },0);
        const itemCount = record?.revenueInfo?.length || 0;
      
        return (
          <Flex vertical><span style={{ fontWeight: 500, color: '#101828' }}>{itemCount} Interactions</span> {formatPrice(totalAmount || 0)} Spent</Flex>
        );
      }
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => {
        return (
          <Flex gap={10} justify="flex-end">
            <Button type="text" onClick={() => handleSelectMessage(record)} icon={<Image src={EditIconN} preview={false} />}></Button>
            <Button 
              type="text" 
              icon={<RightOutlined />}
              onClick={() =>{
                    setSelectedFan(true);
                    const updatedObject = {
                      ...record,
                      user_phone: record.info.phone_number
                  };
                    setSelectedUser(updatedObject);
                  }}
            ></Button>
          </Flex>
        );
      }
    }
  ];

  const columnsTopFan = [
    {
      title: 'Name & Number',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => `${a.revenueUser?.first_name} ${a.revenueUser?.last_name}`.localeCompare(`${b.revenueUser?.first_name} ${b.revenueUser?.last_name}`),
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        const {revenueUser}=record;
        const imageId = revenueUser && revenueUser?.uploadedIds && revenueUser?.uploadedIds.length > 0 ? revenueUser?.uploadedIds[0] : null;
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
               <AvatarImage size={42} font={16} imageId={imageId} title={`${revenueUser?.first_name} ${revenueUser.last_name}`}   />
            <div>
              <AntTitle level={5} style={{ color: '#101828', fontSize: '14px', marginTop: 0, marginBottom: 0, textTransform: 'capitalize' }}>{revenueUser?.first_name} {revenueUser?.last_name}</AntTitle>
              <div style={{ fontSize: '14px', color: '#101828' }}>+1{revenueUser?.phone_number}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      sorter: (a, b) => a.revenueUser?.gender.localeCompare(b.revenueUser?.gender),
      responsive: ['md'],
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        const {revenueUser}=record;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>{revenueUser?.gender}</div>
        );
      }
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      sorter: (a, b) => calculateAge(a.revenueUser?.dob) - calculateAge(b.revenueUser?.dob),
      responsive: ['md'],
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        const {revenueUser}=record;
        const value = calculateAge(revenueUser?.dob);
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>{value}</div>
        );
      }
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      sorter: (a, b) => a.revenueUser?.city.localeCompare(b.revenueUser?.city),
      responsive: ['md'],
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        const {revenueUser}=record;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>{revenueUser?.city}</div>
        );
      }
    },
    {
      title: 'Interactions',
      dataIndex: 'interactions',
      key: 'interactions',
      sorter: (a, b) => a.totalAmount - b.totalAmount,
      responsive: ['md'],
      sortIcon: ({ sortOrder }) => {
        if (sortOrder === 'ascend') return <ArrowUpOutlined />;
        if (sortOrder === 'descend') return <ArrowDownOutlined />;
        return <span style={{ color: '#bfbfbf' }}><ArrowUpOutlined /></span>;
      },
      render: (text, record) => {
        return (
          <Flex vertical><span style={{ fontWeight: 500, color: '#101828' }}>{record?.itemCount} Interactions</span> {formatPrice(record?.totalAmount || 0)} Spent</Flex>
        );
      }
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => {
        return (
          <Flex gap={10} justify="flex-end">
            <Button type="text" onClick={() => handleSelectTopFanMessage(record)} icon={<Image src={EditIconN} preview={false} />}></Button>
            <Button 
              type="text" 
              icon={<RightOutlined />}
              onClick={() =>{
                    setSelectedFan(true);
                    const updatedObject = {
                      ...record,
                      user_phone: record.revenueUser.phone_number
                  };
                    setSelectedUser(updatedObject);
                  }}
            ></Button>
          </Flex>
        );
      }
    }
  ];


  const handleSelectMessage = async(record) => {    
    console.log(record, 'record')
    setSelectedMessage(record.info);
    setIsModalVisible(true);
    setIsHistoryLoading(true);
    try{
      const getUserInfo = await post(`creator/getUserInfo`, {
        phone: record?.info.phone_number
      });
      if (getUserInfo && getUserInfo.status) {
        setUserInfo(getUserInfo.data);
      } else {
        console.error('Information not available!');
      }

        const response = await post(`creator/chat`,{
          phn:record?.info.phone_number
        });
        if(response && response.status){
          setChatHistory(response?.data.reverse());
        }
    }catch(error){
      console.log("loading......");
    } finally {
      setIsHistoryLoading(false);
    }
  }

  const handleSelectTopFanMessage = async(record) => {    
    setSelectedMessage(record.revenueUser);
    setIsModalVisible(true);
    try{
      const getUserInfo = await post(`creator/getUserInfo`, {
        phone: record?.revenueUser.phone_number
      });
      if (getUserInfo && getUserInfo.status) {
        setUserInfo(getUserInfo.data);
      } else {
        console.error('Information not available!');
      }

        const response = await post(`creator/chat`,{
          phn:record?.revenueUser.phone_number
        });
        if(response && response.status){
          setChatHistory(response?.data.reverse());
        }
    }catch(error){
      console.log("loading......");
    } finally {
      setIsHistoryLoading(false);
    }
  }

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedMessage(null);
    setSelectedTopFanMessage(null);
    setIsHistoryLoading(false);
  };

  const reportContentStyle = useMemo(() => 
    isMobile ? { 
      padding: '20px 15px', 
    } : { 
      backgroundColor: '#ffffff',
      height: '100vh', 
      minHeight: '100vh',
      overflow: 'auto', 
      scrollbarWidth: 'thin', 
      scrollbarColor: 'unset', 
      padding: '30px',
    }
  , [isMobile]);


  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      
      setSelectedRowKeys(selectedRowKeys);
      if(selectedRows?.length>0){
        const phoneNumbers = selectedRows.map(user => ({
          phone_number: user.info.phone_number
        }));
        setSelectedItems(phoneNumbers)
      }
     
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  const handleSendMessage = () => {
    if(selectedItems?.length>0){
      setSendMessage(true);
      setOpen(true);
    }else{
      message.error('Please select at least one user to send a message.');
    }
   
  };

  const handleCloseMessage = () => {
    setSendMessage(false);
  };

  const onClose = () => {
    setSendMessage(false);
    setOpen(false);
    setSelectedItems([]);
    setSelectedRowKeys([]);
    
  };

  const handleNewMessageClose = () => {
    confirm({
      title: 'Are you sure you want to leave?',
      icon: <ExclamationCircleFilled style={{ fontSize: 35, color: '#7F56D9', marginBottom: 10 }} />,
      content: 'This message will be closed.',
      okText: 'Leave',
      okButtonProps: {
        style: {
          backgroundColor: '#6941C6',
          borderColor: '#6941C6',
          fontWeight: 'bold',
          color: 'white'       
        },
        className: 'custom-leave-button'
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'white',
          borderColor: '#d9d9d9',
          fontWeight: 'bold',
          color: '#475467'
        },
        className: 'custom-cancel-button'
      },
      okType: 'danger',
      onOk() {
        setIsNewMessageVisible(false);
        setOpen(false);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <Layout>
      <Content style={reportContentStyle}>
      <Spin spinning={loading}>

      {isMobile ?<div>
          <Button
            type="text"
            icon={<ArrowLeftOutlined style={{ color: '#101828' }}/>}
            onClick={handleClose}
            style={{ padding: 0, fontWeight: 600, marginBottom: 10, background: 'transparent' }}
          >Back</Button>
        </div> 
        : <>

       {user?.role==="Team"?
        <Breadcrumb className="breadcrumb-insights" separator={<RightOutlined />} style={{ marginBottom: 25 }}>
          <Breadcrumb.Item href="/team/insights">
            <BarChartOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/team/insights">
            <span>Audience Insights</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>Total Fans</span>
          </Breadcrumb.Item>
        </Breadcrumb>
        :<Breadcrumb className="breadcrumb-insights" separator={<RightOutlined />} style={{ marginBottom: 25 }}>
        <Breadcrumb.Item href="/insights">
          <BarChartOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/insights">
          <span>Audience Insights</span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>Total Fans</span>
        </Breadcrumb.Item>
      </Breadcrumb>}
    </>}
      
      <AntTitle level={2} style={{ marginBottom: 5, marginTop: 0 }}>Total Fans</AntTitle>
      <Text>View and see all your current members</Text>

      <Row className="button-date-group" gutter={16} style={{ margin: '20px 0 30px 0' }}>
        <Col>
          <DropdownSelector fetchCustomData={fetchCustomData} />
        </Col>
         <Col style={{ marginLeft: 'auto' }}>
          {/*<DatePicker placeholder="Select date" onChange={handleDateChange} />*/}
          {/*<DatePicker 
            placeholder="Select date" 
            onChange={handleDateChange}
            suffixIcon={<span style={{ position: 'absolute', left: '10px', zIndex: 10, top: 5 }}><Image src={CalendarIcon} preview={false} /></span>}
            style={{ 
              paddingLeft: '40px' // Make space for the icon 
            }}
          />*/}
        </Col> 
      </Row>

      <Flex horizontal="true" flex={1} style={ isMobile && { flexDirection: 'column' }}>
        <Flex vertical style={{ minWidth: 250, marginBottom: isMobile ? 20 : '' }}>
          <Text style={{ marginTop: 0, marginBottom: 15 }}>Total Fans</Text>
          <AntTitle level={2} style={{ marginTop: 0, marginBottom: 10, fontSize: '36px' }}>{formatNumber(parseFloat(totalUsers) || 0)}</AntTitle>
          <Text>
          {growthString=="increased"?<span style={{ color: '#079455' }}><ArrowUpOutlined />{Math.abs(growth).toFixed(2)}%</span> :<span style={{ color: 'red' }}><ArrowDownOutlined/>{Math.abs(growth).toFixed(2)}%</span> }
           vs last week</Text>
        </Flex>
        <Flex vertical flex={1}>
          {/*<Line ref={chartRef} data={graphData} options={chartOptions} />*/}
          <div className="analytics-graph">
            <Analytics data={data} />
          </div>
        </Flex>
      </Flex>

      <Divider />

      <Tabs className="insight-tabs" defaultActiveKey="1" style={{ marginTop: '20px' }}>
        <TabPane tab="All Fans" key="1">
          <Flex vertical={isMobile ? true : false} justify="space-between" align={isMobile ? "flex-start" : "flex-end"} style={{ marginBottom: 25 }}>
              {/*<Form.Item 
                label="Search fan"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                 <Search
                    placeholder="Search by name"
                    allowClear
                    onSearch={handleSearch}
                    enterButton
                    onChange={searchChange}
                  />
              </Form.Item>*/}
              <Flex vertical={isMobile ? true : false} align={isMobile ? "normal" : "flex-end"} gap={12} style={{ marginBottom: isMobile ? 12 : 0, width: isMobile? '100%':''}}>
                <Form.Item 
                  label="Search fan"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ marginBottom: 0 }}
                >
                  <Search 
                    className="custom-form-search-item search-no-icon"
                    placeholder="Search" 
                    onSearch={handleSearch}
                    enterButton
                    onChange={searchChange}
                    prefix={<SearchOutlined />} 
                    style={{ marginBottom: 0 }} 
                    allowClear
                  />
                </Form.Item>
                <FilterMenu setFilter={setSelectedFilters}/>
                {/*<Button icon={<FilterOutlined />} size="large" type="default" style={{ fontWeight: 600 }}>Filters</Button>*/}
              </Flex>

              <Button block={isMobile ? 'block':''} onClick={handleSendMessage} disabled={selectedRowKeys.length === 0} icon={<Image src={selectedRowKeys.length === 0 ? EditIcon : EditIconWhite} preview={false} />} size="large" type="primary" style={{ fontWeight: 600 }}>New Message</Button>
          </Flex>
          <div className="insight-table">
            <Table 
              rowSelection={rowSelection}
              columns={columnsAllFan} 
              dataSource={allFansData} 
              pagination={pagination}
              rowKey={(record) => record.user_id}
              loading={loading}
              onChange={handleTableChange}
            />
          </div>

        </TabPane>
        <TabPane tab="Top Fans" key="2">
          <Flex vertical={isMobile ? true : false} justify="space-between" align={isMobile ? "flex-start" : "flex-end"} style={{ marginBottom: 25 }}>
              {/*<Form.Item 
                label="Search fan"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                 <Search
                    placeholder="Search by name"
                    allowClear
                    onSearch={handleSearch}
                    enterButton
                    onChange={searchChange}
                  />
              </Form.Item>*/}
              <Flex vertical={isMobile ? true : false} align={isMobile ? "normal" : "flex-end"} gap={12} style={{ marginBottom: isMobile ? 12 : 0, width: isMobile? '100%':''}}>
              <Form.Item 
                  label="Search fan"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  style={{ marginBottom: 0 }}
                >
                  <Search 
                    className="custom-form-search-item search-no-icon"
                    placeholder="Search" 
                    onSearch={handleSearch}
                    enterButton
                    onChange={searchChange}
                    prefix={<SearchOutlined />} 
                    style={{ marginBottom: 0 }} 
                    allowClear
                  />
                </Form.Item>
                <FilterMenu />
               {/* <Dropdown overlay={filterMenu} trigger={['click']} open={dropdownVisible} onOpenChange={setDropdownVisible}>
                  <Flex className="filter-button">
                    <Button icon={<FilterOutlined />} size="large" type="default" style={{ fontWeight: 600 }}>Filters</Button>
                  </Flex>
                </Dropdown>*/}
              </Flex>

              <Button block={isMobile ? 'block':''} onClick={handleSendMessage} disabled={selectedRowKeys.length === 0} icon={<Image src={selectedRowKeys.length === 0 ? EditIcon : EditIconWhite} preview={false} />} size="large" type="primary" style={{ fontWeight: 600 }}>New Message</Button>

              {/*<Button disabled icon={<EditOutlined />} size="large" type="primary" style={{ fontWeight: 600 }}>New Message</Button>*/}
          </Flex>
          <div className="insight-table">
            <Table 
              rowSelection={rowSelection}
              columns={columnsTopFan} 
              dataSource={topFansData} 
              pagination={topPagination} 
              rowKey={(record) => record.user_id}
              loading={loading}
              onChange={handleTopTableChange}
            />
          </div>
        </TabPane>
      </Tabs>
      </Spin>
      </Content>


      {openSendMessage &&
        <SendMessage
          selectedItems={selectedItems} 
          setSelectedItems={setSelectedItems} 
          openSendMessage={openSendMessage} 
          handleCloseMessage={handleCloseMessage} 
          setSelectAll={setSelectAll}
          handleCancel={handleNewMessageClose}
          onClose={onClose} 
          open={open}
        /> 
      }

      {selectedFan &&
        <SidebarFan
          selectedUser={selectedUser} 
          selectedFan={selectedFan} 
          setSelectedFan={setSelectedFan} 
          setIsLoading={setIsLoading} 
          setIsReload={setIsReload} 
        />
      }

      {selectedMessage &&
        <Modal
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
          width={800}
          className="select-message-modal"
        >
          <SelectMessage selectedMessage={selectedMessage} isHistoryLoading={isHistoryLoading} chatHistory={chatHistory} setChatHistory={setChatHistory} />
        </Modal>
      }

    </Layout>
  );
};

export default TotalFansDetails;
