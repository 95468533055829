import React,{useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import { Layout, Flex, Image, Button, Spin } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import SmirkingIcon from '../../../images/smirking-face.png';
import './FanSignup.css';
import { get } from '../../../utils/api';
import { useParams } from 'react-router-dom';

const { Content } = Layout;

const FanSignupUnlock = () => {

    const [data,setData]=useState(null);
    const [isLoading,setIsLoading]=useState(false);
    const [creatorData,setCreatorData]=useState(null);
    const [privateRequestEnabled,setPrivateRequestEnabled]=useState(false);
    const { smsId,phone } = useParams();
    useEffect(()=>{
        const init=async()=>{
            setIsLoading(true);
            try{
                const response = await get(`user/paidContent/${smsId}`);
               
                if (response && response.status) {
                   
                    setData(response?.data.postData);
                    if(response && response?.data?.creatorData){
                        setCreatorData(response?.data?.creatorData);
                        setPrivateRequestEnabled(response?.data?.privateRequestEnabled);
                    }
                }
            }catch(error){
                console.log(error);
            }finally{
                setIsLoading(false); 
            }
        }
        init();
    },[smsId]);

    return (
        <Layout className="unclock-main" style={{ height: '100vh', backgroundColor: '#000000' }}>
            <Spin spinning={isLoading}>
            <Content style={{ flex: 1 }}>
                <Flex vertical style={{ margin: '0 auto', borderRadius: 0, height: '100%' }}>
                    <Flex className='unlock-wrapper'>
                        <div dangerouslySetInnerHTML={{ __html: data?.message }} />
                    </Flex>
                    <Flex gap={15} justify="center" className="button-group" style={{ padding: 12 }}>
                        {privateRequestEnabled && 
                        <Link style={{ display: 'contents' }} to={`/audience/private-requests/${smsId}`}>
                            <Button size="large" style={{ maxWidth: '230px', width: '100%', fontWeight: 600, fontSize: '14px' }}>Private request</Button>
                        </Link>}
                        <Link style={{ display: 'contents' }} to={`sms:${data?.creator_phone}`}>
                            <Button type="primary" size="large" style={{ maxWidth: '230px', width: '100%', fontWeight: 600, fontSize: '14px' }}>Reply</Button>
                        </Link>
                    </Flex>
                </Flex>
            </Content>
            </Spin>
        </Layout>
    );
}

export default FanSignupUnlock;